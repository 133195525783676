import gql from "graphql-tag";

const ADD_OR_UPDATE_STORE = gql`
  mutation addOrUpdateStore(
    $countryCode: String!
    $languageCode: String!
    $taxPercentage: Int!
    $taxPercentageDecimals: Int!
    $currencyUnit: String!
  ) {
    addOrUpdateStore(
      store: {
        countryCode: $countryCode
        languageCode: $languageCode
        taxPercentage: $taxPercentage
        taxPercentageDecimals: $taxPercentageDecimals
        currencyUnit: $currencyUnit
      }
    ) {
      countryCode
      languageCode
      taxPercentage
      tax
      taxPercentageDecimals
      currencyUnit
    }
  }
`;

export default ADD_OR_UPDATE_STORE;
