import React from "react";
import SecondarySidebar from "components/Sidebar/SecondarySidebar";
import editShipping from "helpers/getEditShipping";
import getStockVersion from "helpers/getStockVersion";

export default () => (
  <SecondarySidebar
    sections={[
      {
        title: "Stores",
        layout: "/admin",
        routes: [
          { name: "+ Add", path: "/add-store" },
          { name: "Show all", path: "/stores" },
        ],
      },
      ...(getStockVersion() !== "v1"
        ? [
          {
            title: "Inventories",
            layout: "/admin",
            routes: [{ name: "Show all", path: "/inventories" }],
          },
        ]
        : []),
      {
        title: "Shipping options",
        layout: "/admin",
        routes: editShipping()
          ? [
            { name: "+ Add", path: "/add-shipping-option" },
            { name: "Show all", path: "/shipping-options" },
          ]
          : [{ name: "Show all", path: "/shipping-options" }],
      },
      {
        title: "Shipping rules",
        layout: "/admin",
        routes: [
          { name: "+ Add", path: "/add-shipping-rule" },
          { name: "Show all", path: "/shipping-rules" },
        ],
      },
      {
        title: "Integrations",
        layout: "/admin",
        routes: [
          { name: "Fortnox", path: "/fortnox" },
          { name: "Klaviyo", path: "/klaviyo" },
        ],
      },
      {
        title: "Webhooks",
        layout: "/admin",
        routes: getStockVersion() !== "v1"
          ? [
            { name: "Order", path: "/webhook-order" },
            { name: "Order Shipping", path: "/webhook-shipping" },
            { name: "Product", path: "/webhook-product" },
            { name: "Out of stock", path: "/webhook-outOfStock" },
          ]
          : [
            { name: "Order", path: "/webhook-order" },
            { name: "Order Shipping", path: "/webhook-shipping" },
            { name: "Product", path: "/webhook-product" },
          ],
      },
    ]}
  />
);
