import React from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import ALL_STORES from "graphql/Store/AllStores";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import getFullCountryName from "helpers/getFullCountryName";
import FlagIcon from "components/Ui/FlagIcon";
import MultiSelect from "components/Ui/MultiSelect";
import { ErrorMessage } from "@hookform/error-message";
import { alerts } from "variables/colors";


const StoreSelector = styled(MultiSelect)`
  font-size: 1.3rem;
  margin: 0;
  min-width: 20rem;
  width: 100%;
  height: 4rem;
  padding-top: 0;

  ${MEDIA_MIN_LARGE} {
    margin: 0 0 0 1rem;
    padding-top: 0;
    width: auto;
  }

  .multi-select {
    --rmsc-height: 3.8rem;
  }
`;

const ErrorText = styled.span`
  width: 100%;
  color: ${alerts.error.highlight};
  position: relative;
  letter-spacing: 0;
  bottom: 0;
`;

const Label = styled.div`
  display: inline-flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
`;

export default ({ selectedStores, setSelectedStores, name, errors = false, ...props }) => {

  return (
    <>
      <Query query={ALL_STORES} variables={{ from: 0, size: 300 }}>
        {({ data }) => {
          if (!data) return null;
          const stores = data.allStores.stores
            .slice()
            .sort((a, b) => a.countryCode.localeCompare(b.countryCode))
            .map((store) => ({
              value: store.countryCode,
              label: getFullCountryName(store.countryCode),
              currencyUnit: store.currencyUnit,
              tax: store.tax,
              languageCode: store.languageCode
            }));


          const valueRenderer = (selected, options) => {
            if (selected.length) {
              if (selected.length === options.length) {
                return "All stores selected";
              }
              return `${selected.length} store${selected.length !== 1 ? "s" : ""} selected`;
            }
            return null;
          };

          const itemRenderer = ({ checked, option, onClick }) => (
            <Label>
              <input type="checkbox" onChange={onClick} checked={checked} tabIndex="-1" />
              <span>
                <FlagIcon countryCode={option.value} />
                <span>{option.label}</span>
              </span>
            </Label>
          );

          return (
            <>
              <StoreSelector
                options={stores}
                selected={selectedStores}
                setSelected={setSelectedStores}
                valueRenderer={valueRenderer}
                ItemRenderer={itemRenderer}
                withGrouping
                overrideStrings={{
                  selectSomeItems: "No stores selected",
                  selectAll: `Select all ${stores.length} stores`,
                }}
                enableGroupCheckBox="true"
                {...props}
              />
              {errors && (
                <ErrorText>
                  <ErrorMessage errors={errors} name={name} />
                </ErrorText>
              )}
            </>
          );
        }}
      </Query>
    </>
  );
};
