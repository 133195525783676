import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Mutation, Query } from "@apollo/client/react/components";
import { Controller, useForm } from "react-hook-form";
import uuid from "react-uuid";
import ADD_DISCOUNT_RULE from "graphql/Discount/DiscountRule/AddDiscountRule";
import GET_RULE_TEMPLATES from "graphql/GetRuleTemplates";
import { useNotification } from "context/NotificationContext";
import CategoriesInput from "components/Discount/CategoriesInput";
import CurrencyInput from "components/Discount/CurrencyInput";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Input from "components/Ui/InputNew";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtonSecondary from "components/ActionButtons/ActionButtonSecondary";
import IconButton from "components/Ui/Buttons/IconButton";
import Sidebar from "components/Discount/Sidebar";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import Tooltip from "components/Ui/Tooltip";
import Toggle from "components/Ui/Toggle";
import TableHeader from "components/Table/Header";
import DiscountProducts from "components/Discount/DiscountProducts";
import StoreSelector from "components/StoreSelector/StoreSelector";
import SaveBar from "components/Ui/SaveBar";
import { formatPrice } from "../../../helpers/money";
import TypeButtons from "components/Discount/DiscountRule/TypeButtons";
import FilterRules from "../DiscountCode/FilterRules";
import FilterProducts from "../DiscountCode/FilterProducts";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 6rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }

  h5 {
    margin-bottom: 0;
    border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    padding-top: 3.2rem;
    width: 100%;
  }
`;

const ConditionsHeader = styled(TableHeader)`
  > div {
    cursor: default;
    width: 30%;
  }
`;

const Conditions = styled.div`
  width: 100%;
  position: relative;

  & + & {
    margin-top: 5rem;
  }

  h5 {
    border-top: 0;
    padding: 0 0 3rem;
  }
`;

const AddRowButton = styled(ActionButtonSecondary)`
  position: absolute;
  top: 0;
  right: 0;
`;

const TypeDescription = styled.div`
  border: ${(p) => `${p.theme.colors.primary} solid 0.2rem`};
  width: 100%;
  display: flex;
  border-radius: 0.5rem;
  margin: 0 1rem 4rem 1rem;
  flex-direction: column;
  padding: 1rem 0;

  p {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    padding: 2rem 3rem;
  }
`;

const IconWrapper = styled.div`
  position: relative;
  top: 2rem;
  width: 4rem;
  left: -0.8rem;
  background-color: ${(p) => p.theme.colors.secondaryBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(p) => `${p.theme.colors.secondaryBackground} solid 0.4rem`};
  border-radius: 50%;
`;

const DescriptionIcon = styled.i`
  color: ${(p) => p.theme.colors.primary};
  font-size: 3rem;
  position: relative;
  top: 0.1rem;
  left: 0.05rem;
`;

const Condition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  border-top: none;

  ${MEDIA_MIN_LARGE} {
    padding: 2rem;
  }
`;

const CurrencyCondition = styled(Condition)`
  > div {
    width: 30%;
    margin: 0;
  }
`;

const Padding = styled.div`
  width: 4.6rem !important;
`;

const ToggleWrapper = styled.div`
  margin-top: 0;
`;

const StoreSelectorRule = styled(StoreSelector)`
  margin-bottom: 3.5rem;

  ${MEDIA_MIN_LARGE} {
    margin-left: 0;
  }
`;

const DiscountBox = styled(Box)``;

const getFriendlyName = (template) => {
  switch (template) {
    case "Cart Rule, discount on cart within range":
      return "Discount within range";

    case "Cart Rule, Buy One Get One in percentage":
      return "BOGO - Buy one get one in %";

    case "Buy a specific quantity of selected items and get discount on a specific quantity of those items":
      return "Buy X get Y in %";

    case "Cart Rule, gifts with purchase on cart with value over threshold":
      return "Gift(s) with purchase on cart";

    case "Cart Rule, gifts with purchase when cart contains selected items":
      return "Gift(s) with purchase when cart contains selected items";

    case "Discount on selected number of items when cart contains different selected items":
      return "Discount on items in cart when contains other selected Items";

    default:
      return null;
  }
};

const getUseCaseText = (useCase) => {
  switch (useCase) {
    case "Cart Rule, Buy One Get One in percentage":
      return "BOGO is used to create a discount in percentage of the cheapest product. It is possible to exclude selected categories from this rule, as well as include selected currencies.";

    case "Cart Rule, discount on cart within range":
      return "Discount on cart with range is used to create a discount in percentage that applies to a range between minimum and maximum order value.";

    case "Cart Rule, gifts with purchase on cart with value over threshold":
      return "Gift with purchase on cart with value over threshold is used to create a discount rule that gives the customer a gift product if the cart value has reached the minimum amount.";

    case "Cart Rule, gifts with purchase when cart contains selected items":
      return "Gift with purchase when the cart contains selected items is used to create a discount rule that is activated when the cart contains a certain amount of products. This rule can be limited to selected products.";

    default:
      return useCase;
  }
};

export default ({history}) => {
  const {setNotification} = useNotification();
  const {
    control,
    handleSubmit,
    formState: {errors},
    register,
    setValue,
  } = useForm();
  const [active, setActive] = useState(true);
  const [templateId, setTemplateId] = useState("none");
  const [selectedStores, setSelectedStores] = useState([]);
  const [excludedCategories, setExcludedCategories] = useState([
    {label: "Shipping", value: "Shipping"},
  ]);
  const [currencies, setCurrencies] = useState([]);
  const [recurrent, setRecurrent] = useState(false);
  const [templateData, setTemplateData] = useState({
    currencyOptions: [{id: uuid()}],
  });
  const [selectedProducts, setSelectedProducts] = useState({
    products: [],
    expandedProducts: [],
  });
  const [selectedVariants, setSelectedVariants] = useState({
    products: [],
    expandedProducts: [],
  });
  const [filterRules, setFilterRules] = useState([
    {mode: "INCLUDE", key: "", values: [], id: uuid()},
  ]);
  const [discountRules, setDiscountRules] = useState([
    {mode: "INCLUDE", key: "", values: [], id: uuid()},
  ]);
  const [filterProducts, setFilterProducts] = useState([]);

  useEffect(() => {
    setValue("stores", selectedStores.length);
    // eslint-disable-next-line
  }, [selectedStores]);

  const toCurrencyOptions = (options) =>
    options.map(
      ({atLeastTotalPriceWithDiscount, atMostTotalPriceWithDiscount, currencyUnit}) => ({
        atLeastTotalPriceWithDiscount: formatPrice(atLeastTotalPriceWithDiscount, currencyUnit),
        ...(atMostTotalPriceWithDiscount
          ? {
            atMostTotalPriceWithDiscount: formatPrice(atMostTotalPriceWithDiscount, currencyUnit),
          }
          : {}),
        currencyUnit,
      })
    );

  const getTemplateData = (template, data) => {
    switch (template) {
      case "Cart Rule, Buy One Get One in percentage":
        return {
          discountInPercentage: parseInt(data.discountInPercentage, 10),
          currencies: currencies.map((c) => c.value),
          notForCategories: excludedCategories.map((c) => c.value),
        };
      case "Buy a specific quantity of selected items and get discount on a specific quantity of those items":
        return {
          discountInPercentage: parseInt(data.discountInPercentage, 10),
          stores: selectedStores.map((s) => s.value),
          filterRules: filterRules,
          triggerQuantity: parseInt(data.triggerQuantity),
          discountQuantity: parseInt(data.discountQuantity),
          recurrent: recurrent
        };
      case "Cart Rule, discount on cart within range":
        return {
          discountInPercentage: parseInt(data.discountInPercentage, 10),
          currencyOptions: toCurrencyOptions(Object.values(data?.templateData?.currencyOptions)),
        };
      case "Cart Rule, gifts with purchase on cart with value over threshold":
        return {
          currencyOptions: toCurrencyOptions(Object.values(data?.templateData?.currencyOptions)),
          variantIds: selectedVariants.products,
        };
      case "Cart Rule, gifts with purchase when cart contains selected items":
        return {
          minItems: parseInt(data.minItems, 10),
          variantIds: selectedVariants.products,
          validForProducts: selectedProducts.products,
          stores: selectedStores.map((s) => s.value),
        };
      case "Discount on selected number of items when cart contains different selected items":
        return {
          discountInPercentage: parseInt(data.discountInPercentage, 10),
          minItems: parseInt(data.minItems, 10),
          maxItems: parseInt(data.maxItems, 10),
          filterRules: filterRules,
          discountRules: discountRules,
          stores: selectedStores.map((s) => s.value),
          recurrent: recurrent,
        };
      default:
        return {};
    }
  };

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/discount-rules", "Discount rules"],
          ["admin/add-discount-rule", "Add discount rule"],
        ]}
      />
      <Header heading="Discount rules"/>
      <PageContainer>
        <Sidebar/>
        <GridContainer>
          <Query query={GET_RULE_TEMPLATES} variables={{type: "CART"}}>
            {({loading, error, data: ruleTemplates}) => {
              if (loading) return <Loader/>;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );

              return (
                <Mutation
                  mutation={ADD_DISCOUNT_RULE}
                  onCompleted={() => {
                    setNotification({
                      status: "success",
                      message: `Discount rule successfully added`,
                    });
                    history.push("/admin/discount-rules");
                  }}
                  onError={() => {
                    setNotification({
                      status: "error",
                      message: "An error occurred adding the discount rule, please contact support",
                    });
                  }}>
                  {(addDiscountRule, {loading: addDiscountLoading}) => {
                    const onSubmit = (data) => {
                      addDiscountRule({
                        variables: {
                          name: data.name,
                          templateId: templateId,
                          startDate: data.startDate,
                          endDate: data.endDate,
                          templateData: JSON.stringify(getTemplateData(activeTemplate.name, data)),
                          active: active,
                        },
                      }).catch((e) => {
                        console.log(e);
                      });
                    };

                    const sortedTemplates = [...ruleTemplates.getRuleTemplates].sort((a, b) =>
                      a.name.localeCompare(b.name)
                    );

                    if (templateId === "none") {
                      return (
                        <GridItem columns="12">
                          <DiscountBox
                            preHeading="Discount rules"
                            heading="Add discount rule"
                            showGoBackButton
                            goBackOnClick={() => history.push("/admin/discount-rules")}>
                            <Label>Select template:</Label>
                            <TypeButtons
                              ruleTemplates={sortedTemplates.map((ruleTemplate) => ({
                                name: getFriendlyName(ruleTemplate.name),
                                id: ruleTemplate.id,
                              }))}
                              setTemplateId={setTemplateId}></TypeButtons>
                          </DiscountBox>
                        </GridItem>
                      );
                    }

                    const activeTemplate = ruleTemplates.getRuleTemplates.find(
                      (ruleTemplate) => ruleTemplate.id === templateId
                    );

                    const jsonSchema = JSON.parse(activeTemplate.jsonSchema);
                    const [, handlebarTemplateType] = /"type":"([^"]+)"/.exec(
                      activeTemplate.handlebarTemplate
                    );

                    const maxDiscountInPercentage =
                      handlebarTemplateType === "ADD_BOGO_PERCENTAGE" ||
                      handlebarTemplateType === "ADD_BUYGET"
                        ? 100
                        : 99;
                    return (
                      <>
                        <DiscountBox
                          preHeading="Discount rules"
                          heading="Add discount rule"
                          showGoBackButton
                          goBackOnClick={() => history.push("/admin/discount-rules")}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <ActionButtons inBox>
                              <ActionButton type="submit">
                                <i className="fal fa-fw fa-check"/>
                                Save
                              </ActionButton>
                            </ActionButtons>
                            <GridItem columns="12">
                              <TypeButtons
                                ruleTemplates={sortedTemplates.map((ruleTemplate) => ({
                                  name: getFriendlyName(ruleTemplate.name),
                                  id: ruleTemplate.id,
                                }))}
                                currentTemplate={templateId}
                                setTemplateId={setTemplateId}></TypeButtons>
                            </GridItem>
                            <GridItem columns="12">
                              {addDiscountLoading && <Loader/>}

                              <IconWrapper>
                                <DescriptionIcon className="fa-solid fa-circle-info"/>
                              </IconWrapper>
                              <TypeDescription>
                                <p>{getUseCaseText(activeTemplate.name)}</p>
                              </TypeDescription>
                              <GridItem
                                mobilePadding="3rem 0 0"
                                desktopPadding="0 1.5rem 0 0"
                                columns="6">
                                <Value>
                                  <Toggle
                                    label="Active:"
                                    active={active}
                                    handleToggle={() => setActive(!active)}
                                  />
                                </Value>
                              </GridItem>

                              <GridContainer collapse padding="3rem 0 0">
                                <GridItem
                                  mobilePadding="3rem 0 0"
                                  desktopPadding="0 1.5rem 0 0"
                                  columns="6">
                                  <Controller
                                    name="name"
                                    render={({field}) => (
                                      <Input
                                        {...field}
                                        type="text"
                                        label="Name: *"
                                        errors={errors}
                                      />
                                    )}
                                    control={control}
                                    rules={{
                                      required: "This is a required field",
                                    }}
                                  />

                                  {jsonSchema.properties.discountInPercentage && (
                                    <Controller
                                      name="discountInPercentage"
                                      render={({field}) => (
                                        <Input
                                          {...field}
                                          type="number"
                                          placeholder={`0 - ${maxDiscountInPercentage}`}
                                          label="Discount in percentage:"
                                          icon="badge-percent"
                                          errors={errors}
                                        />
                                      )}
                                      control={control}
                                      rules={{
                                        required: "This is a required field",
                                        min: {
                                          value: 0,
                                          message: `Needs to be a number between 0-${maxDiscountInPercentage}`,
                                        },
                                        max: {
                                          value: maxDiscountInPercentage,
                                          message: `Needs to be a number between 0-${maxDiscountInPercentage}`,
                                        },
                                      }}
                                    />
                                  )}
                                  {jsonSchema.properties.triggerQuantity && (
                                    <Controller
                                      name="triggerQuantity"
                                      render={({field}) => (
                                        <Input
                                          {...field}
                                          type="number"
                                          placeholder="Min 1"
                                          label="Trigger quantity: *"
                                          icon="hashtag"
                                          errors={errors}
                                          toolTip="The minimum quantity of matching items in the cart required to trigger the discount"
                                        />
                                      )}
                                      control={control}
                                      rules={{
                                        required: "This is a required field",
                                        min: {
                                          value: 1,
                                          message: "Needs to be a number 1 or higher",
                                        },
                                      }}
                                    />
                                  )}
                                  {jsonSchema.properties.discountQuantity && (
                                    <Controller
                                      name="discountQuantity"
                                      render={({field}) => (
                                        <Input
                                          {...field}
                                          type="number"
                                          placeholder="Min 1"
                                          label="Discount quantity: *"
                                          icon="hashtag"
                                          errors={errors}
                                          toolTip="The quantity of matching items that will be discounted"
                                        />
                                      )}
                                      control={control}
                                      rules={{
                                        required: "This is a required field",
                                        min: {
                                          value: 1,
                                          message: "Needs to be a number 1 or higher",
                                        },
                                      }}
                                    />
                                  )}
                                  {handlebarTemplateType === "ADD_BOGO_PERCENTAGE" && (
                                    <CategoriesInput
                                      selected={excludedCategories}
                                      setSelected={setExcludedCategories}
                                      exclude={true}
                                    />
                                  )}
                                  {jsonSchema.properties.minItems && (
                                    <>
                                      <Tooltip/>
                                      <Controller
                                        name="minItems"
                                        render={({field}) => (
                                          <Input
                                            {...field}
                                            type="number"
                                            label="Minimum items: *"
                                            icon="cart-minus"
                                            errors={errors}
                                            data-tip="The minimum number of matching items in the cart required for the discount to apply"
                                          />
                                        )}
                                        control={control}
                                        rules={{
                                          required: "This is a required field",
                                          min: {
                                            value: 1,
                                            message: "Needs to be a positive number",
                                          },
                                        }}
                                      />
                                    </>
                                  )}
                                  {jsonSchema.properties.maxItems && (
                                    <>
                                      <Tooltip/>
                                      <Controller
                                        name="maxItems"
                                        render={({field}) => (
                                          <Input
                                            {...field}
                                            type="number"
                                            label="Maximum items: *"
                                            icon="cart-plus"
                                            errors={errors}
                                            data-tip="The maximum number of items in the car for the discount to apply"
                                          />
                                        )}
                                        control={control}
                                        rules={{
                                          required: "This is a required field",
                                          min: {
                                            value: 1,
                                            message: "Needs to be a positive number",
                                          },
                                        }}
                                      />
                                    </>
                                  )}
                                </GridItem>
                                <GridItem
                                  mobilePadding="0"
                                  desktopPadding="0 0 0 1.5rem"
                                  columns="6">
                                  <Controller
                                    name="startDate"
                                    render={({field}) => (
                                      <Input
                                        {...field}
                                        type="date"
                                        label="Valid from:"
                                        icon="calendar-alt"
                                        errors={errors}
                                      />
                                    )}
                                    control={control}
                                  />
                                  <Controller
                                    name="endDate"
                                    render={({field}) => (
                                      <Input
                                        {...field}
                                        label="Valid to:"
                                        icon="calendar-alt"
                                        errors={errors}
                                        type="date"
                                      />
                                    )}
                                    control={control}
                                  />
                                  {jsonSchema.properties.currencies && (
                                    <CurrencyInput
                                      selected={currencies}
                                      setSelected={setCurrencies}
                                    />
                                  )}
                                  {jsonSchema.properties.stores && (
                                    <StoreSelectorRule
                                      {...register("stores")}
                                      label="Select stores:"
                                      selectedStores={selectedStores}
                                      setSelectedStores={setSelectedStores}
                                      errors={errors}
                                      name="stores"
                                      showContinents
                                    />
                                  )}
                                  {jsonSchema.properties.recurrent && (
                                    <ToggleWrapper>
                                      <Toggle
                                        label="Recurrent: *"
                                        active={recurrent}
                                        handleToggle={() => setRecurrent(!recurrent)}
                                        toolTip="If this option is enabled, the discount could be used multiple times within the same cart"
                                      />
                                    </ToggleWrapper>
                                  )}
                                </GridItem>
                              </GridContainer>
                              <Container>
                                <Tooltip/>
                                {jsonSchema.properties.currencyOptions && (
                                  <Conditions>
                                    <h5>Conditions</h5>
                                    <AddRowButton
                                      type="button"
                                      handleOnClick={() =>
                                        setTemplateData(({currencyOptions = [], ...data}) => ({
                                          ...data,
                                          currencyOptions: [...currencyOptions, {id: uuid()}],
                                        }))
                                      }>
                                      <i className="fal fa-plus"></i> Add
                                    </AddRowButton>

                                    <ConditionsHeader>
                                      <div>
                                        Currency code *{" "}
                                        <i
                                          className="fal fa-fw fa-info-circle"
                                          data-tip="ISO-4217, ex.USD"
                                        />
                                      </div>
                                      <div>
                                        Minimum order value *{" "}
                                        <i
                                          className="fal fa-fw fa-info-circle"
                                          data-tip="ex. 60 or 5.5"
                                        />
                                      </div>
                                      {handlebarTemplateType === "ADD_CARTDISCOUNT_PERCENTAGE" && (
                                        <div>
                                          Maximum order value{" "}
                                          <i
                                            className="fal fa-fw fa-info-circle"
                                            data-tip="ex. 60 or 5.5"
                                          />
                                        </div>
                                      )}
                                      <Padding/>
                                    </ConditionsHeader>
                                    {templateData.currencyOptions
                                      .map((rule) => (
                                        <CurrencyCondition key={rule.id}>
                                          <Controller
                                            name={`templateData.currencyOptions.${rule.id}.currencyUnit`}
                                            render={({field}) => (
                                              <Input
                                                {...field}
                                                maxLength={3}
                                                placeholder="USD"
                                                type="text"
                                                errors={errors}
                                                relativeError
                                              />
                                            )}
                                            control={control}
                                            rules={{
                                              required: "This is a required field",
                                              pattern: {
                                                value: /[A-Z]{3}/,
                                                message: "Only capital letter A-Z are allowed",
                                              },
                                            }}
                                          />

                                          <Controller
                                            name={`templateData.currencyOptions.${rule.id}.atLeastTotalPriceWithDiscount`}
                                            render={({field}) => (
                                              <Input
                                                {...field}
                                                errors={errors}
                                                relativeError
                                                placeholder="100"
                                                type="number"
                                              />
                                            )}
                                            control={control}
                                            rules={{
                                              required: "This is a required field",
                                            }}
                                          />

                                          {handlebarTemplateType ===
                                            "ADD_CARTDISCOUNT_PERCENTAGE" && (
                                              <Controller
                                                render={({field}) => (
                                                  <Input
                                                    {...field}
                                                    type="number"
                                                    errors={errors}
                                                    relativeError
                                                    placeholder="1000"
                                                  />
                                                )}
                                                control={control}
                                                name={`templateData.currencyOptions.${rule.id}.atMostTotalPriceWithDiscount`}
                                              />
                                            )}

                                          <IconButton
                                            handleOnClick={() =>
                                              setTemplateData(
                                                ({currencyOptions = [], ...data}) => ({
                                                  ...data,
                                                  currencyOptions: currencyOptions.filter(
                                                    (r) => r.id !== rule.id
                                                  ),
                                                })
                                              )
                                            }>
                                            <i className="fal fa-trash-alt"/>
                                          </IconButton>
                                        </CurrencyCondition>
                                      ))
                                      .reverse()}
                                  </Conditions>
                                )}
                                <SaveBar history={history}/>
                              </Container>
                              {jsonSchema.properties.filterRules && (
                                <FilterRules
                                  control={control}
                                  filterRules={filterRules}
                                  setFilterRules={setFilterRules}
                                  title="Products in cart"
                                />
                              )}
                              {jsonSchema.properties.discountRules && (
                                <FilterRules
                                  control={control}
                                  filterRules={discountRules}
                                  setFilterRules={setDiscountRules}
                                  heading="Products with discount"
                                  subHeading="Products that the discount code will apply on"
                                  title="Products with discount"
                                />
                              )}
                            </GridItem>
                          </form>
                        </DiscountBox>
                        {jsonSchema.properties.validForProducts && (
                          <DiscountProducts
                            padding="2rem 0"
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                          />
                        )}
                        {jsonSchema.properties.variantIds && (
                          <DiscountProducts
                            padding="2rem 0"
                            selectVariants={true}
                            selectedProducts={selectedVariants}
                            setSelectedProducts={setSelectedVariants}
                            labelOverrides={{
                              preHeading: "Discount rule",
                              heading: "Add gift products",
                              subHeading: "",
                            }}
                          />
                        )}
                        {jsonSchema.properties.filterRules && (
                          <FilterProducts
                            padding="2rem 0"
                            filterRules={filterRules}
                            heading="Products in cart"
                            subHeading="Products that must be in the cart for the discount to be applicable"
                            setFilterProducts={setFilterProducts}
                            history={history}
                          />
                        )}
                        {jsonSchema.properties.discountRules && (
                          <FilterProducts
                            padding="2rem 0"
                            filterRules={discountRules}
                            heading="Products with discount"
                            subHeading="Products that the discount will apply on"
                            history={history}
                            excludeIds={filterProducts.map((p) => p.id)}
                          />
                        )}
                      </>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
