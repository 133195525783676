import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { SecureRoute } from "@okta/okta-react";
import { providers, configuredAuthProvider } from "helpers/authProvider";

export default ({ component, ...args }) => {
  const provider = configuredAuthProvider;

  switch (provider) {
    case providers.AUTH0:
      return (
        <Route
          component={withAuthenticationRequired(component, {
            onRedirecting: () => <div>Loading...</div>,
          })}
          {...args}
        />
      );
    case providers.OKTA:
      return <SecureRoute {...args} component={component} />;

    default:
      return null;
  }
};
