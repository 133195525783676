import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import UPDATE_ORDER_EMAIL from "graphql/Order/UpdateOrderEmail";
import Loader from "components/Ui/Loader";
import Input from "components/Ui/Input";
import Dialog from "components/Dialog/Dialog";
import { useNotification } from "context/NotificationContext";

export default function UpdateOrderEmail({ order, open, onClose, refetch }) {
  const { id, person } = order;
  const [email, setEmail] = useState(person.email);
  const billingAddress = {
    email: email,
    givenName: person.givenName,
    familyName: person.familyName,
    streetAddress: person.streetAddress,
    postalCode: person.postalCode,
    city: person.city,
    country: person.country,
    phone: person.phone,
    region: person.region,
  };
  const { setNotification } = useNotification();

  return (
    <Mutation
      mutation={UPDATE_ORDER_EMAIL}
      variables={{ id, email, billingAddress }}
      refetchQueries={refetch}
      awaitRefetchQueries={true}>
      {(updateOrderEmail, result) => {
        function onSubmit() {
          updateOrderEmail(email, billingAddress)
            .then(
              setNotification({
                status: "success",
                message: `Email successfully updated`,
              })
            )
            .then(onClose);
        }
        return (
          <>
            <Dialog
              header="Update customer email"
              text=""
              open={open}
              handleClose={onClose}
              handleOk={onSubmit}>
              {result.loading && <Loader />}
              <Input value={email} type="email" onChange={(e) => setEmail(e.target.value)} />
            </Dialog>
          </>
        );
      }}
    </Mutation>
  );
}
