import gql from "graphql-tag";

const UPDATE_DISCOUNT_RULE = gql`
  mutation updateRule(
    $id: ID!
    $name: String!
    $templateData: AWSJSON!
    $startDate: AWSDate
    $endDate: AWSDate
    $active: Boolean
  ) {
    updateRule(
      updateRule: {
        id: $id
        name: $name
        templateData: $templateData
        startDate: $startDate
        endDate: $endDate
        active: $active
      }
    ) {
      id
      name
      templateData
      active
      startDate
      endDate
    }
  }
`;

export default UPDATE_DISCOUNT_RULE;
