import React from "react";

const CategoryOptions = ({
  Row,
  discountInPercentageInput,
  categoriesInput,
  combineToggle,
  minItemsInput,
}) => {
  return (
    <>
      <Row>
        {discountInPercentageInput}
        {categoriesInput}
      </Row>
      <Row>
        {minItemsInput}
        {combineToggle}
      </Row>
    </>
  );
};

export default CategoryOptions;
