import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ApolloClientConfig } from "./ApolloClientConfig";

const Auth0Config = ({ children }) => {
  const { isAuthenticated, getIdTokenClaims, loginWithRedirect } = useAuth0();
  const [authToken, setAuthToken] = useState();

  useEffect(() => {
    async function getAuth0Token() {
      const claims = await getIdTokenClaims();
      setAuthToken(claims.__raw);
    }

    isAuthenticated && getAuth0Token();
    !isAuthenticated && loginWithRedirect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return <ApolloClientConfig children={children} authToken={authToken} />;
};

export default Auth0Config;
