import gql from "graphql-tag";

const SEARCH_VARIANTS = gql`
  query searchProducts($query: AWSJSON!) {
    searchProducts(query: $query) {
      totalHits
      products {
        id
        name
        slug
        imageUrl
        category
        customerAttribute
        attribute
        created
        lastUpdated
        type
        active
        stock {
          productId
          stock
        }
        stockInventory {
          id
          validateStock
          stockQuantity
          reservedQuantity
          availableQuantity
          isAvailable
        }
        relatedProduct {
          id
        }
      }
    }
  }
`;

export default SEARCH_VARIANTS;
