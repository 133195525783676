import React, { useContext, useEffect, useState } from "react";
import { Query, Subscription } from "@apollo/client/react/components";
import GET_ORDER from "graphql/Order/GetOrder";
import styled from "styled-components/macro";
import SEARCH_ORDERS from "graphql/Order/SearchOrders";
import SEARCH_ORDERS_EXPORT from "graphql/Order/SearchOrdersExport";
import { useQuery } from "@apollo/client";
import UPDATED_ORDER_SUBSCRIPTION from "graphql/Order/UpdatedOrderSubscription";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import OrdersTable from "./OrdersTable";
import Order from "../Order/Order";
import SearchOrders from "./SearchOrders";
import OrdersExport from "./OrdersExport";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import useLocalStorage from "helpers/useLocalStorage";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import SidebarOrders from "components/Order/SidebarOrders";
import PageContainer from "components/Page/PageContainer";
import { AdminContext } from "context/AdminContext";

const TableContainer = styled(GridContainer)`
  padding-top: 0;
  width: 100%;
`;

const BoxPageContainer = styled(PageContainer)`
  display: block;
`;

const OrderBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    padding-top: 0;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
      padding-top: 0;
    }
  }
`;

export default function Orders(props) {
  const [orders, setOrders] = useState();
  const [activeTab, setActiveTab] = useState("table");
  const [updatedOrder, setUpdatedOrder] = useState();
  const [tabs, setTabs] = useLocalStorage("order-tabs", []);
  const [ordersTotalHits, setOrdersTotalHits] = useState(0);
  const [urlId, setUrlId] = useState();
  const { data } = useQuery(GET_ORDER, {
    variables: { id: urlId, includeReservation: false },
    skip: !urlId,
  });
  const { orderFilter, ALL_ORDERS } = useContext(AdminContext);

  const closeTab = (id) => {
    setTabs(tabs.filter((item) => item.id !== id));
    if (tabs.length === 1) return;
    const tabIndex = tabs.findIndex((t) => t.id === id);
    setActiveTab(tabIndex === tabs.length - 1 ? tabs[tabIndex - 1].id : tabs[tabIndex + 1].id);
  };

  useEffect(() => {
    tabs.length > 0 && setActiveTab(tabs[tabs.length - 1].id);
  }, [tabs]);

  useEffect(() => {
    if (props.location.state) {
      const { id, reference } = props.location.state;
      !tabs.find((item) => item.id === id) && setTabs((prev) => [...prev, { id, reference }]);
      setActiveTab(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state]);

  useEffect(() => {
    const id = window.location.pathname.split("/")[3];
    if (id) {
      setUrlId(id);
      if (data) {
        !tabs.find((item) => item.id === id) &&
          setTabs((prev) => [...prev, { id, reference: data.getOrder.reference }]);
        setActiveTab(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Subscription
      subscription={UPDATED_ORDER_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) => {
        setUpdatedOrder(subscriptionData.data.updatedOrder);
      }}>
      {({ _ }) => {
        return (
          <>
            <Breadcrumbs slugs={[["admin/orders", "Orders"]]} />
            <Header heading="Orders" />
            <PageContainer>
              <SidebarOrders />
              <BoxPageContainer>
                <Tabs>
                  <Tab
                    onClick={() => setActiveTab("table")}
                    active={tabs?.length === 0 || activeTab === "table"}>
                    Orders
                  </Tab>
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.id}
                      tab={tab}
                      onClick={() => setActiveTab(tab.id)}
                      active={tab.id === activeTab}
                      closeTab={closeTab}>
                      {tab.id !== "table" && "#"}
                      {tab.reference}
                    </Tab>
                  ))}
                </Tabs>

                {tabs.length === 0 || activeTab === "table" ? (
                  <TableContainer>
                    <GridItem columns="12" mobilePadding="1.5rem">
                      <OrderBox preHeading="Overview" heading="Orders">
                        <SearchOrders />
                        <Query
                          query={SEARCH_ORDERS}
                          fetchPolicy="network-only"
                          errorPolicy="all"
                          variables={{ query: JSON.stringify(orderFilter?.esbQuery ?? ALL_ORDERS) }}
                          onCompleted={(data) => {
                            setOrders(data.searchOrders.orders);
                            setOrdersTotalHits(data.searchOrders.totalHits);
                          }}>
                          {({ loading, error, data }) => {
                            if (!data && error)
                              return (
                                <ErrorMessage inBox>
                                  An error occurred when loading data, please contact support
                                </ErrorMessage>
                              );

                            return (
                              <>
                                {error && (
                                  <ErrorMessage inBox>
                                    An error occurred when loading data: {error?.message}
                                  </ErrorMessage>
                                )}
                                <OrdersTable
                                  orders={orders}
                                  updatedOrder={updatedOrder}
                                  ordersTotalHits={ordersTotalHits}
                                  loading={loading}
                                  history={props.history}
                                  setTabs={setTabs}
                                  tabs={tabs}
                                  setActiveTab={setActiveTab}>
                                  {({ totalHits }) => (
                                    <OrdersExport
                                      size={totalHits}
                                      query={SEARCH_ORDERS_EXPORT}
                                      esbQuery={orderFilter.esbQuery}
                                    />
                                  )}
                                </OrdersTable>
                              </>
                            );
                          }}
                        </Query>
                      </OrderBox>
                    </GridItem>
                  </TableContainer>
                ) : (
                  tabs.map(
                    (tab) =>
                      activeTab === tab.id && (
                        <Order
                          key={tab.id}
                          id={tab.id}
                          status={tab.status}
                          created={tab.created}
                          updatedOrder={updatedOrder}
                        />
                      )
                  )
                )}
              </BoxPageContainer>
            </PageContainer>
          </>
        );
      }}
    </Subscription>
  );
}
