import gql from "graphql-tag";

const SEARCH_ADDONS = gql`
  query searchAddons($query: AWSJSON!) {
    searchProducts(query: $query) {
      totalHits
      products {
        id
        name
        imageUrl
        created
        lastUpdated
        active
      }
    }
  }
`;

export default SEARCH_ADDONS;
