import React from "react";
import Button from "components/Ui/Button";
import styled from "styled-components";
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const TypeButton = styled(Button)`
  text-transform: capitalize;
  background: ${(p) =>
    p.currentType ? (p) => p.theme.colors.primaryGradient : p.theme.colors.greyOpac};
  color: ${(p) => (p.currentType ? p.theme.colors.buttonText : p.theme.colors.white)};
  font-weight: 700;

  &:hover {
    background: ${(p) =>
      !p.currentType ? p.theme.colors.secondary : (p) => p.theme.colors.primaryGradient};
    filter: ${(p) => (p.currentType ? "brightness(110%)" : "brightness(100%)")};
  }

`;

const ButtonContainer = styled.div`
    width: 100%;
    display: grid;
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    ${MEDIA_MIN_MEDIUM} {
        grid-template-columns: 1fr 1fr 1fr;
    }
    ${MEDIA_MIN_LARGE} {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

`;

const getFriendlyTypeName = (name) => {
  switch (name) {
    case "FIXED_PRICE":
      return "fixed amount";

    case "GWP_THRESHOLD":
      return "gift threshold";

    case "GWP_ITEMS":
      return "gift product";

    case "MINIMUM":
      return "minimum order value";

    default:
      return name.toLowerCase();
  }
};

const TypeButtons = ({ discountTypes, setDiscountType, currentType }) => {
  return (
    <ButtonContainer>
      {discountTypes.map((type) => (
        <TypeButton
          type="button"
          currentType={currentType === type}
          key={type}
          onClick={() => setDiscountType(type)}>
          {getFriendlyTypeName(type)}
        </TypeButton>
      ))}
    </ButtonContainer>
  );
};

export default TypeButtons;
