import React from "react";
import styled from "styled-components/macro";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const QuickFilterOption = styled.div`
  margin: 0.6rem 1.2rem 0.6rem 0;
  padding: 0.6rem 1rem 0.5rem;
  background: ${(p) =>
    p.active ? (p) => p.theme.colors.primaryGradient : p.theme.colors.greyOpac};
  color: ${(p) => (p.active ? p.theme.colors.buttonText : p.theme.colors.white)};
  border: 0.1rem solid ${(p) => p.theme.colors.backgroundOpac};
  transition: 0.1s;
  cursor: pointer;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;

  ${MEDIA_MIN_LARGE} {
    height: 4rem;
    margin: 1rem;
    padding: 0.7rem 2rem 0.5rem;

    &:hover {
      background: ${(p) =>
        !p.active ? p.theme.colors.secondary : (p) => p.theme.colors.primaryGradient};
      filter: ${(p) => (p.active ? "brightness(110%)" : "brightness(100%)")};
    }
  }
`;

export default ({ children, ...props }) => (
  <QuickFilterOption {...props}>{children}</QuickFilterOption>
);
