import React from "react";
import styled from "styled-components/macro";
import ButtonOutlined from "components/Ui/ButtonOutlined";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const CancelButton = styled(ButtonOutlined)`
  height: 4rem;
  width: auto;
  padding: 0.1rem 2rem 0;
  margin: 0 0.5rem;

  ${MEDIA_MIN_LARGE} {
    height: 4.4rem;
  }
`;

export default ({ children, handleOnClick }) => (
  <CancelButton type="button" onClick={(e) => handleOnClick(e)}>
    {children}
  </CancelButton>
);
