import { Currency } from "./money";

export default function calculateSales(sales, currencyUnit, taxPercentage, includeVAT) {
  if (includeVAT) {
    return sales ?? 0;
  }

  const Amount = Currency(currencyUnit);
  return sales
    ? Amount(sales)
        .divide(taxPercentage / 100 + 1)
        .getAmount()
    : 0;
}
