import gql from "graphql-tag";

const DELETE_SHIPPING_CODE = gql`
  mutation deleteRule($id: ID!) {
    deleteRule(id: $id) {
      name
      id
    }
  }
`;

export default DELETE_SHIPPING_CODE;
