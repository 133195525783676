import React, { useState } from "react";
import Moment from "react-moment";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";

import GET_ADDON from "graphql/Product/GetAddon";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Sidebar from "components/Product/Sidebar";
import Placeholder from "components/Ui/PlaceholderImage";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import Price from "components/Product/Price";

const ImageContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0;
  width: 100%;

  img {
    max-width: 100%;
  }
`;

const StyledAttributes = styled(Attributes)`
  margin-top: 2rem;

  li {
    &:first-child {
      border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    }
  }
`;

const NoFlex = styled.span`
  display: block !important;
`;

const Prices = styled.div`
  padding: 0;
  width: 100%;

  > div {
    margin: 0.5rem -0.5rem 0;

    ${MEDIA_MIN_MEDIUM} {
      margin: 1rem -1rem 0;
    }
  }
`;

const Addon = (id) => {
  const [addonName, setAddonName] = useState("");

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/addons", "Add-ons"],
          [`admin/addon/${id}`, `${addonName}`],
        ]}
      />
      <Header heading="Add-ons"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer expanded>
          <Query
            query={GET_ADDON}
            variables={{ id }}
            onCompleted={(data) => setAddonName(data.getProduct.name)}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );

              const addon = data.getProduct;

              return (
                <>
                  {addon && (
                    <>
                      <GridItem columns="10" padding="0">
                        <Box
                          preHeading="Add-on"
                          heading={
                            <NoFlex>
                              {addon.name}
                              {addon.active ? (
                                <i className="fal fa-check-circle"></i>
                              ) : (
                                <i className="fal fa-times-circle"></i>
                              )}
                            </NoFlex>
                          }
                          subHeading={addon.id}>
                          <GridContainer padding="0">
                            <GridItem
                              mobilePadding="0 0 1rem"
                              desktopPadding="1rem 0 2rem 1rem"
                              columns="6">
                              <ImageContainer>
                                {addon.imageUrl ? (
                                  <img alt={addon.name} src={addon.imageUrl} />
                                ) : (
                                  <Placeholder />
                                )}
                              </ImageContainer>

                              <StyledAttributes>
                                <Attribute>
                                  <Label>Created:</Label>
                                  <Value>
                                    <Moment format="YYYY-MM-DD, HH:mm">{addon.created}</Moment>
                                  </Value>
                                </Attribute>
                                <Attribute>
                                  <Label>Last updated:</Label>
                                  <Value>
                                    <Moment format="YYYY-MM-DD, HH:mm">{addon.lastUpdated}</Moment>
                                  </Value>
                                </Attribute>
                              </StyledAttributes>
                            </GridItem>
                            <GridItem columns="6">
                              <Prices>
                                <Price price={addon.price} discounts={addon.discount} />
                              </Prices>
                            </GridItem>
                          </GridContainer>
                        </Box>
                      </GridItem>
                    </>
                  )}
                </>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default (props) => {
  return Addon(props.match.params.id.replaceAll("~", "/"));
};
