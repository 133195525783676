import gql from "graphql-tag";

const GET_INVENTORIES = gql`
  query getInventories {
    getInventories {
      id
      name
      created
      updated
      address {
        country
        city
        streetAddress
        postalCode
        region
      }
    }
  }
`;

export default GET_INVENTORIES;
