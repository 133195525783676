import gql from "graphql-tag";

const GET_STOCK = gql`
  query getStock($productId: ID!) {
    getStock(productId: $productId) {
      productId
      stock
    }
  }
`;

export default GET_STOCK;
