import React from "react";
import styled from "styled-components/macro";
import { useForm, Controller } from "react-hook-form-old";
import { Query, Mutation } from "@apollo/client/react/components";

import GET_CONFIGURATION from "graphql/Configuration/GetConfiguration";
import ADD_OR_UPDATE_CONFIGURATION from "graphql/Configuration/AddOrUpdateConfiguration";
import ALL_STORES from "graphql/Store/AllStores";

import { useNotification } from "context/NotificationContext";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Sidebar from "components/Configuration/Sidebar";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import FlagIcon from "components/Ui/FlagIcon";
import Input from "components/Ui/Input";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const Content = styled.div`
  padding-bottom: 3rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }

  p {
    margin-bottom: 3rem;
  }

  a {
    color: ${(p) => p.theme.colors.primary};
  }

  form {
    input {
      max-width: 50rem;
    }

    p {
      border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
      padding-bottom: 2rem;
      margin-bottom: 3rem;
    }

    > div {
    }
  }

  h5 {
    margin-bottom: 3rem;
    border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    padding-top: 3.2rem;
  }
`;

const Stores = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;

  > div {
    width: calc(50% - 2rem);
    margin: 0 1rem;

    ${MEDIA_MIN_LARGE} {
      width: calc(20% - 2rem);
    }
`;

const PaymentAccounts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;

  > div {
    width: calc(50% - 2rem);
    margin: 0 1rem;
`;

export default ({ history }) => {
  const { control, handleSubmit, errors } = useForm();
  const { setNotification } = useNotification();

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/configuration", "Configurations"],
          ["admin/fortnox", "Fortnox"],
        ]}
      />
      <Header heading="Configurations" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query query={ALL_STORES} variables={{ from: 0, size: 300 }}>
            {({ loading: loadingStores, error: errorStores, data: storesData }) => (
              <Query query={GET_CONFIGURATION} variables={{ service: "fortnox" }}>
                {({ loading: loadingConfig, error: errorConfig, data }) => {
                  if (loadingStores || loadingConfig) return <Loader />;
                  if (errorStores)
                    return (
                      <ErrorMessage>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );

                  const { stores } = storesData.allStores;
                  const config =
                    data.getConfiguration.length > 0 && JSON.parse(data.getConfiguration[0].data);

                  return (
                    <Mutation
                      mutation={ADD_OR_UPDATE_CONFIGURATION}
                      onCompleted={() => {
                        setNotification({
                          status: "success",
                          message: "Configuration successfully saved",
                        });
                      }}
                      onError={() => {
                        setNotification({
                          status: "error",
                          message: "Could not save configuration. Please contact support",
                        });
                      }}>
                      {(updateConfiguration, { loading: updateLoading }) => {
                        const onSubmit = (data) => {
                          updateConfiguration({
                            variables: {
                              service: "fortnox",
                              name: "accounts",
                              data: JSON.stringify({ accounts: data }),
                            },
                          }).catch((e) => {
                            console.log(e);
                          });
                        };

                        return (
                          <GridItem columns="12">
                            <Box
                              preHeading="Integrations"
                              heading="Fortnox"
                              showGoBackButton
                              goBackOnClick={() => history.push("/admin/configurations")}>
                              {updateLoading && <Loader />}
                              <Content>
                                <p>
                                  Fortnox needs to be activated and configured for your account by
                                  one of our admins. <br /> Please contact{" "}
                                  <a href="mailto:support@brinkcommerce.com">
                                    support@brinkcommerce.com
                                  </a>{" "}
                                  for assistance.
                                </p>
                                {!errorConfig && config && (
                                  <form onSubmit={handleSubmit(onSubmit)}>
                                    <ActionButtons inBox footerOnMobile>
                                      <ActionButton type="submit">
                                        <i className="fal fa-fw fa-check" /> Save
                                      </ActionButton>
                                    </ActionButtons>

                                    <h5>Payment account ID's</h5>
                                    <PaymentAccounts>
                                      <Controller
                                        as={Input}
                                        control={control}
                                        type="text"
                                        name="paymentAccount"
                                        label="Default"
                                        defaultValue={config.accounts["paymentAccount"]}
                                        errors={errors}
                                      />
                                      <Controller
                                        as={Input}
                                        control={control}
                                        type="text"
                                        name="paymentAccountSwish"
                                        label="Swish"
                                        defaultValue={config.accounts["paymentAccountSwish"]}
                                        errors={errors}
                                      />
                                    </PaymentAccounts>
                                    <h5>Store account ID's</h5>
                                    <Stores>
                                      {stores
                                        .slice()
                                        .sort((a, b) => a.countryCode.localeCompare(b.countryCode))
                                        .map((store) => (
                                          <Controller
                                            key={store.countryCode}
                                            as={Input}
                                            control={control}
                                            type="text"
                                            name={store.countryCode}
                                            label={
                                              <>
                                                <FlagIcon countryCode={store.countryCode} />{" "}
                                                {store.countryCode}
                                              </>
                                            }
                                            defaultValue={config.accounts[store.countryCode]}
                                            errors={errors}
                                          />
                                        ))}
                                    </Stores>
                                  </form>
                                )}
                              </Content>
                            </Box>
                          </GridItem>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Query>
            )}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
