import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { ApolloClientConfig } from "./ApolloClientConfig";

const OktaConfig = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [authToken, setAuthToken] = useState();

  useEffect(() => {
    async function getAuthToken() {
      const oktaToken = await oktaAuth.getIdToken();
      setAuthToken(oktaToken);
    }

    getAuthToken();
  }, [authState, oktaAuth]);

  return <ApolloClientConfig children={children} authToken={authToken} />;
};

export default OktaConfig;
