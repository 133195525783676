import React from "react";
import SecondarySidebar from "components/Sidebar/SecondarySidebar";

const isAddonsEnabled = () => /true/i.test(process.env.REACT_APP_ADDONS_ENABLED || 'false')

export default () => (<SecondarySidebar
  sections={[
    {
      title: "Products",
      layout: "/admin",
      routes: [{ name: "Show all", path: "/products" }],
    },
    {
      title: "Add-ons",
      layout: "/admin",
      routes: [{ name: "Show all", path: "/addons" }],
      enabled: isAddonsEnabled()
    },
    {
      title: "Stock",
      layout: "/admin",
      routes: [{ name: "Status", path: "/stock" }],
    },
    {
      title: "Most sold",
      layout: "/admin",
      routes: [{ name: "Show all", path: "/most-sold-products" }],
    },
  ].filter((s) => typeof s.enabled === 'undefined' || s.enabled)}
/>);
