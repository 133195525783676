import React from "react";
import styled from "styled-components/macro";

const Attribute = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 6rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  padding: 1.2rem 0;

  img {
    max-height: 2rem;
    max-width: 8rem;
  }
`;

export default ({ children, ...props }) => {
  return <Attribute {...props}>{children}</Attribute>;
};
