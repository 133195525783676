import gql from "graphql-tag";

const GET_ADDON = gql`
  query getAddon($id: ID!) {
    getProduct(id: $id) {
      id
      attribute
      name
      active
      imageUrl
      created
      price {
        amount
        currencyUnit
      }
      discount {
        amount
        currencyUnit
      }
      lastUpdated
    }
  }
`;

export default GET_ADDON;
