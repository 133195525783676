import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Timespan = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 0 0 1.5rem;

  > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
  }

  input {
    width: 100%;
  }

  ${MEDIA_MIN_LARGE} {
    width: auto;
    padding: 0.5rem 0;
    flex-wrap: nowrap;

    > div {
      flex-wrap: nowrap;
    }

    input {
      width: 21rem;
    }
  }
`;

export default ({ children, ...props }) => <Timespan {...props}>{children}</Timespan>;
