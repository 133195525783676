import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import CANCEL_ORDER from "graphql/Order/CancelOrder";
import CancelButton from "components/ActionButtons/CancelButton";
import ButtonOutlined from "components/Ui/ButtonOutlined";
import Loader from "components/Ui/Loader";
import Dialog from "components/Dialog/Dialog";
import Input from "components/Ui/Input";
import { useNotification } from "context/NotificationContext";
import styled from "styled-components";

const ReasonButton = styled(ButtonOutlined)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0 1.8rem;
  padding: 2rem 1.5rem 2rem 1rem;
`;

const Error = styled.p`
  color: red;
`;

const ReasonWrapper = styled.span`
  width: 100%;
  overflow: hidden;
  height: ${(p) => (p.showReason ? "20rem" : "0")};
  transition: height 0.3s ease-out;
  label {
    font-weight: 400;
  }
  input {
    margin-bottom: 2rem;
  }
`;

export default function CancelOrder({ id, refetch, fullRefund }) {
  const { setNotification } = useNotification();
  const [open, setOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState();
  const [showReason, setShowReason] = useState(false);
  const [showError, setShowError] = useState(false);

  return (
    <Mutation
      mutation={CANCEL_ORDER}
      variables={{ id, ...(cancelReason && { reason: cancelReason }) }}
      refetchQueries={refetch}
      onCompleted={({ cancelOrder }) => {
        setOpen(false);
        setNotification({
          status: "success",
          message: `Order "${cancelOrder.id} has been canceled"`,
        });
      }}
      onError={(error) => {
        console.error(error);
        setNotification({
          status: "error",
          message: `Order status "canceled" could not be updated, please contact support`,
        });
      }}>
      {(cancelOrder, { loading, error }) => {
        if (loading) return <Loader />;

        const handleClickOpen = (event) => {
          event.stopPropagation();
          setOpen(true);
        };

        const handleClose = (event) => {
          event.stopPropagation();
          setOpen(false);
        };

        const handleOk = () => {
          if (!cancelReason || cancelReason.cause) {
            setShowError(false);
            return cancelOrder();
          } else {
            setShowError(true);
          }
        };

        return (
          <>
            <CancelButton type="button" handleOnClick={handleClickOpen}>
              <i className={fullRefund ? "fa-light fa-arrow-rotate-left" : "fal fa-ban"}></i>{" "}
              {fullRefund ? "Full refund" : "Cancel order"}
            </CancelButton>
            <Dialog
              header="Cancel Order?"
              text="Request the order to be canceled by the payment provider"
              open={open}
              handleClose={handleClose}
              handleOk={handleOk}>
              <ReasonButton onClick={() => setShowReason(!showReason)}>
                Add Reason
                <i className={`fa-light fa-chevron-${showReason ? "up" : "down"}`}></i>
              </ReasonButton>
              <ReasonWrapper showReason={showReason}>
                <Input
                  label="Cancel code"
                  onChange={(e) => setCancelReason({ ...cancelReason, code: e.target.value })}
                />
                <Input
                  label="Cancel cause"
                  onChange={(e) => setCancelReason({ ...cancelReason, cause: e.target.value })}
                />
              </ReasonWrapper>
              {showError && <Error showError={showError}>Cause is required</Error>}
            </Dialog>
          </>
        );
      }}
    </Mutation>
  );
}
