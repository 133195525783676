import gql from "graphql-tag";

const ORDER_AGGREGATE = gql`
  query orderAggregates($query: AWSJSON!) {
    orderAggregates(query: $query) {
      aggregations
    }
  }
`;

export default ORDER_AGGREGATE;
