import React, { useState } from "react";
import Input from "../Ui/InputNew";
import styled from "styled-components/macro";
import TableHeader from "../Table/Header";
import ActionButtonSecondary from "../ActionButtons/ActionButtonSecondary";
import { useNotification } from "../../context/NotificationContext";
import { formatFromMinorUnits, formatPrice } from "../../helpers/money";
import { useQuery } from "@apollo/client";
import ALL_STORES from "../../graphql/Store/AllStores";
import Select from "../Ui/Select";
import Loader from "../Ui/Loader";

const CurrencySelect = styled(Select)`
    margin: 0 1rem 0 0;
    width: 25rem;
`;

const ConditionsHeader = styled(TableHeader)`
    > div {
        cursor: default;
        width: 30%;
    }
`;

const Conditions = styled.div`
    width: 100%;
    position: relative;

    & + & {
        margin-top: 5rem;
    }

    h5 {
        border-top: 0;
    }
`;

const AddContainer = styled.div`
    display: flex;

    input {
        margin-right: 1rem;
    }
`;

const Error = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 2rem;
    color: red;
`;

const Padding = styled.div`
    width: 4.6rem !important;
`;

const AddRowButton = styled(ActionButtonSecondary)`
    height: 5rem;
`;

const AddedThreshold = styled(ConditionsHeader)`
    border-radius: 0;
    background: none;
    font-weight: 400;

    i {
        &:hover {
            cursor: pointer;
        }
    }
`;

const ThresholdsInput = ({ thresholds, setThresholds }) => {
  const [threshold, setThreshold] = useState({
    currencyUnit: "",
    atLeastTotalPriceWithDiscount: "",
  });
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const { setNotification } = useNotification();

  const usedCurrencies = (thresholds?.map(threshold => threshold.currencyUnit));

  const { data, loading } = useQuery(ALL_STORES, {
    onCompleted: () => {
      const currencies = data.allStores.stores.map((store) => store.currencyUnit);
      setCurrencyOptions([...new Set(currencies)]);
    },
  });
  const resetThreshold = () =>
    setThreshold({ currencyUnit: "", atLeastTotalPriceWithDiscount: "" });

  const addThreshold = () => {
    if (
      !threshold["currencyUnit"] ||
      !threshold["atLeastTotalPriceWithDiscount"] ||
      threshold["currencyUnit"] === "notSelected"
    ) {
      setNotification({ status: "error", message: "Threshold: All fields is required" });
      return;
    }
    if (thresholds.find((th) => th.currencyUnit === threshold.currencyUnit)) {
      setNotification({ status: "error", message: "Threshold: Currency already added" });
      return;
    }
    setThresholds([
      ...thresholds,
      {
        currencyUnit: threshold.currencyUnit,
        atLeastTotalPriceWithDiscount: formatPrice(
          threshold.atLeastTotalPriceWithDiscount,
          threshold.currencyUnit,
        ),
      },
    ]);
    resetThreshold();
  };

  const removeThreshold = (currencyUnit) => {
    const newArr = thresholds.filter((threshold) => threshold.currencyUnit !== currencyUnit);
    setThresholds(newArr);
  };
  if (currencyOptions.length < 1) return null;

  const options = currencyOptions.filter(currencyOption => !usedCurrencies.includes(currencyOption));
  if (loading) return <Loader color="black" />;
  return (
    <Conditions>
      <h5>Add thresholds</h5>
      <AddContainer>
        <CurrencySelect
          handleChange={(e) => setThreshold({ ...threshold, currencyUnit: e.target.value })}>
          {[
            <option key={"notSelected"} value={"notSelected"}>
              Select Currency
            </option>,
            ...options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            )),
          ]}
        </CurrencySelect>
        <Input
          relativeError
          placeholder="Minimum order value ex. 100"
          type="number"
          value={threshold["atLeastTotalPriceWithDiscount"]}
          onChange={(e) =>
            setThreshold({ ...threshold, atLeastTotalPriceWithDiscount: e.target.value })
          }
        />
        <AddRowButton type="button" handleOnClick={addThreshold}>
          <i className="fal fa-plus"></i> Add
        </AddRowButton>
      </AddContainer>
      <Padding />
      <h5>Added thresholds</h5>
      {thresholds?.length > 0 && (
        <ConditionsHeader>
          <div>Currency code</div>
          <div>Minimum order value</div>
          <Padding />
        </ConditionsHeader>
      )}
      {thresholds?.length === 0 && <Error>No thresholds added</Error>}
      {thresholds?.map((threshold) => (
        <AddedThreshold key={threshold.currencyUnit}>
          <div>{threshold.currencyUnit}</div>
          <div>
            {formatFromMinorUnits(threshold.atLeastTotalPriceWithDiscount, threshold.currencyUnit)}
          </div>
          <i className="fal fa-trash-alt" onClick={() => removeThreshold(threshold.currencyUnit)} />
        </AddedThreshold>
      ))}
    </Conditions>
  );
};

export default ThresholdsInput;
