import gql from "graphql-tag";

const GET_DISCOUNT_CODE = gql`
  query getCode($id: ID!) {
    getDiscount(id: $id) {
      id
      code
      startDate
      endDate
      discountInPercentage
      discountInFixedPrice {
        amount
        currencyUnit
      }
      usageCount
      created
      usageLimit
      type
      gifts {
        id
        relatedProduct {
          id
        }
      }
      description
      lastUpdated
      notForCategories
      categories
      createdBy
      group
      validForProducts {
        id
      }
      notForProducts {
        id
      }
      notForProductVariants {
        id
      }
      stores {
        countryCode
        languageCode
        tax
        currencyUnit
      }
      maxItems
      minItems
      combine
      freeShipping
      recurrent
      discountRules {
        mode
        key
        values
      }
      filterRules {
        mode
        key
        values
      }
      currencyOptions {
        atLeastTotalPriceWithDiscount
        currencyUnit
      }
    }
  }
`;

export default GET_DISCOUNT_CODE;
