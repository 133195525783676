import gql from "graphql-tag";

const SEARCH_PERSONS_EXPORT = gql`
  query searchPersons(
    $query: AWSJSON!
    $id: Boolean!
    $email: Boolean!
    $givenName: Boolean!
    $familyName: Boolean!
    $streetAddress: Boolean!
    $houseNumberOrName: Boolean!
    $postalCode: Boolean!
    $city: Boolean!
    $region: Boolean!
    $country: Boolean!
    $phone: Boolean!
    $created: Boolean!
    $lastUpdated: Boolean!
  ) {
    searchPersons(query: $query) {
      totalHits
      persons {
        id @include(if: $id)
        email @include(if: $email)
        givenName @include(if: $givenName)
        familyName @include(if: $familyName)
        streetAddress @include(if: $streetAddress)
        houseNumberOrName @include(if: $houseNumberOrName)
        postalCode @include(if: $postalCode)
        region @include(if: $region)
        city @include(if: $city)
        country @include(if: $country)
        phone @include(if: $phone)
        created @include(if: $created)
        lastUpdated @include(if: $lastUpdated)
      }
    }
  }
`;

export default SEARCH_PERSONS_EXPORT;
