import gql from "graphql-tag";

const ADD_OR_UPDATE_WEBHOOK_CONFIGURATION = gql`
  mutation addOrUpdateWebhookConfigurations(
    $name: String!
    $type: WebhookConfigurationType!
    $data: InputWebhookConfigurationData!
  ) {
    addOrUpdateWebhookConfigurations(
      webhookConfigurations: { name: $name, type: $type, data: $data }
    ) {
      name
      data
    }
  }
`;

export default ADD_OR_UPDATE_WEBHOOK_CONFIGURATION;
