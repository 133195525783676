import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import ADD_DISCOUNT_CODE from "graphql/Discount/DiscountCode/AddDiscountCode";
import GET_DISCOUNT_CODE from "graphql/Discount/DiscountCode/GetDiscountCode";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Dialog from "components/Dialog/Dialog";
import { useNotification } from "context/NotificationContext";
import uuid from "react-uuid";
import Input from "components/Ui/Input";
import { Query } from "@apollo/client/react/components";

const DuplicateDiscountCode = ({ showDialog, id, setShowDialog, setTabs, refetch }) => {
  const { setNotification } = useNotification();
  const [newDiscountCode, setNewDiscountCode] = useState();
  const [discountCode, setDiscountCode] = useState();

  const [addDiscountCode, { loading: duplicateLoading }] = useMutation(ADD_DISCOUNT_CODE, {
    onCompleted: (data) => {
      setShowDialog(false);
      setNotification({
        status: "success",
        message: `Discount code added`,
      });
      setTabs((prev) => [...prev, { id: data.addDiscount.id, reference: data.addDiscount.code }]);
    },
    onError: () => {
      setNotification({
        status: "error",
        message: `Discount code couldn't be added, please contact support`,
      });
    },
    refetchQueries: refetch,
  });

  const addNewDiscountCode = () => {
    const discountToDuplicate = { ...discountCode };
    Object.keys(discountToDuplicate).forEach(
      (key) => discountToDuplicate[key] ?? delete discountToDuplicate[key]
    );

    const {
      created,
      revenueDinero,
      revenue,
      id,
      code,
      lastUpdated,
      validForProducts,
      notForProductVariants,
      notForProducts,
        gifts = [],
      ...rest
    } = discountToDuplicate;

    addDiscountCode({
      variables: {
        ...rest,
        code: newDiscountCode,
        id: uuid(),
        stores: discountToDuplicate?.stores
          ?.filter((store) => store?.countryCode)
          ?.map((store) => ({
            countryCode: store?.countryCode,
            languageCode: store?.languageCode,
          })),
        gifts: gifts.map((g) => g.id),
        validForProducts: validForProducts.map((p) => p.id),
        notForProductVariants: notForProductVariants.map((p) => p.id),
        notForProducts: notForProducts.map((p) => p.id),
      },
    });
  };
  return (
    <Query
      query={GET_DISCOUNT_CODE}
      variables={{ id }}
      onCompleted={({ getDiscount }) => {
        setDiscountCode(getDiscount);
      }}>
      {({ loading, error }) => {
        if (loading) return <Loader />;
        if (error)
          return (
            <ErrorMessage>An error occurred when loading data, please contact support</ErrorMessage>
          );

        return (
          <Dialog
            preHeading={discountCode?.code}
            subHeading="Create a new discount code with parameters of existing"
            header="Duplicate discount code"
            open={showDialog}
            handleClose={() => setShowDialog(false)}
            handleOk={addNewDiscountCode}>
            {duplicateLoading && <Loader />}
            <span>New discount code:</span>
            <Input type="text" onChange={(e) => setNewDiscountCode(e.target.value)}></Input>
          </Dialog>
        );
      }}
    </Query>
  );
};

export default DuplicateDiscountCode;
