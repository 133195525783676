import React from "react";
import styled, { css } from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  position: ${(p) => (p.inBox ? "absolute" : "relative")};
  top: ${(p) => (p.inBox && !p.footerOnMobile ? "1.5rem" : "auto")};
  bottom: ${(p) => (p.footerOnMobile ? "2rem" : "auto")};
  right: 1rem;

  ${MEDIA_MIN_LARGE} {
    margin-top: -2rem;
    padding: 3rem 0;
    right: ${(p) => (p.inBox ? "2rem" : "auto")};
    top: ${(p) => (p.inBox ? "1.6rem" : "auto")};
    bottom: auto;
  }

  ${(p) =>
    p.sticky === "true" &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `};
`;

export default ({ children, sticky, inBox, footerOnMobile, ...props }) => (
  <Container {...props} sticky={sticky} inBox={inBox} footerOnMobile={footerOnMobile}>
    {children}
  </Container>
);
