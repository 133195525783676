import React from "react";
import styled, { css } from "styled-components/macro";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Checkbox = styled.input.attrs((p) => ({
  type: "checkbox",
  name: p.name,
}))`
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  padding: 0;
  border: 0.1rem solid ${(p) => p.theme.colors.checkboxBorder};
  position: relative;
  flex-shrink: 0;
  background: ${(p) => p.theme.colors.inputBackgroundColor};
  margin: 0;
  -webkit-appearance: none;

  ${MEDIA_MIN_LARGE} {
    height: 2.4rem;
    width: 2.4rem;
  }

  &:checked {
    background: ${(p) => p.theme.colors.primary};
  }

  ${(p) =>
    p.indeterminate &&
    css`
      background: ${(p) => p.theme.colors.primary};

      &:before {
        content: "";
        position: absolute;
        top: 0.8rem;
        left: 0.5rem;
        width: 0.8rem;
        height: 0.2rem;
        background: ${(p) => p.theme.constants.white};

        ${MEDIA_MIN_LARGE} {
          top: 1rem;
          left: 0.6rem;
          width: 1rem;
        }
      }
    `};

  &:checked::before {
    display: none;
  }

  &:checked::after {
    content: "";
    position: absolute;
    top: 0.2rem;
    left: 0.6rem;
    width: 0.4rem;
    height: 1rem;
    transform: rotate(45deg);
    border-style: solid;
    border-color: ${(p) => p.theme.constants.white};
    border-width: 0 0.2rem 0.2rem 0;

    ${MEDIA_MIN_LARGE} {
      top: 0.2rem;
      left: 0.8rem;
      width: 0.5rem;
      height: 1.3rem;
    }
  }

  &:focus {
    outline: none;
  }
`;

export default ({ name, onClick, disabled, indeterminate, ...props }) => {
  return (
    <Checkbox
      {...props}
      disabled={disabled}
      indeterminate={indeterminate}
      name={name}
      onClick={onClick}
    />
  );
};
