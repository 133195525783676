import gql from "graphql-tag";

const DELETE_DISCOUNT_RULE = gql`
  mutation deleteRule($id: ID!, $deleteMode: DeleteMode) {
    deleteRule(id: $id, deleteMode: $deleteMode) {
      id
      name
    }
  }
`;

export default DELETE_DISCOUNT_RULE;
