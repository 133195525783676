import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form";
import { useNotification } from "context/NotificationContext";
import styled from "styled-components";
import ActionButtons from "../ActionButtons/ActionButtons";
import ActionButton from "../ActionButtons/ActionButton";
import Dialog from "../Dialog/Dialog";
import Input from "../Ui/InputNew";
import { formatFromMinorUnits, formatPrice } from "helpers/money";
import Toggle from "../Ui/Toggle";
import Loader from "../Ui/Loader";
import ADD_POST_PURCHASE_DISCOUNT from "graphql/Order/AddPostPurchaseDiscount";
import isRefundable from "helpers/isRefundable";

const DiscountToggle = styled(Toggle)`
  margin-bottom: 2rem;
`;

export default function AddPostPurchaseDiscount({ order, refetch }) {
  const [dialog, setDialog] = useState(false);
  const [fixedPrice, setFixedPrice] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setNotification } = useNotification();

  if (!order) return null;
  if (!isRefundable(order)) return null;
  if (order.customerAttribute.postPurchaseDiscount) return null;

  const openDialog = setDialog.bind(this, true);
  const closeDialog = setDialog.bind(this, false);

  const totalOrderAmount = formatFromMinorUnits(order.orderAmountWithDiscount, order.currencyUnit);

  return (
    <>
      <ActionButtons inBox>
        <ActionButton handleOnClick={openDialog}>Add discount</ActionButton>
      </ActionButtons>
      <Mutation mutation={ADD_POST_PURCHASE_DISCOUNT} refetchQueries={refetch}>
        {(addPostPurchaseDiscount, { loading }) => {
          function onSubmit({ discountInPercentage, discountInFixedAmount }) {
            addPostPurchaseDiscount({
              variables: {
                discountOrder: {
                  id: order.id,
                  ...(fixedPrice
                    ? { discountInFixedAmount: formatPrice(discountInFixedAmount, order.currencyUnit) }
                    : { discountInPercentage: parseInt(discountInPercentage, 10) }),
                },
              },
            })
              .then(() => {
                setNotification({
                  status: "success",
                  message: `Discount successfully added`,
                });
              })
              .then(closeDialog);
          }
          return (
            <Dialog
              open={dialog}
              header="Add discount"
              text="Add discount to all order lines without prior discount"
              handleClose={closeDialog}
              handleOk={handleSubmit(onSubmit)}>
              {loading && <Loader />}
              <form>
                <DiscountToggle
                  active={fixedPrice}
                  handleToggle={() => setFixedPrice(!fixedPrice)}
                  label="Percentage / Fixed price"
                />
                {fixedPrice ? (
                  <Controller
                    name="discountInFixedAmount"
                    control={control}
                    rules={{
                      required: "This is a required field",
                      max: {
                        value: totalOrderAmount,
                        message: "Can't exceed total order amount",
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        placeholder={`1 - ${totalOrderAmount}`}
                        label={<strong>Discount in fixed price:</strong>}
                        icon="money-bill"
                        errors={errors}
                      />
                    )}
                  />
                ) : (
                  <Controller
                    name="discountInPercentage"
                    control={control}
                    rules={{
                      required: "This is a required field",
                      min: {
                        value: 1,
                        message: "Needs to be a number between 1-99",
                      },
                      max: {
                        value: 99,
                        message: "Needs to be a number between 1-99",
                      },
                      pattern: {
                        value: /^[\d]+$/,
                        message: "Only characters 0-9 are allowed",
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        placeholder="1 - 99"
                        label={<strong>Discount in percentage:</strong>}
                        icon="badge-percent"
                        errors={errors}
                      />
                    )}
                  />
                )}
              </form>
            </Dialog>
          );
        }}
      </Mutation>
    </>
  );
}
