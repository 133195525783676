import gql from "graphql-tag";

const SEARCH_PERSONS = gql`
query searchPersons($query: AWSJSON!) {
  searchPersons(query: $query) {
    totalHits
    persons {
      id
      email
      givenName
      familyName
      streetAddress
      houseNumberOrName
      postalCode
      region
      city
      country
      phone
      created
      lastUpdated
    }
  }
}
`;

export default SEARCH_PERSONS;
