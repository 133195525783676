import gql from "graphql-tag";

const DELETE_STORE = gql`
  mutation deleteStore($countryCode: String!, $languageCode: String!) {
    deleteStore(countryCode: $countryCode, languageCode: $languageCode) {
      countryCode
      languageCode
    }
  }
`;

export default DELETE_STORE;
