import React, { useState } from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import getFullCountryName from "helpers/getFullCountryName";
import FlagIcon from "components/Ui/FlagIcon";
import Button from "components/Ui/Button";

const Container = styled.div`
  width: 100%;
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  width: 100%;
  max-height: ${(p) => (p.expanded ? "none" : "34rem")};
  overflow: hidden;
  transition: all 0.3s;

  ${MEDIA_MIN_MEDIUM} {
    max-height: ${(p) => (p.expanded ? "none" : "35rem")};
  }
`;

const ListItem = styled.li`
  padding: 1.5rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};

  ${MEDIA_MIN_MEDIUM} {
    padding: 1.5rem 2rem;

    &:nth-child(2) {
      font-size: 2rem;

      img {
        width: 2.4rem;
      }
    }

    &:nth-child(3) {
      font-size: 1.8rem;

      img {
        width: 2.2rem;
      }
    }

    &:nth-child(4) {
      font-size: 1.6rem;

      img {
        width: 2rem;
      }
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-left: 1rem;
  }
`;

const Value = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled(ListItem)`
  padding: 2rem 0;
  font-weight: 700;

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem;
  }
`;

const Indicator = styled.div`
  margin-right: 2rem;
  padding-top: 0.2rem;

  i {
    font-size: 1.4rem;

    ${MEDIA_MIN_MEDIUM} {
      font-size: 1.8rem;
    }
  }

  .fa-arrow-alt-up {
    color: green;
    transform: rotate(45deg);
  }

  .fa-arrow-alt-down {
    color: red;
    transform: rotate(-45deg);
  }
`;

const Prev = styled.span`
  opacity: 0.4;
  margin-right: 1rem;
`;

const Current = styled.span`
  min-width: 5rem;
  text-align: right;
`;

const ExpandButton = styled(Button)`
  width: auto;
  height: 4rem;
  padding: 1rem 2.5rem;
`;

export default ({ obj, ...props }) => {
  const [expanded, setExpanded] = useState(false);
  const { prev, current } = obj;

  return (
    <Container {...props}>
      <h3>Top countries</h3>
      <List expanded={expanded}>
        <Header>
          <Title>Country</Title>
          <Value>Order count</Value>
        </Header>
        {current.data &&
          Object.keys(current.data)
            .slice()
            .sort((a, b) => current.data[b] - current.data[a])
            .map((key, index) => (
              <ListItem key={key}>
                <Title>
                  {index + 1}. <FlagIcon countryCode={key} /> {getFullCountryName(key)}
                </Title>
                <Value>
                  {prev?.data[key] && current.data[key] - prev.data[key] !== 0 && (
                    <>
                      <Prev>
                        {prev.data[key] && current.data[key] > prev.data[key] && <>+</>}
                        {prev.data[key] && current.data[key] - prev.data[key]}
                      </Prev>
                      <Indicator>
                        {prev.data[key] && prev.data[key] > current.data[key] && (
                          <i className="fas fa-arrow-alt-down"></i>
                        )}
                        {prev.data[key] && prev.data[key] < current.data[key] && (
                          <i className="fas fa-arrow-alt-up"></i>
                        )}
                      </Indicator>
                    </>
                  )}
                  <Current>{current.data[key]}</Current>
                </Value>
              </ListItem>
            ))}
      </List>
      <ExpandButton onClick={() => setExpanded(!expanded)}>
        {expanded ? "Show less" : "Show all"}
      </ExpandButton>
    </Container>
  );
};
