import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";

import SEARCH_ADDONS from "graphql/Product/SearchAddons";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import AddonsTable from "./AddonsTable";
import SearchProducts from "../SearchProducts";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Sidebar from "components/Product/Sidebar";

const AddonsBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
    }
  }
`;

const PAGE_SIZE = 25;

const Products = (props) => {
  const [esbQuery, setEsbQuery] = useState();
  const [searchFilters, setSearchFilters] = useState([]);

  useEffect(() => {
    const sort = esb.sort("lastUpdated", "desc");
    const activeProductsFilter = esb.queryStringQuery("type:addon AND !(archived:1)");
    const boolQuery = esb.boolQuery().filter([...searchFilters, activeProductsFilter]);

    setEsbQuery(
      esb.requestBodySearch().query(boolQuery).sort(sort).size(PAGE_SIZE).from(0)
    );
  }, [searchFilters]);

  if (!esbQuery) return null;
  return (
    <>
      <Breadcrumbs slugs={[["admin/products/addons", "Add-ons"]]} />
      <Header heading="Add-ons"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <AddonsBox preHeading="Overview" heading="Add-ons">
              <SearchProducts setSearchFilters={setSearchFilters} />
              <Query
                query={SEARCH_ADDONS}
                variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
                fetchPolicy={"cache-first"}>
                {({ loading, error, data, fetchMore }) => {
                  if (error)
                    return (
                      <ErrorMessage>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );

                  return (
                    <AddonsTable
                      data={data}
                      loading={loading}
                      pageSize={PAGE_SIZE}
                      fetchMore={fetchMore}
                      history={props.history}
                      esbQuery={esbQuery}
                    />
                  );
                }}
              </Query>
            </AddonsBox>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default (props) => {
  return Products(props);
};
