import React from "react";
import Order from "../Table/Row";
import OrderStatus from "./OrderStatus";
import Money from "../Money/Money";
import getFriendlyPaymentName from "../../helpers/getFriendlyPaymentName";
import getFullCountryName from "../../helpers/getFullCountryName";
import FlagIcon from "../Ui/FlagIcon";
import Moment from "react-moment";
import styled from "styled-components/macro";
import Column from "../Table/Column";
import { MEDIA_MIN_LARGE } from "../../variables/mediaQueries";
import { useQuery } from "@apollo/client";
import SEARCH_ORDERS from "../../graphql/Order/SearchOrders";
import esb from "elastic-builder";
import moment from "moment-timezone";
import Tooltip from "../Ui/Tooltip";

const Reference = styled(Column)`
width: 20%;
padding-left: 1rem;
font-size: 1.3rem;

${MEDIA_MIN_LARGE} {
  width: 10%;
  padding-left: 1rem;
}
`;

const Status = styled(Column)`
    width: 12%;
    font-size: 1.3rem;

    i {
        margin-right: 0;
    }

    ${MEDIA_MIN_LARGE} {
        width: 10%;
    }
`;

const CustomerInfo = styled(Column)`
  display: none;
    
  ${MEDIA_MIN_LARGE} {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 18%;
    font-size: 1.3rem;
  }
`;

const Email = styled.span`
  ${MEDIA_MIN_LARGE} {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
  }
`;

const AmountWithDiscount = styled(Column)`
    width: 33%;
    font-size: 1.3rem;

    ${MEDIA_MIN_LARGE} {
        width: 15%;
    }
`;

const PaymentMethod = styled(Column)`
    width: 15%;
    padding-right: 2rem;
    font-size: 1.3rem;
`;

const Country = styled(Column)`
    width: 15%;
    font-size: 1.3rem;

    ${MEDIA_MIN_LARGE} {
        width: 10%;
    }

    img {
        margin: 0.5rem 0 0;
        padding-bottom: 0.3rem;

        ${MEDIA_MIN_LARGE} {
            padding-bottom: 0.2rem;
        }
    }
`;

const Date = styled(Column)`
    width: 35%;
    font-size: 1.3rem;

    ${MEDIA_MIN_LARGE} {
        width: 12.5%;
    }
`;

const FollowUpOrder = styled.strong`
    width: 35%;
    font-size: 1.3rem;

    ${MEDIA_MIN_LARGE} {
        width: 10%;
    }
  &:hover {
    text-decoration: underline;
  }
`;

const OrderRow = ({ order, tabs, setTabs, setActiveTab }) => {

  const query = esb.requestBodySearch()
    .sort(esb.sort("lastUpdated", "desc"))
    .size(1)
    .from(0)
    .query(
      esb.boolQuery().must([
        esb.matchQuery("statusLog.status", "success"),
        esb.termQuery("personId.keyword", order.person.id),
        esb
          .rangeQuery("created")
          .gte(`${moment(order.created).format("YYYY-MM-DD")}T23:59`.replace("T", "-"))
          .lte(`${moment().format("YYYY-MM-DD")}T23:59`.replace("T", "-"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(moment.tz.guess()),
      ])
    );

  const { data } = useQuery(SEARCH_ORDERS, {
    variables: { query: JSON.stringify(query).toString() },
  });

  const handleOrderClick = (id, reference, status, created) => {
    !tabs.find((item) => item.id === id) &&
      setTabs((prev) => [...prev, { id, reference, status, created }]);
    setActiveTab(id);
  };

  const formatName = (order) =>
    order.shippingAddress
      ? `${order.shippingAddress.givenName} ${order.shippingAddress.familyName}`
      : "";

  const formatEmail = (order) => (order.shippingAddress ? `${order.shippingAddress.email}` : "");

  const followUpOrder = data?.searchOrders?.orders[0];

  return (
    <Order
      key={order.id}
      onClick={() => handleOrderClick(order.id, order.reference, order.status, order.created)}>
      <Reference>
        <span>
          <strong>{order.reference}</strong>
        </span>
      </Reference>
      <Status>
        <OrderStatus status={order.status} showIcon />
      </Status>
      <CustomerInfo>
        {formatName(order)} <Email> {formatEmail(order)} </Email>
      </CustomerInfo>
      <AmountWithDiscount>
        <Money
          amount={
            order?.taxIncludedPricing === false
              ? order.orderAmountWithDiscount + order.orderTaxAmount
              : order.orderAmountWithDiscount
          }
          currencyUnit={order.currencyUnit}
        />
      </AmountWithDiscount>
      <PaymentMethod hideOnMobile>
        {order.paymentMethod ? getFriendlyPaymentName(order.paymentMethod) : "-"}
      </PaymentMethod>
      <Country hideOnMobile>
        <span data-tip={getFullCountryName(order.country)}>
          {order.country ? <FlagIcon countryCode={order.country} /> : "-"}
        </span>
      </Country>
      <Date>
        <Moment format="YYYY-MM-DD, HH:mm">{order.created}</Moment>
      </Date>
      <Reference
        onClick={(event) => {
          if (followUpOrder) {
            event.stopPropagation();
            handleOrderClick(
              followUpOrder?.id,
              followUpOrder?.reference,
              followUpOrder?.status,
              followUpOrder?.created
            );
          }
        }}>
        {followUpOrder ? (
          <>
            <Tooltip />
            <FollowUpOrder
              data-tip={`Successful order created ${moment(followUpOrder?.created).format(
                "YYYY-MM-DD, HH:mm"
              )}`}>
              {followUpOrder?.reference}
            </FollowUpOrder>
          </>
        ) : (
          <strong
            data-tip={`No successful order created after ${moment(order.created).format(
              "YYYY-MM-DD, HH:mm"
            )}`}>
            -
          </strong>
        )}
      </Reference>
    </Order>
  );
};

export default OrderRow;
