import gql from "graphql-tag";

const SEARCH_SHIPPING_OPTIONS = gql`
  query searchProducts($query: AWSJSON!) {
    searchProducts(query: $query) {
      totalHits
      products {
        id
        name
        imageUrl
        category
        created
        lastUpdated
        type
        active
        archived
      }
    }
  }
`;

export default SEARCH_SHIPPING_OPTIONS;
