import React from "react";
import styled from "styled-components/macro";
import uuid from "react-uuid";
import TableHeader from "components/Table/Header";
import Products from "components/Table/Rows";
import Row from "components/Table/Row";
import Column from "components/Table/Column";
import Loader from "components/Ui/Loader";
import Placeholder from "components/Ui/PlaceholderImage";

const Header = styled(TableHeader)``;

const Product = styled(Row)`
  width: 100% !important;
  min-height: 6rem;
`;

const Image = styled.img`
  width: 5rem;
  margin-right: 1rem;
`;

const PlaceholderImage = styled(Placeholder)`
  width: 6rem;
  margin-left: 0;
`;

const Placement = styled(Column)`
  width: 10%;
`;

const Number = styled.strong`
margin-left: 1rem;
`

const Name = styled(Column)`
  width: 50%;
`;

const Category = styled(Column)`
  width: 25%;
`;

const ItemsSold = styled(Column)`
display: flex;
justify-content: center;
  width: 15%;
`;

export default function MostSoldProductsTable({ data, buckets, loading, history }) {

  const handleProductClick = (productId) => {
    history.push(`/admin/product/${productId.replaceAll("/", "~")}`);
  };

  const products = data && data.getProducts;
  return (
    <>
      <Header>
        <Placement>
          <span>No. </span>
        </Placement>
        <Name>
          <span>Name </span>
        </Name>
        <Category>
          <span>Category </span>
        </Category>
        <ItemsSold>
          <span>Items sold </span>
        </ItemsSold>
      </Header>
      <Products>
        {loading && <Loader />}
        {products &&
          !loading &&
          buckets
            .filter(
              (bucket) =>
                products.find((product) => product.id === bucket.key)?.type === "productVariant"
            )
            .map((bucket, index) => {
              const product = products.find((product) => product.id === bucket.key);
              return (
                <Product key={uuid()} onClick={() => handleProductClick(product.relatedProduct.id)}>
                   <Placement>
                    <Number>{index +1}</Number>
                  </Placement>
                  <Name>
                    {product.imageUrl ? (
                      <Image src={`${product.imageUrl}?fm=webp&w=150`} alt={product.name} />
                    ) : (
                      <PlaceholderImage />
                    )}
                    <strong>{product.name}</strong>
                  </Name>
                  <Category hideOnMobile>{product.category}</Category>
                  <ItemsSold>
                    <strong>{bucket.doc_count.toLocaleString()}</strong>
                  </ItemsSold>
                </Product>
              );
            })}
      </Products>
    </>
  );
}
