import React, { useContext, useState } from "react";
import esb from "elastic-builder";
import useLocalStorage from "../helpers/useLocalStorage";

export const AdminContext = React.createContext({});
export const useAdminContext = () => useContext(AdminContext);

const ORDERS_PER_PAGE = 25;
const CURRENT_PAGE = 1;
const INCLUDE_LIST = [
  { value: "created", label: "Created" },
  { value: "lastUpdated", label: "Last Updated" },
];

const ALL_ORDERS = esb.requestBodySearch()
  .sort(esb.sort("lastUpdated", "desc"))
  .size(ORDERS_PER_PAGE)
  .from(0)
  .query(esb.boolQuery().must([esb.matchQuery("statusLog.status", "success")]))
  .trackTotalHits(true);

const ALL_ORDERS_FOLLOW_UP = esb.requestBodySearch()
  .sort(esb.sort("lastUpdated", "desc"))
  .size(ORDERS_PER_PAGE)
  .from(0)
  .query(esb.boolQuery().mustNot([esb.matchQuery("statusLog.status", "success")]))
  .trackTotalHits(true);

const defaultOrderFilter = {
  esbQuery: ALL_ORDERS,
};

const defaultOrderFollowUpFilter = {
  esbQuery: ALL_ORDERS_FOLLOW_UP,
};

const defaultOrderTable = {
  currentPage: CURRENT_PAGE,
  ordersPerPage: ORDERS_PER_PAGE,
};

const defaultOrderFollowUpTable = {
  currentPage: CURRENT_PAGE,
  ordersPerPage: ORDERS_PER_PAGE,
};

export const AdminProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [esbQueryFollowUp, setEsbQueryFollowUp] = useState(ALL_ORDERS_FOLLOW_UP);
  const [customerTabs, setCustomerTabs] = useState([]);
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [include, setInclude] = useState(INCLUDE_LIST[0].value);
  const [ordersFollowUpTableQuery, setOrdersFollowUpTableQuery] = useState();
  const [currentPageCustomer, setCurrentPageCustomer] = useState(1);
  const [orderFilter, setOrderFilter] = useLocalStorage("order-filter", defaultOrderFilter);
  const [orderFollowUpFilter, setOrderFollowUpFilter] = useLocalStorage(
    "order-filter-follow-up",
    defaultOrderFollowUpFilter,
  );
  const [orderTableSettings, setOrderTableSettings] = useLocalStorage(
    "order-table-settings",
    defaultOrderTable,
  );
  const [orderFollowUpTableSettings, setOrderFollowUpTableSettings] = useLocalStorage(
    "order-table-settings-follow-up",
    defaultOrderFollowUpTable,
  );


  const updateOrderFilter = (key, value, filterActive = orderFilter?.filterActive ?? false) => {
    setOrderFilter({ ...orderFilter, [key]: value, filterActive });
  };

  const clearOrderFilter = () => {
    setOrderFilter(defaultOrderFilter);
  };

  const updateOrderFollowUpFilter = (key, value, filterActive = orderFollowUpFilter?.filterActive ?? false) => {
    setOrderFollowUpFilter({ ...orderFollowUpFilter, [key]: value, filterActive });
  };

  const clearOrderFollowUpFilter = () => {
    setOrderFollowUpFilter(defaultOrderFollowUpFilter);
  };

  const updateOrderTableSettings = (key, value) => {
    setOrderTableSettings({ ...orderTableSettings, [key]: value });
  };

  const updateOrderFollowUpTableSettings = (key, value) => {
    setOrderFollowUpTableSettings({ ...orderFollowUpTableSettings, [key]: value });
  };

  return (
    <AdminContext.Provider
      value={{
        ALL_ORDERS,
        ALL_ORDERS_FOLLOW_UP,
        ORDERS_PER_PAGE,
        orderFilter,
        orderFollowUpFilter,
        tabs,
        user,
        customerTabs,
        showFilterForm,
        esbQueryFollowUp,
        include,
        currentPageCustomer,
        orderTableSettings,
        orderFollowUpTableSettings,
        defaultOrderFollowUpFilter,
        defaultOrderFilter,
        ordersFollowUpTableQuery,
        setOrdersFollowUpTableQuery,
        updateOrderFilter,
        clearOrderFilter,
        updateOrderFollowUpFilter,
        clearOrderFollowUpFilter,
        setTabs,
        setCustomerTabs,
        setShowFilterForm,
        setUser,
        setEsbQueryFollowUp,
        setInclude,
        setCurrentPageCustomer,
        updateOrderTableSettings,
        updateOrderFollowUpTableSettings,
      }}>
      {children}
    </AdminContext.Provider>
  );
};
