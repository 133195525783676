import gql from "graphql-tag";

const DELETE_WEBHOOK_CONFIGURATION = gql`
  mutation deleteWebhookConfiguration($service: WebhookConfigurationType!, $name: String!) {
    deleteWebhookConfiguration(service: $service, name: $name) {
      name
    }
  }
`;

export default DELETE_WEBHOOK_CONFIGURATION;
