import React from "react";
import styled from "styled-components/macro";

import Button from "components/Ui/Button";

const ButtonOutlined = styled(Button)`
  background: transparent;
  border: 0.1rem solid ${(p) => p.theme.colors.darkerGrey};
  color: ${(p) => p.theme.colors.white};
  height: auto;
  width: auto;
  margin: 0 1rem;
  font-weight: 400;
  padding: 0.8rem 1.5rem 0.8rem 1rem;
  transition: all 0.2s;

  i {
    font-size: 1.8rem;
    margin-right: 0.5rem;
  }

  &:hover {
    background: ${(p) => p.theme.colors.darkerGrey};
    color: ${(p) => p.theme.colors.black};
  }
`;

export default ({ children, handleOnClick, ...props }) => (
  <ButtonOutlined onClick={handleOnClick ? (e) => handleOnClick(e) : null} {...props} type="button">
    {children}
  </ButtonOutlined>
);
