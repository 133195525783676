import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import esb from "elastic-builder";
import uuid from "react-uuid";
import moment from "moment-timezone";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import SearchForm from "components/Table/Search/SearchForm";
import QuickFilter from "components/Table/Filter/QuickFilter";
import QuickFilterOption from "components/Table/Filter/QuickFilterOption";
import ToggleButton from "components/Table/Filter/ToggleButton";
import ClearButton from "components/Table/Filter/ClearButton";
import Timespan from "components/Table/Filter/Timespan";
import StoreSelector from "components/StoreSelector/StoreSelector";
import FilterButton from "components/Ui/FilterButton";
import FilterForm from "components/Filter/FilterForm";

const Buttons = styled.div`
  display: flex;
  padding: 0.5rem 0;
  margin: 1.5rem auto 1.5rem;

  ${MEDIA_MIN_LARGE} {
    margin: 1.5rem 0;
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;

  ${MEDIA_MIN_LARGE} {
    width: auto;
    padding-bottom: 0;
  }
`;

const StoreFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${MEDIA_MIN_LARGE} {
    margin-left: 1rem;
    flex-wrap: nowrap;
    width: auto;
  }
`;

const StoreSelect = styled(StoreSelector)`
  .multi-select {
    .dropdown-heading {
      height: 4rem;
    }
  }
`;

const FILTER_STATUSES = [
  { value: "success", label: "Success" },
  { value: "shipped", label: "Shipped" },
  { value: "failed", label: "Failed" },
  { value: "canceled", label: "Canceled" },
];
const DEFAULT_STATUS = [];
const DEFAULT_DATETIME_FROM = `${moment().subtract(1, "month").format("YYYY-MM-DD")}T00:00`;
const DEFAULT_DATETIME_TO = `${moment().format("YYYY-MM-DD")}T23:59`;

export default ({ allOrders, setEsbQuery, ordersPerPage, discountId }) => {
  const [searchInput, setSearchInput] = useState("");
  const [defaultQuery] = useState(allOrders);
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [filterDateTimeFrom, setFilterDateTimeFrom] = useState(DEFAULT_DATETIME_FROM);
  const [filterDateTimeTo, setFilterDateTimeTo] = useState(DEFAULT_DATETIME_TO);
  const [filterStatusOptionValues, setFilterStatusOptionValues] = useState(DEFAULT_STATUS);
  const [filterStores, setFilterStores] = useState([]);

  const searchOrders = (event) => {
    event.preventDefault();
    searchInput.length ? setInputError(false) : setInputError(true);
    if (searchInput && searchInput.replace(/\s/g, "") !== "") {
      setEsbQuery(
        esb.requestBodySearch().query(
          esb
            .queryStringQuery(
              `${searchInput.replace(
                "@",
                "*"
              )} AND discount:${discountId} AND statusLog.status:success`
            )
            .analyzeWildcard()
        )
      );
    } else {
      setSearchInput("");
      setEsbQuery(defaultQuery);
    }
  };

  const filterOrders = (event) => {
    document.getElementById("searchForm").scrollIntoView({ behavior: "smooth" });
    event && event.preventDefault();


    const boolQuery = esb.boolQuery().must(
      esb
        .rangeQuery("created")
        .gte(filterDateTimeFrom.replace("T", "-"))
        .lte(filterDateTimeTo.replace("T", "-"))
        .format("yyyy-MM-dd-HH:mm")
        .timeZone(moment.tz.guess())
    );
    if (filterStatusOptionValues.length) {
      boolQuery.must(esb.termsQuery("status", filterStatusOptionValues));
    }

    boolQuery.must([
      esb.termQuery("discount.keyword", discountId),
      esb.matchQuery("statusLog.status", "success"),
    ]);

    if (filterStores.length) {
      boolQuery.must(
        esb.termsQuery(
          "shippingAddress.country",
          filterStores.map((s) => s.value.toLowerCase())
        )
      );
    }

    setEsbQuery(
      esb
        .requestBodySearch()
        .query(boolQuery)
        .sort(esb.sort("lastUpdated", "desc"))
        .size(ordersPerPage)
        .from(0)
    );
  };

  const clearFilter = (event) => {
    event.preventDefault();
    clearFilterInput();
    setEsbQuery(defaultQuery);
  };

  const clearFilterInput = () => {
    setFilterStatusOptionValues(DEFAULT_STATUS);
    setFilterDateTimeFrom(DEFAULT_DATETIME_FROM);
    setFilterDateTimeTo(DEFAULT_DATETIME_TO);
    setSearchInput("");
    setFilterStores([]);
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    filterOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatusOptionValues]);

  return (
    <>
      <SearchForm
        id="searchForm"
        autoComplete="off"
        placeholder="Order number, e-mail, name..., (Use * as wildcard)"
        inputError={inputError}
        setInputError={setInputError}
        setSearchInput={setSearchInput}
        searchFunc={searchOrders}
        defaultValue={searchInput}
      />

      <QuickFilter expanded={showFilterForm.toString()}>
        Filter:
        <div>
          <QuickFilterOption
            active={filterStatusOptionValues.length === 0}
            onClick={() => {
              setFilterStatusOptionValues([]);
            }}>
            All
          </QuickFilterOption>
          {FILTER_STATUSES.map((status) => (
            <QuickFilterOption
              key={uuid()}
              active={filterStatusOptionValues.includes(status.value)}
              onClick={() => {
                setFilterStatusOptionValues(
                  filterStatusOptionValues.includes(status.value)
                    ? filterStatusOptionValues.filter((value) => value !== status.value)
                    : [...filterStatusOptionValues, status.value]
                );
              }}>
              {status.label}
            </QuickFilterOption>
          ))}
        </div>
        <ToggleButton
          showFilterForm={showFilterForm}
          onClick={() => setShowFilterForm(!showFilterForm)}
        />
      </QuickFilter>
      {showFilterForm && (
        <FilterForm>
          <Timespan>
            <div>
              <Label>From:</Label>
              <input
                type="datetime-local"
                id="fromDate"
                name="fromDate"
                value={filterDateTimeFrom}
                onChange={(e) => setFilterDateTimeFrom(e.target.value)}
              />
            </div>
            <div>
              <Label>To:</Label>
              <input
                type="datetime-local"
                id="toDate"
                name="toDate"
                value={filterDateTimeTo}
                onChange={(e) => setFilterDateTimeTo(e.target.value)}
              />
            </div>
          </Timespan>
          <StoreFilter>
            <Label>Stores:</Label>
            <StoreSelect selectedStores={filterStores} setSelectedStores={setFilterStores} />
          </StoreFilter>
          <Buttons>
            <FilterButton isLoading={false} onClick={filterOrders}>
              <i className="fal fa-sliders-h"></i> Filter
            </FilterButton>
            <ClearButton onClick={clearFilter} />
          </Buttons>
        </FilterForm>
      )}
    </>
  );
};
