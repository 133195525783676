import React, { useEffect, useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import esb from "elastic-builder";

import DELETE_DISCOUNT_CODE from "graphql/Discount/DiscountCode/DeleteDiscountCode";
import { useNotification } from "context/NotificationContext";
import Loader from "components/Ui/Loader";
import ProgressLoader from "components/Ui/ProgressLoader";
import Dialog from "components/Dialog/Dialog";
import { useQuery } from "@apollo/client";
import SEARCH_DISCOUNT_CODES_ID from "../../graphql/Discount/DiscountCode/SearchDiscountCodesId";

export default ({ id, setDeleteOpen, history }) => {
  const { setNotification } = useNotification();
  const [voucherCodes, setVoucherCodes] = useState()
  const [voucherGroupLoading, setVoucherGroupLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const esbBoolQuery = esb
    .boolQuery()
    .must([
      esb.termsQuery("group.keyword", id),
      esb.queryStringQuery("!(archived:1) AND (type:VOUCHER)"),
    ]);


  const voucherGroupDeleted = () => {
    setVoucherGroupLoading(false);
    setNotification({
      status: "success",
      message: `Voucher group "${id}" successfully deleted`,
    });
    history.push(`/admin/vouchers`);
  };

  const BATCH_SIZE = 500;

  const voucherGroupQuery = esb.requestBodySearch().query(esbBoolQuery).size(BATCH_SIZE);
  const { data, fetchMore, loading, error } = useQuery(SEARCH_DISCOUNT_CODES_ID, {
    variables: { query: JSON.stringify(voucherGroupQuery.toJSON()) },
  });

  useEffect(() => {
    const fetchAllData = async () => {
      if (loading) return;
      if (error) {
        console.error(error);
        return;
      }
      const size = data.searchDiscounts.totalHits;

      let discountCodes = [...data.searchDiscounts.discounts];
      const loops = Math.trunc(size / BATCH_SIZE);
      for (let i = 0; i < loops; i++) {
        const from = (i + 1) * BATCH_SIZE;
        const nextBatch = await fetchMore({
          variables: {
            query: JSON.stringify(voucherGroupQuery.size(BATCH_SIZE).from(from).toJSON()),
          },
        });
        discountCodes = discountCodes.concat([...nextBatch.data.searchDiscounts.discounts]);
      }
      setVoucherCodes(discountCodes);
    };

    data && fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data]);

  return (
          <Mutation
            mutation={DELETE_DISCOUNT_CODE}
            onError={() => {
              setNotification({
                status: "error",
                message: `Discount rule "${id}" could not be deleted, please contact support`,
              });
            }}>
            {(deleteDiscount) => {
              const deleteVoucherGroup = async (voucherCodes) => {
                setVoucherGroupLoading(true);

                let req = [];
                for (let i = 0; i < voucherCodes.length; i++) {
                  req.push(
                    deleteDiscount({
                      variables: { ...voucherCodes[i], deleteMode: "SOFT" },
                    })
                  );
                  if ((i + 1) % 10 === 0) {
                    await Promise.all(req);
                    await new Promise((r) => setTimeout(r, 500));
                    req = [];
                    setPercentage(Math.round((i / voucherCodes.length) * 100));
                  }
                }
                if (req.length > 0) {
                  await Promise.all(req);
                  setPercentage(100);
                }
                voucherGroupDeleted();
              };

              if (error) {
                setNotification({
                  status: "error",
                  message: `Voucher group "${id}" could not be deleted, please contact support`,
                });
                setDeleteOpen(false);
              }
              if (loading) return <Loader color="black" />;
              if (!voucherCodes) return <Loader color="black" />;


              return (
                <>
                  <Dialog
                    header="Delete voucher group?"
                    text="Deleting the voucher group will not effect already used vouchers"
                    handleClose={() => setDeleteOpen(false)}
                    open={true}
                    handleOk={() => deleteVoucherGroup(voucherCodes)}
                  />
                  {voucherGroupLoading && <ProgressLoader text={percentage} />}
                </>
              );
            }}
          </Mutation>
  );
};
