import React, { useState } from "react";
import getFullCountryName from "helpers/getFullCountryName";

import SearchForm from "components/Table/Search/SearchForm";

export default ({ setStores, allStores }) => {
  const [searchInput, setSearchInput] = useState("");
  const [inputError, setInputError] = useState(false);

  const searchStores = (evt) => {
    evt.preventDefault();
      searchInput.length ? setInputError(false) : setInputError(true);
      const result = allStores.filter(store => getFullCountryName(store.countryCode).toLowerCase().includes(searchInput.toLowerCase()))
      setStores(result)
  };

  return (
    <SearchForm
      autoComplete="off"
      placeholder="Country name..."
      inputError={inputError}
      setInputError={setInputError}
      setSearchInput={setSearchInput}
      searchFunc={searchStores}
    />
  );
};
