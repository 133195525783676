import React from "react";
import styled from "styled-components/macro";
import { alerts } from "variables/colors";

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: ${(p) => (p.inBox ? "2rem 0" : "2rem")};
  border: none;
  border-radius: 0.3rem;
  padding: 1.7rem 3rem;
  background: ${alerts.error.background};
  color: ${alerts.error.text};
  height: 5rem;

  i {
    font-size: 2.4rem;
    margin-right: 0.8rem;
  }
`;

export default ({ children, inBox, ...other }) => (
  <ErrorMessage inBox={inBox} {...other}>
    <i className="fal fa-exclamation-circle"></i> {children}
  </ErrorMessage>
);
