import React, { useState, useEffect } from "react";
import { Query } from "@apollo/client/react/components";
import styled from "styled-components/macro";
import ORDER_AGGREGATE from "../../graphql/Order/OrderAggregate";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import esb from "elastic-builder";
import GET_PRODUCTS from "../../graphql/Product/GetProducts";
import moment from "moment-timezone";
import Box from "components/Content/Box";
import Sidebar from "components/Product/Sidebar";
import PageContainer from "components/Page/PageContainer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PeriodSelector from "components/Product/PeriodSelector";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import MostSoldProductsTable from "./MostSoldProductsTable";
import Timespan from "components/Table/Filter/Timespan";
import Input from "components/Ui/Input";

const Container = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

const StyledBox = styled(Box)`
  padding: 3rem 3rem 1rem;
  min-height: 40rem;
`;

const CustomTime = styled(Timespan)`
  margin: 2rem 0;
  display: flex;
  justify-content: flex-end;
  div {
    margin-left: 1rem;
    width: auto;
  }
  input {
    height: 4rem;
    width: 17rem;
    padding: 0.1rem 1rem 0;
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;

  ${MEDIA_MIN_LARGE} {
    width: auto;
    padding-bottom: 0;
  }
`;

const Table = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
`;

const DEFAULT_DATETIME_FROM = moment().subtract(1, "month").startOf("day");
const DEFAULT_DATETIME_TO = moment().endOf("day");

export default (props) => {
  const [mostSoldVariants, setMostSoldVariants] = useState();
  const [filterDateTimeFrom, setFilterDateTimeFrom] = useState(DEFAULT_DATETIME_FROM);
  const [filterDateTimeTo, setFilterDateTimeTo] = useState(DEFAULT_DATETIME_TO);
  const [customPeriod] = useState({
    id: "custom",
    length: 90,
    format: "YYYY-MM-DD",
    interval: "DAY",
    type: "days",
    shortFormat: "dd",
    current: {
      label: "Custom",
      startDate: DEFAULT_DATETIME_FROM,
      endDate: DEFAULT_DATETIME_TO,
    },
    prev: {
      label: "Custom",
      startDate: DEFAULT_DATETIME_FROM,
      endDate: DEFAULT_DATETIME_TO,
    },
  });

  const periods = {
    day: {
      id: "day",
      length: 24,
      format: "k",
      interval: "HOUR",
      type: "hour",
      shortFormat: "HH",
      current: {
        label: "Last 24h",
        startDate: moment().subtract(24, "hours").startOf("hour"),
        endDate: moment(),
      },
      prev: {
        label: "Previous 24h",
        startDate: moment().subtract(48, "hours").startOf("hour"),
        endDate: moment().subtract(24, "hours").startOf("hour"),
      },
    },
    week: {
      id: "week",
      length: 7,
      format: "YYYY-MM-DD",
      interval: "DAY",
      type: "days",
      shortFormat: "dd",
      current: {
        label: "Last 7 days",
        startDate: moment().subtract(6, "days").startOf("day"),
        endDate: moment().add(1, "days").startOf("day"),
      },
      prev: {
        label: "Previous 7 days",
        startDate: moment().subtract(13, "days").startOf("day"),
        endDate: moment().subtract(6, "days").startOf("day"),
      },
    },
    month: {
      id: "month",
      length: 30,
      format: "YYYY-MM-DD",
      interval: "DAY",
      type: "days",
      shortFormat: "dd",
      current: {
        label: "Last 30 days",
        startDate: moment().subtract(29, "days").startOf("day"),
        endDate: moment().add(1, "days").startOf("day"),
      },
      prev: {
        label: "Previous 30 days",
        startDate: moment().subtract(59, "days").startOf("day"),
        endDate: moment().subtract(29, "days").startOf("day"),
      },
    },
    quarter: {
      id: "quarter",
      length: 90,
      format: "YYYY-MM-DD",
      interval: "DAY",
      type: "days",
      shortFormat: "dd",
      current: {
        label: "Last 90 days",
        startDate: moment().subtract(89, "days").startOf("day"),
        endDate: moment().add(1, "days").startOf("day"),
      },
      prev: {
        label: "Previous 90 days",
        startDate: moment().subtract(179, "days").startOf("day"),
        endDate: moment().subtract(89, "days").startOf("day"),
      },
    },
  };

  const [period, setPeriod] = useState(periods.day);

  const updateFromDate = (date) => {
    const updateDate = moment(date);
    if (updateDate.isValid() && updateDate.isBefore(filterDateTimeTo, "day")) {
      setFilterDateTimeFrom(updateDate);
      updateCustomPeriodFrom(date);
    }
  };

  const updateToDate = (date) => {
    const updateDate = moment(date);
    if (updateDate.isValid() && updateDate.isAfter(filterDateTimeFrom, "day")) {
      setFilterDateTimeTo(updateDate);
      updateCustomPeriodTo(date);
    }
  };

  const updateCustomPeriodFrom = (fromDate) => {
    const startDate = moment(fromDate);
    const current = {
      label: "Custom",
      startDate: startDate.startOf("day"),
      endDate: filterDateTimeTo.endOf("day"),
    };
    setPeriod({
      ...customPeriod,
      ...getCustomPeriodConfig(current.startDate, current.endDate),
      current: current,
    });
  };

  const updateCustomPeriodTo = (toDate) => {
    const endDate = moment(toDate);
    const current = {
      label: "Custom",
      startDate: filterDateTimeFrom.startOf("day"),
      endDate: endDate.endOf("day"),
    };
    setPeriod({
      ...customPeriod,
      ...getCustomPeriodConfig(current.startDate, current.endDate),
      current: current,
    });
  };

  const getCustomPeriodConfig = (startDate, endDate) => {
    if (endDate.diff(startDate, "days") >= 1) {
      return { length: 90, format: "YYYY-MM-DD", interval: "DAY", type: "days", shortFormat: "dd" };
    } else {
      return { length: 24, format: "k", interval: "HOUR", type: "hour", shortFormat: "HH" };
    }
  };

  useEffect(() => {
    setMostSoldVariants(
      esb.requestBodySearch()
        .query(
          esb
            .boolQuery()
            .must([
              esb.matchQuery("statusLog.status", "success"),
              esb
                .rangeQuery("created")
                .gte(period.current.startDate.format("YYYY-MM-DD-HH:mm"))
                .lte(period.current.endDate.format("YYYY-MM-DD-HH:mm"))
                .format("yyyy-MM-dd-HH:mm")
                .timeZone(moment.tz.guess())
            ])
        )
        .agg(esb.termsAggregation("variants", "orderLines.productId.keyword").size(100))
    );
  }, [period]);

  if (!mostSoldVariants) return null;

  return (
    <>
      <Breadcrumbs slugs={[["admin/most-sold-products", "most-sold-products"]]} />
      <Header heading="Most sold products"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <StyledBox
              preHeading="Top 100"
              heading="Most sold variants"
              headingIcon="award"
              highLightIcon>
              <Container>
                <Table>
                  <PeriodSelector
                    period={period}
                    periods={periods}
                    setPeriod={setPeriod}
                    customPeriod={customPeriod}
                  />
                  {period.id === "custom" && (
                    <CustomTime>
                      <div>
                        <Label>From:</Label>
                        <Input
                          type="date"
                          id="fromDate"
                          max={filterDateTimeTo.format("YYYY-MM-DD")}
                          name="fromDate"
                          value={filterDateTimeFrom.format("YYYY-MM-DD")}
                          onChange={(e) => updateFromDate(e.target.value)}
                        />
                      </div>
                      <div>
                        <Label>To:</Label>
                        <Input
                          type="date"
                          id="toDate"
                          min={filterDateTimeFrom.format("YYYY-MM-DD")}
                          name="toDate"
                          max={moment().format("YYYY-MM-DD")}
                          value={filterDateTimeTo.format("YYYY-MM-DD")}
                          onChange={(e) => updateToDate(e.target.value)}
                        />
                      </div>
                    </CustomTime>
                  )}
                  <Query
                    query={ORDER_AGGREGATE}
                    fetchPolicy="network-only"
                    variables={{ query: JSON.stringify(mostSoldVariants.toJSON()) }}>
                    {({ loading: loadingAggregate, error, data }) => {
                      if (loadingAggregate) return null;
                      if (error)
                        return (
                          <ErrorMessage>
                            An error occurred when loading data, please contact support
                          </ErrorMessage>
                        );
                      const buckets = JSON.parse(data.orderAggregates.aggregations).variants
                        .buckets;
                      return (
                        <Query
                          query={GET_PRODUCTS}
                          variables={{
                            ids: buckets.map((bucket) => bucket.key),
                          }}>
                          {({ loading, error, data: dataProducts }) => {
                            if (error)
                              return (
                                <ErrorMessage>
                                  An error occurred when loading data, please contact support
                                </ErrorMessage>
                              );
                            return (
                              <MostSoldProductsTable
                                data={dataProducts}
                                buckets={buckets}
                                loading={loading}
                                history={props.history}
                              />
                            );
                          }}
                        </Query>
                      );
                    }}
                  </Query>
                </Table>
              </Container>
            </StyledBox>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};
