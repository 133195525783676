import React, { useState } from "react";
import Moment from "react-moment";
import { Query } from "@apollo/client/react/components";
import GET_STOCK_INVENTORY from "graphql/Stock/GetStockInventory";
import styled from "styled-components/macro";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import UpdateProductStock from "components/Product/UpdateProductStock";
import Price from "components/Product/Price";
import IconButton from "components/Ui/Buttons/IconButton";
import Placeholder from "components/Ui/PlaceholderImage";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import getStockVersion from "helpers/getStockVersion";
import getEditStock from "helpers/getEditStock";

const Variant = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${(p) => p.theme.colors.secondaryBackground};
  box-shadow: 0 0.2rem 1.6rem 0 rgb(0 0 0 / 8%);
  padding: 2rem;
  position: relative;
  width: 100%;
  margin: 0 0 2rem 0;
  overflow: hidden;

  ${MEDIA_MIN_MEDIUM} {
    padding: 2.5rem;
  }
`;

const ToggleButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;

  img {
    max-width: 100%;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
  cursor: pointer;
  padding-right: 5rem;
`;

const Details = styled.div`
  padding: 0.5rem 0 0 1rem;
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    padding-left: 3rem;
  }
`;

const Name = styled.h5`
  margin-top: 0.2rem;
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 0.05rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.5rem;
  }

  .fa-check-circle {
    margin-left: 1rem;
    color: green;
  }

  .fa-times-circle {
    margin-left: 1rem;
    color: red;
  }
`;

const VariantId = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  color: ${(p) => p.theme.colors.darkerGrey};
  text-transform: initial;
  line-height: 1.3rem;
  margin: 0.5rem 0;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.5rem;
    margin: 1rem 0 0.5rem;
  }
`;

const ExtendedDetails = styled.div`
  width: 100%;
  border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  margin-top: 2rem;
`;

const Prices = styled.div`
  padding: 2rem 0 0;
  width: 100%;

  > div {
    margin: 0.5rem -0.5rem 0;

    ${MEDIA_MIN_MEDIUM} {
      margin: 1rem -1rem 0;
    }
  }
`;

const OutOfStock = styled.span`
  background: ${(p) => p.theme.colors.primaryGradient};
  color: ${(p) => p.theme.colors.buttontext};
  text-transform: initial;
  padding: 0.3rem 4rem 0.2rem;
  transform: rotate(-45deg);
  z-index: 1;
  position: absolute;
  left: -4rem;
  top: 3rem;
  font-size: 1rem;
  text-transform: uppercase;
`;

const Stock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  p {
    margin: 0;
  }
`;

const Inventories = styled.div`
  padding-top: 4rem;
  i {
    padding-right: 1rem;
    font-size: 2rem;
  }
`;

const InventoryHeader = styled.div`
  display: block;
  p {
    text-transform: uppercase;
    margin: 0;
    padding-top: 0.5rem;
    font-size: 1.1rem;
  }
`;

const ObjectValue = styled.div`
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 0.1rem solid ${(p) => p.theme.colors.checkboxBorder};
`;

const ObjectLabel = styled.span`
  font-weight: 700;
`;

const AttributeHeader = styled.div`
  border-bottom: 0.2rem solid
    ${(p) => (p.expanded ? p.theme.colors.primary : p.theme.colors.backgroundOpac)};
  padding: 2rem 0;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  i {
    font-size: 1.3rem;
  }
  &:hover {
    cursor: pointer;
    border-bottom: 0.2rem solid ${(p) => p.theme.colors.primary};
  }
`;

export default ({ variant, expanded = false }) => {
  const [expandedDetails, setExpandedDetails] = useState(expanded);
  const [expandedCustomerAttributes, setExpandedCustomerAttributes] = useState(false);
  const [expandedFilterAttributes, setExpandedFilterAttributes] = useState(false);
  const [stock, setStock] = useState(
    variant.stockInventory?.availableQuantity || variant.stock?.stock || 0
  );
  const [editStock, setEditStock] = useState(false);

  const handleCustomerAttribute = (attribute) => {
    if (Array.isArray(attribute)) return attribute.map((a) => handleCustomerAttribute(a));
    if (typeof attribute === "object")
      return Object.keys(attribute).map(
        (key) =>
          attribute[key] !== null && (
            <ObjectValue key={key}>
              <ObjectLabel>{toLabel(key)}:</ObjectLabel>
              {attribute[key]}
            </ObjectValue>
          )
      );
    return <ObjectValue key={attribute}>{attribute.toString()}</ObjectValue>;
  };

  const customerAttribute = variant.customerAttribute
    ? JSON.parse(variant.customerAttribute)
    : undefined;

  const toLabel = (label) =>
    label
      ? label.length === 2
        ? label.toUpperCase()
        : label.charAt(0).toUpperCase() +
          label
            .slice(1)
            .replace(/([a-z](?=[A-Z]))/g, "$1 ")
            .toLowerCase()
      : "";

  return (
    <Variant key={variant.id}>
      {stock === 0 && <OutOfStock>Out of stock</OutOfStock>}
      <DetailsContainer onClick={() => setExpandedDetails(!expandedDetails)}>
        <ToggleButton>
          {expandedDetails ? <i className="fal fa-minus"></i> : <i className="fal fa-plus"></i>}
        </ToggleButton>

        <ImageContainer>
          {variant.imageUrl ? <img alt={variant.name} src={variant.imageUrl} /> : <Placeholder />}
        </ImageContainer>

        <Details>
          <Name>
            {variant.name}
            {variant.active ? (
              <i className="fal fa-check-circle"></i>
            ) : (
              <i className="fal fa-times-circle"></i>
            )}
          </Name>
          <VariantId>{variant.id}</VariantId>
          <Stock>
            <p>
              <strong>Stock:</strong> {stock}
            </p>
            {getStockVersion() === "v1" && getEditStock() && (
              <IconButton
                onClick={(e) => {
                  setEditStock(true);
                  e.stopPropagation();
                }}>
                <i className="fal fa-pen" />
              </IconButton>
            )}
            <UpdateProductStock
              variant={variant}
              stock={stock}
              setStock={setStock}
              editStock={editStock}
              handleClose={() => setEditStock(false)}
            />
          </Stock>
        </Details>
      </DetailsContainer>
      {expandedDetails && (
        <ExtendedDetails>
          <Attributes>
            <Attribute>
              <Label>URL:</Label>
              <Value>/{variant.slug}</Value>
            </Attribute>
            <Attribute>
              <Label>Created:</Label>
              <Value>
                <Moment format="YYYY-MM-DD, HH:mm">{variant.created}</Moment>
              </Value>
            </Attribute>
            <Attribute>
              <Label>Last updated:</Label>
              <Value>
                <Moment format="YYYY-MM-DD, HH:mm">{variant.lastUpdated}</Moment>
              </Value>
            </Attribute>
          </Attributes>
          {customerAttribute && Object.keys(customerAttribute).length > 0 && (
            <>
              <AttributeHeader
                expanded={expandedCustomerAttributes}
                onClick={() => setExpandedCustomerAttributes(!expandedCustomerAttributes)}>
                Customer Attributes
                <i className={`fal fa-chevron-${expandedCustomerAttributes ? "up" : "down"}`}></i>
              </AttributeHeader>
              {expandedCustomerAttributes &&
                Object.keys(customerAttribute).map(
                  (attribute) =>
                    customerAttribute[attribute] !== null && (
                      <Attribute key={attribute}>
                        <Label>{toLabel(attribute)}:</Label>
                        <Value> {handleCustomerAttribute(customerAttribute[attribute])}</Value>
                      </Attribute>
                    )
                )}
            </>
          )}
          {variant.filterAttributes.length > 0 && (
            <>
              <AttributeHeader
                expanded={expandedFilterAttributes}
                onClick={() => setExpandedFilterAttributes(!expandedFilterAttributes)}>
                Filter Attributes
                <i className={`fal fa-chevron-${expandedFilterAttributes ? "up" : "down"}`}></i>
              </AttributeHeader>
              {expandedFilterAttributes && (
                <Attributes>
                  {variant.filterAttributes.map((filterAttribute) => (
                    <Attribute key={filterAttribute.key}>
                      <Label>{filterAttribute.key}</Label>
                      <Value>{filterAttribute.value}</Value>
                    </Attribute>
                  ))}
                </Attributes>
              )}
            </>
          )}
          <Prices>
            <Price price={variant.price} discounts={variant.discount} />
          </Prices>
          {getStockVersion() !== "v1" && stock > 0 && (
            <Query
              query={GET_STOCK_INVENTORY}
              variables={{
                productId: variant.id,
              }}>
              {({ loading, error, data }) => {
                if (loading) return <Loader />;
                if (error)
                  return (
                    <ErrorMessage>
                      An error occurred when loading stock data, please contact support
                    </ErrorMessage>
                  );
                const { inventories } = data.getStockInventory;
                return (
                  <Inventories>
                    <InventoryHeader>
                      <i className="fa-duotone fa-warehouse-full"></i>
                      <p>Inventories</p>
                    </InventoryHeader>
                    <Attributes>
                      {inventories.map((i) => (
                        <Attribute key={i.inventory.name}>
                          <Label>{i.inventory.name}:</Label>
                          <Value>{i.availableQuantity}</Value>
                        </Attribute>
                      ))}
                    </Attributes>
                  </Inventories>
                );
              }}
            </Query>
          )}
        </ExtendedDetails>
      )}
    </Variant>
  );
};
