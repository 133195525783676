import gql from "graphql-tag";

const GET_ORDER = gql`
  query getOrder($id: ID!, $includeReservation: Boolean!) {
    getOrder(id: $id) {
      id
      reference
      status
      statusLog {
        status
        timestamp
      }
      paymentMethod
      paymentProvider
      paymentReference
      originalPaymentReference
      paymentExpiresAt
      potentialFraud
      failedReason
      currencyUnit
      orderTaxAmount
      externalTaxRate
      taxIncludedPricing
      orderAmount
      orderAmountWithDiscount
      discount {
        id
        code
      }
      refundActive
      refundFee
      customerAttribute
      orderRules {
        id
        type
      }
      created
      lastUpdated
      person {
        id
        email
        givenName
        familyName
        streetAddress
        houseNumberOrName
        postalCode
        region
        city
        country
        phone
        created
        lastUpdated
      }
      shippingAddress {
        givenName
        familyName
        email
        streetAddress
        houseNumberOrName
        postalCode
        region
        city
        country
        phone
      }
      billingAddress {
        givenName
        familyName
        email
        streetAddress
        houseNumberOrName
        postalCode
        region
        city
        country
        phone
      }
      orderLines {
        id
        name
        quantity
        relatedOrderLine
        refundOfOrderLine
        taxRate
        taxRateDecimals
        externalTaxRate
        imageUrl
        totalAmount
        totalAmountWithDiscount
        totalTaxAmount
        price
        options {
          key
          value
        }
        type
        product {
          id
          name
          relatedProduct {
            id
          }
          imageUrl
        }
      }
      reservation @include(if: $includeReservation) {
        status
        processedProducts {
          inventory {
            created
            id
            name
            updated
            address {
              country
              city
              streetAddress
              postalCode
              region
            }
          }
          productId
          quantity
        }
      }
    }
  }
`;

export default GET_ORDER;
