import gql from "graphql-tag";

const ADD_DISCOUNT_RULE = gql`
  mutation addRule(
    $name: String!
    $templateId: ID!
    $templateData: AWSJSON!
    $startDate: AWSDate
    $endDate: AWSDate
    $active: Boolean
  ) {
    addRule(
      rule: {
        name: $name
        templateId: $templateId
        templateData: $templateData
        startDate: $startDate
        endDate: $endDate
        active: $active
      }
    ) {
      id
      name
      startDate
      endDate
      templateData
      active
    }
  }
`;

export default ADD_DISCOUNT_RULE;
