import React from "react";
import styled from "styled-components/macro";
import uuid from "react-uuid";
import Rows from "components/Table/Rows";
import Row from "components/Table/Row";
import Column from "components/Table/Column";

const Stores = styled(Rows)`
min-height: 0;
`

const Inventory = styled(Row)`
  width: 100% !important;
  min-height: 6rem;
  display: flex;
  justify-content: space-between;
`;

const Id = styled(Column)`
  i {
    margin-right: 2rem;
    font-size: 2rem;
  }
`;

const Prio = styled(Column)`
margin-right: 2rem;
font-size: 1.1rem;
`

export default ({ history, inventories }) => {
  
  const handleClick = (inventory) => {
    history.push({
      pathname: `/admin/inventory/${inventory.inventoryId}`,
      state: inventory,
    });
  };

  return (
    <>
      <Stores>
        {inventories?.length > 0 && (
          <>
            {[...inventories].sort((a, b) => a.priority - b.priority).map((i) => {
              return (
                <Inventory key={uuid()} onClick={() => handleClick(i.inventory)}>
                  <Id>
                    <i className="fa-duotone fa-warehouse-full"></i>
                    <strong>{i.inventory.name}</strong>
                  </Id>
                  <Prio>
                    Priority: {i.priority +1}
                  </Prio>
                </Inventory>
              );
            })}
          </>
        )}
      </Stores>
    </>
  );
};
