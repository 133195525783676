import gql from "graphql-tag";

const GET_PRODUCT = gql`
  query getProduct($id: ID!) {
    getProduct(id: $id) {
      id
      attribute
      name
      active
      slug
      category
      imageUrl
      images
      created
      price {
        amount
        currencyUnit
      }
      lastUpdated
      compatibleAddons {
        id
        name
        imageUrl
      }
      customerAttribute
      relatedVariants {
        id
        name
        active
        archived
        slug
        category
        created
        lastUpdated
        imageUrl
        images
        customerAttribute
        relatedProduct {
          id
        }
        stock {
          productId
          stock
        }
        stockInventory {
          id
          validateStock
          stockQuantity
          reservedQuantity
          availableQuantity
          isAvailable
        }
        price {
          amount
          currencyUnit
        }
        discount {
          amount
          currencyUnit
        }
        filterAttributes {
          key
          value
        }
      }
    }
  }
`;

export default GET_PRODUCT;
