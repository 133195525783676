import esb from "elastic-builder";
import moment from "moment-timezone";

export const getFilterQuery = (orderFilter) => {
  const boolQuery = esb.boolQuery();
  let filterActive;
  if (orderFilter?.valueRange?.from || orderFilter?.valueRange?.to) {
    filterActive = true;
    boolQuery.must(
      esb
        .rangeQuery("orderAmountWithDiscount")
        .gte(orderFilter?.valueRange?.from ?? 0)
        .lte(
          orderFilter?.valueRange?.to && orderFilter?.valueRange?.to !== 0
            ? orderFilter?.valueRange?.to
            : 999900
        )
    );
  }
  if (orderFilter?.dateRange?.from || orderFilter?.dateRange?.to) {
    filterActive = true;
    boolQuery.must(
      esb
        .rangeQuery(orderFilter?.dateRange?.include ?? "created")
        .gte(
          orderFilter?.dateRange?.from
            ? orderFilter?.dateRange?.from?.replace("T", "-")
            : `${moment().subtract(1, "years").format("YYYY-MM-DD")}-00:00`
        )
        .lte(
          orderFilter?.dateRange?.to
            ? orderFilter?.dateRange?.to?.replace("T", "-")
            : `${moment().format("YYYY-MM-DD")}-23:59`
        )
        .format("yyyy-MM-dd-HH:mm")
        .timeZone(moment.tz.guess())
    );
  }
  if (orderFilter?.valueRange?.currencyUnit) {
    filterActive = true;
    boolQuery.must(esb.matchQuery("currencyUnit", orderFilter.valueRange.currencyUnit));
  }
  if (orderFilter?.filterStatus?.length) {
    filterActive = true;
    boolQuery.must(esb.termsQuery("status", orderFilter.filterStatus));
  }
  if (orderFilter?.filterStores?.length) {
    filterActive = true;
    boolQuery.must(
      esb.termsQuery(
        "shippingAddress.country",
        orderFilter.filterStores.map((s) => s.value.toLowerCase())
      )
    );
  }
  if (orderFilter?.shippingMethods?.length) {
    filterActive = true;
    boolQuery.must([
      esb.termsQuery(
        "orderLines.productId.keyword",
        orderFilter.shippingMethods.map((s) => s.id)
      ),
    ]);
  }
  return ({boolQuery, filterActive});
};
