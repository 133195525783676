import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import styled from "styled-components/macro";
import uuid from "react-uuid";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Button from "components/Ui/Button";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import Products from "components/Table/Rows";
import Product from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Ui/Loader";

const Header = styled(TableHeader)`
  padding-left: 1rem;
  padding-right: 1rem;

  ${MEDIA_MIN_LARGE} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const Name = styled(Column)`
  width: 40%;
  padding: 0 1rem 0 0;

  ${MEDIA_MIN_LARGE} {
    padding: 0 2rem 0 1rem;
  }
`;

const Stock = styled(Column)`
  width: 20%;
`;

const Status = styled(Column)`
  width: 15%;

  ${MEDIA_MIN_LARGE} {
    width: 20%;
  }

  .fa-check-circle {
    color: green;
  }

  .fa-times-circle {
    color: red;
  }

  .few-left {
    color: orange;
  }

  i {
    font-size: 1.8rem;
    margin-right: 0.8rem;

    ${MEDIA_MIN_LARGE} {
      font-size: 2.4rem;
    }
  }
`;

const Date = styled(Column)`
  width: 25%;

  ${MEDIA_MIN_LARGE} {
    width: 20%;
  }
`;

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};

  i {
    font-size: 1.8rem;
  }
`;

export default function StockTable({
  data,
  loading,
  productsPerPage,
  fetchMore,
  history,
  esbQuery,
}) {
  const [itemsPerPage, setItemsPerPage] = useState(productsPerPage);
  const [goToPageInput, setGoToPageInput] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState("down");
  const [sortField, setSortField] = useState("lastUpdated");
  const [products, setProducts] = useState(data && data.searchProducts.products);
  const productsTotalHits = data && data.searchProducts.totalHits;

  useEffect(() => {
    const from = (currentPage - 1) * itemsPerPage;
    fetchMore({
      variables: { query: JSON.stringify(esbQuery.size(itemsPerPage).from(from).toJSON()) },
    })
      .then((result) => setProducts(result.data.searchProducts.products))
      .catch((error) => console.log(error));
    setGoToPageInput(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, esbQuery]);

  const handlProductClick = (productId) => {
    history.push(`/admin/product/${productId.replaceAll("/", "~")}`);
  };

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";

    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  const getSortType = (field, sort) =>
    field === sortField ? sortTypes[sort].class : sortTypes.default.class;

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const toValue = (sortField, obj) => {
    if (Number.isInteger(obj[sortField]) || sortField === "active") {
      return obj[sortField];
    } else {
      return obj[sortField].toLowerCase();
    }
  };

  return (
    <>
      <Toolbar>
        <HoM>
          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={(e) => setCurrentPage(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of {productsTotalHits ? Math.ceil(productsTotalHits / itemsPerPage) : "-"} pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          <span>Total variants: {productsTotalHits}</span>
        </TotalItems>
      </Toolbar>
      <Header>
        <Name onClick={() => onSortChange("name")}>
          <span>Name </span>
          <SortButton>
            <i className={`fas fa-${getSortType("name", currentSort)}`} />
          </SortButton>
        </Name>
        <Status onClick={() => onSortChange("stock")}>
          <span>Status </span>
          <SortButton>
            <i className={`fas fa-${getSortType("stock", currentSort)}`} />
          </SortButton>
        </Status>
        <Stock onClick={() => onSortChange("stock")}>
          <span>Stock quantity </span>
          <SortButton>
            <i className={`fas fa-${getSortType("stock", currentSort)}`} />
          </SortButton>
        </Stock>
        <Date onClick={() => onSortChange("lastUpdated")}>
          <span>Last updated </span>
          <SortButton>
            <i className={`fas fa-${getSortType("lastUpdated", currentSort)}`} />
          </SortButton>
        </Date>
      </Header>
      <Products>
        {loading && <Loader />}
        {products && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={data.searchProducts.totalHits}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {[...products]
              .map((p) => ({
                ...p,
                stock: p.stockInventory?.availableQuantity || p.stock?.stock || 0,
              }))
              .sort(sortTypes[currentSort].fn)
              .map(({ name, stock, lastUpdated, relatedProduct }) => {
                return (
                  <Product key={uuid()} onClick={() => handlProductClick(relatedProduct.id)}>
                    <Name>
                      <strong>{name}</strong>
                    </Name>
                    {stock > 0 && stock < 10 ? (
                      <Status>
                        <i className={"fal fa-check-circle few-left"} /> <HoM>Few left</HoM>
                      </Status>
                    ) : stock > 0 ? (
                      <Status>
                        <i className={"fal fa-check-circle"} /> <HoM>In stock</HoM>
                      </Status>
                    ) : (
                      <Status>
                        <i className={"fal fa-times-circle"} /> <HoM>Out of stock</HoM>
                      </Status>
                    )}
                    <Stock>{stock}</Stock>
                    <Date>
                      <Moment format="YYYY-MM-DD HH:mm">{lastUpdated}</Moment>
                    </Date>
                  </Product>
                );
              })}
          </Pagination>
        )}
      </Products>
    </>
  );
}
