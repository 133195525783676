import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";
import SEARCH_SHIPPING_PROVIDERS from "graphql/Product/SearchShippingProviders";
import Loader from "components/Ui/Loader";
import Button from "components/Ui/Button";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Select from "components/Ui/Select";
import AddProvider from "./AddProvider";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
`;

const ProviderSelect = styled(Select)`
  height: 5rem;
  width: 100%;
  border-radius: 0.3rem;
  border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
  text-transform: initial;
  margin: 0;
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.8rem;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const AddProviderButton = styled(Button)`
  width: 6rem;
  margin-left: 1rem;
  height: 5rem;
  top: -0.1rem;
  i {
    margin: 0;
  }
`;

const ALL_SHIPPING_PROVIDERS = esb.requestBodySearch()
  .sort(esb.sort("lastUpdated", "desc"))
  .query(esb.queryStringQuery("type:shippingProvider"))
  .size(100)
  .from(0);

export default React.forwardRef(({ providerId, setProviderValue }, ref) => {
  const [esbQuery] = useState(ALL_SHIPPING_PROVIDERS);
  const [showAddProvider, setShowAddProvider] = useState(false);
  const [providers, setProviders] = useState(null);

  useEffect(() => {
    if (!providerId && providers) setProviderValue(providers[0]?.id);
    if (providers?.length < 1) setShowAddProvider(true);
  }, [providers, setProviderValue, providerId]);

  const handleProviderChange = (e) => setProviderValue(e.target.value);
  return (
    <Query
      query={SEARCH_SHIPPING_PROVIDERS}
      variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
      onCompleted={(data) => setProviders(data.searchProducts.products)}>
      {({ loading, error }) => {
        if (loading) return <Loader />;
        if (error)
          return (
            <ErrorMessage>
              An error occurred getting shipping provider, please contact support
            </ErrorMessage>
          );
        if (!providers) return null;
        return (
          <>
            {providers.length > 0 && (
              <>
               <Label>Shipping Provider</Label>
              <Container>
                <ProviderSelect
                  name="shippingProvider"
                  ref={ref}
                  defaultValue={providerId ?? providers[0]?.id}
                  handleChange={handleProviderChange}>
                  {providers &&
                    providers.map((provider) => (
                      <option key={provider.id} value={provider.id}>
                        {provider.name}
                      </option>
                    ))}
                </ProviderSelect>
                <AddProviderButton
                  type="button"
                  large
                  onClick={() => setShowAddProvider(!showAddProvider)}>
                  {showAddProvider ? (
                    <i className="fal fa-minus"></i>
                  ) : (
                    <i className="fal fa-plus"></i>
                  )}
                </AddProviderButton>
              </Container>
              </>
            )}
            <AddProvider
              showAddProvider={showAddProvider}
              setShowAddProvider={setShowAddProvider}
              providers={providers}
              setProviders={setProviders}
            />
          </>
        );
      }}
    </Query>
  );
});
