import React from "react";
import styled from "styled-components/macro";
import uuid from "react-uuid";
import Chip from "components/Ui/Chip";
import { Currency } from "../../helpers/money";

const OriginalPrice = styled.span`
  text-decoration: ${(p) => (p.discount && p.discount.amount > 0 ? "line-through" : "none")};
`;

const DiscountedPrice = styled.span`
  margin-left: 1rem;
`;

export default ({ price, discounts }) => (
  <>
    <strong>Prices: </strong>
    <div>
      {price
        ? price.map((price) => {
            const discount =
              discounts && discounts.find((d) => d.currencyUnit === price.currencyUnit);

            const Amount = Currency(price.currencyUnit);
            const priceMoney = Amount(price.amount);
            const discountMoney = Amount(discount?.amount ?? 0);
            return (
              <Chip key={uuid()}>
                <OriginalPrice discount={discount}>{priceMoney.toFormat()}</OriginalPrice>
                {discountMoney.getAmount() > 0 && (
                  <DiscountedPrice>{priceMoney.subtract(discountMoney).toFormat()}</DiscountedPrice>
                )}
              </Chip>
            );
          })
        : "Prices missing"}
    </div>
  </>
);
