import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "../../variables/mediaQueries";
import logo from "images/brink-logo.svg";

const Container = styled.div`
  width: 100%;
  overflow: hidden;

  ${MEDIA_MIN_MEDIUM} {
    max-width: ${(p) => (p.expanded ? "14rem" : "3.5rem")};
    max-height: ${(p) => (p.expanded ? "8rem" : "5rem")};
    transition: all 0.6s;
  }
`;

const Logo = styled.img`
  width: 14rem;
`;

export default ({ expanded = true, ...props }) => (
  <Container expanded={expanded}>
    <Logo {...props} expanded={expanded} alt="Brink Commerce logo" src={logo} />
  </Container>
);
