import React from "react";
import Order from "../Table/Row";
import OrderStatus from "./OrderStatus";
import Money from "../Money/Money";
import getFriendlyPaymentName from "../../helpers/getFriendlyPaymentName";
import getFullCountryName from "../../helpers/getFullCountryName";
import FlagIcon from "../Ui/FlagIcon";
import Moment from "react-moment";
import styled from "styled-components/macro";
import Column from "../Table/Column";
import { MEDIA_MIN_LARGE } from "../../variables/mediaQueries";

const Reference = styled(Column)`
  width: 20%;
  padding-left: 1rem;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 10%;
    padding-left: 1rem;
  }
`;

const CustomerInfo = styled(Column)`
  display: none;

  ${MEDIA_MIN_LARGE} {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 18%;
    font-size: 1.3rem;
  }
`;

const Status = styled(Column)`
  width: 12%;
  font-size: 1.3rem;

  i {
    margin-right: 0;
  }

  ${MEDIA_MIN_LARGE} {
    width: 10%;
  }
`;

const Email = styled.span`
  ${MEDIA_MIN_LARGE} {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
  }
`;
const AmountWithDiscount = styled(Column)`
  width: 33%;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 15%;
  }
`;

const PaymentMethod = styled(Column)`
  width: 15%;
  padding-right: 2rem;
  font-size: 1.3rem;
`;

const Country = styled(Column)`
  width: 15%;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 10%;
  }

  img {
    margin: 0.5rem 0 0;
    padding-bottom: 0.3rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 0.2rem;
    }
  }
`;

const Date = styled(Column)`
  width: 35%;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 12.5%;
  }
`;

const OrderRow = ({ order, tabs, setTabs, setActiveTab }) => {
  const handleOrderClick = (id, reference, status, created) => {
    !tabs.find((item) => item.id === id) &&
      setTabs((prev) => [...prev, { id, reference, status, created }]);
    setActiveTab(id);
  };

  const formatName = (order) =>
    order.shippingAddress
      ? `${order.shippingAddress.givenName} ${order.shippingAddress.familyName}`
      : "";

  const formatEmail = (order) => (order.shippingAddress ? `${order.shippingAddress.email} ` : "");

  return (
    <Order
      key={order.id}
      onClick={() => handleOrderClick(order.id, order.reference, order.status, order.created)}>
      <Reference>
        <span>
          <strong>{order.reference}</strong>
        </span>
      </Reference>
      <Status>
        <OrderStatus status={order.status} showIcon />
      </Status>
      <CustomerInfo>
        {formatName(order)} <Email> {formatEmail(order)} </Email>
      </CustomerInfo>
      <AmountWithDiscount>
        <Money
          amount={
            order?.taxIncludedPricing === false
              ? order.orderAmountWithDiscount + order.orderTaxAmount
              : order.orderAmountWithDiscount
          }
          currencyUnit={order.currencyUnit}
        />
      </AmountWithDiscount>
      <PaymentMethod hideOnMobile>
        {order.paymentMethod ? getFriendlyPaymentName(order.paymentMethod) : "-"}
      </PaymentMethod>
      <Country hideOnMobile>
        <span data-tip={getFullCountryName(order.country)}>
          {order.country ? <FlagIcon countryCode={order.country} /> : "-"}
        </span>
      </Country>
      <Date>
        <Moment format="YYYY-MM-DD, HH:mm">{order.created}</Moment>
      </Date>
    </Order>
  );
};

export default OrderRow;
