import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const Img = styled.img`
  width: 1.6rem;
  margin-right: 1rem;
  padding-bottom: 0.2rem;

  ${MEDIA_MIN_MEDIUM} {
    width: 2rem;
    padding-bottom: 0.2rem;
  }
`;

const FlagIcon = ({ countryCode, returnNull, ...props }) => {
  const [flags, setFlags] = useState([]);
  useEffect(() => {
    const context = require.context("../../icons/flags/svg", true, /.svg$/);
    const flags = context.keys().reduce((acc, key) => {
      const fileName = key.match(/(\w+)/)[0];
      return {
        ...acc,
        [fileName]: context(key),
      };
    }, {});
    setFlags(flags);
  }, []);

  if (!countryCode) return null;
  if (flags[countryCode.toLowerCase()] && typeof flags[countryCode.toLowerCase()] === "string") {
    return <Img {...props} src={flags[countryCode.toLowerCase()]} />;
  }
  return returnNull ? null : countryCode;
};

export default FlagIcon;
