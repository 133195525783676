import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useAuth0, Auth0Provider } from "@auth0/auth0-react";
import Login from "./components/Login";
import AdminLayout from "./layouts/Admin";
import AuthenticatedRoute from "components/Authentication/AuthenticatedRoute";
import Loader from "./components/Ui/Loader";

export const Auth0WithRouterAccess = () => {
  const history = useHistory();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}>
      <Auth0Routes />
    </Auth0Provider>
  );
};

const Auth0Routes = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <Loader />;
  return (
    <>
      <Route exact path="/">
        {isAuthenticated ? <Redirect to="/admin/dashboard" /> : <Redirect to="/login" />}
      </Route>
      <Route path="/login" component={Login} displayName="Login" />
      <AuthenticatedRoute path="/admin" component={AdminLayout} displayName="Dashboard" />
    </>
  );
};
