export default (includeVAT) => `
  def orderAmountWithDiscount = doc['orderAmountWithDiscount'].value;
  def orderTaxAmount = doc['orderTaxAmount'].value;
  def taxIncludedPricing = true;
  def includeVAT = (boolean)${includeVAT};
  if (doc.containsKey('taxIncludedPricing') && !doc['taxIncludedPricing'].empty) {
    taxIncludedPricing = doc['taxIncludedPricing'].value;
  }
  if (!doc.containsKey('refundAmount') || doc['refundAmount'].empty) {
    if (!taxIncludedPricing && includeVAT) {
      return orderAmountWithDiscount + orderTaxAmount;
    } else {
      return orderAmountWithDiscount;
    }
  } else {
    orderAmountWithDiscount = orderAmountWithDiscount + doc['refundAmount'].value;
    if (!taxIncludedPricing && includeVAT) {
      def externalOrderTaxAmount = 0;
      if (doc.containsKey('externalTaxRate') && !doc['externalTaxRate'].empty) {
        def externalTaxRate = (float)doc['externalTaxRate'].value;
        externalOrderTaxAmount = (int)(orderAmountWithDiscount * externalTaxRate);
      }
      return orderAmountWithDiscount + externalOrderTaxAmount;
    } else {
      return orderAmountWithDiscount;
    }
  }
`;
