import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const ClearFilter = styled.span`
  margin-left: 2rem;
  padding-top: 0.8rem;
  position: relative;
  cursor: pointer;
  height: 3.2rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0.4rem;
    left: 0;
    right: 0;
    width: 100%;
    height: 0.1rem;
    background: ${(p) => p.theme.colors.white};
    transition: background: 0.2s;
  }

  ${MEDIA_MIN_LARGE} {

    &:hover {
      &:after {
        background: transparent;
      }
    }
  }
`;

export default ({ ...props }) => <ClearFilter {...props}>Clear filter</ClearFilter>;
