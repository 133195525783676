import gql from "graphql-tag";

const SEARCH_PRODUCTS_INPUT = gql`
  query searchProducts($query: AWSJSON!) {
    searchProducts(query: $query) {
      totalHits
      products {
        id
        active
        name
        category
        imageUrl
        lastUpdated
        relatedVariants {
          id
          active
          name
          imageUrl
        }
      }
    }
  }
`;

export default SEARCH_PRODUCTS_INPUT;
