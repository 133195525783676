import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Mutation, Query } from "@apollo/client/react/components";
import { Controller, useForm } from "react-hook-form";
import Moment from "react-moment";
import uuid from "react-uuid";
import FilterProducts from "../DiscountCode/FilterProducts";
import GET_DISCOUNT_RULE from "graphql/Discount/DiscountRule/GetDiscountRule";
import UPDATE_DISCOUNT_RULE from "graphql/Discount/DiscountRule/UpdateDiscountRule";
import FilterRules from "../DiscountCode/FilterRules";
import { useNotification } from "context/NotificationContext";
import CategoriesInput from "components/Discount/CategoriesInput";
import CurrencyInput from "components/Discount/CurrencyInput";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Input from "components/Ui/InputNew";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtonSecondary from "components/ActionButtons/ActionButtonSecondary";
import IconButton from "components/Ui/Buttons/IconButton";
import Sidebar from "components/Discount/Sidebar";
import DeleteDiscountRule from "components/Discount/DeleteDiscountRule";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import Tooltip from "components/Ui/Tooltip";
import Toggle from "components/Ui/Toggle";
import TableHeader from "components/Table/Header";
import DiscountProducts from "components/Discount/DiscountProducts";
import StoreSelector from "components/StoreSelector/StoreSelector";
import { formatFromMinorUnits, formatPrice } from "../../../helpers/money";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 6.5rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }

  h5 {
    margin-bottom: 0;
    padding-top: 3.2rem;
    width: 100%;
  }
`;

const RuleAttributes = styled(Attributes)`
  margin: -1.5rem 0 3rem;
`;

const ConditionsHeader = styled(TableHeader)`
  > div {
    cursor: default;
    width: 30%;
  }
`;

const Conditions = styled.div`
  width: 100%;
  position: relative;

  & + & {
    margin-top: 5rem;
  }

  h5 {
    border-top: 0;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    padding: 0 0 3rem;
  }
`;

const AddRowButton = styled(ActionButtonSecondary)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Condition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  border-top: none;

  ${MEDIA_MIN_LARGE} {
    padding: 2rem;
  }
`;

const CurrencyCondition = styled(Condition)`
  > div {
    width: 30%;
    margin: 0;
  }
`;

const Padding = styled.div`
  width: 5.5rem !important;
`;

const DeleteButton = styled(IconButton)`
  margin-bottom: 3rem;
`;

const StoreSelectorRule = styled(StoreSelector)`
  margin-bottom: 3.5rem;

  ${MEDIA_MIN_LARGE} {
    margin-left: 0;
  }
`;

const ToggleWrapper = styled.div`
  //margin-top: 7rem;
`;

export default (props) => {
  const {setNotification} = useNotification();
  const {
    control,
    handleSubmit,
    formState: {errors},
    register,
    setValue,
  } = useForm();
  const [active, setActive] = useState();
  const [templateData, setTemplateData] = useState({});
  const [selectedStores, setSelectedStores] = useState([]);
  const [jsonSchema, setJsonSchema] = useState(null);
  const [excludedCategories, setExcludedCategories] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [handlebarTemplateType, setHandlebarTemplateType] = useState(null);
  const [filterRules, setFilterRules] = useState();
  const [discountRules, setDiscountRules] = useState();
  const [recurrent, setRecurrent] = useState();
  const [discountRule, setDiscountRule] = useState({});
  const [queryCompleted, setQueryCompleted] = useState(false);
  const [id] = useState(props.match.params.id);
  const history = props.history;
  const [selectedProducts, setSelectedProducts] = useState({
    products: [],
    expandedProducts: [],
  });
  const [selectedVariants, setSelectedVariants] = useState({
    products: [],
    expandedProducts: [],
  });

  useEffect(() => {
    setValue("stores", selectedStores.length);
    // eslint-disable-next-line
  }, [selectedStores]);

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/discount-rules", "Discount rules"],
          [`admin/discount-rule/${id}`, `${discountRule && discountRule.name}`],
        ]}
      />
      <Header heading="Discount rules"/>
      <PageContainer>
        <Sidebar/>
        <GridContainer>
          <Query
            query={GET_DISCOUNT_RULE}
            variables={{id}}
            onCompleted={(data) => {
              const {getRule} = data;
              const templateData = JSON.parse(JSON.parse(getRule.templateData));
              const jsonSchema = JSON.parse(getRule.ruleTemplate.jsonSchema);
              const [, handlebarTemplateType] = /"type":"([^"]+)"/.exec(
                getRule.ruleTemplate.handlebarTemplate
              );

              if (templateData.currencyOptions) {
                templateData.currencyOptions = templateData.currencyOptions.map((options) => ({
                  ...options,
                  id: uuid(),
                }));
              }

              if (templateData.currencies) {
                setCurrencies(
                  templateData.currencies.map((currencyUnit) => ({
                    value: currencyUnit,
                    label: currencyUnit,
                  }))
                );
              }

              if (templateData.stores) {
                setSelectedStores(
                  templateData.stores.map((store) => ({
                    value: store,
                    label: store,
                  }))
                );
              }

              if (templateData.notForCategories) {
                setExcludedCategories(
                  templateData.notForCategories.map((category) => ({
                    value: category,
                    label: category,
                  }))
                );
              }

              if (templateData.validForProducts) {
                setSelectedProducts({
                  products: templateData.validForProducts,
                  expandedProducts: [],
                });
              }

              if (templateData.variantIds) {
                setSelectedVariants({
                  products: templateData.variantIds,
                  expandedProducts: [],
                });
              }

              if (templateData.recurrent !== undefined) setRecurrent(templateData.recurrent);
              if (templateData.filterRules) setFilterRules(templateData.filterRules);
              if (templateData.discountRules) setDiscountRules(templateData.discountRules);

              setActive(data.getRule.active);
              setDiscountRule(getRule);
              setTemplateData(templateData);
              setJsonSchema(jsonSchema);
              setHandlebarTemplateType(handlebarTemplateType);
              setQueryCompleted(true);
            }}>
            {({loading, error, data}) => {
              if (loading || !queryCompleted) return <Loader/>;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );

              const discountRule = data.getRule;
              const discountRuleData = JSON.parse(JSON.parse(discountRule.templateData));

              return (
                <Mutation
                  mutation={UPDATE_DISCOUNT_RULE}
                  onCompleted={() => {
                    setNotification({
                      status: "success",
                      message: `Discount rule successfully updated`,
                    });
                  }}
                  onError={() => {
                    setNotification({
                      status: "error",
                      message:
                        "An error occurred updating the discount rule, please contact support",
                    });
                  }}>
                  {(updateDiscount, {loading: addDiscountLoading}) => {
                    const onSubmit = (data) => {
                      const toCurrencyOptions = (options) =>
                        options.map(
                          ({
                             atLeastTotalPriceWithDiscount,
                             atMostTotalPriceWithDiscount,
                             currencyUnit,
                           }) => ({
                            atLeastTotalPriceWithDiscount: formatPrice(
                              atLeastTotalPriceWithDiscount,
                              currencyUnit
                            ),
                            ...(atMostTotalPriceWithDiscount
                              ? {
                                atMostTotalPriceWithDiscount: formatPrice(
                                  atMostTotalPriceWithDiscount,
                                  currencyUnit
                                ),
                              }
                              : {}),
                            currencyUnit,
                          })
                        );
                      const templateData = Object.assign(
                        data.discountInPercentage
                          ? {discountInPercentage: parseInt(data.discountInPercentage, 10)}
                          : {},
                        data?.templateData?.currencyOptions
                          ? {
                            currencyOptions: toCurrencyOptions(
                              Object.values(data.templateData.currencyOptions)
                            ),
                          }
                          : {},
                        data.minItems ? {minItems: parseInt(data.minItems, 10)} : {},
                        data.maxItems ? {maxItems: parseInt(data.maxItems, 10)} : {},
                        data.triggerQuantity ? {triggerQuantity: parseInt(data.triggerQuantity, 10)} : {},
                        data.discountQuantity ? {discountQuantity: parseInt(data.discountQuantity, 10)} : {},
                        currencies?.length > 0
                          ? {
                            currencies: currencies.map((c) => c.value),
                          }
                          : {},
                        selectedStores?.length > 0
                          ? {stores: selectedStores.map((s) => s.value)}
                          : {},
                        handlebarTemplateType === "ADD_BOGO_PERCENTAGE"
                          ? {
                            notForCategories: excludedCategories.map((c) => c.value),
                          }
                          : {},
                        selectedProducts?.products?.length > 0
                          ? {
                            validForProducts: selectedProducts.products,
                          }
                          : {},
                        jsonSchema.properties.variantIds
                          ? {
                            variantIds: selectedVariants.products,
                          }
                          : {},
                        filterRules?.length > 0
                          ? {
                            filterRules: filterRules,
                          }
                          : {},
                        discountRules?.length > 0
                          ? {
                            discountRules: discountRules,
                          }
                          : {},
                        (recurrent !== undefined) ? {recurrent} : {}
                      );


                      updateDiscount({
                        variables: {
                          id: id,
                          name: data.name,
                          startDate: data.startDate || undefined,
                          endDate: data.endDate || undefined,
                          templateData: JSON.stringify(templateData),
                          active: active,
                        },
                      }).catch((e) => {
                        console.log(e);
                      });
                    };


                    const maxDiscountInPercentage =
                      handlebarTemplateType === "ADD_BOGO_PERCENTAGE" ||
                      handlebarTemplateType === "ADD_BUYGET" ? 100 : 99;

                    return (
                      <>
                        <GridItem columns="12" padding="1rem 0">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Box
                              preHeading="Discount rules"
                              heading={discountRule.name}
                              subHeading={discountRule.id}
                              showGoBackButton
                              goBackOnClick={() => history.push("/admin/discount-rules")}>
                              {addDiscountLoading && <Loader/>}

                              <ActionButtons inBox footerOnMobile>
                                <DeleteDiscountRule history={history} id={discountRule.id}/>
                                <ActionButton type="submit">
                                  <i className="fal fa-fw fa-check"/>
                                  Save
                                </ActionButton>
                              </ActionButtons>

                              <RuleAttributes>
                                <Attribute>
                                  <Label>Active:</Label>
                                  <Value>
                                    <Toggle
                                      active={active}
                                      handleToggle={() => setActive(!active)}
                                    />
                                  </Value>
                                </Attribute>
                                <Attribute>
                                  <Label>Created:</Label>
                                  <Value>
                                    <Moment format="YYYY-MM-DD HH:mm">
                                      {discountRule.created}
                                    </Moment>
                                  </Value>
                                </Attribute>
                                <Attribute>
                                  <Label>Last updated:</Label>
                                  <Value>
                                    <Moment format="YYYY-MM-DD HH:mm">
                                      {discountRule.lastUpdated}
                                    </Moment>
                                  </Value>
                                </Attribute>
                              </RuleAttributes>

                              <GridContainer collapse padding="0">
                                <GridItem
                                  mobilePadding="0"
                                  desktopPadding="0 1.5rem 0 0"
                                  columns="6">
                                  <Controller
                                    name="name"
                                    render={({field}) => (
                                      <Input {...field} type="text" errors={errors} label="Name"/>
                                    )}
                                    defaultValue={discountRule.name}
                                    control={control}
                                    rules={{
                                      required: "This is a required field",
                                    }}
                                  />
                                  {jsonSchema.properties.discountInPercentage && (
                                    <Controller
                                      name="discountInPercentage"
                                      render={({field}) => (
                                        <Input
                                          {...field}
                                          type="number"
                                          placeholder={`0 - ${maxDiscountInPercentage}`}
                                          label="Discount in percentage"
                                          icon="badge-percent"
                                          errors={errors}
                                        />
                                      )}
                                      control={control}
                                      rules={{
                                        required: "This is a required field",
                                        min: {
                                          value: 0,
                                          message: `Needs to be a number between 0-${maxDiscountInPercentage}`,
                                        },
                                        max: {
                                          value: maxDiscountInPercentage,
                                          message: `Needs to be a number between 0-${maxDiscountInPercentage}`,
                                        },
                                      }}
                                      defaultValue={discountRuleData.discountInPercentage}
                                    />
                                  )}
                                  {jsonSchema.properties.triggerQuantity && (
                                    <Controller
                                      name="triggerQuantity"
                                      render={({field}) => (
                                        <Input
                                          {...field}
                                          type="number"
                                          placeholder="Min 1"
                                          label="Trigger quantity: *"
                                          icon="hashtag"
                                          errors={errors}
                                          toolTip="The minimum quantity of matching items in the cart required to trigger the discount"
                                        />
                                      )}
                                      control={control}
                                      rules={{
                                        required: "This is a required field",
                                        min: {
                                          value: 1,
                                          message: "Needs to be a number 1 or higher",
                                        },
                                      }}
                                      defaultValue={discountRuleData.triggerQuantity}
                                    />
                                  )}
                                  {jsonSchema.properties.discountQuantity && (
                                    <Controller
                                      name="discountQuantity"
                                      render={({field}) => (
                                        <Input
                                          {...field}
                                          type="number"
                                          placeholder="Min 1"
                                          label="Discount quantity: *"
                                          icon="hashtag"
                                          errors={errors}
                                          toolTip="The quantity of matching items that will be discounted"
                                        />
                                      )}
                                      control={control}
                                      rules={{
                                        required: "This is a required field",
                                        min: {
                                          value: 1,
                                          message: "Needs to be a number 1 or higher",
                                        },
                                      }}
                                      defaultValue={discountRuleData.discountQuantity}
                                    />
                                  )}
                                  {handlebarTemplateType === "ADD_BOGO_PERCENTAGE" && (
                                    <CategoriesInput
                                      selected={excludedCategories}
                                      setSelected={setExcludedCategories}
                                      exclude={true}
                                    />
                                  )}
                                  {jsonSchema.properties.minItems && (
                                    <>
                                      <Tooltip/>
                                      <Controller
                                        name="minItems"
                                        render={({field}) => (
                                          <Input
                                            {...field}
                                            min="1"
                                            type="number"
                                            placeholder="1"
                                            label="Minimum items"
                                            icon="shopping-basket"
                                            data-tip="The minimum number of matching items in the cart required for the discount to apply"
                                            errors={errors}
                                          />
                                        )}
                                        control={control}
                                        rules={{
                                          min: {
                                            value: 1,
                                            message: "Needs to be a positive number",
                                          },
                                        }}
                                        defaultValue={discountRuleData.minItems}
                                      />
                                    </>
                                  )}
                                  {jsonSchema.properties.maxItems && (
                                    <>
                                      <Tooltip/>
                                      <Controller
                                        name="maxItems"
                                        render={({field}) => (
                                          <Input
                                            {...field}
                                            min="1"
                                            type="number"
                                            placeholder="1"
                                            label="Maximum items"
                                            icon="shopping-basket"
                                            errors={errors}
                                            data-tip="The minimum number of matching items in the cart required for the discount to apply"
                                          />
                                        )}
                                        control={control}
                                        rules={{
                                          min: {
                                            value: 1,
                                            message: "Needs to be a positive number",
                                          },
                                        }}
                                        defaultValue={discountRuleData.maxItems}
                                      />
                                    </>
                                  )}
                                </GridItem>
                                <GridItem
                                  mobilePadding="0"
                                  desktopPadding="0 0 0 1.5rem"
                                  columns="6">
                                  <Controller
                                    name="startDate"
                                    render={({field}) => (
                                      <Input
                                        {...field}
                                        type="date"
                                        errors={errors}
                                        label="Valid from:"
                                        icon="calendar-alt"
                                      />
                                    )}
                                    control={control}
                                    defaultValue={discountRule.startDate || ""}
                                  />
                                  <Controller
                                    name="endDate"
                                    render={({field}) => (
                                      <Input
                                        {...field}
                                        type="date"
                                        errors={errors}
                                        label="Valid to:"
                                        icon="calendar-alt"
                                      />
                                    )}
                                    control={control}
                                    defaultValue={discountRule.endDate || ""}
                                  />
                                  {jsonSchema.properties.currencies && (
                                    <CurrencyInput
                                      selected={currencies}
                                      setSelected={setCurrencies}
                                    />
                                  )}
                                  {jsonSchema.properties.stores && (
                                    <StoreSelectorRule
                                      {...register("stores", {
                                        min: {
                                          value: 1,
                                          message: "This is a required field",
                                        },
                                      })}
                                      label="Select stores"
                                      selectedStores={selectedStores}
                                      setSelectedStores={setSelectedStores}
                                      errors={errors}
                                      name="stores"
                                      showContinents
                                    />
                                  )}
                                  {jsonSchema.properties.recurrent && (
                                    <ToggleWrapper>
                                      <Toggle
                                        label="Recurrent:"
                                        active={recurrent}
                                        handleToggle={() => setRecurrent(!recurrent)}
                                      />
                                    </ToggleWrapper>
                                  )}
                                </GridItem>
                              </GridContainer>

                              <Container>
                                <Tooltip/>
                                {jsonSchema && jsonSchema.properties.currencyOptions && (
                                  <Conditions>
                                    <h5>Conditions</h5>
                                    <AddRowButton
                                      type="button"
                                      handleOnClick={() => {
                                        setTemplateData(({currencyOptions = [], ...data}) => ({
                                          ...data,
                                          currencyOptions: [...currencyOptions, {id: uuid()}],
                                        }));
                                      }}>
                                      <i className="fal fa-plus"></i> Add
                                    </AddRowButton>
                                    <ConditionsHeader>
                                      <div>
                                        Currency code *{" "}
                                        <i
                                          className="fal fa-fw fa-info-circle"
                                          data-tip="ISO-4217, ex.USD"
                                        />
                                      </div>
                                      <div>
                                        Minimum order value *{" "}
                                        <i
                                          className="fal fa-fw fa-info-circle"
                                          data-tip="ex. 60 or 5.5"
                                        />
                                      </div>
                                      {handlebarTemplateType === "ADD_CARTDISCOUNT_PERCENTAGE" && (
                                        <div>
                                          Maximum order value{" "}
                                          <i
                                            className="fal fa-fw fa-info-circle"
                                            data-tip="ex. 60 or 5.5"
                                          />
                                        </div>
                                      )}
                                      <Padding/>
                                    </ConditionsHeader>
                                    {templateData.currencyOptions
                                      .map((rule) => (
                                        <CurrencyCondition key={rule.id}>
                                          <Controller
                                            name={`templateData.currencyOptions.${rule.id}.currencyUnit`}
                                            render={({field}) => (
                                              <Input
                                                {...field}
                                                maxLength={3}
                                                placeholder="USD"
                                                type="text"
                                                errors={errors}
                                              />
                                            )}
                                            control={control}
                                            rules={{
                                              required: "This is a required field",
                                              pattern: {
                                                value: /[A-Z]{3}/,
                                                message: "Only capital letter A-Z are allowed",
                                              },
                                            }}
                                            defaultValue={rule.currencyUnit}
                                          />
                                          <Controller
                                            name={`templateData.currencyOptions.${rule.id}.atLeastTotalPriceWithDiscount`}
                                            render={({field}) => (
                                              <Input
                                                {...field}
                                                type="number"
                                                errors={errors}
                                                placeholder="100"
                                              />
                                            )}
                                            control={control}
                                            rules={{
                                              required: "This is a required field",
                                            }}
                                            defaultValue={formatFromMinorUnits(
                                              rule.atLeastTotalPriceWithDiscount,
                                              rule.currencyUnit
                                            )}
                                          />

                                          {handlebarTemplateType ===
                                            "ADD_CARTDISCOUNT_PERCENTAGE" && (
                                              <Controller
                                                name={`templateData.currencyOptions.${rule.id}.atMostTotalPriceWithDiscount`}
                                                render={({field}) => (
                                                  <Input
                                                    {...field}
                                                    type="number"
                                                    errors={errors}
                                                    placeholder="1000"
                                                  />
                                                )}
                                                control={control}
                                                defaultValue={formatFromMinorUnits(
                                                  rule.atMostTotalPriceWithDiscount,
                                                  rule.currencyUnit
                                                )}
                                              />
                                            )}

                                          <DeleteButton
                                            handleOnClick={() => {
                                              setTemplateData(
                                                ({currencyOptions = [], ...data}) => ({
                                                  ...data,
                                                  currencyOptions: currencyOptions.filter(
                                                    (r) => r.id !== rule.id
                                                  ),
                                                })
                                              );
                                            }}>
                                            <i className="fal fa-fw fa-trash-alt"/>
                                          </DeleteButton>
                                        </CurrencyCondition>
                                      ))
                                      .reverse()}
                                  </Conditions>
                                )}
                                {jsonSchema.properties.filterRules && (
                                  <FilterRules
                                    control={control}
                                    filterRules={filterRules}
                                    setFilterRules={setFilterRules}
                                    title="Products in cart"
                                  />
                                )}
                                {jsonSchema.properties.discountRules && (
                                  <FilterRules
                                    control={control}
                                    filterRules={discountRules}
                                    setFilterRules={setDiscountRules}
                                    heading="Products with discount"
                                    subHeading="Products that the discount code will apply on"
                                    title="Products with discount"
                                  />
                                )}
                              </Container>
                            </Box>
                          </form>
                        </GridItem>
                        {jsonSchema.properties.validForProducts && (
                          <DiscountProducts
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                          />
                        )}
                        {jsonSchema.properties.variantIds && (
                          <DiscountProducts
                            selectVariants={true}
                            selectedProducts={selectedVariants}
                            setSelectedProducts={setSelectedVariants}
                            labelOverrides={{
                              preHeading: "Discount rule",
                              heading: "Add gift products",
                              subHeading: "",
                            }}
                          />
                        )}
                        {jsonSchema.properties.filterRules && (
                          <FilterProducts
                            padding="2rem 0"
                            filterRules={filterRules}
                            heading="Products in cart"
                            subHeading="Products that must be in the cart for the discount to be applicable"
                          />
                        )}
                        {jsonSchema.properties.discountRules && (
                          <FilterProducts
                            padding="2rem 0"
                            filterRules={discountRules}
                            heading="Products with discount"
                            subHeading="Products that the discount will apply on"
                          />
                        )}
                      </>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
