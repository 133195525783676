import React from "react";
import { Currency } from "../../helpers/money";
import Tooltip from "components/Ui/Tooltip";

export default function DiscountValue({ discount, simple }) {
  const formattedDiscountAmount = discount.discountInFixedPrice
    ?.map((fp) => {
      const Amount = Currency(fp.currencyUnit);
      return Amount(fp.amount).toFormat("$0");
    })
    .sort()
    .join(" / ");

  if (simple)
    return discount.discountInPercentage ? (
      <span>{discount.discountInPercentage} %</span>
    ) : (
      <span>{formattedDiscountAmount}</span>
    );
  return discount.discountInPercentage ? (
    <span>{discount.discountInPercentage} %</span>
  ) : (
    <span data-tip={formattedDiscountAmount}>
      <Tooltip />
      <span className="fa fa-coins" />
    </span>
  );
}
