import gql from "graphql-tag";

const UPDATE_ORDER_COMMENTS = gql`
  mutation updateOrder($id: ID!, $customerAttribute: AWSJSON!) {
    updateOrder(
      updateOrder: {
        id: $id
        customerAttribute: $customerAttribute
      }
    ) {
      id
      customerAttribute
      status
    }
  }
`;

export default UPDATE_ORDER_COMMENTS;