import Dashboard from "views/Dashboard/Dashboard";

// Products
import Products from "views/Products/Products";
import Addons from "views/Products/Addons/Addons";
import Stock from "views/Products/Stock/Stock";
import Product from "views/Product/Product";
import Addon from "views/Addon/Addon";
import MostSoldProducts from "views/Products/MostSoldProducts";

// Orders
import Orders from "views/Orders/Orders";
import OrdersFollowUp from "views/Orders/OrdersFollowUp";

// Customers
import Customers from "views/Customers/Customers";

// Discounts
import Discounts from "views/Discount/Discounts";
import DiscountCodes from "views/Discount/DiscountCode/DiscountCodes";
import AddDiscountCode from "views/Discount/DiscountCode/AddDiscountCode";
import DiscountRules from "views/Discount/DiscountRule/DiscountRules";
import DiscountRule from "views/Discount/DiscountRule/DiscountRule";
import AddDiscountRule from "views/Discount/DiscountRule/AddDiscountRule";
import Vouchers from "views/Discount/Voucher/Vouchers";
import Voucher from "views/Discount/Voucher/Voucher";
import VoucherGroup from "views/Discount/Voucher/VoucherGroup";
import AddVoucherGroup from "views/Discount/Voucher/AddVoucherGroup";

// Stores
import Stores from "views/Stores/Stores";
import AddStore from "views/Stores/AddStore";
import Store from "views/Stores/Store";

//Inventories
import Inventories from "views/Inventories/Inventories";
import Inventory from "views/Inventories/Inventory";
import getStockVersion from "helpers/getStockVersion";

// Configuration
import Configuration from "views/Configuration/Configuration";
import Fortnox from "views/Configuration/Integration/Fortnox";
import Klaviyo from "views/Configuration/Integration/Klaviyo";
import OrderWebhooks from "views/Configuration/Webhooks/OrderWebhooks";
import ProductWebhooks from "views/Configuration/Webhooks/ProductWebhooks";
import OutOfStockWebhooks from "views/Configuration/Webhooks/OutOfStockWebhooks";
import OrderShippingWebhooks from "./views/Configuration/Webhooks/OrderShippingWebhooks";
import AddProductWebhook from "views/Configuration/Webhooks/Product/AddProductWebhook";
import UpdateProductWebhook from "views/Configuration/Webhooks/Product/UpdateProductWebhook";
import AddOrderWebhook from "views/Configuration/Webhooks/Order/AddOrderWebhook";
import UpdateOrderWebhook from "views/Configuration/Webhooks/Order/UpdateOrderWebhook";
import AddOutOfStockWebhook from "views/Configuration/Webhooks/OutOfStock/AddOutOfStockWebhook";
import UpdateOutOfStockWebhook from "views/Configuration/Webhooks/OutOfStock/UpdateOutOfStockWebhook";
import AddOrderShippingWebhook from "views/Configuration/Webhooks/OrderShipping/AddOrderShippingWebhook";
import UpdateOrderShippingWebhook from "./views/Configuration/Webhooks/OrderShipping/UpdateOrderShippingWebhook";

// Shipping
import Shipping from "views/Shipping/ShippingRule/ShippingRules";
import ShippingRule from "views/Shipping/ShippingRule/ShippingRule";
import AddShippingRule from "views/Shipping/ShippingRule/AddShippingRule";
import ShippingOptions from "views/Shipping/ShippingOption/ShippingOptions";
import ShippingOption from "views/Shipping/ShippingOption/ShippingOption";
import AddShippingOption from "views/Shipping/ShippingOption/AddShippingOption";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa-thin fa-gauge-max",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "fa-thin fa-box-open-full",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/addons",
    name: "Add-ons",
    visible: false,
    component: Addons,
    layout: "/admin",
    parentPath: "/products",
  },
  {
    path: "/most-sold-products",
    name: "Most sold products",
    icon: "fal fa-fw fa-box-full",
    visible: false,
    component: MostSoldProducts,
    layout: "/admin",
    parentPath: "/products",
  },
  {
    path: "/product/:id",
    name: "Product",
    icon: "fal fa-fw fa-box-full",
    component: Product,
    layout: "/admin",
    visible: false,
    parentPath: "/products",
  },
  {
    path: "/addon/:id",
    name: "Add-on",
    icon: "fal fa-fw fa-box-full",
    component: Addon,
    layout: "/admin",
    visible: false,
    parentPath: "/addons",
  },
  {
    path: "/stock",
    name: "Stock",
    icon: "fal fa-fw fa-box-full",
    component: Stock,
    layout: "/admin",
    visible: false,
    parentPath: "/products",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "fa-thin fa-cart-shopping",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/follow-up",
    name: "Follow-up",
    icon: "fa-thin fa-cart-circle-arrow-up",
    component: OrdersFollowUp,
    layout: "/admin",
    visible: false,
    parentPath: "/orders",
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "fa-thin fa-users",
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/discounts",
    name: "Discounts",
    icon: "fa-thin fa-tags",
    component: Discounts,
    layout: "/admin",
  },
  {
    path: "/discount-codes",
    name: "Discount codes",
    component: DiscountCodes,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },

  {
    path: "/add-discount-code",
    name: "Add discount code",
    component: AddDiscountCode,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/discount-rules",
    name: "Discount rules",
    component: DiscountRules,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/discount-rule/:id",
    name: "Discount rule",
    component: DiscountRule,
    layout: "/admin",
    visible: false,
    parentPath: "/discounts",
  },
  {
    path: "/add-discount-rule",
    name: "Add discount rule",
    component: AddDiscountRule,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    component: Vouchers,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/voucher-group",
    name: "Voucher group",
    component: VoucherGroup,
    layout: "/admin",
    visible: false,
    parentPath: "/discounts",
  },
  {
    path: "/voucher/:id",
    name: "Voucher",
    component: Voucher,
    layout: "/admin",
    visible: false,
    parentPath: "/discounts",
  },
  {
    path: "/add-voucher-group",
    name: "Add voucher group",
    component: AddVoucherGroup,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/stores",
    name: "Stores",
    icon: "fal fa-fw fa-store",
    component: Stores,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/add-store",
    name: "Add store",
    icon: "fal fa-fw fa-store",
    component: AddStore,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/store/:id",
    name: "Store",
    component: Store,
    layout: "/admin",
    visible: false,
    parentPath: "/stores",
  },
  ...getStockVersion() !== "v1" ? [{
    path: "/inventories",
    name: "Inventories",
    icon: "fal fa-fw fa-store",
    component: Inventories,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  }, {
    path: "/inventory/:id",
    name: "Inventory",
    component: Inventory,
    layout: "/admin",
    visible: false,
    parentPath: "/inventories",
  }] : [],
  // Configuration
  {
    path: "/configurations",
    name: "Configurations",
    icon: "fa-thin fa-gear",
    component: Configuration,
    visible: false,
    layout: "/admin",
  },
  {
    path: "/fortnox",
    name: "Fortnox",
    component: Fortnox,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/klaviyo",
    name: "Klaviyo",
    component: Klaviyo,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/webhook-order",
    name: "Order",
    component: OrderWebhooks,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/webhook-product",
    name: "Product",
    component: ProductWebhooks,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/webhook-outOfStock",
    name: "Out of stock",
    component: OutOfStockWebhooks,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/webhook-shipping",
    name: "Order Shipping",
    component: OrderShippingWebhooks,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/order-webhook/:id",
    name: "Edit order webhook",
    component: UpdateOrderWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configurations",
  },
  {
    path: "/product-webhook/:id",
    name: "Edit product webhook",
    component: UpdateProductWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configurations",
  },
  {
    path: "/outOfStock-webhook/:id",
    name: "Edit out of stock webhook",
    component: UpdateOutOfStockWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configurations",
  },
  {
    path: "/order-shipping-webhook/:id",
    name: "Edit order shipping webhook",
    component: UpdateOrderShippingWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configurations",
  },
  {
    path: "/add-product-webhook",
    name: "Add product webhook",
    component: AddProductWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configurations",
  },
  {
    path: "/add-order-webhook",
    name: "Add order webhook",
    component: AddOrderWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configurations",
  },
  {
    path: "/add-outOfStock-webhook",
    name: "Add add out of stock webhook",
    component: AddOutOfStockWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configurations",
  },
  {
    path: "/add-order-shipping-webhook",
    name: "Add add order shipping webhook",
    component: AddOrderShippingWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configurations",
  },
  // Shipping
  {
    path: "/shipping-rules",
    name: "Shipping",
    icon: "fal fa-fw fa-shipping-fast",
    component: Shipping,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/shipping-rule/:id",
    name: "Shipping rule",
    component: ShippingRule,
    visible: false,
    layout: "/admin",
    parentPath: "/shipping-rules",
  },
  {
    path: "/add-shipping-rule",
    name: "Add shipping rule",
    component: AddShippingRule,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/shipping-options",
    name: "Shipping options",
    component: ShippingOptions,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
  {
    path: "/shipping-option/:id",
    name: "Shipping Option",
    icon: "fal fa-fw fa-box-full",
    component: ShippingOption,
    layout: "/admin",
    visible: false,
    parentPath: "/shipping-rules",
  },
  {
    path: "/add-shipping-option",
    name: "Add shipping option",
    component: AddShippingOption,
    visible: false,
    layout: "/admin",
    parentPath: "/configurations",
  },
];
export default dashRoutes;
