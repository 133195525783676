import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import esb from "elastic-builder";
import { useAdminContext } from "context/AdminContext";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import QuickFilter from "components/Table/Filter/QuickFilter";
import QuickFilterOption from "components/Table/Filter/QuickFilterOption";
import SearchForm from "components/Table/Search/SearchForm";
import ClearButton from "components/Table/Filter/ClearButton";
import StoreSelector from "components/StoreSelector/StoreSelector";
import FilterButton from "components/Ui/FilterButton";
import ShippingSelector from "./Filter/ShippingSelector";
import OrderValueRange from "./Filter/OrderValueRange";
import TimeSpan from "./Filter/TimeSpan";
import Button from "../../components/Ui/Button";
import { getFilterQuery } from "./Filter/getFilterQuery";

const Container = styled.div`
  width: 100%;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
`;

const FilterForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Filter = styled.div`
  background: ${(p) => p.theme.colors.background};
  border-radius: 0.6rem;
  padding: 1rem 2rem;
  border: 0.1rem solid ${(p) => p.theme.colors.backgroundOpac};
  margin-bottom: 1rem;

  :hover {
    transition: background 0.2s ease-in;
    background: ${(p) => (p.expanded ? (p) => p.theme.colors.background : p.theme.colors.greyOpac)};
  }
`;

const FilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  i {
    transition: transform 0.2s;
    transform: rotate(${(p) => (p.$expanded ? "180deg" : "0deg")});
  }
`;

const FilterDropdown = styled.div`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyOpac};
`;
const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    gap: 1.5rem;
    width: 100%;
    padding: 0 0 2rem;
    margin-top: 1rem;
    min-height: 4rem;

    ${MEDIA_MIN_LARGE} {
        padding: 0;
        margin: 0 0 2rem;
        justify-content: center;
    }

    button {
        margin: 0 !important;
    }
`;
const Heading = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  display: flex;
  align-items: center;

  i {
    font-size: 2rem;
    color: ${(p) => (p.$filterActive ? p.theme.colors.primaryDarker : p.theme.colors.white)};
  }

  i {
    transform: none;
    margin-right: 1rem;
  }
`;

const HeadingClearButton = styled(Button)`
  height: 3rem;
  width: 6rem;
  margin-left: 1rem;
`;

const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  margin-bottom: 2rem;

  > div {
    min-width: 33.5rem;
  }

  ${MEDIA_MIN_LARGE} {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

const StoreSelect = styled(StoreSelector)`
  margin: 0 1rem;

  .multi-select {
    .dropdown-heading {
      height: 4rem;
    }
  }
`;

const FILTER_STATUSES = [
  { value: "success", label: "Success" },
  { value: "shipped", label: "Shipped" },
  { value: "refunded", label: "Refunded" },
  { value: "canceled", label: "Canceled" },
  { value: "failed", label: "Failed" },
  { value: "waiting", label: "Waiting" },
];

export default () => {
  const { orderFilter, updateOrderFilter, clearOrderFilter, orderTableSettings } =
    useAdminContext();

  const [inputError, setInputError] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [appliedOrderFilter, setAppliedOrderFilter] = useState(orderFilter)

  const searchAndFilterOnReference = (query) => {
    updateOrderFilter(
      "esbQuery",
      esb
        .requestBodySearch()
        .query(
          query.boolQuery.must([
            esb.matchQuery("statusLog.status", "success"),
            esb.queryStringQuery(searchInput.trim()).fuzziness("AUTO").fields(["reference"]),
          ])
        )
        .sort(esb.sort("lastUpdated", "desc"))
        .size(orderTableSettings.ordersPerPage)
        .from(0)
        .trackTotalHits(true),
      query.filterActive
    );
  };

  const searchAndFilterOnInput = (query) => {
    updateOrderFilter(
      "esbQuery",
      esb
        .requestBodySearch()
        .query(
          query.boolQuery.must([
            esb.matchQuery("statusLog.status", "success"),
            esb.queryStringQuery(formatInput(searchInput)).analyzeWildcard(),
          ])
        )
        .sort(esb.sort("lastUpdated", "desc"))
        .size(orderTableSettings.ordersPerPage)
        .from(0)
        .trackTotalHits(true),
      query.filterActive
    );
  };

  const filterWithoutSearch = (query) => {
    updateOrderFilter(
      "esbQuery",
      esb
        .requestBodySearch()
        .query(query.boolQuery.must(esb.matchQuery("statusLog.status", "success")))
        .sort(esb.sort("lastUpdated", "desc"))
        .size(orderTableSettings.ordersPerPage)
        .from(0)
        .trackTotalHits(true),
      query.filterActive
    );
  };

  const searchAndFilterOrders = (event) => {
    event.preventDefault();
    searchInput.length ? setInputError(false) : setInputError(true);
    const query = getFilterQuery(orderFilter);
    if (/^\d+$/.test(searchInput.trim())) {
      searchAndFilterOnReference(query);
    } else if (searchInput && searchInput.replace(/\s/g, "") !== "") {
      searchAndFilterOnInput(query);
    } else {
      setSearchInput("");
      filterWithoutSearch(query);
    }
    setAppliedOrderFilter(orderFilter)
  };

  const formatInput = (input) => {
    if (/^([0-9a-fA-F]{8})-(([0-9a-fA-F]{4}-){3})([0-9a-fA-F]{12})$/.test(input)) {
      return `"${input}"`;
    }
    return input.replace("@", "*");
  };

  const clearFilter = (event) => {
    setExpanded(false);
    event && event.preventDefault();
    event && event.stopPropagation();
    clearFilterInput();
  };
  const clearFilterInput = () => {
    clearOrderFilter();
  };

  const isFirstRun = useRef(true);
  useLayoutEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    searchAndFilterOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <SearchForm
        autoComplete="off"
        placeholder="Order number, e-mail, name..., (Use * as wildcard)"
        inputError={inputError}
        setInputError={setInputError}
        setSearchInput={setSearchInput}
        searchFunc={searchAndFilterOrders}
        defaultValue={searchInput}
      />
      <Filter expanded={expanded}>
        <FilterHeader $expanded={expanded} onClick={() => setExpanded(!expanded)}>
          <Heading $filterActive={!!orderFilter?.filterActive}>
            <i className="fa-light fa-sliders"></i>All filters{" "}
            {orderFilter?.filterActive ? `(${Object.keys(appliedOrderFilter).length - 2})` : ""}
            {orderFilter?.filterActive && (
              <HeadingClearButton onClick={clearFilter}>Clear</HeadingClearButton>
            )}
          </Heading>
          <div>
            <i className="fa-regular fa-chevron-down"></i>
          </div>
        </FilterHeader>

        {expanded && (
          <FilterDropdown>
            <Container>
              <QuickFilter expanded={"false"}>
                <div>
                  {FILTER_STATUSES.map((status) => (
                    <QuickFilterOption
                      key={status.value}
                      active={orderFilter?.filterStatus?.includes(status.value)}
                      onClick={() => {
                        updateOrderFilter(
                          "filterStatus",
                          orderFilter?.filterStatus?.includes(status.value)
                            ? orderFilter?.filterStatus?.filter((value) => value !== status.value)
                            : orderFilter?.filterStatus
                            ? [...orderFilter?.filterStatus, status.value]
                            : [status.value]
                        );
                      }}>
                      {status.label}
                    </QuickFilterOption>
                  ))}
                </div>
              </QuickFilter>
              <FilterForm>
                <SelectWrapper>
                  <div>
                    Stores
                    <StoreSelect
                      showContinents
                      dropDownTop="4rem"
                      small
                      selectedStores={orderFilter?.filterStores ?? []}
                      setSelectedStores={(value) => updateOrderFilter("filterStores", value)}
                      inOrderFilter={true}
                    />
                  </div>
                  <div>
                    Shipping
                    <ShippingSelector
                      label={"Select shipping methods"}
                      updateOrderFilter={updateOrderFilter}
                      orderFilter={orderFilter}
                    />
                  </div>
                </SelectWrapper>
                <TimeSpan orderFilter={orderFilter} updateOrderFilter={updateOrderFilter} />
                <OrderValueRange updateOrderFilter={updateOrderFilter} orderFilter={orderFilter} />

                <Buttons>
                  {Object.keys(orderFilter).length > 2 && (
                    <>
                      {orderFilter.filterActive && <ClearButton onClick={clearFilter} />}
                      <FilterButton isLoading={false} onClick={searchAndFilterOrders}>
                        <i className="fal fa-sliders-h"></i>Apply
                      </FilterButton>
                    </>
                  )}
                </Buttons>
              </FilterForm>
            </Container>
          </FilterDropdown>
        )}
      </Filter>
    </Container>
  );
};
