import gql from "graphql-tag";

const SET_ORDER_AS_SHIPPED = gql`
  mutation setOrderAsShipped($id: ID!) {
    setOrderAsShipped(id: $id) {
      id
      status
      reference
      statusLog {
        status
        timestamp
      }
    }
  }
`;

export default SET_ORDER_AS_SHIPPED;
