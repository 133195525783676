import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import styled from "styled-components/macro";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import ISO6391 from "iso-639-1";
import LocaleCode from "locale-code";
import ALL_STORES from "graphql/Store/AllStores";
import GET_SHIPPING_OPTION from "graphql/Shipping/ShippingOption/GetShippingOption";
import UPDATE_SHIPPING_OPTION from "graphql/Shipping/ShippingOption/UpdateShippingOption";
import _ from "lodash";
import SaveBar from "components/Ui/SaveBar";
import { useNotification } from "context/NotificationContext";
import editShipping from "helpers/getEditShipping";
import InfoBox from "components/Ui/InfoBox";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import Input from "components/Ui/InputNew";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import Button from "components/Ui/Button";
import IconButton from "components/Ui/Buttons/IconButton";
import Sidebar from "components/Shipping/Sidebar";
import ProviderChoice from "components/Shipping/ProviderChoice";
import Header from "components/Header/Header";
import Chip from "components/Ui/Chip";
import Toggle from "components/Ui/Toggle";
import AddTranslation from "components/Shipping/AddTranslation";
import DeleteShippingOption from "components/Shipping/DeleteShippingOption";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import getEditFreeShipping from "helpers/getEditFreeShipping";
import { formatPrice, formatFromMinorUnits } from "../../../helpers/money";
import { useQuery, useMutation } from "@apollo/client";

const OptionBox = styled(Box)`
  padding-bottom: 5rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 1rem;
  }
`;

const Heading = styled.h5`
  margin: 1rem 0 !important;
`;

const Container = styled(GridContainer)`
  padding: 0 0 3rem;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  width: 100%;
  border: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  border-radius: 0.6rem;
  min-height: 18rem;

  img {
    max-width: 10rem;
  }
`;

const PriceContainer = styled.div`
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
`;

const PriceInput = styled(Input)``;

const Translations = styled.div``;

const ShowAllPrices = styled.p`
  border-bottom: 0.2rem solid ${(p) => p.theme.colors.primary};
  padding: 2rem 0 0.5rem !important;
  width: 6rem;
  &:hover {
    cursor: pointer;
    border-bottom: 0.2rem solid ${(p) => p.theme.colors.secondary};
  }
`;

const AddTranslationButton = styled(Button)`
  i {
    margin-right: 1.5rem;
  }
  margin: 0 0 2rem;
  ${MEDIA_MIN_LARGE} {
    width: 25%;
    i {
      margin-right: 1rem;
    }
  }
`;

const LanguageField = styled.div`
  display: flex;
  position: relative;

  input {
    padding-left: 4.5rem;
  }

  button {
    margin-top: 3.7rem;
  }

  img {
    position: absolute;
    top: 4.3rem;
    left: 1.5rem;

    ${MEDIA_MIN_LARGE} {
      top: 4.4rem;
    }
  }
`;

const ShippingOption = () => {
  const location = useLocation();
  const { id } = location.state;
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const { setNotification } = useNotification();
  const history = useHistory();
  const [descriptions, setDescriptions] = useState({});
  const [displayNames, setDisplayNames] = useState({});
  const [showDescTranslation, setShowDescTranslation] = useState(false);
  const [showNameTranslation, setShowNameTranslation] = useState(false);
  const [showPricesFromStore, setShowPricesFromStore] = useState(false);
  const [freeShipping, setFreeShipping] = useState();
  const [active, setActive] = useState();
  const [activeBadge, setActiveBadge] = useState();

  const { loading, error, data } = useQuery(GET_SHIPPING_OPTION, {
    variables: { id: id },
  });

  const {
    loading: allStoresLoading,
    error: allStoresError,
    data: allStoresData,
  } = useQuery(ALL_STORES, {
    variables: { from: 0, size: 300 },
  });

  const [updateShipping, { loading: addShippingLoading }] = useMutation(UPDATE_SHIPPING_OPTION);

  const shippingOption = data?.getProduct;

  const currencyUnits = allStoresData?.allStores?.stores.map((store) => store.currencyUnit);
  const allCurrencies = [...new Set(currencyUnits)];
  const newCurrencies = _.difference(
    allCurrencies,
    shippingOption?.price?.map((price) => price.currencyUnit)
  );

  useEffect(() => {
    if (data) {
      const customerAttribute = JSON.parse(data.getProduct.customerAttribute)
      setFreeShipping(customerAttribute?.["brink:freeShipping"])

      setValue("name", data.getProduct.name);
      setValue("imageUrl", data.getProduct.imageUrl);
      const { description, displayName } = JSON.parse(data.getProduct.attribute);
      setValue("displayNames", displayName);
      setDisplayNames(displayName);
      setValue("descriptions", description);
      setDescriptions(description);
      setActive(data.getProduct.active);
      setActiveBadge(data.getProduct.active);
    }
  }, [data, setValue]);

  const onSubmit = (data) => {
    const price = Object.keys(data.price).map((key) => ({
      amount: formatPrice(data.price[key].amount, key),
      currencyUnit: key,
    }));

    updateShipping(toShippingOption(data, price))
      .then((response) => {
        console.log(response);
        setActiveBadge(response?.data?.updateProduct[0].active);
        setNotification({
          status: "success",
          message: `Shipping option successfully updated`,
        });
      })
      .catch((error) =>
        setNotification({
          status: "error",
          message: "An error occurred adding the shipping option, please contact support",
        })
      );
  };

  const getLanguageName = (code) => {
    if (ISO6391.getName(code)) return ISO6391.getName(code);
    if (LocaleCode.getLanguageName(code.replace("_", "-")))
      return LocaleCode.getLanguageName(code.replace("_", "-"));
    return "";
  };

  const removeDescription = (key) =>
    setDescriptions((current) => {
      const updatedDescriptions = { ...current, [key]: null };
      setValue("descriptions", updatedDescriptions);
      return updatedDescriptions;
    });

  const removeDisplayName = (key) =>
    setDisplayNames((current) => {
      const updatedDisplayNames = { ...current, [key]: null };
      setValue("displayNames", updatedDisplayNames);
      return updatedDisplayNames;
    });

  const updateDescription = (langCode, description) => {
    const updatedDescriptions = { ...descriptions, [langCode]: description };
    setDescriptions(updatedDescriptions);
    setValue("descriptions", updatedDescriptions);
  };

  const updateDisplayName = (langCode, displayName) => {
    const updatedDisplayNames = { ...displayNames, [langCode]: displayName };
    setDisplayNames(updatedDisplayNames);
    setValue("displayNames", updatedDisplayNames);
  };

  const toShippingOption = (data, price) => ({
    variables: {
      id: id,
      name: data.name,
      imageUrl: data.imageUrl,
      price: price,
      category: "Shipping",
      active: active,
      ...(getEditFreeShipping
        ? {
            customerAttribute: JSON.stringify({ "brink:freeShipping": freeShipping }),
          }
        : {}),
      attribute: JSON.stringify({
        description: data.descriptions,
        displayName: data.displayNames,
      }),
      relatedProduct: data.provider,
    },
  });

  if (loading || allStoresLoading) return <Loader />;
  if (error || allStoresError) console.log(error);
  if (!data || !allStoresData) return null;

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/shipping-options", "Shipping options"],
          [`admin/shipping-options/${id}`, `${shippingOption.name}`],
        ]}
      />
      <Header heading="Shipping options"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer padding="0 2rem" expanded>
          <GridContainer>
            <GridItem columns="12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <SaveBar history={history} />
                <OptionBox
                  preHeading="Shipping Option"
                  heading={
                    <>
                      {shippingOption.name}
                      {activeBadge ? (
                        <i className="fal fa-check-circle"></i>
                      ) : (
                        <i className="fal fa-times-circle"></i>
                      )}
                    </>
                  }
                  subHeading={shippingOption.id}
                  showGoBackButton
                  goBackOnClick={() => history.push("/admin/shipping-options")}>
                  {editShipping() ? (
                    <>
                      {addShippingLoading && <Loader />}
                      <ActionButtons inBox footerOnMobile>
                        <DeleteShippingOption
                          name={shippingOption.name}
                          history={history}
                          id={shippingOption.id}
                        />
                        <ActionButton type="submit">
                          <i className="fal fa-fw fa-check" />
                          Save
                        </ActionButton>
                      </ActionButtons>
                      <GridContainer>
                        <GridItem columns="6">
                          <ImageContainer>
                            <img
                              data-src="holder.js/100px180/"
                              alt={shippingOption.name}
                              src={shippingOption.imageUrl}
                              data-holder-rendered="true"
                            />
                          </ImageContainer>
                        </GridItem>
                        <GridItem columns="6" padding="0 2rem">
                          <Attributes>
                            <Attribute>
                              <Label>Created:</Label>
                              <Value>
                                <Moment format="YYYY-MM-DD HH:mm">{shippingOption.created}</Moment>
                              </Value>
                            </Attribute>
                            <Attribute>
                              <Label>Last updated:</Label>
                              <Value>
                                <Moment format="YYYY-MM-DD HH:mm">
                                  {shippingOption.lastUpdated}
                                </Moment>
                              </Value>
                            </Attribute>
                            {getEditFreeShipping() && (
                              <Attribute>
                                <Label>Free shipping:</Label>
                                <Toggle
                                  data-tip="When creating a discount code with free shipping, should this option be included?"
                                  active={freeShipping}
                                  handleToggle={() => setFreeShipping(!freeShipping)}
                                />
                              </Attribute>
                            )}
                            <Attribute>
                              <Label>Active:</Label>
                              <Toggle active={active} handleToggle={() => setActive(!active)} />
                            </Attribute>
                          </Attributes>
                        </GridItem>
                        <GridItem columns="6" padding="1rem 2rem 0 2rem">
                          <Input
                            {...register("name", {
                              rules: "This is a required field",
                            })}
                            type="text"
                            defaultValue={shippingOption.name}
                            label="Internal name"
                            errors={errors}
                          />
                        </GridItem>
                        <GridItem columns="6" padding="1rem 2rem 0 2rem">
                          <ProviderChoice
                            {...register("provider", {
                              rules: "This is a required field",
                            })}
                            setProviderValue={(value) => setValue("provider", value)}
                            providerId={shippingOption.relatedProduct.id}
                          />
                        </GridItem>
                        <GridItem columns="12" padding="0 2rem">
                          <Input
                            {...register("imageUrl", {
                              rules: "This is a required field",
                            })}
                            type="text"
                            defaultValue={shippingOption.imageUrl}
                            label="Image URL"
                            icon="image"
                            errors={errors}
                          />
                        </GridItem>

                        <GridItem columns="12" padding="0 2rem">
                          <Heading>Display Name</Heading>
                          <Translations>
                            {Object.keys(displayNames).map((key) => {
                               if (!displayNames[key]) return null;
                              return (
                                <LanguageField key={key}>
                                  <Input
                                    {...register(`displayNames.${key}`, {
                                      rules: "This is a required field",
                                    })}
                                    type="text"
                                    icon="box"
                                    label={`${getLanguageName(key)} (${key})`}
                                    errors={errors}
                                  />
                                  <IconButton onClick={() => removeDisplayName(key)}>
                                    <i className="fal fa-trash-alt"></i>
                                  </IconButton>
                                </LanguageField>
                              );
                            })}
                          </Translations>
                          <AddTranslationButton
                            type="button"
                            handleOnClick={() => setShowNameTranslation(!showNameTranslation)}>
                            <i
                              className={`fa-light fa-layer-${
                                showNameTranslation ? "minus" : "plus"
                              }`}
                            />
                            Translation
                          </AddTranslationButton>
                          <AddTranslation
                            showTranslation={showNameTranslation}
                            setShowTranslation={setShowNameTranslation}
                            translations={displayNames}
                            edit
                            updateTranslation={updateDisplayName}
                          />
                        </GridItem>
                        <GridItem columns="12" padding="0 2rem">
                          <Heading>Description</Heading>
                          <Translations>
                            {Object.keys(descriptions).map((key) => {
                              if (!descriptions[key]) return null;
                              return (
                                <LanguageField key={key}>
                                  <Input
                                    {...register(`descriptions.${key}`, {
                                      rules: "This is a required field",
                                    })}
                                    type="text"
                                    label={`${getLanguageName(key)} (${key})`}
                                    icon="memo-pad"
                                    errors={errors}
                                  />
                                  <IconButton
                                    onClick={() => {
                                      removeDescription(key);
                                    }}>
                                    <i className="fal fa-trash-alt"></i>
                                  </IconButton>
                                </LanguageField>
                              );
                            })}
                          </Translations>

                          <AddTranslationButton
                            type="button"
                            handleOnClick={() => setShowDescTranslation(!showDescTranslation)}>
                            <i
                              className={`fa-light fa-layer-${
                                showDescTranslation ? "minus" : "plus"
                              }`}
                            />
                            Translation
                          </AddTranslationButton>
                          <AddTranslation
                            showTranslation={showDescTranslation}
                            setShowTranslation={setShowDescTranslation}
                            translations={descriptions}
                            edit
                            updateTranslation={updateDescription}
                          />
                        </GridItem>
                        <GridItem padding="0 2rem">
                          <Heading>Price</Heading>
                        </GridItem>
                        <>
                          {newCurrencies.length > 0 && !showPricesFromStore && (
                            <GridItem columns="12" padding="0 0 1rem">
                              <InfoBox>
                                Additional currencies exists, input prices for all configured
                                currencies?
                                <ShowAllPrices
                                  onClick={() => setShowPricesFromStore(!showPricesFromStore)}>
                                  Show All
                                </ShowAllPrices>
                              </InfoBox>
                            </GridItem>
                          )}
                          {!showPricesFromStore ? (
                            <GridContainer padding="0 0 0 2rem">
                              {shippingOption.price.map((price) => (
                                <GridItem columns="3" padding="0 1rem 0 0" key={price.currencyUnit}>
                                  <PriceInput
                                    {...register(`price[${price.currencyUnit}].amount`, {
                                      rules: "This is a required field",
                                    })}
                                    type="number"
                                    step="any"
                                    icon="tag"
                                    label={price.currencyUnit}
                                    defaultValue={formatFromMinorUnits(
                                      price.amount,
                                      price.currencyUnit
                                    )}
                                    errors={errors}
                                  />
                                </GridItem>
                              ))}
                            </GridContainer>
                          ) : (
                            <GridContainer padding="0 0 0 2rem">
                              {allCurrencies.map((currencyUnit) => (
                                <GridItem columns="3" padding="0 1rem 0 0" key={currencyUnit}>
                                  <PriceInput
                                    {...register(`price[${currencyUnit}].amount`, {
                                      rules: "This is a required field",
                                    })}
                                    type="number"
                                    icon="tag"
                                    label={currencyUnit}
                                    step="any"
                                    defaultValue={formatFromMinorUnits(
                                      shippingOption.price.find(
                                        (price) => price.currencyUnit === currencyUnit
                                      )?.amount ?? 0,
                                      currencyUnit
                                    )}
                                    errors={errors}
                                  />
                                </GridItem>
                              ))}
                            </GridContainer>
                          )}
                        </>
                      </GridContainer>
                    </>
                  ) : (
                    <>
                      <Container>
                        <GridItem columns="2">
                          <ImageContainer>
                            <img
                              data-src="holder.js/100px180/"
                              alt={shippingOption.name}
                              src={shippingOption.imageUrl}
                              data-holder-rendered="true"
                            />
                          </ImageContainer>
                        </GridItem>
                        <GridContainer>
                          <GridItem columns="6">
                            <Attributes>
                              <Attribute>
                                <Label>Provider:</Label>
                                <Value>{shippingOption.relatedProduct.name}</Value>
                              </Attribute>
                              <Attribute>
                                <Label>Internal name:</Label>
                                <Value>{shippingOption.name}</Value>
                              </Attribute>
                            </Attributes>
                          </GridItem>
                          <GridItem columns="6">
                            <Attributes>
                              <Attribute>
                                <Label>Created:</Label>
                                <Value>
                                  <Moment format="YYYY-MM-DD HH:mm">
                                    {shippingOption.created}
                                  </Moment>
                                </Value>
                              </Attribute>
                              <Attribute>
                                <Label>Last updated:</Label>
                                <Value>
                                  <Moment format="YYYY-MM-DD HH:mm">
                                    {shippingOption.lastUpdated}
                                  </Moment>
                                </Value>
                              </Attribute>
                            </Attributes>
                          </GridItem>
                        </GridContainer>
                        <GridItem padding="0 3rem" columns="12">
                          <Attribute>
                            <Label>Image URL:</Label>
                            <Value>
                              <a
                                href={shippingOption.imageUrl}
                                target="_blank"
                                rel="noopener noreferrer">
                                {shippingOption.imageUrl}
                              </a>
                            </Value>
                          </Attribute>
                        </GridItem>
                      </Container>
                      <GridContainer>
                        <GridItem columns="6">
                          <h5>Display Name</h5>
                          <Attributes>
                            {Object.keys(displayNames).map((key) => {
                              return (
                                <Attribute key={key}>
                                  <Label>{`${ISO6391.getName(key)} (${key})`}:</Label>
                                  <Value>{displayNames[key]}</Value>
                                </Attribute>
                              );
                            })}
                          </Attributes>
                        </GridItem>
                        <GridItem columns="6">
                          <h5>Description</h5>
                          <Attributes>
                            {Object.keys(descriptions).map((key) => {
                              if (!descriptions[key]) return null;
                              return (
                                <Attribute key={key}>
                                  <Label>{`${ISO6391.getName(key)} (${key})`}:</Label>
                                  <Value>{descriptions[key]}</Value>
                                </Attribute>
                              );
                            })}
                          </Attributes>
                        </GridItem>
                      </GridContainer>
                      <GridItem padding="0 0 0 3rem">
                        <h5>Price</h5>
                      </GridItem>
                      <PriceContainer>
                        {shippingOption.price.map((price) => {
                          return (
                            <Chip key={price.currencyUnit}>
                              {price.currencyUnit} {formatFromMinorUnits(price.amount)}
                            </Chip>
                          );
                        })}
                      </PriceContainer>
                    </>
                  )}
                </OptionBox>
              </form>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default ShippingOption;
