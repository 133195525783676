import gql from "graphql-tag";

const SEARCH_SHIPPING_PROVIDERS = gql`
  query searchProducts($query: AWSJSON!) {
    searchProducts(query: $query) {
      totalHits
      products {
        id
        name
      }
    }
  }
`;

export default SEARCH_SHIPPING_PROVIDERS;
