import React from "react";
import OktaConfig from "./OktaConfig";
import Auth0Config from "./Auth0Config";
import { providers, configuredAuthProvider } from "helpers/authProvider";

const ApolloClientConfigWrapper = ({ children }) => {
  return (
    <>
      {configuredAuthProvider === providers.AUTH0 && <Auth0Config children={children} />}
      {configuredAuthProvider === providers.OKTA && <OktaConfig children={children} />}
    </>
  );
};

export default ApolloClientConfigWrapper;
