import React from "react";
import styled from "styled-components/macro";
import { Mutation } from "@apollo/client/react/components";
import uuid from "react-uuid";
import { useForm, Controller } from "react-hook-form-old";

import ADD_SHIPPING_PROVIDER from "graphql/Shipping/ShippingProvider/AddShippingProvider";

import { useNotification } from "context/NotificationContext";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Ui/Loader";
import Input from "components/Ui/Input";
import Button from "components/Ui/Button";
import Label from "components/AttributeList/Label";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  height: ${(p) => (p.showAddProvider ? "30rem" : 0)};
  overflow: hidden;
  transition: 0.4s ease-in height;
`;

const Attributes = styled.ul`
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 2.5rem 0 0;
`;

const AddButton = styled(Button)`
  margin-top: 2rem;
`;

export default ({ showAddProvider, setShowAddProvider, setProviders, providers }) => {
  const { control, handleSubmit, errors } = useForm();
  const { setNotification } = useNotification();

  const toShippingProvider = (data) => ({
    variables: {
      id: uuid(),
      name: data.name,
      type: "shippingProvider",
      category: "Shipping",
    },
  });

  return (
      <Wrapper showAddProvider={showAddProvider}>
        <Mutation
          mutation={ADD_SHIPPING_PROVIDER}
          onCompleted={(data) => {
            setNotification({
              status: "success",
              message: `Shipping provider successfully added`,
            });
            setProviders([
              ...providers,
              { name: data.addProduct[0].name, id: data.addProduct[0].id },
            ]);
          }}
          onError={() => {
            setNotification({
              status: "error",
              message: "An error occurred adding the shipping provider, please contact support",
            });
          }}>
          {(updateShipping, { loading: addShippingLoading }) => {
            const onSubmit = (data) => {
              setShowAddProvider(false);
              updateShipping(toShippingProvider(data)).catch((e) => {
                console.log(e);
              });
            };
            return (
              <GridItem columns="12" padding="0">
                <div>
                <Label>Add Provider</Label>
                  <Attributes>
                    {addShippingLoading && <Loader />}
                    <Controller
                      as={Input}
                      control={control}
                      rules={{
                        required: "This is a required field",
                        pattern: {
                          message: "",
                        },
                      }}
                      type="text"
                      defaultValue=""
                      name="name"
                      label="Name"
                      errors={errors}
                    />
                    <AddButton onClick={handleSubmit(onSubmit)}>Add Provider</AddButton>
                  </Attributes>
                </div>
              </GridItem>
            );
          }}
        </Mutation>
      </Wrapper>
  );
};
