import React from "react";
import Button from "components/Ui/Button";
import styled from "styled-components";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const TypeButton = styled(Button)`
  margin: 2rem 0.5rem 0;
  width: 100%;
  text-transform: capitalize;
  background: ${(p) =>
    p.currentType ? (p) => p.theme.colors.primaryGradient : p.theme.colors.greyOpac};
  color: ${(p) => (p.currentType ? p.theme.colors.buttonText : p.theme.colors.white)};
  font-weight: 700;

  &:hover {
    background: ${(p) =>
      !p.currentType ? p.theme.colors.secondary : (p) => p.theme.colors.primaryGradient};
    filter: ${(p) => (p.currentType ? "brightness(110%)" : "brightness(100%)")};
  }
  ${MEDIA_MIN_LARGE} {
    padding: 0rem 4rem;
    width: 46%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${MEDIA_MIN_LARGE} {
    justify-content: space-between;
  }
`;

const getFriendlyTypeName = (name) => {
  switch (name) {
    case "FIXED_PRICE":
      return "fixed amount";

    case "GWP_THRESHOLD":
      return "gift threshold";

    case "GWP_ITEMS":
      return "gift product";

    default:
      return name.toLowerCase();
  }
};

const TypeButtons = ({ ruleTemplates, setTemplateId, currentTemplate, setTemplate }) => {
  return (
    <ButtonContainer>
      {ruleTemplates.map((type) => {
        return type.name ? (
          <TypeButton
            type="button"
            currentType={currentTemplate === type.id}
            key={type.id}
            onClick={() => setTemplate ? setTemplate(type) : setTemplateId(type.id)}>
            {getFriendlyTypeName(type.name)}
          </TypeButton>
        ) : null;
      })}
    </ButtonContainer>
  );
};

export default TypeButtons;
