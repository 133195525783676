import gql from "graphql-tag";

const ADD_STOCK = gql`
  mutation addStock($productId: ID!, $stock: Int!) {
    addStock(
      stock: {
        productId: $productId
        stock: $stock
      }
    ) {
      productId
      stock
    }
  }
`;

export default ADD_STOCK;
