import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "../../../variables/mediaQueries";
import Select from "../../../components/Ui/Select";
import Timespan from "../../../components/Table/Filter/Timespan";

const TimespanContainer = styled(Timespan)`
    margin-right: 0.5rem;
   display: flex;


  ${MEDIA_MIN_MEDIUM} {
    padding: 0;
      margin-bottom: 1rem;
  }
    
    input {
        padding: 0.8rem 1rem;
    }
    
    >div {
        display: flex;
        flex-direction: column;
        width: auto;
        margin-right: 1rem;
    }
`;

const Label = styled.label`
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  white-space: nowrap;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }
`;

const IncludeSelect = styled(Select)`
  max-height: 4rem;
    background-color: white;
    margin: 0;
`;

const INCLUDE_LIST = [
  { value: "created", label: "Created" },
  { value: "lastUpdated", label: "Last Updated" },
];

const TimeSpan = ({ orderFilter, updateOrderFilter }) => {
  return (
    <TimespanContainer>
      <div>
        <Label>From:</Label>
        <input
          type="datetime-local"
          id="fromDate"
          value={orderFilter?.dateRange?.from ?? ""}
          name="fromDate"
          onChange={(e) =>
            updateOrderFilter("dateRange", { ...orderFilter?.dateRange, from: e.target.value })
          }
        />
      </div>
      <div>
        <Label>To:</Label>
        <input
          type="datetime-local"
          value={orderFilter?.dateRange?.to ?? ""}
          id="toDate"
          name="toDate"
          onChange={(e) =>
            updateOrderFilter("dateRange", { ...orderFilter?.dateRange, to: e.target.value })
          }
        />
      </div>
      <div>
        <Label>Date on:</Label>
        <IncludeSelect
          handleChange={(e) =>
            updateOrderFilter("dateRange", { ...orderFilter?.dateRange, include: e.target.value })
          }>
          {INCLUDE_LIST.map((s) => (
              <option
                key={s.value}
                value={s.value}
                selected={orderFilter?.dateRange?.include === s.value ?? s.value === "created"}>
                {s.label}
              </option>
          ))}
        </IncludeSelect>
      </div>
    </TimespanContainer>
  );
};

export default TimeSpan;
