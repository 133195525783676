import React from "react";
import Moment from "react-moment";

import FlagIcon from "components/Ui/FlagIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import getFullCountryName from "helpers/getFullCountryName";

export default ({ customer }) => (
  <GridContainer padding="0" collapse>
    <GridItem mobilePadding="1rem 0 0" desktopPadding="1rem 2rem 2rem 0" columns="6">
      <h5>Details</h5>
      <Attributes>
        <Attribute>
          <Label>E-mail:</Label>
          <Value>{customer.email}</Value>
        </Attribute>
        <Attribute>
          <Label>Name:</Label>
          <Value>
            {customer.givenName} {customer.familyName}
          </Value>
        </Attribute>
        <Attribute>
          <Label>Phone number:</Label>
          <Value>{customer.phone}</Value>
        </Attribute>
        <Attribute>
          <Label>User created:</Label>
          <Value>
            <Moment format="YYYY-MM-DD HH:mm">{customer.created}</Moment>
          </Value>
        </Attribute>
        <Attribute>
          <Label>User updated:</Label>
          <Value>
            <Moment format="YYYY-MM-DD HH:mm">{customer.created}</Moment>
          </Value>
        </Attribute>
      </Attributes>
    </GridItem>
    <GridItem mobilePadding="0 0 2rem" desktopPadding="1rem 0 2rem 2rem" columns="6">
      <h5>Address</h5>
      <Attributes>
        <Attribute>
          <Label>Street address:</Label>
          <Value>{customer.streetAddress}</Value>
        </Attribute>
        {customer.postalCode && (
          <Attribute>
            <Label>Postal code:</Label>
            <Value>{customer.postalCode}</Value>
          </Attribute>
        )}
        {customer.region && (
          <Attribute>
            <Label>Region/state/province code:</Label>
            <Value>{customer.region}</Value>
          </Attribute>
        )}
        {customer.houseNumberOrName && (
          <Attribute>
            <Label>House number or name:</Label>
            <Value>{customer.houseNumberOrName}</Value>
          </Attribute>
        )}
        <Attribute>
          <Label>City:</Label>
          <Value>{customer.city}</Value>
        </Attribute>
        <Attribute>
          <Label>Country:</Label>
          <Value>
            <FlagIcon countryCode={customer.country} />
            {getFullCountryName(customer.country)}
          </Value>
        </Attribute>
      </Attributes>
    </GridItem>
  </GridContainer>
);
