import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components/macro";
import { AdminProvider } from "./context/AdminContext";
import { NotificationProvider } from "./context/NotificationContext";
import { useCookies } from "react-cookie";
import { getTheme } from "./variables/colors";
import GlobalStyle from "./globalStyle";
import { BrowserRouter as Router } from "react-router-dom";
import AppWithRouterAccess from "./AppWithRouterAccess";
import { Auth0WithRouterAccess } from "./Auth0";
import { providers, configuredAuthProvider } from "helpers/authProvider";

function App() {
  const [cookies] = useCookies(["theme"]);
  const [theme, setTheme] = useState({});
  useEffect(() => {
    setTheme(getTheme(cookies.theme));
  }, [cookies]);

  if (!theme || !theme.colors) return null;

  return (
    <ThemeProvider theme={theme}>
      <AdminProvider>
        <NotificationProvider>
          <GlobalStyle />
          <Router>
            {configuredAuthProvider === providers.AUTH0 && <Auth0WithRouterAccess />}
            {configuredAuthProvider === providers.OKTA && <AppWithRouterAccess />}
          </Router>
        </NotificationProvider>
      </AdminProvider>
    </ThemeProvider>
  );
}

export default App;
