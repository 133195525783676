import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useAdminContext } from "context/AdminContext";
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import Pagination from "components/Pagination/Pagination";
import Button from "components/Ui/Button";
import Tooltip from "components/Ui/Tooltip";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import Orders from "components/Table/Rows";

import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Loader from "components/Ui/Loader";
import OrderFollowUpRow from "../../components/Order/OrderFollowUpRow";
import esb from "elastic-builder";
import { getFilterQuery } from "./Filter/getFilterQuery";

const Reference = styled(Column)`
    width: 20%;
    padding-left: 1rem;
    font-size: 1.3rem;

    ${MEDIA_MIN_LARGE} {
        width: 10%;
        padding-left: 1rem;
    }
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const Status = styled(Column)`
  width: 12%;
  font-size: 1.3rem;

  i {
    margin-right: 0;
  }

  ${MEDIA_MIN_LARGE} {
    width: 10%;
  }
`;

const CustomerInfo = styled(Column)`
    display: none;
  font-size: 1.3rem;

  i {
    margin-right: 0;
  }

  ${MEDIA_MIN_LARGE} {
      display: flex;
    width: 18%;
  }
`;

const AmountWithDiscount = styled(Column)`
  width: 33%;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 15%;
  }
`;

const PaymentMethod = styled(Column)`
  width: 15%;
  padding-right: 2rem;
  font-size: 1.3rem;
`;

const Country = styled(Column)`
  width: 15%;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 10%;
  }

  img {
    margin: 0.5rem 0 0;
    padding-bottom: 0.3rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 0.2rem;
    }
  }
`;

const Date = styled(Column)`
    width: 35%;
    font-size: 1.3rem;

    ${MEDIA_MIN_LARGE} {
        width: 12.5%;
    }
`;

const FollowUp = styled.div`
  width: 18%;
  font-size: 1.3rem;

  ${MEDIA_MIN_LARGE} {
    width: 10%;
  }
`;

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};
  padding-top: 0.3rem !important;

  i {
    font-size: 1.6rem;
  }
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  margin: 1rem 0;
  height: auto;

  div {
    display: flex;
    align-items: center;
  }
`;

const Border = styled.div`
  display: none;

  ${MEDIA_MIN_MEDIUM} {
    display: block;
    border-right: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    height: 3.5rem;
  }
`;

const MAX_RESULT_WINDOW = 10000;

export default function OrdersFollowUpTable({
  orders,
  ordersTotalHits,
  updatedOrder,
  loading,
  setTabs,
  tabs,
  setActiveTab,
  children: renderTableActions = null,
}) {
  const [goToPageInput, setGoToPageInput] = useState(1);
  const [currentSort, setCurrentSort] = useState("default");
  const [sortField, setSortField] = useState("reference");
  const {
    updateOrderFollowUpTableSettings,
    orderFollowUpTableSettings,
    updateOrderFollowUpFilter,
    orderFollowUpFilter,
  } = useAdminContext();

  const itemsPerPage = orderFollowUpTableSettings.ordersPerPage;

  const currentPage = orderFollowUpTableSettings.currentPage;

  const fromDate = orderFollowUpTableSettings?.filterDateTimeFrom;

  const toDate = orderFollowUpTableSettings?.filterDateTimeTo;

  const dateOn = orderFollowUpTableSettings?.include;

  const setItemsPerPage = (value) => {
  updateOrderFollowUpTableSettings("ordersPerPage", value);
  };

  const setCurrentPage = (value) => {
   updateOrderFollowUpTableSettings("currentPage", value);
  };

  useEffect(() => {
    const from = (currentPage - 1) * itemsPerPage;
    const query = getFilterQuery(orderFollowUpFilter);

    updateOrderFollowUpFilter(
        "esbQuery",
        esb
          .requestBodySearch()
          .query(query.boolQuery.mustNot([esb.matchQuery("statusLog.status", "success")]))
          .sort(esb.sort("lastUpdated", "desc"))
          .size(itemsPerPage)
          .from(from)
          .trackTotalHits(true),
        query.filterActive
      );
    setGoToPageInput(parseInt(currentPage, 10));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, updatedOrder, fromDate, toDate, dateOn]);

  const toValue = (sortField, obj) => {
    const fieldValue = obj[sortField];

    if (Number.isInteger(fieldValue)) {
      return fieldValue;
    } else if (typeof fieldValue.givenName === "string") {
      return (fieldValue.givenName + fieldValue.familyName).toLowerCase();
    } else {
      return fieldValue;
    }
  };

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);

        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const getSortType = (field, sort) => {
    if (field === sortField) {
      return sortTypes[sort].class;
    }
    return sortTypes.default.class;
  };

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";

    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  return (
    <TableWrapper>
      <Tooltip />
      <StyledToolbar>
        <div>
          <HoM>
            <GoToPage>
              Page
              <input
                value={goToPageInput}
                onChange={(e) => {
                  setGoToPageInput(e.target.value);
                }}
              />
              <button
                type="button"
                onClick={() => {
           updateOrderFollowUpTableSettings("currentPage", parseInt(goToPageInput))
                }}>
                <i className="fal fa-sync"></i>
              </button>
              of{" "}
              {ordersTotalHits
                ? Math.ceil(Math.min(ordersTotalHits, MAX_RESULT_WINDOW) / itemsPerPage)
                : "-"}{" "}
              pages
            </GoToPage>
          </HoM>
          <ItemsPerPage
            defaultValue={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            orderFollowUpTable={true}
          />
        </div>
        <div>
          <Border />
          <TotalItems>
            Total orders: <span>{ordersTotalHits || "-"}</span>
          </TotalItems>
          {renderTableActions &&
            renderTableActions({ totalHits: Math.min(ordersTotalHits, MAX_RESULT_WINDOW) })}
        </div>
      </StyledToolbar>
      <TableHeader>
        <Reference onClick={() => onSortChange("reference")}>
          <span>
            <HoM>Order</HoM> #
          </span>
          <SortButton>
            <i className={`fas fa-${getSortType("reference", currentSort)}`} />
          </SortButton>
        </Reference>
        <Status onClick={() => onSortChange("status")}>
          <HoM>Status</HoM>
          <SortButton>
            <i className={`fas fa-${getSortType("status", currentSort)}`} />
          </SortButton>
        </Status>
        <CustomerInfo hideOnMobile onClick={() => onSortChange("shippingAddress")}>
          <HoM>Customer</HoM>
          <SortButton>
            <i className={`fas fa-${getSortType("shippingAddress", currentSort)}`} />
          </SortButton>
        </CustomerInfo>
        <AmountWithDiscount onClick={() => onSortChange("orderAmountWithDiscount")}>
          <span>Order value</span>
          <SortButton>
            <i className={`fas fa-${getSortType("orderAmountWithDiscount", currentSort)}`} />
          </SortButton>
        </AmountWithDiscount>
        <PaymentMethod hideOnMobile onClick={() => onSortChange("paymentMethod")}>
          <span>Payment method </span>
          <SortButton>
            <i className={`fas fa-${getSortType("paymentMethod", currentSort)}`} />
          </SortButton>
        </PaymentMethod>
        <Country hideOnMobile onClick={() => onSortChange("country")}>
          <HoM>Country</HoM>
          <SortButton>
            <i className={`fas fa-${getSortType("country", currentSort)}`} />
          </SortButton>
        </Country>
        <Date onClick={() => onSortChange("created")}>
          <span>Created </span>
          <SortButton>
            <i className={`fas fa-${getSortType("created", currentSort)}`} />
          </SortButton>
        </Date>
          <FollowUp
            onClick={() => onSortChange("reference")}>
            <span>
              <HoM>Successful</HoM> #
            </span>
          </FollowUp>
      </TableHeader>
      <Orders>
        {loading && <Loader />}
        {orders && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={Math.min(ordersTotalHits, MAX_RESULT_WINDOW)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {[...orders]
              .map((o) => ({
                ...o,
                country: o.shippingAddress ? o.shippingAddress.country : "",
                paymentMethod: o.paymentMethod || "",
              }))
              .sort(sortTypes[currentSort].fn)
              .map((order) => (
                <React.Fragment key={order.id}>
                    <OrderFollowUpRow
                      key={order.id}
                      order={order}
                      tabs={tabs}
                      setTabs={setTabs}
                      setActiveTab={setActiveTab}
                    />
                </React.Fragment>
              ))}
          </Pagination>
        )}
      </Orders>
    </TableWrapper>
  );
}
