import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.6rem 1rem 1.4rem;
  min-height: 8rem;
  transition: all 0.2s;
  cursor: pointer;
  box-shadow: none;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  position: relative;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 0.2rem 1.6rem 0 rgb(0 0 0 / 8%);
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 0.2rem 2rem 0;
    min-height: 10rem;
  }
`;

export default ({ children, ...props }) => <Row {...props}> {children}</Row>;
