import React from "react";
import FilterProducts from "../../../../../views/Discount/DiscountCode/FilterProducts";
import FilterRules from "../../../../../views/Discount/DiscountCode/FilterRules";

const FilterOptions = ({
  filterRules,
  history,
  setFilterRules,
  control,
  OptionsBox,
  Row,
  minItemsInput,
  discountInPercentageInput,
  combineToggle
}) => {
  return (
    <>
      <OptionsBox hideHeader>
        <Row >
          {discountInPercentageInput}
          {minItemsInput}
          {combineToggle}
          </Row>
      </OptionsBox>
      <OptionsBox
        preHeading="Filter"
        heading="Add rules for filter attributes"
        subHeading="Choose to include/exclude filter attributes, preview included products below"
        headingIcon="tag">
        <FilterRules filterRules={filterRules} setFilterRules={setFilterRules} control={control} />
      </OptionsBox>
      <FilterProducts padding="1rem 0 0 0" history={history} filterRules={filterRules} />
    </>
  );
};

export default FilterOptions;
