import React, { useState } from "react";
import { Query } from "@apollo/client/react/components";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import esb from "elastic-builder";
import styled from "styled-components/macro";
import SEARCH_DISCOUNT_CODE from "graphql/Discount/SearchDiscounts";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import VoucherExport from "./VoucherExport";
import Sidebar from "components/Discount/Sidebar";
import VoucherGroupTable from "./VoucherGroupTable";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import DiscountValue from "components/Discount/DiscountValue";
import ActionButtons from "components/ActionButtons/ActionButtons";
import DeleteButton from "components/ActionButtons/DeleteButton";
import DeleteVoucherGroup from "components/Discount/DeleteVoucherGroup";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import getEditFreeShipping from "helpers/getEditFreeShipping";
import SearchVouchers from "./SearchVouchers";
import InputNew from "../../../components/Ui/InputNew";
import ActionButton from "../../../components/ActionButtons/ActionButton";
import { Controller, useForm } from "react-hook-form";
import UpdateVoucherGroup from "../../../components/Discount/UpdateVoucherGroup";

const DeleteVoucherButton = styled(DeleteButton)`
  p {
    display: none;
  }

  i {
    font-size: 1.5rem;
    margin: 0;
  }

  ${MEDIA_MIN_LARGE} {
    right: 0;

    p {
      margin: 0;
      display: block;
    }

    i {
      margin: 0.2rem 0.5rem 0 0;
    }
  }
`;

const FreeShippingValue = styled(Value)`
  .fa-check-circle {
    font-size: 2rem;
    color: green;
  }

  .fa-times-circle {
    font-size: 2rem;
    color: red;
  }
`;

const ValidDateRange = styled(Attribute)`
  justify-content: flex-start;

  &:last-child {
    margin-left: 1rem;
  }

  > div {
    width: calc(50% - 0.5rem);

    label {
      font-size: 1.3rem;
      line-height: 1.4rem;
      font-weight: 700;
    }
  }
`;

const VoucherLabel = styled(Label)`
  min-width: 25rem;
`;

const VOUCHERS_PER_PAGE = 25;

const VoucherGroup = (props) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const id = queryString.parse(props.location.search).groupName;
  const [firstVoucher, setFirstVoucher] = useState();
  const [updateData, setUpdateData] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setUpdateOpen(true);
    setUpdateData(data);
  };

  const esbBoolQuery = esb
    .boolQuery()
    .must([
      esb.termsQuery("group.keyword", id),
      esb.queryStringQuery("!(archived:1) AND (type:VOUCHER)"),
    ]);

  const voucherGroupQuery = esb
    .requestBodySearch()
    .query(esbBoolQuery)
    .from(0)
    .size(VOUCHERS_PER_PAGE);

  const [esbQuery, setEsbQuery] = useState(voucherGroupQuery);

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/vouchers", "Vouchers"],
          [`admin/voucher?groupname=${encodeURIComponent(id)}`, `${id}`],
        ]}
      />
      <Header heading="Vouchers" />
      <PageContainer>
        <Sidebar />
        <GridContainer expanded>
          <GridItem columns="12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                preHeading="Voucher Group"
                heading={id}
                showGoBackButton
                goBackOnClick={() => props.history.push("/admin/vouchers")}>
                <ActionButtons inBox>
                  <DeleteVoucherButton handleOnClick={() => setDeleteOpen(true)}>
                    <p>Delete</p>
                  </DeleteVoucherButton>
                  {deleteOpen && (
                    <DeleteVoucherGroup
                      id={id}
                      history={props.history}
                      setDeleteOpen={setDeleteOpen}
                    />
                  )}
                  <ActionButton type="submit">
                    <i className="fal fa-fw fa-check" />
                    Save
                  </ActionButton>
                  {updateOpen && (
                    <UpdateVoucherGroup
                      id={id}
                      updateData={updateData}
                      setUpdateOpen={setUpdateOpen}
                    />
                  )}
                </ActionButtons>
                <SearchVouchers setEsbQuery={setEsbQuery} group={id} />
                <GridItem columns="12">
                  <Query
                    query={SEARCH_DISCOUNT_CODE}
                    fetchPolicy="network-only"
                    variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
                    onCompleted={(data) => {
                      if (!firstVoucher) setFirstVoucher(data?.searchDiscounts?.discounts[0]);
                    }}>
                    {({ loading, error, data, fetchMore }) => {
                      if (loading) return <Loader />;
                      if (error)
                        return (
                          <ErrorMessage>
                            An error occurred when loading data, please contact support
                          </ErrorMessage>
                        );
                      if (!firstVoucher) return null;
                      return (
                        <>
                          <Attributes>
                            {firstVoucher?.startDate && firstVoucher?.endDate && (
                              <ValidDateRange>
                                <Controller
                                  name="startDate"
                                  render={({ field }) => (
                                    <InputNew
                                      {...field}
                                      type="date"
                                      label="Valid from:"
                                      errors={errors}
                                    />
                                  )}
                                  rules={{
                                    required: "This is a required field",
                                  }}
                                  defaultValue={firstVoucher?.startDate}
                                  control={control}
                                />
                                <Controller
                                  name="endDate"
                                  render={({ field }) => (
                                    <InputNew
                                      {...field}
                                      type="date"
                                      label="Valid to:"
                                      errors={errors}
                                    />
                                  )}
                                  rules={{
                                    required: "This is a required field",
                                  }}
                                  defaultValue={firstVoucher?.endDate}
                                  control={control}
                                />
                              </ValidDateRange>
                            )}
                            <Attribute>
                              <VoucherLabel>Discount: </VoucherLabel>
                              <Value>
                                <DiscountValue discount={firstVoucher} simple />
                              </Value>
                            </Attribute>
                            {getEditFreeShipping() && (
                              <Attribute>
                                <VoucherLabel>Free Shipping: </VoucherLabel>
                                <FreeShippingValue>
                                  {firstVoucher.freeShipping ? (
                                    <i className="fal fa-check-circle"></i>
                                  ) : (
                                    <i className="fal fa-times-circle"></i>
                                  )}
                                </FreeShippingValue>
                              </Attribute>
                            )}
                            {!firstVoucher.discountInFixedPrice && (
                              <Attribute>
                                <VoucherLabel>Combine with item discounts: </VoucherLabel>
                                <FreeShippingValue>
                                  {firstVoucher.combine ? (
                                    <i className="fal fa-check-circle"></i>
                                  ) : (
                                    <i className="fal fa-times-circle"></i>
                                  )}
                                </FreeShippingValue>
                              </Attribute>
                            )}
                            {firstVoucher?.createdBy && (
                              <Attribute>
                                <VoucherLabel>Created by: </VoucherLabel>
                                <Value>{firstVoucher.createdBy}</Value>
                              </Attribute>
                            )}
                          </Attributes>

                          <VoucherGroupTable
                            data={data}
                            loading={loading}
                            discountsPerPage={VOUCHERS_PER_PAGE}
                            history={props.history}
                            fetchMore={fetchMore}
                            esbQuery={esbQuery}>
                            {({ voucherCodesTotalHits }) => (
                              <VoucherExport
                                group={id}
                                query={SEARCH_DISCOUNT_CODE}
                                esbQuery={esbBoolQuery}
                                size={voucherCodesTotalHits}
                              />
                            )}
                          </VoucherGroupTable>
                        </>
                      );
                    }}
                  </Query>
                </GridItem>
              </Box>
            </form>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default withRouter(VoucherGroup);
