export const TINY = 320;
export const SMALL = 560;
export const MEDIUM = 960;
export const LARGE = 1400;
export const XLARGE = 1680;
export const MEDIA_TINY = `@media (max-width: ${TINY}px)`;
export const MEDIA_MAX_SMALL = `@media (max-width: ${SMALL}px)`;
export const MEDIA_SMALL = `@media (min-width: ${TINY + 1}px) and (max-width: ${SMALL}px)`;
export const MEDIA_MIN_MEDIUM = `@media (min-width: ${SMALL + 1}px)`;
export const MEDIA_MAX_MEDIUM = `@media (max-width: ${MEDIUM}px)`;
export const MEDIA_MEDIUM = `@media (min-width: ${SMALL + 1}px) and (max-width: ${MEDIUM}px)`;
export const MEDIA_MIN_LARGE = `@media (min-width: ${MEDIUM + 1}px)`;
export const MEDIA_MIN_X_LARGE = `@media (min-width: ${LARGE + 1}px)`;
export const MEDIA_MIN_XX_LARGE = `@media (min-width: ${XLARGE + 1}px)`;
