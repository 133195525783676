import gql from "graphql-tag";

const ADD_SHIPPING_PROVIDER = gql`
  mutation addProduct(
    $id: ID!
    $name: String!
    $type: ProductType!
    $category: String!
  ) {
    addProduct(
      products: {
        id: $id
        name: $name
        type: $type
        category: $category
      }
    ) {
      id
      name
      type
    }
  }
`;

export default ADD_SHIPPING_PROVIDER;