import Dinero from "dinero.js";
import axios from "axios";

Dinero.globalExchangeRatesApi = {
  propertyPath: "data.rates.{{to}}",
};

const mergeDinero = async (dinero1Promise, dinero2Promise, currencyUnit, rates) => {
  const dinero1 = await dinero1Promise;
  const dinero2 = await dinero2Promise;
  const convertedDinero1 =
    dinero1.getCurrency() !== currencyUnit
      ? await dinero1.convert(currencyUnit, {
          endpoint: new Promise((resolve) => resolve(rates[dinero1.getCurrency()])),
        })
      : dinero1;
  const convertedDinero2 =
    dinero2.getCurrency() !== currencyUnit
      ? await dinero2.convert(currencyUnit, {
          endpoint: new Promise((resolve) => resolve(rates[dinero2.getCurrency()])),
        })
      : dinero2;
  return convertedDinero1.add(convertedDinero2);
};

export const mergeDineros = async (dineros, currencyUnit, rates) =>
  dineros.reduce(
    (a, b) => mergeDinero(a, b, currencyUnit, rates),
    Dinero({ amount: 0, currency: currencyUnit })
  );

export const getRates = (currencyUnit) =>
  axios
    .get(`https://api.coinbase.com/v2/exchange-rates?currency=${currencyUnit}`)
    .then((response) => ({ [currencyUnit]: response.data }));

export default { mergeDineros, getRates };
