import React from "react";
import GridItem from "components/Grid/GridItem";
import FlagIcon from "components/Ui/FlagIcon";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import getFullCountryName from "helpers/getFullCountryName";

export default ({ address, hideEmail, hidePhone }) => {
  return (
    <>
      <GridItem padding="0" columns="12">
        <Attributes>
          <Attribute>
            <Label>Name:</Label>
            <Value>
              {address.givenName} {address.familyName}
            </Value>
          </Attribute>
          {address.email && !hideEmail && (
            <Attribute>
              <Label>Email:</Label>
              <Value>{address.email}</Value>
            </Attribute>
          )}
          <Attribute>
            <Label>Street address:</Label>
            <Value>{address.streetAddress}</Value>
          </Attribute>
          {address.houseNumberOrName && (
            <Attribute>
              <Label>House number or name:</Label>
              <Value>{address.houseNumberOrName}</Value>
            </Attribute>
          )}
          {address.postalCode && (
            <Attribute>
              <Label>Postal code:</Label>
              <Value>{address.postalCode}</Value>
            </Attribute>
          )}
          {address.region && (
            <Attribute>
              <Label>Region/state/province code:</Label>
              <Value>{address.region}</Value>
            </Attribute>
          )}
          <Attribute>
            <Label>City:</Label>
            <Value>{address.city}</Value>
          </Attribute>
          <Attribute>
            <Label>Country:</Label>
            <Value>
              <FlagIcon countryCode={address.country} />
              {getFullCountryName(address.country)}
            </Value>
          </Attribute>
          {!hidePhone && (
            <Attribute>
              <Label>Phone number:</Label>
              <Value>{address.phone}</Value>
            </Attribute>
          )}
        </Attributes>
      </GridItem>
    </>
  );
};
