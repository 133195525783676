import React from "react";
import StoreGroupedSelector from "./StoreContinentSelector";
import StoreSingleSelector from "./StoreSingleSelector";

export default ({ selectedStores, setSelectedStores, name, label, errors, showContinents, setValue, ...props }) => {
  return (
    <>
      {showContinents ? (
        <StoreGroupedSelector
          selectedStores={selectedStores}
          setSelectedStores={setSelectedStores}
          label={label}
          name={name}
          errors={errors}
          setValue={setValue}
          {...props}
        />
      ) : (
        <StoreSingleSelector
          selectedStores={selectedStores}
          setSelectedStores={setSelectedStores}
          label={label}
          name={name}
          errors={errors}
          setValue={setValue}
          {...props}
        />
      )}
    </>
  );
};
