import gql from "graphql-tag";

const UPDATE_ORDER_REFUND_ACTIVE = gql`
  mutation updateOrderStatus($id: ID!, $refundActive: Boolean) {
    updateOrder(
      updateOrder: {
        id: $id
        refundActive: $refundActive
      }
    ) {
      id
      refundActive
      status
    }
  }
`;

export default UPDATE_ORDER_REFUND_ACTIVE