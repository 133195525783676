import React from "react";

const ProductOptions = ({ Row, discountInPercentageInput, minItemsInput, combineToggle }) => {
  return (
    <>
      <Row>
        {discountInPercentageInput}
        {minItemsInput}
      </Row>
      <Row>{combineToggle}</Row>
    </>
  );
};

export default ProductOptions;
