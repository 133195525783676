import gql from "graphql-tag";

const GET_STOCK_INVENTORY = gql`
  query getStockInventory($productId: ID!) {
    getStockInventory(productId: $productId) {
      id
      inventories {
        inventory {
          id
          name
        }
        stockQuantity
        reservedQuantity
        availableQuantity
        isAvailable
      }
    }
  }
`;

export default GET_STOCK_INVENTORY;
