import gql from "graphql-tag";

const UPDATE_ORDER_EMAIL = gql`
  mutation updateOrder($id: ID!, $email: String!, $billingAddress: InputAddress!) {
    updateOrder(updateOrder: { id: $id, email: $email, billingAddress: $billingAddress }) {
      id
      status
    }
  }
`;

export default UPDATE_ORDER_EMAIL;
