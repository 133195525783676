import gql from "graphql-tag";

const UPDATE_VOUCHER = gql`
    mutation updateDiscount(
        $id: ID!
        $startDate: AWSDate!
        $endDate: AWSDate!
    ) {
        updateDiscount(
            updateDiscount: {
                id: $id
                startDate: $startDate
                endDate: $endDate
            }
        ) {
            id
            code
            startDate
            endDate
        }
    }
`;

export default UPDATE_VOUCHER;
