import React from "react";
import styled from "styled-components";
import InputNew from "components/Ui/InputNew";
import moment from "moment/min/moment-with-locales";
import { Controller } from "react-hook-form";
import Tooltip from "components/Ui/Tooltip";
import TextArea from "components/Ui/TextArea";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import getEditFreeShipping from "helpers/getEditFreeShipping";
import Toggle from "components/Ui/Toggle";
import StoreSelector from "components/StoreSelector/StoreSelector";

const Container = styled.div`
  margin: 2rem 1rem 1rem 1rem;
  width: 100%;
`;

const GeneralInput = styled(InputNew)`
  width: calc(100% - 1rem);
`;

const GeneralController = styled(Controller)`
  width: calc(100% - 1rem);
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${MEDIA_MIN_LARGE} {
    flex-direction: row;
  }
`;

const FreeShipping = styled.span`
  padding-top: 1rem;
  padding-bottom: 3rem;
`;

const ShippingToggle = styled(Toggle)`
  margin-top: 0.5rem;
  height: 3.5rem;
  width: 6rem;
  border-radius: 10rem;
  div {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
  }
`;

const GeneralOptions = ({
  register,
  discountToCreate,
  errors,
  updateDiscountToCreate,
  setValue,
  control,
  selectedStores,
  updateSelectedStores,
}) => {
  return (
    <Container>
      <Tooltip />
      <Row>
        <GeneralInput
          {...register("code", {
            required: "This is a required field",
            pattern: {
              value: /^[A-Za-z0-9_.]+$/,
              message: "Only characters Aa-Zz and 0-9 are allowed",
            },
          })}
          onChange={(e) => {
            updateDiscountToCreate({ code: e.target.value });
            setValue("code", e.target.value);
          }}
          type="text"
          placeholder="Aa - Zz, 0 - 9"
          label="Discount code *"
          icon="ticket"
          value={discountToCreate.code || ""}
          errors={errors}
        />
        <GeneralController
          control={control}
          name="stores"
          rules={{
            required: "This is a required field",
            validate: () => {
              return selectedStores?.length > 0
            }
          }}
          render={() => (
            <StoreSelector
              selectedStores={selectedStores}
              name="stores"
              setSelectedStores={updateSelectedStores}
              label="Stores *"
              showContinents
              setValue={setValue}
              errors={errors}
            />
          )}
        />
        <InputNew
          {...register("usageLimit", {
            min: {
              value: 1,
              message: "Needs to be a positive number",
            },
          })}
          onChange={(e) => {
            updateDiscountToCreate({
              usageLimit: e.target.value,
            });
            setValue("usageLimit", e.target.value);
          }}
          errors={errors}
          value={discountToCreate.usageLimit || ""}
          type="number"
          label="Usage limit"
          placeholder="Unlimited"
        />
      </Row>
      <Row>
        <TextArea
          {...register("description")}
          onChange={(e) => {
            updateDiscountToCreate({ description: e.target.value });
            setValue("description", e.target.value);
          }}
          label="Description"
          errors={errors}
          placeholder="Description of the discount"
          value={discountToCreate.description || ""}
        />
      </Row>
      {getEditFreeShipping() && (
        <Row>
          <FreeShipping>
            <ShippingToggle
              label="Free shipping"
              data-tip="If this option is enabled, free shipping will be applied on all shipping methods"
              id="freeShipping"
              handleToggle={() =>
                updateDiscountToCreate({ freeShipping: !discountToCreate.freeShipping })
              }
              active={discountToCreate.freeShipping}
            />
          </FreeShipping>
        </Row>
      )}
      <Row>
        <GeneralInput
          {...register("startDate", {
            required: "This is a required field",
            validate: (value) => moment(value, "YYYY-MM-DD", true).isValid(),
          })}
          onChange={(e) => {
            updateDiscountToCreate({ startDate: e.target.value });
            setValue("startDate", e.target.value);
          }}
          type="date"
          label="Valid from *"
          icon="calendar-alt"
          errors={errors}
          value={discountToCreate.startDate || ""}
        />
        <GeneralInput
          {...register("endDate", {
            required: "This is a required field",
            validate: (value) => moment(value, "YYYY-MM-DD", true).isValid(),
          })}
          onChange={(e) => {
            updateDiscountToCreate({ endDate: e.target.value });
            setValue("endDate", e.target.value);
          }}
          type="date"
          label="Valid to *"
          errors={errors}
          value={discountToCreate.endDate || ""}
          icon="calendar-alt"
        />
      </Row>
    </Container>
  );
};

export default GeneralOptions;
