import React from "react";
import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(p) => p.color || "white"};
  cursor: pointer;
  position: relative;

  &:focus,
  &:visited {
    color: ${(p) => p.color || "white"};
  }

  ${(p) =>
    p.underline === "true" &&
    css`
      &:after {
        content: "";
        position: absolute;
        height: 0.1rem;
        left: 0;
        right: 0;
        bottom: -0.2rem;
        background-color: ${(p) => (p.inverted ? "transparent" : p.color)};
        transition: background-color 0.2s;
      }

      &:hover {
        &:after {
          background-color: ${(p) => (p.inverted ? p.color : "transparent")};
        }
      }
    `}
`;

export default ({ children, to, underline, inverted, color, ...other }) => (
  <StyledLink to={to} underline={underline} inverted={inverted} color={color} {...other}>
    {children}
  </StyledLink>
);
