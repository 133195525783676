import React, { useState } from "react";
import Moment from "react-moment";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";

import GET_PRODUCT from "graphql/Product/GetProduct";

import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ProductVariants from "./ProductVariants";
import Sidebar from "components/Product/Sidebar";
import Placeholder from "components/Ui/PlaceholderImage";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import CompatibleAddons from "components/Addons/CompatibleAddons";

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;

  img {
    max-width: 100%;
  }
`;

const StyledAttributes = styled(Attributes)`
  margin-top: 2rem;

  li {
    &:first-child {
      border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    }
  }
`;

const CustomerAttribute = styled(Attribute)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const NoFlex = styled.span`
  display: block !important;
`;
const ObjectValue = styled.div`
  margin-right: 1rem;
  padding-right: 1rem;
  border-right: 0.1rem solid ${(p) => p.theme.colors.checkboxBorder};
  overflow: wrap;
`;

const ObjectLabel = styled.span`
  font-weight: 700;
`;

const AttributeHeader = styled.div`
  border-bottom: 0.2rem solid
    ${(p) => (p.expanded ? p.theme.colors.primary : p.theme.colors.backgroundOpac)};
  padding: 2rem 0;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  i {
    font-size: 1.3rem;
  }
  &:hover {
    cursor: pointer;
    border-bottom: 0.2rem solid ${(p) => p.theme.colors.primary};
  }
`;

const isAddonsEnabled = () => /true/i.test(process.env.REACT_APP_ADDONS_ENABLED || "false");

const Product = (id, variantId) => {
  const [productName, setProductName] = useState("");
  const [expandedCustomerAttributes, setExpandedCustomerAttributes] = useState(false);

  const handleCustomerAttribute = (attribute) => {
    if (Array.isArray(attribute)) return attribute.map((a) => handleCustomerAttribute(a));
    if (typeof attribute === "object")
      return Object.keys(attribute).map(
        (key) =>
          attribute[key] !== null && (
            <ObjectValue key={key}>
              <ObjectLabel>{toLabel(key)}:</ObjectLabel>
              {attribute[key]}
            </ObjectValue>
          )
      );
    return <ObjectValue key={attribute}>{attribute.toString()}</ObjectValue>;
  };

  const toLabel = (label) =>
    label
      ? label.length === 2
        ? label.toUpperCase()
        : label.charAt(0).toUpperCase() +
          label
            .slice(1)
            .replace(/([a-z](?=[A-Z]))/g, "$1 ")
            .toLowerCase()
      : "";

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/products", "Products"],
          [`admin/product/${id}`, `${productName}`],
        ]}
      />
      <Header heading="Products"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer expanded>
          <Query
            query={GET_PRODUCT}
            variables={{ id, includeInventory: false }}
            onCompleted={(data) => setProductName(data.getProduct.name)}>
            {({ loading, error, data, subscribeToMore }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );

              const product = data.getProduct;

              const customerAttribute = product.customerAttribute
                ? JSON.parse(product.customerAttribute)
                : undefined;

              return (
                <>
                  {product && (
                    <>
                      <GridItem columns="12" padding="0">
                        <GridContainer padding="0">
                          <GridItem columns="5">
                            <Box
                              preHeading="Product"
                              heading={
                                <NoFlex>
                                  {product.name}
                                  {product.active ? (
                                    <i className="fal fa-check-circle"></i>
                                  ) : (
                                    <i className="fal fa-times-circle"></i>
                                  )}
                                </NoFlex>
                              }
                              subHeading={product.id}>
                              <ImageContainer>
                                {product.imageUrl ? (
                                  <img alt={product.name} src={product.imageUrl} />
                                ) : (
                                  <Placeholder />
                                )}
                              </ImageContainer>

                              <StyledAttributes>
                                <Attribute>
                                  <Label>URL:</Label>
                                  <Value>/{product.slug}</Value>
                                </Attribute>
                                <Attribute>
                                  <Label>Category:</Label>
                                  <Value>{product.category}</Value>
                                </Attribute>
                                <Attribute>
                                  <Label>Created:</Label>
                                  <Value>
                                    <Moment format="YYYY-MM-DD, HH:mm">{product.created}</Moment>
                                  </Value>
                                </Attribute>
                                <Attribute>
                                  <Label>Last updated:</Label>
                                  <Value>
                                    <Moment format="YYYY-MM-DD, HH:mm">
                                      {product.lastUpdated}
                                    </Moment>
                                  </Value>
                                </Attribute>
                                {customerAttribute && Object.keys(customerAttribute).length > 0 && (
                                  <>
                                    <AttributeHeader
                                      expanded={expandedCustomerAttributes}
                                      onClick={() =>
                                        setExpandedCustomerAttributes(!expandedCustomerAttributes)
                                      }>
                                      Customer Attributes
                                      <i
                                        className={`fal fa-chevron-${
                                          expandedCustomerAttributes ? "up" : "down"
                                        }`}></i>
                                    </AttributeHeader>
                                    {expandedCustomerAttributes &&
                                      Object.keys(customerAttribute).map(
                                        (attribute) =>
                                          customerAttribute[attribute] !== null && (
                                            <CustomerAttribute key={attribute}>
                                              <Label>{toLabel(attribute)}:</Label>
                                              <Value>
                                                {handleCustomerAttribute(
                                                  customerAttribute[attribute]
                                                )}
                                              </Value>
                                            </CustomerAttribute>
                                          )
                                      )}
                                  </>
                                )}
                              </StyledAttributes>
                              {isAddonsEnabled() && (
                                <CompatibleAddons addons={product.compatibleAddons} />
                              )}
                            </Box>
                          </GridItem>
                          <GridItem columns="7">
                            <ProductVariants
                              variants={product.relatedVariants.filter((p) => !p.archived)}
                              subscribeToMore={subscribeToMore}
                              expandedVariantId={variantId}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </>
                  )}
                </>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default (props) => {
  return Product(props.match.params.id.replaceAll("~", "/"), props.history.location.state?.data);
};
