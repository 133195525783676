import gql from "graphql-tag";

const ADD_DISCOUNT_CODE = gql`
    mutation addDiscount(
        $code: String!
        $discountInPercentage: Int
        $discountInFixedPrice: [InputMoney]
        $usageLimit: Int
        $stores: [InputDiscountStore]
        $type: DiscountType!
        $description: String
        $startDate: AWSDate!
        $endDate: AWSDate!
        $gifts: [String]
        $validForProducts: [String]
        $notForCategories: [String]
        $categories: [String]
        $notForProducts: [String]
        $notForProductVariants: [String]
        $group: String
        $minItems: Int
        $maxItems: Int
        $recurrent: Boolean
        $combine: Boolean
        $freeShipping: Boolean
        $filterRules: [InputFilterRule!]
        $discountRules: [InputFilterRule!]
        $currencyOptions: [InputCurrencyOption!]
    ) {
        addDiscount(
            discount: {
                code: $code
                discountInPercentage: $discountInPercentage
                discountInFixedPrice: $discountInFixedPrice
                usageLimit: $usageLimit
                stores: $stores
                type: $type
                description: $description
                startDate: $startDate
                endDate: $endDate
                gifts: $gifts
                validForProducts: $validForProducts
                notForCategories: $notForCategories
                categories: $categories
                notForProducts: $notForProducts
                notForProductVariants: $notForProductVariants
                group: $group
                minItems: $minItems
                maxItems: $maxItems
                combine: $combine
                recurrent: $recurrent
                freeShipping: $freeShipping
                filterRules: $filterRules
                discountRules: $discountRules
                currencyOptions: $currencyOptions
            }
        ) {
            id
            code
            startDate
            endDate
            type
            description
            discountInPercentage
            discountInFixedPrice {
                amount
                currencyUnit
            }
            usageLimit
            created
            lastUpdated
            notForCategories
            categories
            minItems
            maxItems
            recurrent
            gifts {
                id
            }
            combine
            freeShipping
            filterRules {
                mode
                key
                values
            }
            discountRules {
                mode
                key
                values
            }
            currencyOptions {
                atLeastTotalPriceWithDiscount
                currencyUnit
            }
        }
    }
`;

export default ADD_DISCOUNT_CODE;
