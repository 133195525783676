import React from "react";
import styled from "styled-components/macro";

const Value = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  position: relative;

  a {
    color: ${(p) => p.theme.colors.primary};
  }

  img {
    margin-right: 1rem;
  }
`;

export default ({ children, ...props }) => {
  return <Value {...props}>{children}</Value>;
};
