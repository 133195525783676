import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const Container = styled.div`
  position: relative;
  padding: 1.5rem 1.5rem 0;
  width: 100%;
  color: ${(p) => p.theme.colors.secondaryText};
  border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  margin-top: 1rem;

  span {
    &:last-child {
      font-weight: 700;
      letter-spacing: 0;
    }
  }

  ${MEDIA_MIN_MEDIUM} {
    border-top: none;
    padding: 2.5rem 2rem 0 2.8rem;
    margin-top: 0;
  }
`;

const Link = styled(NavLink)`
  text-decoration: none;
  color: ${(p) => p.theme.colors.secondaryText};
  cursor: pointer;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  transition: opacity 0.2s;
  display: inline-block;

  &:first-letter {
    text-transform: capitalize;
  }

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.3rem;
  }

  &:focus,
  &:visited {
    color: ${(p) => p.theme.colors.secondaryText};
  }

  &:hover {
    opacity: 0.6;
  }
`;

const Separator = styled.span`
  padding: 0 1rem;
`;

export default ({ slugs, ...props }) => (
  <Container {...props}>
    <span>
      <Link to="/">
        <i className="fal fa-home"></i>
      </Link>
      <Separator>/</Separator>
    </span>
    {slugs.map((slug, index) => {
      return (
        <span key={slug}>
          {slugs.length - 1 !== index ? (
            <Link to={`/${slug[0]}`}>{slug[1]}</Link>
          ) : (
            <span>{slug[1]}</span>
          )}

          {slugs.length - 1 !== index && <Separator>/</Separator>}
        </span>
      );
    })}
  </Container>
);
