import React, { useContext, useState } from "react";

export const NotificationContext = React.createContext({});
export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({});

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification,
      }}>
      {children}
    </NotificationContext.Provider>
  );
};
