import React from "react";
import SecondarySidebar from "components/Sidebar/SecondarySidebar";

export default () => (
  <SecondarySidebar
    sections={[
      {
        title: "Overview",
        layout: "/admin",
        routes: [{ name: "Overview", path: "/discounts" }],
      },
      {
        title: "Discount codes",
        layout: "/admin",
        routes: [
          { name: "+ Add", path: "/add-discount-code" },
          { name: "Show all", path: "/discount-codes" },
        ],
      },
      {
        title: "Discount rules",
        layout: "/admin",
        routes: [
          { name: "+ Add", path: "/add-discount-rule" },
          { name: "Show all", path: "/discount-rules" },
        ],
      },
      {
        title: "Vouchers",
        layout: "/admin",
        routes: [
          { name: "+ Add", path: "/add-voucher-group" },
          { name: "Show all", path: "/vouchers" },
        ],
      },
    ]}
  />
);
