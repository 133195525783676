import gql from "graphql-tag";

const UPDATE_STOCK = gql`
  mutation updateStock($productId: ID!, $stock: Int!) {
    updateStock(stock: { productId: $productId, stock: $stock }) {
      productId
      stock
    }
  }
`;

export default UPDATE_STOCK;
