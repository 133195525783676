import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const ToggleButton = styled.div`
  margin-left: 1rem;
  cursor: pointer;
  position: absolute;
  top: -2rem;
  right: 0;

  i {
    margin-left: 0.5rem;
    font-size: 4rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    text-align: center;
    padding-top: 0.1rem;
    transition: all 0.2s;
    border: 0.1rem solid transparent;
  }

  ${MEDIA_MIN_LARGE} {
    position: relative;
    top: 0;

    &:hover {
      i {
        border: 0.1rem solid ${(p) => p.theme.colors.opac1};
      }
    }
  }
`;

export default ({ showFilterForm, ...props }) => (
  <ToggleButton {...props}>
    <i className="fal fa-ellipsis-h"></i>
  </ToggleButton>
);
