import React, { useEffect, useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import esb from "elastic-builder";

import { useNotification } from "context/NotificationContext";
import Loader from "components/Ui/Loader";
import ProgressLoader from "components/Ui/ProgressLoader";
import Dialog from "components/Dialog/Dialog";
import UPDATE_VOUCHER from "../../graphql/Discount/Vouchers/UpdateVoucher";
import Label from "../AttributeList/Label";
import { useQuery } from "@apollo/client";
import SEARCH_DISCOUNT_CODES_ID from "../../graphql/Discount/DiscountCode/SearchDiscountCodesId";

export default ({ id, setUpdateOpen, updateData }) => {
  const { setNotification } = useNotification();
  const [voucherCodes, setVoucherCodes] = useState();
  const [voucherGroupLoading, setVoucherGroupLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const esbBoolQuery = esb
    .boolQuery()
    .must([
      esb.termsQuery("group.keyword", id),
      esb.queryStringQuery("!(archived:1) AND (type:VOUCHER)"),
    ]);

  const voucherGroupUpdated = () => {
    setVoucherGroupLoading(false);
    setNotification({
      status: "success",
      message: `Voucher group "${id}" successfully updated`,
    });
    setUpdateOpen(false);
  };

  const BATCH_SIZE = 500;

  const voucherGroupQuery = esb.requestBodySearch().query(esbBoolQuery).size(BATCH_SIZE);
  const { data, fetchMore, loading, error } = useQuery(SEARCH_DISCOUNT_CODES_ID, {
    variables: { query: JSON.stringify(voucherGroupQuery.toJSON()) },
  });

  useEffect(() => {
    const fetchAllData = async () => {
      if (loading) return;
      if (error) {
        console.error(error);
        return;
      }
      const size = data.searchDiscounts.totalHits;

      let discountCodes = [...data.searchDiscounts.discounts];
      const loops = Math.trunc(size / BATCH_SIZE);
      for (let i = 0; i < loops; i++) {
        const from = (i + 1) * BATCH_SIZE;
        const nextBatch = await fetchMore({
          variables: {
            query: JSON.stringify(voucherGroupQuery.size(BATCH_SIZE).from(from).toJSON()),
          },
        });
        discountCodes = discountCodes.concat([...nextBatch.data.searchDiscounts.discounts]);
      }
      setVoucherCodes(discountCodes);
    };

    data && fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data]);

  if (error) {
    setNotification({
      status: "error",
      message: `Discount rule "${id}" could not be updated, please contact support`,
    });
    setUpdateOpen(false);
  }
  if (loading) return <Loader color="black" />;
  if (!voucherCodes) return <Loader color="black" />;

  return (
    <Mutation
      mutation={UPDATE_VOUCHER}
      onError={() => {
        setNotification({
          status: "error",
          message: `Voucher group "${id}" could not be updated, please contact support`,
        });
      }}>
      {(updateDiscount) => {
        const updateVoucherGroup = async (voucherCodes) => {
          setVoucherGroupLoading(true);
          let req = [];
          for (let i = 0; i < voucherCodes.length; i++) {
            req.push(
              updateDiscount({
                variables: { ...voucherCodes[i], ...updateData },
              })
            );
            if ((i + 1) % 10 === 0) {
              await Promise.all(req);
              await new Promise((r) => setTimeout(r, 500));
              req = [];
              setPercentage(Math.round((i / voucherCodes.length) * 100));
            }
          }
          if (req.length > 0) {
            await Promise.all(req);
            setPercentage(100);
          }
          voucherGroupUpdated();
        };

        return (
          <>
            <Dialog
              header="Update voucher group?"
              handleClose={() => setUpdateOpen(false)}
              open={true}
              handleOk={() => updateVoucherGroup(voucherCodes)}>
              <div>
                <Label>New values:</Label> <br />{" "}
                <div>
                  {" "}
                  Valid from: {updateData.startDate} Valid to: {updateData.endDate}
                </div>{" "}
              </div>
            </Dialog>

            {voucherGroupLoading && <ProgressLoader text={percentage} />}
          </>
        );
      }}
    </Mutation>
  );
};
