import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const TotalItems = styled.div`
  border-right: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  padding: 0.45rem 1rem;
  text-align: center;
  white-space: pre-wrap;

  &:first-letter {
    text-transform: capitalize;
  }

  ${MEDIA_MIN_LARGE} {
    text-align: left;
    padding: 0.45rem 2rem;

    span {
      display: inline-block;
    }
  }
`;

export default ({ children, ...props }) => <TotalItems {...props}>{children}</TotalItems>;
