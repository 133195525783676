import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";
import SEARCH_ORDERS from "graphql/Order/SearchOrders";
import Loader from "components/Ui/Loader";
import HoM from "components/Ui/HoM";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Order from "./CustomerOrder";
import SearchCustomerOrders from "../../views/Customers/SearchCustomerOrders";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtons from "components/ActionButtons/ActionButtons";

const Container = styled(GridItem)`
  display: block;
  margin-top: 2rem;
`;

const CustomerOrderBox = styled(Box)`
  margin-top: 3rem;
`;

export default ({ personId, history }) => {
  const [esbQuery, setEsbQuery] = useState(
    esb.requestBodySearch().query(esb.termQuery("personId.keyword", personId))
  );
  const [orders, setOrders] = useState(null);

  const handleOrderClick = (id, reference) => {
    history.push(`/admin/orders/`, { id, reference });
  };

  return (
    <>
      <CustomerOrderBox preHeading="Customer" heading="Orders" headingIcon="shopping-cart">
        <SearchCustomerOrders allOrders={esbQuery} setEsbQuery={setEsbQuery} personId={personId} />
      </CustomerOrderBox>
      <Query
        query={SEARCH_ORDERS}
        variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
        onCompleted={({ searchOrders }) => {
          setOrders(searchOrders.orders);
        }}>
        {({ loading, error }) => {
          if (loading) return <Loader />;
          if (error)
            return (
              <ErrorMessage>
                An error occurred when loading data, please contact support
              </ErrorMessage>
            );
          return (
            <>
              {orders &&
                orders.map((order) => {
                  return (
                    <Container key={order.id} columns="12" padding="0">
                      <Box heading={`Order #${order.reference}`} subHeading={order.id}>
                        <ActionButtons inBox>
                          <ActionButton
                            iconOnlyMobile
                            handleOnClick={() => handleOrderClick(order.id, order.reference)}>
                            <i className="fal fa-info-circle"></i>
                            <HoM>Open order</HoM>
                          </ActionButton>
                        </ActionButtons>
                        <Order order={order} />
                      </Box>
                    </Container>
                  );
                })}
            </>
          );
        }}
      </Query>
    </>
  );
};
