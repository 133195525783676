import gql from "graphql-tag";

const GET_CONFIGURATION = gql`
  query getConfiguration($service: String!, $name: String) {
    getConfiguration(service: $service, name: $name) {
      service
      name
      data
    }
  }
`;

export default GET_CONFIGURATION;
