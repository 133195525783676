import gql from "graphql-tag";

const UPPDATE_ORDER_REFUND_FEE = gql`
  mutation updateOrderStatus($id: ID!, $refundFee: Boolean) {
    updateOrder(
      updateOrder: {
        id: $id
        refundFee: $refundFee
      }
    ) {
      id
      refundFee
      status
    }
  }
`;

export default UPPDATE_ORDER_REFUND_FEE