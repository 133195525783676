import React from "react";
import styled from "styled-components/macro";

const Container = styled.div``;

const Description = styled.div`
  border: ${(p) => `${p.theme.colors.primary} solid 0.2rem`};
  width: 100%;
  display: flex;
  border-radius: 0.5rem;
  margin: 0 1rem 2rem 1rem;
  flex-direction: column;

  p {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    padding: 2rem 3rem;
  }
`;

const IconWrapper = styled.div`
width: 4rem;
  position: relative;
  top: 2rem;
  left: -0.8rem;
  background-color: ${(p) => p.theme.colors.secondaryBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(p) => `${p.theme.colors.secondaryBackground} solid 0.4rem`};
  border-radius: 50%;
`;

const DescriptionIcon = styled.i`
  color: ${(p) => p.theme.colors.primary};
  font-size: 3rem;
  position: relative;
  top: 0.1rem;
  left: 0.05rem;
`;

export default ({ children, ...props }) => {
  return (
    <Container {...props}>
      <IconWrapper>
        <DescriptionIcon className="fa-solid fa-circle-info" />
      </IconWrapper>
      <Description>
        <p>{children}</p>
      </Description>
    </Container>
  );
};
