import Dinero from "dinero.js";

const currenciesWithoutDecimals = ["JPY", "KRW", "ISK"];

export const Currency = (currency) => (amount) => {
  return currenciesWithoutDecimals.includes(currency)
    ? Dinero({ currency, amount, precision: 0 })
    : Dinero({ currency, amount });
};

export const formatFromMinorUnits = (price, currency) => {
  return currenciesWithoutDecimals.includes(currency) ? Number(price) : Number(price / 100);
};

export const formatPrice = (price, currency) => {
  return currenciesWithoutDecimals.includes(currency)
    ? Number(price) | 0
    : Number(price * 100).toFixed(2) | 0;
};

export default { Currency, formatFromMinorUnits, formatPrice };
