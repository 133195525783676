import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import Vouchers from "components/Table/Rows";
import Voucher from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Ui/Loader";
import esb from "elastic-builder";
import { Query } from "@apollo/client/react/components";
import SEARCH_DISCOUNT_CODE from "graphql/Discount/SearchDiscounts";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import DiscountValue from "components/Discount/DiscountValue";
import moment from "moment/min/moment-with-locales";

const Name = styled(Column)`
  width: 30%;
  padding-left: 1rem;

  ${MEDIA_MIN_LARGE} {
    padding-left: 2rem;
  }
`;

const DiscountValueColumn = styled(Column)`
  width: 15%;
  justify-content: center;

  i {
    font-size: 1.8rem;
    margin-right: 0.8rem;

    ${MEDIA_MIN_LARGE} {
      font-size: 2.4rem;
    }
  }
`;

export default ({ data, loading, vouchersPerPage, history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPageInput, setGoToPageInput] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(vouchersPerPage);
  const firstUpdate = useRef(true);

  const toVouchers = (data) => {
    const vouchers =
      data &&
      JSON.parse(data.discountAggregates.aggregations)
        .groups.buckets.map((bucket) => ({
          name: bucket.key,
          count: bucket.doc_count,
          created: bucket.created.buckets[0].key_as_string,
        }))
        .sort((a, b) => Date.parse(b.created) - Date.parse(a.created));
    return vouchers;
  };

  const vouchers = toVouchers(data);
  const vouchersTotalHits = vouchers?.length;
  const [displayVouchers, setDisplayVouchers] = useState(vouchers?.slice(0, vouchersPerPage));

  const getVoucherGroupQuery = (id) => {
    return esb
      .requestBodySearch()
      .query(
        esb
          .boolQuery()
          .must([esb.termsQuery("group.keyword", id), esb.termsQuery("type.keyword", "VOUCHER")])
      )
      .from(0)
      .size(1);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const from = (currentPage - 1) * itemsPerPage;
    const to = currentPage * itemsPerPage;

    setDisplayVouchers(vouchers?.slice(from, to));
    setGoToPageInput(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage]);

  const handleVoucherClick = (voucherGroup) => {
    history.push(`/admin/voucher-group?groupName=${encodeURIComponent(voucherGroup)}`);
  };

  const formatCreated = (dateStr) => {
    return moment(dateStr).format("YYYY-MM-DD HH:mm");
  };

  return (
    <>
      <Toolbar>
        <HoM>
          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={() => setCurrentPage(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of {vouchersTotalHits ? Math.ceil(vouchersTotalHits / itemsPerPage) : "-"} pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          <HoM>Total </HoM>voucher groups: <span>{vouchersTotalHits || "-"}</span>
        </TotalItems>
      </Toolbar>
      <TableHeader>
        <Name>
          <span>Voucher group name</span>
        </Name>
        <DiscountValueColumn>
          <span>Discount</span>
        </DiscountValueColumn>
        <DiscountValueColumn>
          <span>Number of codes</span>
        </DiscountValueColumn>
        <DiscountValueColumn>
          <span>Valid from</span>
        </DiscountValueColumn>
        <DiscountValueColumn>
          <span>Valid to</span>
        </DiscountValueColumn>
        <DiscountValueColumn>
          <span>Created</span>
        </DiscountValueColumn>
      </TableHeader>
      <Vouchers>
        {loading && <Loader />}
        {vouchers && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={vouchersTotalHits}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {[...displayVouchers].map((voucher) => (
              <Voucher key={voucher.name} onClick={() => handleVoucherClick(voucher.name)}>
                <Query
                  query={SEARCH_DISCOUNT_CODE}
                  variables={{
                    query: JSON.stringify(getVoucherGroupQuery(voucher.name).toJSON()),
                  }}>
                  {({ loading, error, data }) => {
                    if (loading) return <Loader />;
                    if (error)
                      return (
                        <ErrorMessage>
                          An error occurred when loading data, please contact support
                        </ErrorMessage>
                      );

                    return (
                      <>
                        <Name>
                          <strong>{voucher.name}</strong>
                        </Name>
                        <DiscountValueColumn>
                          <DiscountValue discount={data.searchDiscounts.discounts[0]} />
                        </DiscountValueColumn>
                        <DiscountValueColumn>
                          <strong>{voucher.count}</strong>
                        </DiscountValueColumn>
                        <DiscountValueColumn>
                          {data.searchDiscounts.discounts[0].startDate}
                        </DiscountValueColumn>
                        <DiscountValueColumn>
                          {data.searchDiscounts.discounts[0].endDate}
                        </DiscountValueColumn>
                        <DiscountValueColumn>{formatCreated(voucher.created)}</DiscountValueColumn>
                      </>
                    );
                  }}
                </Query>
              </Voucher>
            ))}
          </Pagination>
        )}
      </Vouchers>
    </>
  );
};
