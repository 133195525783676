import React, { useState, useEffect } from "react";
import { Query } from "@apollo/client/react/components";
import Box from "components/Dashboard/Box";
import styled from "styled-components/macro";
import ORDER_AGGREGATE from "graphql/Order/OrderAggregate";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import esb from "elastic-builder";
import GET_PRODUCTS from "graphql/Product/GetProducts";
import moment from "moment-timezone";

const Container = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

const StyledBox = styled(Box)`
  padding: 3rem 3rem 1rem;
  min-height: 40rem;
`;

const Table = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 4rem;
  font-weight: 700;
  font-size: 1.2rem;
  padding: 1rem 0;
  margin-top: 0;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  border-radius: 0.5rem;

  div {
    display: flex;
    align-items: center;

    &:last-child {
      width: 25%;
      flex-shrink: 0;
      justify-content: flex-end;
    }
  }
`;

const Row = styled.li`
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 1.5rem 0 1.3rem 0;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;

    &:last-child {
      width: 25%;
      flex-shrink: 0;
      justify-content: flex-end;
    }
  }
`;

export default ({ selectedStores, period }) => {
  if (!selectedStores) return <Loader />;
  const [mostSoldVariants, setMostSoldVariants] = useState();

  useEffect(() => {
    setMostSoldVariants(
      esb.requestBodySearch()
        .query(
          esb
            .boolQuery()
            .must([
              esb.matchQuery("statusLog.status", "success"),
              esb.termsQuery("store.keyword", selectedStores),
              esb
                .rangeQuery("created")
                .gte(period.current.startDate.format("YYYY-MM-DD-HH:mm"))
                .lte(period.current.endDate.format("YYYY-MM-DD-HH:mm"))
                .format("yyyy-MM-dd-HH:mm")
                .timeZone(moment.tz.guess()),
            ])
        )
        .agg(esb.termsAggregation("variants", "orderLines.productId.keyword").size(50))
    );
  }, [selectedStores, period]);

  if (!mostSoldVariants) return null;
  return (
    <Query query={ORDER_AGGREGATE} variables={{ query: JSON.stringify(mostSoldVariants.toJSON()) }}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error)
          return (
            <ErrorMessage>An error occurred when loading data, please contact support</ErrorMessage>
          );

        const buckets = JSON.parse(data.orderAggregates.aggregations).variants.buckets;
        return (
          <Query
            query={GET_PRODUCTS}
            variables={{
              ids: buckets.map((bucket) => bucket.key),
            }}>
            {({ loading, error, data: dataProducts }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );
              const products = dataProducts.getProducts;
              return (
                <StyledBox
                  preHeading="Top 5"
                  heading="Most sold variants"
                  headingIcon="award"
                  highLightIcon>
                  <Container>
                    <Table>
                      <Header>
                        <div>Name</div>
                        <div>Count</div>
                      </Header>
                      {buckets
                        .filter(
                          (bucket) =>
                            products.find((product) => product.id === bucket.key)?.type ===
                            "productVariant"
                        )
                        .slice(0, 5)
                        .map((bucket) => (
                          <Row key={bucket.key}>
                            <div>{products.find((product) => product.id === bucket.key)?.name}</div>
                            <div>{bucket.doc_count}</div>
                          </Row>
                        ))}
                    </Table>
                  </Container>
                </StyledBox>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};
