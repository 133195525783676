import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 1rem 0 2rem 2.2rem;
  background: ${(p) => p.theme.colors.greyOpac};
  border-radius: 0 0 1rem 1rem;
  display: ${(p) => (p.expanded ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Check = styled.i`
  color: green;
`;

const Xmark = styled.i`
  color: red;
`;

const Option = styled.div`
 margin-right: 4rem;
  i {
    margin-right: 1rem;
  }
`;

const getGraphicOptionValue = (value) => {
  if (value === "true") return <Check className="fa-light fa-circle-check" />;
  if (value === "false") return <Xmark className="fa-thin fa-circle-xmark" />;
  return value;
};

export default function Options({ options, expanded }) {
  if (!options || !options?.length) return null;

  return (
    <Container expanded={expanded}>
      <div>
        {options.map((o) => (
          <Option>
            <i class="fa-light fa-user-pen"></i>
            <strong>{o.key}</strong> : {getGraphicOptionValue(o.value)}
          </Option>
        ))}
      </div>
    </Container>
  );
}
