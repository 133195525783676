import React from "react";
import styled from "styled-components/macro";
import placeholder from "images/placeholder.svg";

const Logo = styled.img`
  width: 100%;
`;

export default ({ ...props }) => (
  <Logo {...props} alt="No image found for product" src={placeholder} />
);
