import React from "react";
import SecondarySidebar from "components/Sidebar/SecondarySidebar";

export default () => (
  <SecondarySidebar
    sections={[
      {
        title: "Orders",
        layout: "/admin",
        routes: [
          {name: "Orders", path: "/orders" },
        ],
      },
      {
        title: "Follow-up",
        layout: "/admin",
        routes: [
          { name: "Follow-up", path: "/follow-up" },
        ],
      },
    ]}
  />
);
