import React from "react";
import GridItem from "../Grid/GridItem";
import { useQuery } from "@apollo/client";
import styled from "styled-components/macro";
import Box from "../Content/Box";
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "../../variables/mediaQueries";
import Product from "../Table/Row";
import uuid from "react-uuid";
import TableHeader from "../Table/Header";
import Placeholder from "../Ui/PlaceholderImage";
import Column from "../Table/Column";
import { useHistory } from "react-router-dom";
import getStockVersion from "../../helpers/getStockVersion";
import GET_PRODUCTS_WITH_STOCK from "../../graphql/Product/GetProductsWithStock";

const ProductsBox = styled(Box)`
  margin-top: 2rem;
  > h3 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
    }
  }
`;

const Header = styled(TableHeader)`
  padding-left: 5rem;
  padding-right: 1rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 1rem 0;
  }
  ${MEDIA_MIN_LARGE} {
    padding-right: 2rem;
    padding-left: 10rem;
  }
`;


const Image = styled.img`
  width: 4rem;
  margin-left: 0;

  ${MEDIA_MIN_LARGE} {
    width: 7rem;
    margin-left: 1rem;
  }
`;

const PlaceholderImage = styled(Placeholder)`
  width: 4rem;
  margin-left: 0;

  ${MEDIA_MIN_LARGE} {
    width: 7rem;
    margin-left: 1rem;
  }
`;

const Name = styled(Column)`
  width: 50%;
  padding: 0 1rem;

  ${MEDIA_MIN_LARGE} {
    width: 50%;
    padding: 0 2rem;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  p {
    display: none;
    font-size: 0.9rem;
    ${MEDIA_MIN_LARGE} {
      display: block;
    }
  }
`;

const Stock = styled(Column)`
  width: 50%;
  
`;

const DiscountProductVariantsResult = ({ selectedProducts }) => {
  const history = useHistory()
  const {data} = useQuery(GET_PRODUCTS_WITH_STOCK, {variables: {ids: selectedProducts }})
  const handleProductClick = (productId) => {
    history.push(`/admin/product/${productId.replaceAll("/", "~")}`);
  };
 if(!data) return null
  return (
    <GridItem columns="12" padding="2">
      <ProductsBox
        heading="Selected gift variants"
        preHeading="Discount Rule"
        headingIcon="bags-shopping">

        <Header>
          <Name>
            <span>Name </span>
          </Name>
          <Stock>
            <span>Stock </span>
          </Stock>
        </Header>
        {data?.getProducts.map((product) => {
          return (
            <Product
              key={uuid()} onClick={() =>
              handleProductClick(product.relatedProduct.id)
            }>
              {product.imageUrl ? (
                <Image src={`${product.imageUrl}?fm=webp&w=150`} alt={product.name} />
              ) : (
                <PlaceholderImage />
              )}

              <Name>
                <NameWrapper>
                  <strong>{product.name}</strong>
                  <p>{product.id}</p>
                </NameWrapper>
              </Name>
              <Stock>
                {getStockVersion() === "v2" ? product?.stockInventory?.stockQuantity: product?.stock?.stock}
              </Stock>
            </Product>
          );
        })}
      </ProductsBox>
    </GridItem>
  );
};

export default DiscountProductVariantsResult;
