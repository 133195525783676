export default function isRefundable(order) {
  const paymentMethodsWithCapture = ["klarna", "creditcard", "paypal", "other"];

  const isSuccess = order.status === "success";
  const isPaymentMethodWithCapture = paymentMethodsWithCapture.includes(
    (order.paymentMethod ?? "").toLowerCase()
  );

  const isShipped = order.status === "shipped";
  const isRefunded = order.status === "refunded";
  const refundableLines = order.orderLines.filter(
    (o) => o.type === "physical" || o.type === "addon"
  );

  const isFullyRefunded = (refundableLines
  .map((rl) =>
    order?.orderLines
      ?.filter((ol) => ol?.product?.id === rl?.product?.id)
      .reduce((acc, curr) => acc + curr.quantity, 0)
  )
  .reduce((acc, curr) => acc + curr, 0)) === 0;

  return (
    ((isSuccess && !isPaymentMethodWithCapture) || isShipped || isRefunded) && !isFullyRefunded
  );
}
