import React from "react";
import styled from "styled-components/macro";
import brinkLogo from "images/brink-logo.svg";
import efvaattlingLogo from "images/clientlogos/efva-attling-logo.svg";
import tanrevelLogo from "images/clientlogos/tanrevel-logo.svg";
import cestnormalLogo from "images/clientlogos/cest-normal-logo.svg";
import rondureLogo from "images/clientlogos/rondure-logo.svg";
import comisLogo from "images/clientlogos/comis-logo.svg";
import axelarigatoLogo from "images/clientlogos/axel-arigato-logo.svg";
import happySocksLogo from "images/clientlogos/happy-socks-logo.svg";

const Logo = styled.img`
  max-width: 25rem;
  max-height: ${(p) => p.maxHeight || "8rem"};
  padding-bottom: 1rem;
`;

export default () => {
  const client = window.location.href?.match(/admin\.(.*)\.brinkcommerce/)?.[1];

  switch (client) {
    case "cestnormal":
      return {
        displayName: "C'est Normal",
        logo: <Logo src={cestnormalLogo} alt="C'est Normal logo" />,
      };
    case "efvaattling":
      return {
        displayName: "Efva Attling",
        logo: <Logo src={efvaattlingLogo} alt="Efva Attling logo" />,
      };
    case "rondure":
      return { displayName: "Rondure", logo: <Logo src={rondureLogo} alt="Rondure logo" /> };
    case "tanrevel":
      return { displayName: "Tanrevel", logo: <Logo src={tanrevelLogo} alt="Tanrevel logo" /> };
    case "comis":
      return {
        displayName: "Comis",
        logo: <Logo maxHeight="12rem" src={comisLogo} alt="Comis logo" />,
      };
    case "axelarigato":
      return {
        displayName: "Axel Arigato",
        logo: <Logo src={axelarigatoLogo} alt="Axel Arigato logo" />,
      };
    case "happysockstest":
    case "happysocks":
      return {
        displayName: "Happy Socks",
        logo: <Logo maxHeight="12rem" src={happySocksLogo} alt="Happy Socks logo" />,
      };
    default:
      return {
        displayName: "Brink",
        logo: <Logo maxHeight="12rem" src={brinkLogo} alt="Brink logo" />,
      };
  }
};
