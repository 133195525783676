import React from "react";
import styled from "styled-components/macro";
import uuid from "react-uuid";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

import Row from "components/Table/Row";
import Column from "components/Table/Column";
import Checkbox from "components/Ui/Checkbox";
import PlaceholderImage from "components/Ui/PlaceholderImage";

const Product = styled(Row)`
  min-height: 8rem;
  user-select: none;

  ${MEDIA_MIN_LARGE} {
    padding-top: 0;
  }

  img {
    width: 4rem;
    margin-left: 1rem;

    ${MEDIA_MIN_LARGE} {
      max-width: 7rem;
      max-height: 7rem;
      margin-left: 2rem;
    }
  }
`;

const Name = styled(Column)`
  width: 80%;
  padding: 0 1rem;

  ${MEDIA_MIN_LARGE} {
    width: 80%;
    padding: 0 2rem;
  }
`;

const SelectedVariantCount = styled(Column)`
  width: 25%;

  ${MEDIA_MIN_LARGE} {
    width: 18%;
  }
`;

const ToggleButton = styled.div`
  font-size: 1.6rem;
  padding-right: 1rem;

  ${MEDIA_MIN_LARGE} {
    font-size: 2rem;
    padding-right: 2rem;
  }
`;

const Variant = styled(Product)`
  min-height: 7rem;
  padding-left: 5rem;
  user-select: none;

  ${MEDIA_MIN_LARGE} {
    padding-left: 9rem;
    padding-top: 0;
  }
`;

const VariantImage = styled.img`
  max-height: 4rem;
`;

export default ({
  product,
  selectedProducts,
  setSelectedProducts,
  setPreventSort,
  selectVariants,
}) => {
  const { products, expandedProducts } = selectedProducts;
  const toggleExpanded = () => {
    setPreventSort(true);
    setSelectedProducts((prev) => ({
      ...prev,
      expandedProducts: prev.expandedProducts.includes(product.id)
        ? prev.expandedProducts.filter((v) => v !== product.id)
        : [...prev.expandedProducts, product.id],
    }));
  };

  const handleVariant = (variantId) => {
    setPreventSort(true);
    setSelectedProducts((prev) => ({
      ...prev,
      products: prev.products.includes(variantId)
        ? prev.products.filter((v) => v !== variantId)
        : [...prev.products, variantId],
    }));
  };

  const handleProduct = (productId) => {
    setPreventSort(true);

    selectVariants
      ? product.relatedVariants.map((variant) =>
          setSelectedProducts((prev) => ({
            ...prev,
            products: prev.products.includes(variant.id)
              ? prev.products.filter((v) => v !== variant.id)
              : [...prev.products, variant.id],
          }))
        )
      : setSelectedProducts((prev) => ({
          ...prev,
          products: prev.products.includes(productId)
            ? prev.products.filter((p) => p !== productId)
            : [...prev.products, productId],
        }));
  };

  return (
    <>
      <Product
        onClick={() => {
          selectVariants ? toggleExpanded() : handleProduct(product.id);
        }}>
        {selectVariants && (
          <ToggleButton>
            {expandedProducts.includes(product.id) ? (
              <i className="far fa-fw fa-chevron-down"></i>
            ) : (
              <i className="far fa-fw fa-chevron-right"></i>
            )}
          </ToggleButton>
        )}
        <Checkbox
          onChange={() => {
            handleProduct(product.id);
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          defaultChecked={
            selectVariants
              ? product?.relatedVariants?.length &&
                product?.relatedVariants?.every((variant) => products.includes(variant.id))
              : products.includes(product.id)
          }
          indeterminate={
            selectVariants
              ? product?.relatedVariants?.some((variant) => products.includes(variant.id))
              : false
          }
        />
        {product.imageUrl ? (
          <img src={`${product.imageUrl}?fm=webp&w=150`} alt={product.name} />
        ) : (
          <PlaceholderImage />
        )}
        <Name>
          <strong>{product.name}</strong>
        </Name>
        {selectVariants && (
          <SelectedVariantCount>
            {product.relatedVariants.filter((variant) => products.includes(variant.id)).length} /
            {product.relatedVariants.length}
          </SelectedVariantCount>
        )}
      </Product>
      {selectVariants &&
        expandedProducts.includes(product.id) &&
        product.relatedVariants
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((variant) => (
            <Variant
              key={uuid()}
              onClick={() => {
                handleVariant(variant.id);
              }}>
              <Checkbox
                id="checkVariant"
                onChange={() => {
                  handleVariant(variant.id);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                defaultChecked={products.includes(variant.id)}
              />
              {variant.imageUrl ? (
                <VariantImage src={`${variant.imageUrl}?fm=webp&w=100`} alt={variant.name} />
              ) : (
                <PlaceholderImage />
              )}
              <Name>
                <strong>{variant.name}</strong>
              </Name>
            </Variant>
          ))}
    </>
  );
};
