import React from "react";
import Login from "./components/Login";
import { Route, Redirect, useHistory, Switch } from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import AdminLayout from "./layouts/Admin";
import AuthenticatedRoute from "./components/Authentication/AuthenticatedRoute";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

const oktaAuth = new OktaAuth({
  issuer: `${process.env.REACT_APP_OKTA_DOMAIN_URL}/oauth2/default`,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ["openid", "profile", "email"],
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  disableHttpsCheck: false,
  pkce: true,
});

const AppWithRouterAccess = () => {
  const history = useHistory();

  const loginRequired = () => {
    history.push("/login");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={loginRequired}
      restoreOriginalUri={restoreOriginalUri}>
      <OktaRoutes />
    </Security>
  );
};

export default AppWithRouterAccess;

const OktaRoutes = () => {
  const history = useHistory();

  const onAuthResume = async () => {
    history.push("/login");
  };

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/admin/dashboard" />
      </Route>
      <Route exact path="/login" render={() => <Login />} />
      <AuthenticatedRoute path="/admin" component={AdminLayout} displayName="Dashboard" />
      <Route
        path="/implicit/callback"
        render={(props) => <LoginCallback {...props} onAuthResume={onAuthResume} />}
      />
    </Switch>
  );
};
