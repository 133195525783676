import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import Vouchers from "components/Table/Rows";
import Voucher from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Ui/Loader";

const Code = styled(Column)`
  width: 50%;
  padding-left: 1rem;

  ${MEDIA_MIN_LARGE} {
    padding-left: 2rem;
  }
`;

const Used = styled(Column)`
  width: 40%;

  i {
    font-size: 1.8rem;
    margin-right: 0.8rem;

    ${MEDIA_MIN_LARGE} {
      font-size: 2.4rem;
    }
  }
`;

const Consumed = styled(Column)`
  width: 10%;
  font-size: 2rem;
  padding-right: 3rem;
  justify-content: flex-end;

  .fa-check-circle {
    color: green;
  }

  .fa-times-circle {
    color: red;
  }
`;

const MAX_RESULT_WINDOW = 5000;

export default ({
  data,
  loading,
  fetchMore,
  history,
  discountsPerPage,
  esbQuery,
  children: renderTableActions = null,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPageInput, setGoToPageInput] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(discountsPerPage);

  const toVoucherCodes = (data) =>
    data &&
    data.searchDiscounts.discounts.map((discount) => ({
      id: discount.id,
      code: discount.code,
      used: discount.usageCount,
      discountInPercentage: discount.discountInPercentage,
      limit: discount.usageLimit,
      consumed: discount.usageCount === discount.usageLimit,
    }));

  const [voucherCodes, setVoucherCodes] = useState(toVoucherCodes(data));
  const voucherCodesTotalHits = data && data.searchDiscounts.totalHits;

  useEffect(() => {
    const from = (currentPage - 1) * itemsPerPage;
    fetchMore({
      variables: { query: JSON.stringify(esbQuery.size(itemsPerPage).from(from).toJSON()) },
    })
      .then((result) => setVoucherCodes(toVoucherCodes(result.data)))
      .catch((error) => {
        console.error(error);
      });
    setGoToPageInput(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, esbQuery]);

  return (
    <>
      <Toolbar>
        <HoM>
          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={() => setCurrentPage(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of {voucherCodesTotalHits ? Math.ceil(voucherCodesTotalHits / itemsPerPage) : "-"} pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          <HoM>Total </HoM>vouchers: <span>{voucherCodesTotalHits || "-"}</span>
        </TotalItems>
        {renderTableActions &&
          renderTableActions({
            voucherCodesTotalHits: Math.min(voucherCodesTotalHits, MAX_RESULT_WINDOW),
          })}
      </Toolbar>
      <TableHeader>
        <Column>
          <span>Discount code</span>
        </Column>
        <Column>
          <span>Used</span>
        </Column>
        <Column>
          <span>Consumed</span>
        </Column>
      </TableHeader>
      <Vouchers>
        {loading && <Loader />}
        {voucherCodes && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={voucherCodesTotalHits}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {[...voucherCodes].map((voucherCode) => (
              <Voucher
                key={voucherCode.code}
                onClick={() => history.push(`/admin/voucher/${voucherCode.id}`)}>
                <Code>
                  <strong>{voucherCode.code}</strong>
                </Code>
                <Used>
                  <span>
                    {voucherCode.used}/{voucherCode.limit}
                  </span>
                </Used>
                <Consumed>
                  {voucherCode.consumed ? (
                    <i className={"fal fa-check-circle"} />
                  ) : (
                    <i className={"fal fa-times-circle"} />
                  )}
                </Consumed>
              </Voucher>
            ))}
          </Pagination>
        )}
      </Vouchers>
    </>
  );
};
