import React from "react";
import ThresholdsInput from "../../../ThresholdsInput";

const GWPThresholdOptions = ({ OptionsBox, currencyOptions, setCurrencyOptions }) => {
  return (
    <OptionsBox
      preHeading="Gift"
      heading="Thresholds"
      subHeading="Minimum order value"
      headingIcon="bars-progress">
      <ThresholdsInput thresholds={currencyOptions} setThresholds={setCurrencyOptions} />
    </OptionsBox>
  );
};

export default GWPThresholdOptions;
