import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "../../variables/mediaQueries";

const Attributes = styled.ul`
  list-style: none;
  padding-left: 0;
  width: 100%;
  margin: 0;
  columns: ${(p) => p.columns || "1"};

  ${MEDIA_MIN_LARGE} {
  }
`;

export default ({ children, columns, ...props }) => {
  return (
    <Attributes columns={columns} {...props}>
      {children}
    </Attributes>
  );
};
