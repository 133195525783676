import React from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";

import ALL_STORES from "graphql/Store/AllStores";

import MultiSelect from "components/Ui/MultiSelect";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
`;

export default ({ selected, setSelected }) => {

  const getOptions = (data) => {
  const currencies = data.allStores.stores.map(s => s.currencyUnit)
  const options = [...new Set(currencies)].map((currency) => ({
      value: currency,
      label: currency,
    })); 
    return options;
  };

  return (
    <Query
      query={ALL_STORES}
      variables={{ from: 0, size: 300 }}>
      {({ error, data }) => {
        if (error)
          return <ErrorMessage>An error occurred getting data, contact support</ErrorMessage>;
        let options = [];
        if (data) options = getOptions(data);

        const itemRenderer = ({ checked, option, onClick }) => (
          <Label>
            <span>
              <span>{option.label}</span>
            </span>
            <input
              type="checkbox"
              onChange={onClick}
              checked={checked}
              tabIndex="-1"
              style={{ float: "right" }}
            />
          </Label>
        );

        return (
          <MultiSelect
            options={options}
            selected={selected}
            setSelected={setSelected}
            label="Select Currencies"
            ItemRenderer={itemRenderer}
            valueRenderer={(selected) => {
              if (selected.length === 0) {
                return <span>No currencies selected</span>;
              }
              if (selected.length === options.length) {
                return <span>All currencies selected</span>;
              }
              return (
                <span>
                  {selected.length} {selected.length === 1 ? "currency" : "currencies"} selected
                </span>
              );
            }}
          />
        );
      }}
    </Query>
  );
};
