import React from "react";
import GridItem from "components/Grid/GridItem";
import styled from "styled-components";
import InputNew from "components/Ui/InputNew";
import { Controller } from "react-hook-form";
import Tooltip from "components/Ui/Tooltip";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const AmountLabel = styled.div`
  font-weight: 800;
  cursor: default;
  margin-bottom: 1rem;
  i {
    margin-right: 0.5rem;
  }
`;

const CurrenciesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CurrencyWrapper = styled.div`
  display: flex;
`;

const Currency = styled.div`
  width: 100%;
  ${MEDIA_MIN_LARGE} {
    width: 24%;
  }
`;

const CurrencyInput = styled(InputNew)`
  margin-bottom: 1rem;
  border-radius: 0 0.3rem 0.3rem 0 !important;
`;

const Label = styled.label`
  padding-bottom: 0.8rem;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const Icon = styled.div`
  width: 6rem;
  height: 5rem;
  background-color: ${(p) => p.theme.colors.inputBorderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem 0 0 0.3rem;
  i {
    font-size: 2rem;
  }
`;

export default ({ currencies, control, errors }) => {
  return (
    <GridItem columns="12" padding="0 0 4rem">
      <Tooltip />
      <AmountLabel>
        <i className="fal fa-fw fa-info-circle" data-tip="Only digits, ex. 60" />
        Amount
      </AmountLabel>
      <CurrenciesWrapper>
        {!currencies.length && <p>No stores selected</p>}
        {currencies.map((currency) => {
          return (
            <Currency key={currency}>
              <Controller
                name={`price[${currency}].amount`}
                render={({ field }) => (
                  <>
                  <Label>{currency}</Label>
                  <CurrencyWrapper>
                    <Icon>
                      <i class="fa-light fa-money-bill-1"></i>
                    </Icon>
                    <CurrencyInput key={currency} {...field} type="number" defaultValue={0} />
                  </CurrencyWrapper>
                  </>
             
                )}
                control={control}
                rules={{
                  required: "This is a required field",
                  min: {
                    value: 1,
                    message: "Amount needs to be greater than 0",
                  },
                }}
                errors={errors}
              />
            </Currency>
          );
        })}
      </CurrenciesWrapper>
    </GridItem>
  );
};
