import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { alerts } from "variables/colors";
import { useNotification } from "context/NotificationContext";

const Alert = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  border: none;
  border-radius: 0.3rem;
  padding: 1.7rem 3rem;
  position: fixed;
  bottom: 3rem;
  right: 2rem;
  z-index: 1000;
  transition: transform 0.4s;
  transform: translate(${(p) => (p.showMessage ? "0, 0" : "calc(100% + 3rem), 0")});

  i {
    font-size: 2.4rem;
    margin-right: 0.8rem;
  }
`;

const Success = styled(Alert)`
  background: ${alerts.success.background};
  color: ${alerts.success.text};
`;

const Error = styled(Alert)`
  background: ${alerts.error.background};
  color: ${alerts.error.text};
`;

const Default = styled(Alert)`
  background: ${alerts.general.background};
  color: ${alerts.general.text};
`;

export default ({ ...props }) => {
  const { notification } = useNotification();
  const [showMessage, setShowMessage] = useState(false);
  const { message, status } = notification || {};

  useEffect(() => {
    setShowMessage(notification.message || false);

    const timer = setTimeout(
      () => {
        setShowMessage(false);
      },
      notification.status === "error" ? 6000 : 5000
    );

    return () => {
      clearTimeout(timer);
    };
  }, [notification]);

  return (
    <>
      <Success showMessage={showMessage && status === "success"} {...props}>
        <i className="fal fa-check-circle"></i>
        {message}
      </Success>
      <Error showMessage={showMessage && status === "error"} {...props}>
        <i className="fal fa-exclamation-circle"></i>
        {message}
      </Error>
      <Default showMessage={showMessage && status === "default"} {...props}>
        <i className="fal fa-info-circle"></i>
        {message}
      </Default>
    </>
  );
};
