import { createGlobalStyle } from "styled-components/macro";
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_XX_LARGE } from "variables/mediaQueries";
import MuseoSansRegular from "fonts/museosans-300-webfont.woff2";
import MuseoSansBold from "fonts/museosans-700-webfont.woff2";

const GlobalStyle = createGlobalStyle`

    @font-face {
      font-family: "Museo Sans";
      src: local("Museo Sans"), url(${MuseoSansRegular}) format("woff2");
      font-weight: 300;
      font-style: normal;
    }

    @font-face {
      font-family: "Museo Sans";
      src: local("Museo Sans"), url(${MuseoSansBold}) format("woff2");
      font-weight: 700;
      font-style: normal;
    }

    * {
      box-sizing: border-box;

      &:focus {
        outline: none;
      }
    }

    html {
        font-size: 62.5%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100%;
    }

    body {
      background: ${(p) => p.theme.colors.background};
      color: ${(p) => p.theme.colors.white};
      margin: 0;
      height: 100%;
    }

    #root {
      height: 100%;
    }

    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    button,
    input,
    select,
    textarea,
    ::placeholder {
        line-height: 1.8rem;
        letter-spacing: 0.1rem;
        font-family: "Museo Sans", sans-serif;
        font-weight: 300;
        font-size: 1.2rem;

        ${MEDIA_MIN_MEDIUM} {
          font-size: 1.3rem;
          line-height: 1.5em;
        }

        ${MEDIA_MIN_XX_LARGE} {
          font-size: 1.4rem;
          line-height: 1.6em;
        }
    }

    input:-webkit-autofill::first-line {
        font-family: "Museo Sans", sans-serif;
        font-size: 1.3rem;
    }

    h1,
    h2,
    h3 {
      font-weight: 700;
      font-family: "Museo Sans", sans-serif;
    }

    h1 {
    font-size: 3em;
    line-height: 1.15em;
    }

    h2 {
    font-size: 2.6em;
    }

    h3 {
    font-size: 1.825em;
    line-height: 1.4em;
    margin: 2rem 0 1rem;
    }

    h4 {
    font-size: 1.3em;
    line-height: 1.4em;
    margin-top: 1rem;
    margin-bottom: 1rem;
    }

    h5 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 1.5rem;
    }


    h6 {
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: 500;
    }

    h4,
    h5,
    h6 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    }

    strong {
      letter-spacing: 0.05rem;
    }

    p {
    margin: 0 0 1rem;
    }

    blockquote p {
    font-style: italic;
    }

    a {
    color: white;
    text-decoration: none;
    }

    a:hover,
    a:focus {
    color: white;
    text-decoration: none;
    }

    select, input {
        border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
    }

  .ct-chart-line {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.3));
  }

  .ct-series-a {
    .ct-line {
      stroke: ${(p) => p.theme.colors.primary};
      stroke-width: 0.3rem;
      opacity: 0.8;
    }

    .ct-area {
      fill: ${(p) => p.theme.colors.primary};
    }
  }

  .ct-series-b {
    .ct-line {
      stroke: ${(p) => p.theme.colors.opac1};
      stroke-width: 0.4rem;
      opacity: 0.8;
    }

    .ct-area {
      fill: ${(p) => p.theme.colors.opac1};
    }
  }

  .ct-series-a .ct-point {
    stroke: ${(p) => p.theme.colors.primary};
    stroke-width: 1.2rem;
  }

  .ct-chart-pie {
    .ct-label {
      fill: white;
      font-size: 1.3rem;
    }
  }

  .ct-series .ct-bar {
      fill: ${(p) => p.theme.colors.primary};
      stroke: ${(p) => p.theme.colors.primary};
      stroke-width: 2rem;
  }

  .ct-series-a .ct-bar {
    opacity: 0.9;
  }

  .ct-series-b .ct-bar {
    opacity: 0.8;
  }

  .ct-series-c .ct-bar {
    opacity: 0.7;
  }

  .ct-series-d .ct-bar {
    opacity: 0.6;
  }

  .ct-series-e .ct-bar {
    opacity: 0.5;
  }

  .ct-series-f .ct-bar {
    opacity: 0.4;
  }

  .ct-series-g .ct-bar {
    opacity: 0.3;
  }

  .ct-series-h .ct-bar {
    opacity: 0.2;
  }

  .ct-label,
  .ct-grid {
    stroke: ${(p) => p.theme.colors.whiteOpac};
    color: ${(p) => p.theme.colors.white};
  }

  .ct-vertical {
    stroke: ${(p) => p.theme.colors.whiteOpac};
    stroke-dasharray: 0;
  }

  .ct-label {
    font-size: 0.8rem;
    padding-top: 1rem;
  }

  .ct-chart-donut {
    .ct-series-a {
      path {
        fill: ${(p) => p.theme.colors.primary};
      }
    }

    .ct-series-b {
      path {
        fill: ${(p) => p.theme.colors.whiteOpac};
      }
    }
  }
`;

export default GlobalStyle;
