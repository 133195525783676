import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

import Button from "components/Ui/Button";

const PrimaryActionButton = styled(Button)`
  height: 4rem;
  width: auto;
  padding: 0 2rem;
  margin: 0 0.5rem;

  ${MEDIA_MIN_LARGE} {
    height: 4.4rem;
    padding: 0 2rem;
  }
`;

export default ({ type, children, handleOnClick, iconOnlyMobile, ...props }) => (
  <PrimaryActionButton
    {...props}
    type={type || "button"}
    iconOnlyMobile={iconOnlyMobile}
    onClick={() => handleOnClick && handleOnClick()}>
    {children}
  </PrimaryActionButton>
);
