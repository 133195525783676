import React from "react";
import GridItem from "components/Grid/GridItem";
import { useHistory } from "react-router-dom";
import Box from "components/Content/Box";
import Rows from "components/Table/Rows";
import Row from "components/Table/Row";
import TableHeader from "components/Table/Header";
import Column from "components/Table/Column";
import styled from "styled-components";
import uuid from "react-uuid";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const InventoryRow = styled(Row)`
  min-height: 8rem;
  cursor: default;
  &:hover {
    box-shadow: none;
  }
`;

const Inventories = styled(Rows)`
  min-height: 0;
`;

const Variant = styled(Column)`
  width: 40%;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Quantity = styled(Column)`
  width: 30%;
  display: flex;
  justify-content: center;
`;

const Inventory = styled(Column)`
  width: 30%;
  i {
    margin-right: 1rem;
    font-size: 1.8rem;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Image = styled.img`
  width: 4rem;
  margin-right: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    width: 6rem;
  }
`;

export default ({ processedProducts }) => {
  const history = useHistory();

  const handleProductClick = (productId) => {
    history.push(`/admin/product/${productId.replaceAll("/", "~")}`);
  };

  const handleInventoryClick = (inventory) => {
    history.push({
      pathname: `/admin/inventory/${inventory.id}`,
      state: inventory,
    });
  };

  return (
    <GridItem columns="12">
      <Box preHeading="Order" heading="Inventories" headingIcon="warehouse-full">
        <TableHeader>
          <Variant>Product</Variant>
          <Quantity>Quantity</Quantity>
          <Inventory>Inventory</Inventory>
        </TableHeader>
        <Inventories>
          {processedProducts.map((p) => {
            return (
              <InventoryRow key={uuid()}>
                <Variant onClick={() => handleProductClick(p.product.relatedProduct.id)}>
                  <Image src={`${p.product.imageUrl}?fm=webp&w=150`} />
                  {p.product.name}
                </Variant>
                <Quantity>{p.quantity}</Quantity>
                <Inventory onClick={() => handleInventoryClick(p.inventory)}>
                  <i className="fa-duotone fa-warehouse-full"></i>
                  {p.inventory.name}
                </Inventory>
              </InventoryRow>
            );
          })}
        </Inventories>
      </Box>
    </GridItem>
  );
};
