import React from "react";
import styled from "styled-components/macro";
import { shadow } from "variables/colors";
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Container = styled.div`
  background: ${(p) => p.theme.colors.secondaryBackground};
  box-shadow: ${shadow};
  min-height: 32rem;
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    min-height: ${(p) => (p.large ? "50rem" : "35rem")};
  }

  ${MEDIA_MIN_LARGE} {
  }
`;

export default ({ children, large, ...props }) => {
  return (
    <Container large={large} {...props}>
      {children}
    </Container>
  );
};
