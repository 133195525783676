import gql from "graphql-tag";
import { PRODUCT_DETAILS_FRAGMENT } from "../Fragments";

const GET_PRODUCTS = gql`
  query getProducts($ids: [ID!]!) {
    getProducts(ids: $ids) {
        ...ProductDetails
    }
  }
  ${PRODUCT_DETAILS_FRAGMENT}
`;

export default GET_PRODUCTS;
