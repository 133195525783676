import gql from "graphql-tag";

const SEARCH_DISCOUNT_RULES = gql`
  query searchRules($query: AWSJSON!) {
    searchRules(query: $query) {
      rules {
        active
        brinkRule
        created
        id
        lastUpdated
        name
        startDate
        endDate
        ruleTemplate {
          id
        }
        templateData
        type
      }
      totalHits
    }
  }
`;

export default SEARCH_DISCOUNT_RULES;
