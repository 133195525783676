import gql from "graphql-tag";

const EXTEND_PAYMENT_ORDER = gql`
  mutation ExtendPaymentOrder($id: ID!) {
    extendPaymentOrder(id: $id) {
      id
      paymentExpiresAt
    }
  }
`;

export default EXTEND_PAYMENT_ORDER;
