import React from "react";
import styled, { keyframes } from "styled-components/macro";

const styledKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10002;
  background: ${(p) => p.theme.colors.background};
  opacity: 0.8;
`;

const ProgressLoader = styled.div`
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
  padding: 1rem;
  border-radius: 3rem;
  text-align: center;
  font-size: 1.8rem;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 5.5rem;
    height: 5.5rem;
    border: 0.3rem solid ${(p) => p.color || p.theme.colors.white};
    border-radius: 50%;
    animation: ${styledKeyframes} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(p) => p.color || p.theme.colors.white} transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;

const Text = styled.span`
  position: relative;
  top: 65%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export default ({ text, color, ...props }) => (
  <Container onClick={(e) => e.stopPropagation()}>
    <ProgressLoader color={color} {...props}>
      <Text>{text} %</Text>
      <div />
      <div />
      <div />
      <div />
    </ProgressLoader>
  </Container>
);
