import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useQuery } from "@apollo/client";
import SEARCH_SHIPPING_OPTIONS from "graphql/Product/SearchShippingOptions";
import esb from "elastic-builder";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Container = styled.div`

    font-size: 1.3rem;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 4rem;

    ${MEDIA_MIN_LARGE} {
        max-width: 50rem;
    }
`;

const ShippingMethodSelector = styled.div`
    font-size: 1.3rem;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3rem;
    border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
    padding: 0 1.5rem;
    background: ${(p) => p.theme.colors.primaryWhite};
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${(p) => (p.expanded ? "800" : "-1")};
`;

const Summary = styled.div`
    height: 4rem;
    display: flex;
    justify-content: space-between;
    color: black;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;

const Icon = styled.i`
    font-size: ${(p) => (p.small ? "1.8rem" : "2rem")};
    margin-right: 1rem;
    position: relative;
    top: 0.25rem;
`;


const DropDown = styled.div`
    position: absolute;
    z-index: 1000;
    display: ${(p) => (p.expanded ? "flex" : "none")};
    max-height: 40rem;
    flex-direction: column;
    background-color: ${(p) => p.theme.colors.black};
    overflow: scroll;
    width: calc(100% - 1rem);
    top: ${(p) => p.dropDownTop};
    left: 0;
`;

const Checkbox = styled.input`
    margin-right: 1rem;
`;

const ShippingMethodWrapper = styled.div`
    display: flex;
    align-items: center;
    min-height: 4rem;
    padding: 1rem 2rem;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};

    &:hover {
        cursor: pointer;
    }

    span {
        display: flex;
        align-items: baseline;
    }

    i {
        margin-left: 1rem;
    }
`;

const Method = styled.span`
    margin-left: 0.2rem;
`;

export default ({
                  label,
                  errors,
                  setValue,
                  dropDownTop = "5.5rem",
                  small,
                  updateOrderFilter,
                  orderFilter,
                  ...props
                }) => {
  const ALL_SHIPPING_OPTIONS = esb.requestBodySearch()
    .query(esb.queryStringQuery("type:shippingOption AND !(archived:1)"))
    .size(500);

  const [expanded, setExpanded] = useState(false);
  const [selectAllShippingMethods, setSelectAllShippingMethods] = useState(false);
  const [esbQuery] = useState(ALL_SHIPPING_OPTIONS);
  const shippingMethods = [];

  const { error, data } = useQuery(SEARCH_SHIPPING_OPTIONS, {
    variables: { query: JSON.stringify(esbQuery.toJSON()) },
  });

  if (data && data.searchProducts && data.searchProducts.products) {
    data.searchProducts.products.forEach((product) => {
      if (!shippingMethods?.id?.includes(product.id))
        shippingMethods.push({ id: product.id, name: product.name });
      shippingMethods.sort((a, b) => (a.name > b.name ? 1 : -1));
    });
  }

  const clearSelector = () => {
    updateOrderFilter("shippingMethods", []);
    setSelectAllShippingMethods(false);
  };

  useEffect(() => {
    if (orderFilter?.shippingMethods && orderFilter?.shippingMethods?.length === 0) {
      clearSelector();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFilter?.shippingMethods?.length]);

  const updateShippingMethods = (shippingMethod) => {
    let updatedShippingMethods = orderFilter?.shippingMethods
      ? [...orderFilter?.shippingMethods]
      : [];

    const index = updatedShippingMethods.findIndex((s) => s.id === shippingMethod.id);

    if (index > -1) {
      updatedShippingMethods.splice(index, 1);
    } else {
      updatedShippingMethods.push(shippingMethod);
    }

    updateOrderFilter("shippingMethods", updatedShippingMethods);
    setSelectAllShippingMethods(selectAllShippingMethods);
  };

  const handleOutsideClick = () => {
    setExpanded(!expanded);
  };

  const handleSelectAll = () => {
    if (!selectAllShippingMethods) {
      updateOrderFilter("shippingMethods", shippingMethods);
      setSelectAllShippingMethods(true);
    } else {
      updateOrderFilter("shippingMethods", []);
      setSelectAllShippingMethods(false);
    }
  };

  if (error) return null;

  return (
    <>
      <Container>
        <ShippingMethodSelector errors={error}>
          <Summary onClick={() => setExpanded(!expanded)} small={small}>
            <span>
              {orderFilter?.shippingMethods?.length > 0 ? (
                <>{orderFilter?.shippingMethods?.length} shipping methods selected</>
              ) : (
                <>
                  {" "}
                  <Icon className="fal fa-fw fa-shipping-fast" /> Select shipping methods
                </>
              )}
            </span>
            {expanded ? (
              <i className="far fa-fw fa-chevron-up"></i>
            ) : (
              <i className="far fa-fw fa-chevron-down"></i>
            )}
          </Summary>
          <Backdrop expanded={expanded} onClick={handleOutsideClick} />
          <DropDown expanded={expanded} dropDownTop={dropDownTop}>
            {expanded && (
              <>
                {shippingMethods?.length !== 0 && (
                  <ShippingMethodWrapper>
                    <Checkbox
                      checked={selectAllShippingMethods}
                      type="checkbox"
                      tabIndex="-1"
                      onChange={handleSelectAll}
                    />
                    <span onClick={() => setSelectAllShippingMethods(!selectAllShippingMethods)}>
                      <Icon className={`fa-duotone fa-circle-check`} small />
                      Select all
                    </span>
                  </ShippingMethodWrapper>
                )}
              </>
            )}
            {expanded &&
              shippingMethods.map((method) => {
                return (
                  <ShippingMethodWrapper key={method.id}>
                    <Checkbox
                      onChange={() => {
                        if (selectAllShippingMethods) {
                          setSelectAllShippingMethods(false);
                        }
                        updateShippingMethods(method);
                      }}
                      checked={
                        orderFilter?.shippingMethods?.length > 0 &&
                        orderFilter?.shippingMethods.some(
                          (s) => s.id === method.id && s.name === method.name,
                        )
                      }
                      type="checkbox"
                      tabIndex="-1"
                    />
                    <Method onClick={() => updateShippingMethods(method)}>
                      <span>{method.name}</span>
                    </Method>
                  </ShippingMethodWrapper>
                );
              })}
          </DropDown>
        </ShippingMethodSelector>
      </Container>
    </>
  );
};
