import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Column = styled.div`
  display: ${(p) => (p.hideOnMobile ? "none" : "flex")};
  align-items: center;

  ${MEDIA_MIN_MEDIUM} {
    i {
      margin-bottom: 0.2rem;
    }
  }
  ${MEDIA_MIN_LARGE} {
    display: flex;
  }
`;

export default ({ children, hideOnMobile, ...props }) => (
  <Column hideOnMobile={hideOnMobile} {...props}>
    {children}
  </Column>
);
