import gql from "graphql-tag";

const SEARCH_DISCOUNTS = gql`
  query searchDiscounts($query: AWSJSON!) {
    searchDiscounts(query: $query) {
      discounts {
        id
        code
        description
        startDate
        endDate
        freeShipping
        discountInPercentage
        discountInFixedPrice {
          amount
          currencyUnit
        }
        combine
        created
        createdBy
        lastUpdated
        usageLimit
        usageCount
      }
      totalHits
    }
  }
`;

export default SEARCH_DISCOUNTS;
