import React, { useState } from "react";
import esb from "elastic-builder";

import SearchForm from "components/Table/Search/SearchForm";

export default ({ setSearchFilters }) => {
  const [searchInput, setSearchInput] = useState("");
  const [inputError, setInputError] = useState(false);

  const searchProducts = (evt) => {
    evt.preventDefault();

    searchInput.trim() === ""
      ? setSearchFilters([])
      : setSearchFilters([esb.queryStringQuery(formatInput(searchInput)).analyzeWildcard()]);
  };

  const formatInput = (input) => {
    if (/[-/]/gm.test(input)) {
      return `"${input}"`;
    }
    return input.replace("@", "*");
  };

  return (
    <SearchForm
      autoComplete="off"
      placeholder="Name, ID, SKU..."
      inputError={inputError}
      setInputError={setInputError}
      setSearchInput={setSearchInput}
      searchFunc={searchProducts}
    />
  );
};
