import gql from "graphql-tag";

const GET_RULES = gql`
  query getRules($ids: [ID!]!) {
    getRules(ids: $ids) {
      id
      name
      brinkRule
      active
      type
    }
  }
`;

export default GET_RULES;
