import React from "react";
import { useQuery } from "@apollo/client";
import ALL_STORES from "graphql/Store/AllStores";
import styled from "styled-components/macro";
import Select from "components/Ui/Select";
import { formatFromMinorUnits, formatPrice } from "helpers/money";
import Input from "../../../components/Ui/Input";
import { MEDIA_MIN_LARGE } from "../../../variables/mediaQueries";

const OrderValue = styled.div`
  display: flex;
    flex-direction: column;
  width: 100%;
  max-width: 80rem;
  margin-right: 0.5rem;
    background-color: ${p => p.theme.colors.grey};

  p {
    margin: 0;
  }
    >div {
        margin-bottom: 1rem;
        ${MEDIA_MIN_LARGE} {
           margin-right: 1rem;
        }
    }
    ${MEDIA_MIN_LARGE} {
        flex-direction: row;
    }
`;

const OrderValueInput = styled(Input)`
  max-height: 4rem;
  background-color: white;
`;

const IncludeSelect = styled(Select)`
  background-color: ${(p) => p.theme.colors.primaryWhite};
  max-height: 4rem;
  margin: 0;
`;

export default ({ updateOrderFilter, orderFilter }) => {
  const { error, data } = useQuery(ALL_STORES, {
    variables: { from: 0, size: 300 },
  });
  const allStores = [];

  const handleChangeCurrencyUnit = (event) => {
    event.preventDefault();
    updateOrderFilter("valueRange", {
      ...orderFilter?.valueRange,
      currencyUnit: event.target.value,
    });
  };

  if (data && data.allStores && data.allStores.stores) {
    data.allStores.stores.forEach((store) => {
      if (!allStores.includes(store.currencyUnit)) allStores.push(store.currencyUnit);
    });
    allStores.sort();
  }

  if (error) return null;

  return (
    <>
      <OrderValue>
        <div>
          <p>Currency:</p>
          <IncludeSelect handleChange={handleChangeCurrencyUnit}>
            <option value={""}>Select currency</option>
            {allStores?.map((store) => (
              <option
                key={store}
                value={store}
                selected={store === orderFilter?.valueRange?.currencyUnit || ""}>
                {store}
              </option>
            ))}
          </IncludeSelect>
        </div>
        {orderFilter?.valueRange?.currencyUnit && (
          <>
            <div>
              <p>Min. order value:</p>
              <OrderValueInput
                type="text"
                pattern="[0-9]*"
                defaultValue={
                  orderFilter?.valueRange.from
                    ? formatFromMinorUnits(orderFilter.valueRange.from)
                    : undefined
                }
                id="orderValueMin"
                name="orderValueMin"
                onChange={(e) =>
                  updateOrderFilter("valueRange", {
                    ...orderFilter?.valueRange,
                    from: formatPrice(e.target.value),
                  })
                }
              />
            </div>
            <div>
              <p>Max. order value:</p>
              <OrderValueInput
                type="text"
                pattern="[0-9]*"
                id="orderValueMax"
                name="orderValueMax"
                defaultValue={
                  orderFilter?.valueRange.to
                    ? formatFromMinorUnits(orderFilter.valueRange.to)
                    : undefined
                }
                onChange={(e) =>
                  updateOrderFilter("valueRange", {
                    ...orderFilter?.valueRange,
                    to: formatPrice(e.target.value),
                  })
                }
              />
            </div>
          </>
        )}
      </OrderValue>
    </>
  );
};
