import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Button from "components/Ui/Button";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import Discounts from "components/Table/Rows";
import Discount from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Ui/Loader";

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};
`;

const Column35w = styled(Column)`
  width: 50%;
  padding-left: 1rem;

  ${MEDIA_MIN_LARGE} {
    width: 35%;
    padding-left: 2rem;
  }
`;

const Column25w = styled(Column)`
  width: 25%;
`;

const Column15w = styled(Column)`
  width: 15%;
`;

const Valid = styled(Column15w)`
  .fa-check-circle {
    color: green;
  }

  .fa-times-circle {
    color: red;
  }

  i {
    font-size: 1.8rem;
    margin-right: 0.8rem;

    ${MEDIA_MIN_LARGE} {
      font-size: 2.4rem;
    }
  }
`;

export default ({ data, loading, fetchMore, discountsPerPage, history, esbQuery }) => {
  const [currentSort, setCurrentSort] = useState("default");
  const [sortField, setSortField] = useState("reference");
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPageInput, setGoToPageInput] = useState("1");
  const [itemsPerPage, setItemsPerPage] = useState(discountsPerPage);
  const [discountsRules, setDiscountRules] = useState(data && data.searchRules.rules);
  const discountRulesTotalHits = data && data.searchRules.rules.length;

  useEffect(() => {
    const from = (currentPage - 1) * itemsPerPage;
    fetchMore({
      variables: { query: JSON.stringify(esbQuery.size(itemsPerPage).from(from).toJSON()) },
    })
      .then((result) => setDiscountRules(result.data.searchRules.rules))
      .catch((error) => {
        console.error(error);
      });
    setGoToPageInput(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, esbQuery]);

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";

    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  const getSortType = (field, sort) =>
    field === sortField ? sortTypes[sort].class : sortTypes.default.class;

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const toValue = (sortField, obj) => {
    if (Number.isInteger(obj[sortField]) || sortField === "active") {
      return obj[sortField];
    } else {
      return obj[sortField].toLowerCase();
    }
  };

  const handleDiscountRuleClick = (discountId) => {
    history.push(`/admin/discount-rule/${discountId}`);
  };

  const isActive = (rule) => {
    if (rule.active) {
      const currentDate = moment().startOf("day");
      if (rule.startDate && rule.endDate) {
        return currentDate.isBetween(moment(rule.startDate), moment(rule.endDate), undefined, "[]");
      } else if (rule.startDate) {
        return currentDate.isSameOrAfter(moment(rule.startDate));
      } else if (rule.endDate) {
        return currentDate.isSameOrBefore(moment(rule.endDate));
      }
    }
    return rule.active;
  };

  return (
    <>
      <Toolbar>
        <HoM>
          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={(e) => setCurrentPage(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of {discountRulesTotalHits ? Math.ceil(discountRulesTotalHits / itemsPerPage) : "-"}{" "}
            pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          <HoM>Total </HoM>discount rules: <span>{discountRulesTotalHits || "-"}</span>
        </TotalItems>
      </Toolbar>
      <TableHeader>
        <Column35w onClick={() => onSortChange("code")}>
          <span>Name </span>
          <SortButton>
            <i className={`fas fa-${getSortType("code", currentSort)}`} />
          </SortButton>
        </Column35w>
        <Valid onClick={() => onSortChange("active")}>
          <span>Active </span>
          <SortButton>
            <i className={`fas fa-${getSortType("active", currentSort)}`} />
          </SortButton>
        </Valid>
        <Column25w onClick={() => onSortChange("created")}>
          <span>Created </span>
          <SortButton>
            <i className={`fas fa-${getSortType("created", currentSort)}`} />
          </SortButton>
        </Column25w>
        <Column25w onClick={() => onSortChange("lastUpdated")}>
          <span>Last updated </span>
          <SortButton>
            <i className={`fas fa-${getSortType("lastUpdated", currentSort)}`} />
          </SortButton>
        </Column25w>
      </TableHeader>
      <Discounts>
        {loading && <Loader />}
        {discountsRules && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={discountRulesTotalHits}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {[...discountsRules].sort(sortTypes[currentSort].fn).map((discountRule) => (
              <Discount
                key={discountRule.id}
                onClick={() => handleDiscountRuleClick(discountRule.id)}>
                <Column35w>
                  <strong>{discountRule.name}</strong>
                </Column35w>
                {isActive(discountRule) ? (
                  <Valid>
                    <i className={"fal fa-check-circle"} />
                  </Valid>
                ) : (
                  <Valid>
                    <i className={"fal fa-times-circle"} />
                  </Valid>
                )}
                <Column25w>
                  <Moment format="YYYY-MM-DD, HH:mm">{discountRule.created}</Moment>
                </Column25w>
                <Column25w>
                  <Moment format="YYYY-MM-DD, HH:mm">{discountRule.lastUpdated}</Moment>
                </Column25w>
              </Discount>
            ))}
          </Pagination>
        )}
      </Discounts>
    </>
  );
};
