import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "../../variables/mediaQueries";

const Container = styled.div`
  padding: 1rem 1rem;
  display: inline-block;
  background: ${(p) => p.theme.colors.backgroundOpac};
  margin: 0.5rem;
  border-radius: 0.2rem;
  width: ${(p) => (p.columns ? `calc(100% / ${p.columns} - 2rem)` : "auto")};

  ${MEDIA_MIN_MEDIUM} {
    margin: 1rem;
    padding: 1rem 2rem;
  }
`;

export default ({ children, columns, onClick }) => (
  <Container onClick={onClick} columns={columns}>
    {children}
  </Container>
);
