import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";

import DELETE_WEBHOOK_CONFIGURATION from "graphql/Configuration/DeleteWebhookConfiguration";

import { useNotification } from "context/NotificationContext";

import DeleteButton from "components/ActionButtons/DeleteButton";
import Loader from "components/Ui/Loader";
import Dialog from "components/Dialog/Dialog";

export default ({ service, name, history }) => {
  const [open, setOpen] = useState(false);
  const { setNotification } = useNotification();

  return (
    <Mutation
      mutation={DELETE_WEBHOOK_CONFIGURATION}
      variables={{ service, name }}
      onCompleted={({ deleteWebhookConfiguration }) => {
        setNotification({
          status: "success",
          message: `Webhook '${deleteWebhookConfiguration.name}' successfully deleted`,
        });
        history.push(`/admin/webhook-${service}`);
      }}
      onError={() => {
        setNotification({
          status: "error",
          message: `Webhook '${name}' could not be deleted, please contact support`,
        });
        setOpen(false)
      }}>
      {(deleteWebhook, { loading }) => {
        if (loading) return <Loader />;
        return (
          <>
            <DeleteButton handleOnClick={() => setOpen(true)}>Delete</DeleteButton>
            <Dialog
              header="Delete webhook"
              text="Deleting the webhook will not be reversible, are you sure?"
              open={open}
              handleClose={() => setOpen(false)}
              handleOk={deleteWebhook}
            />
          </>
        );
      }}
    </Mutation>
  );
};
