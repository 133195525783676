import gql from "graphql-tag";

const SEARCH_DISCOUNT_CODES_ID = gql`
    query searchDiscounts($query: AWSJSON!) {
        searchDiscounts(query: $query) {
            discounts {
                id
            }
            totalHits
        }
    }
`;

export default SEARCH_DISCOUNT_CODES_ID;
