import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Money from "components/Money/Money";
import Address from "components/Order/Address";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import getFullCountryName from "helpers/getFullCountryName";

const StyledLink = styled.a`
    text-decoration: none;
    color: ${(p) => p.color || "black"};
    cursor: pointer;
    position: relative;

    &:focus,
    &:hover,
    &:visited {
        color: ${(p) => p.color || "grey"};
    }
`;

const ShippingLogo = styled.img`
    position: absolute;
    top: 3rem;
    right: 1.5rem;
    max-height: 3rem !important;
    max-width: 10rem !important;

    ${MEDIA_MIN_MEDIUM} {
        top: 3.5rem;
        right: 3rem;
        max-height: 4rem !important;
        max-width: 15rem !important;
    }
`;

const Method = styled(Value)`
    position: initial;
`;

export default ({ order, shipping }) => {
  let totalAmountWithDiscount = shipping?.totalAmountWithDiscount ?? 0;
  if (order.taxIncludedPricing === false) {
    totalAmountWithDiscount = totalAmountWithDiscount + shipping?.totalTaxAmount ?? 0;
  }

  const formatTaxRate = (taxRate = 0, taxRateDecimals = 0) => {
    const fixed = taxRateDecimals > 2 ? 2 : taxRateDecimals;
    const pow = Math.pow(10, taxRateDecimals);
    return (taxRate / pow).toFixed(fixed);
  };

  return (
    <>
      <GridItem columns="12">
        <Box preHeading="Order" heading="Shipping" headingIcon="shipping-fast">
          {shipping?.product?.imageUrl && (
            <ShippingLogo alt={shipping.name} src={shipping.product.imageUrl} />
          )}
          {order.shippingAddress && (
            <GridItem columns="6" mobilePadding="0" desktopPadding="0 1.5rem 0 0">
              <h5>Address:</h5>
              <Address address={order.shippingAddress} hideEmail hidePhone />
            </GridItem>
          )}

          <GridItem columns="6" mobilePadding="0" desktopPadding="0 0 0 1.5rem">
            <h5>Details:</h5>
            <Attributes>
              <Attribute>
                <Label>Shipping method:</Label>
                <Method>{shipping?.name}</Method>
              </Attribute>
              <Attribute>
                <Label>Shipping cost:</Label>
                <Value>
                  <Money amount={totalAmountWithDiscount} currencyUnit={order.currencyUnit} />
                </Value>
              </Attribute>
              <Attribute>
                <Label>Shipping tax:</Label>
                <Value>
                  <Money amount={shipping?.totalTaxAmount ?? 0} currencyUnit={order.currencyUnit} />
                </Value>
              </Attribute>
              <Attribute>
                <Label>Shipping tax rate:</Label>
                <Value>
                  {shipping?.externalTaxRate !== null
                    ? (shipping?.externalTaxRate * 100).toFixed(2)
                    : formatTaxRate(shipping?.taxRate, shipping?.taxRateDecimals)}{" "}
                  %
                </Value>
              </Attribute>
              {order.customerAttribute && order.customerAttribute.reason && (
                <Attribute>
                  <Label>Return reason:</Label>
                  <Value>{order.customerAttribute.reason.cause}</Value>
                </Attribute>
              )}
            </Attributes>
          </GridItem>
        </Box>
      </GridItem>

      {order.customerAttribute && order.customerAttribute.ingrid && (
        <GridContainer collapse>
          <Box heading="Ingrid details" headingIcon="boxes-packing" preHeading="Order">
            <GridItem columns={order.customerAttribute.ingrid?.address ? "6" : "12"} paddinng="0">
              <Attributes>
                <Attribute>
                  <Label>Id:</Label>
                  <Value>{order.customerAttribute.ingrid.id}</Value>
                </Attribute>
                <Attribute>
                  <Label>Transport Id:</Label>
                  <Value>{order.customerAttribute.ingrid.tos_id}</Value>
                </Attribute>
                <Attribute>
                  <Label>Method Id:</Label>
                  <Value>{order.customerAttribute.ingrid.external_method_id}</Value>
                </Attribute>
                <Attribute>
                  <Label>Delivery type:</Label>
                  <Value>{order.customerAttribute.ingrid.delivery_type}</Value>
                </Attribute>
              </Attributes>
            </GridItem>
            {order.customerAttribute.ingrid?.address && (
              <GridItem columns="6">
                <Attributes>
                  <Attribute>
                    <Label>Address:</Label>
                    <Value>{`${
                      order.customerAttribute.ingrid.address.name || ""
                    } ${order.customerAttribute.ingrid.address.address_lines.map(
                      (line) => line,
                    )}`}</Value>
                  </Attribute>
                  <Attribute>
                    <Label>Postal code:</Label>
                    <Value>{order.customerAttribute.ingrid.address.postal_code}</Value>
                  </Attribute>
                  <Attribute>
                    <Label>City:</Label>
                    <Value>{`${order.customerAttribute.ingrid.address.city}, ${getFullCountryName(
                      order.customerAttribute.ingrid.address.country,
                    )}`}</Value>
                  </Attribute>
                </Attributes>
              </GridItem>
            )}
          </Box>
        </GridContainer>
      )}

      {order.customerAttribute && order.customerAttribute.tracking && (
        <GridContainer collapse>
          <Box heading="Tracking details" headingIcon="location-arrow" preHeading="Order">
            <GridItem columns="12" paddinng="0">
              <Attributes>
                <Attribute>
                  <Label>Tracking number:</Label>
                  <Value>{order.customerAttribute.tracking.trackingNumber}</Value>
                </Attribute>
                <Attribute>
                  <Label>Tracking company:</Label>
                  <Value>{order.customerAttribute.tracking.trackingCompany}</Value>
                </Attribute>
                {order.customerAttribute.tracking.trackingUrl !== "" && (
                  <Attribute>
                    <Label>Tracking URL:</Label>
                    <Value>
                      <StyledLink
                        rel="noopener noreferrer"
                        href={`https://${order.customerAttribute.tracking.trackingUrl}`}
                        target="_blank">
                        {order.customerAttribute.tracking.trackingUrl}
                      </StyledLink>
                    </Value>
                  </Attribute>
                )}
              </Attributes>
            </GridItem>
          </Box>
        </GridContainer>
      )}
    </>
  );
};
