import gql from "graphql-tag";

const SEARCH_ORDERS = gql`
  query searchOrders($query: AWSJSON!) {
    searchOrders(query: $query) {
      orders {
        id
        reference
        status
        paymentProvider
        paymentReference
        paymentMethod
        paymentExpiresAt
        orderTaxAmount
        orderAmount
        orderAmountWithDiscount
        orderLines {
          id
          name
          quantity
          relatedOrderLine
          refundOfOrderLine
          taxRate
          taxRateDecimals
          externalTaxRate
          imageUrl
          totalAmount
          totalAmountWithDiscount
          totalTaxAmount
          price
          type
          product {
            id
            name
            type
            relatedProduct {
              id
            }
            imageUrl
          }
        }
        refundAmount
        currencyUnit
        created
        person {
          id
          email
        }
        lastUpdated
        taxIncludedPricing
        shippingAddress {
          country
          givenName
          familyName
          email
          city
          streetAddress
          postalCode
          region
          phone
        }
        customerAttribute
      }
      totalHits
    }
  }
`;

export default SEARCH_ORDERS;
