import gql from "graphql-tag";

const GET_RULE_TEMPLATES = gql`
  query getRuleTemplates($type: RuleType) {
    getRuleTemplates(type: $type) {
      id
      name
      type
      jsonSchema
      handlebarTemplate
    }
  }
`;

export default GET_RULE_TEMPLATES;
