import gql from "graphql-tag";

const GET_ORDER_INVOICE = gql`
  query getOrderInvoice($id: ID!) {
    getOrderInvoice(id: $id) {
      url
    }
  }
`;

export default GET_ORDER_INVOICE;
