import React from "react";
import styled from "styled-components/macro";
import FlagIcon from "components/Ui/FlagIcon";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import IconButton from "components/Ui/Buttons/IconButton";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import getFullCountryName from "helpers/getFullCountryName";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import { useAdminContext } from "context/AdminContext";

const EmailField = styled(Value)`
  position: initial;
  padding-right: 4rem;
`;

export default ({ billingAddress, personId, onEmailClick, linkButton, history }) => {
  const { customerTabs, setCustomerTabs } = useAdminContext();

  const handleCustomerClick = (id, reference) => {
    !customerTabs.find((item) => item.id === id) &&
      setCustomerTabs((prev) => [...prev, { id, reference }]);
    history.push(`/admin/customers/`);
  };

  return (
    <GridItem columns="12">
      <Box preHeading="Order" heading="Billing" headingIcon="user">
        {linkButton && (
          <ActionButtons inBox>
            <ActionButton
              handleOnClick={() => {
                handleCustomerClick(personId, billingAddress.familyName);
              }}>
              <i className="fa-light fa-arrow-up-right-from-square"></i>
              Customer
            </ActionButton>
          </ActionButtons>
        )}
        <GridItem columns="6" mobilePadding="0" desktopPadding="0 1.5rem 0 0">
          <h5>Address</h5>
          <Attributes>
            <Attribute>
              <Label>Name:</Label>
              <Value>
                {billingAddress.givenName} {billingAddress.familyName}
              </Value>
            </Attribute>
            <Attribute>
              <Label>Street address:</Label>
              <Value>{billingAddress.streetAddress}</Value>
            </Attribute>
            {billingAddress.houseNumberOrName && (
              <Attribute>
                <Label>House number or name:</Label>
                <Value>{billingAddress.houseNumberOrName}</Value>
              </Attribute>
            )}
            {billingAddress.postalCode && (
              <Attribute>
                <Label>Postal code:</Label>
                <Value>{billingAddress.postalCode}</Value>
              </Attribute>
            )}
            {billingAddress.region && (
              <Attribute>
                <Label>Region/state/province code:</Label>
                <Value>{billingAddress.region}</Value>
              </Attribute>
            )}
            <Attribute>
              <Label>City:</Label>
              <Value>{billingAddress.city}</Value>
            </Attribute>
            <Attribute>
              <Label>Country:</Label>
              <Value>
                <FlagIcon countryCode={billingAddress.country} />
                {getFullCountryName(billingAddress.country)}
              </Value>
            </Attribute>
          </Attributes>
        </GridItem>
        <GridItem columns="6" mobilePadding="0" desktopPadding="0 0 0 1.5rem">
          <h5>Details</h5>
          <Attributes>
            <Attribute>
              <Label>E-mail:</Label>
              <EmailField>
                {billingAddress.email}{" "}
                {onEmailClick && (
                  <IconButton onClick={onEmailClick}>
                    <i className="fal fa-pen" />
                  </IconButton>
                )}
              </EmailField>
            </Attribute>
            <Attribute>
              <Label>Phone number:</Label>
              <Value>{billingAddress.phone}</Value>
            </Attribute>
          </Attributes>
        </GridItem>
      </Box>
    </GridItem>
  );
};
