import React from "react";

const BogoOptions = ({ Row, discountInPercentageInput, categoriesInput, combineToggle }) => {
  return (
    <>
      <Row>
        {discountInPercentageInput}
        {categoriesInput}
      </Row>
      <Row>{combineToggle}</Row>
    </>
  );
};

export default BogoOptions;
