import React, { useState } from "react";
import styled from "styled-components/macro";
import { useForm, Controller } from "react-hook-form-old";
import { Mutation } from "@apollo/client/react/components";
import uuid from "react-uuid";

import ADD_OR_UPDATE_WEBHOOK_CONFIGURATION from "graphql/Configuration/AddOrUpdateWebhookConfiguration";

import { useNotification } from "context/NotificationContext";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Sidebar from "components/Configuration/Sidebar";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtonSecondary from "components/ActionButtons/ActionButtonSecondary";
import IconButton from "components/Ui/Buttons/IconButton";
import TableHeader from "components/Table/Header";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import Input from "components/Ui/Input";

const Webhook = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 6rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }

  h5 {
    font-weight: 400;
    text-transform: uppercase;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    padding: 0 0 2rem;
    width: 100%;
    margin: 3rem 0 0;
  }
`;

const Fieldset = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div {
    width: 100%;
  }

  ${MEDIA_MIN_LARGE} {
    > div {
      width: 49%;
    }
  }
`;

const Headers = styled.div`
  width: 100%;
  position: relative;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  border-top: none;

  > div {
    width: 47%;
    margin: 0;
  }

  ${MEDIA_MIN_LARGE} {
    padding: 2rem;
  }
`;

const AddHeaderButton = styled(ActionButtonSecondary)`
  position: absolute;
  top: 0.7rem;
  right: 0;
`;

const Padding = styled.div`
  width: 4rem !important;
  padding: 0;
`;

const NameField = styled.div`
  position: relative;

  input {
    padding-left: 12rem;

    ${MEDIA_MIN_LARGE} {
      padding-left: 13.6rem;
    }
  }
`;

const FixedValue = styled.div`
  position: absolute;
  left: 2rem;
  top: 4.2rem;
  color: ${(p) => p.theme.colors.darkerGrey};

  ${MEDIA_MIN_LARGE} {
    top: 4.4rem;
  }
`;

export default ({ history }) => {
  const { control, handleSubmit, errors } = useForm();
  const { setNotification } = useNotification();
  const [headers, setHeaders] = useState({});

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/configurations", "Configurations"],
          ["admin/webhook-order", "Order webhooks"],
          ["admin/add-order-webhook", "Add webhook"],
        ]}
      />
      <Header heading="Configurations" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Mutation
            mutation={ADD_OR_UPDATE_WEBHOOK_CONFIGURATION}
            onCompleted={() => {
              setNotification({
                status: "success",
                message: "Configuration successfully saved",
              });
              history.push(`/admin/webhook-order`);
            }}
            onError={() => {
              setNotification({
                status: "error",
                message: "Could not save configuration. Please contact support",
              });
            }}>
            {(addOrUpdateWebhookConfigurations, { loading: updateLoading }) => {
              const onSubmit = (data) => {
                const headerObject = {};
                data.headers &&
                  Object.keys(data.headers).forEach(
                    (key) => (headerObject[data.headers[key].key] = data.headers[key].value)
                  );

                addOrUpdateWebhookConfigurations({
                  variables: {
                    name: data.name,
                    type: "order",
                    data: {
                      url: data.url,
                      headers: JSON.stringify(headerObject),
                    },
                  },
                }).catch((e) => {
                  console.log(e);
                });
              };

              return (
                <GridItem columns="12">
                  <Box
                    preHeading="Webhooks"
                    heading="Add order webhook"
                    showGoBackButton
                    goBackOnClick={() => history.push("/admin/webhook-order")}>
                    {updateLoading && <Loader />}
                    <Webhook onSubmit={handleSubmit(onSubmit)}>
                      <ActionButtons inBox footerOnMobile>
                        <ActionButton type="submit">
                          <i className="fal fa-fw fa-check" /> Save
                        </ActionButton>
                      </ActionButtons>
                      <Fieldset>
                        <NameField>
                          <Controller
                            as={Input}
                            control={control}
                            type="text"
                            rules={{
                              required: "This is a required field",
                            }}
                            name="name"
                            defaultValue=""
                            errors={errors}
                            label="Name"
                          />
                          <FixedValue>webhook.order.</FixedValue>
                        </NameField>
                        <Controller
                          as={Input}
                          control={control}
                          type="text"
                          rules={{
                            required: "This is a required field",
                          }}
                          name="url"
                          defaultValue=""
                          errors={errors}
                          label="Webhook URL"
                        />
                      </Fieldset>

                      <Headers>
                        <h5>Headers</h5>
                        <AddHeaderButton
                          type="button"
                          handleOnClick={() => {
                            setHeaders((prev) => ({
                              ...prev,
                              [`additional-${uuid()}`]: "",
                            }));
                          }}>
                          <i className="fal fa-fw fa-plus" /> Add
                        </AddHeaderButton>
                        <TableHeader>
                          <div>Key</div>
                          <div>Value</div>
                          <Padding />
                        </TableHeader>
                        {Object.keys(headers)
                          .reverse()
                          .filter((key) => headers[key] !== undefined)
                          .map((key) => {
                            return (
                              <HeaderRow key={key}>
                                <Controller
                                  as={Input}
                                  control={control}
                                  type="text"
                                  rules={{
                                    required: "This is a required field",
                                  }}
                                  name={`headers.${key}.key`}
                                  defaultValue=""
                                  errors={errors}
                                  relativeError
                                />
                                <Controller
                                  as={Input}
                                  control={control}
                                  type="text"
                                  rules={{
                                    required: "This is a required field",
                                  }}
                                  name={`headers.${key}.value`}
                                  defaultValue=""
                                  errors={errors}
                                  relativeError
                                />
                                <IconButton
                                  handleOnClick={() => {
                                    setHeaders((prev) => ({
                                      ...prev,
                                      [key]: undefined,
                                    }));
                                  }}>
                                  <i className="fal fa-fw fa-trash-alt" />
                                </IconButton>
                              </HeaderRow>
                            );
                          })}
                      </Headers>
                    </Webhook>
                  </Box>
                </GridItem>
              );
            }}
          </Mutation>
        </GridContainer>
      </PageContainer>
    </>
  );
};
