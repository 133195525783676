import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { sidebarWidth } from "variables/general";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import Logo from "../Ui/Logo";
import ThemeToggle from "../Ui/ThemeToggle";
import { isMobile } from "helpers/getViewport";
import isTestEnvironment from "helpers/isTestEnvironment"

const Container = styled.div`
  width: 100%;
  max-width: 96%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: ${(p) => p.theme.constants.background};
  background: linear-gradient(0deg,
  ${(p) => p.theme.constants.background} 0%,
  ${(p) => p.theme.constants.secondaryBackground} 100%);
  display: flex;
  flex-wrap: wrap;
  transition: all 0.4s;
  transform: ${(p) => (p.expanded ? "translate(-100%, 0)" : "translate(0, 0)")};
  z-index: 10001;
  padding-bottom: 15rem;
  padding-top: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    transform: translate(0, 0);
    padding-top: 0;
    max-width: ${sidebarWidth};
  }
`;

const SidebarLogo = styled(Logo)`
  max-width: 100%;
  padding: 1.5rem;
  position: fixed;
  top: 0;
`;

const ToggleWrapper = styled.div`
  position: absolute;
  right: ${(p) => (p.expanded ? "-4.5rem" : "-1.5rem")};
  top: ${(p) => (p.expanded ? "-3rem" : "0")};
  width: 4rem;
  height: 12rem;
  border-radius: 2rem;
  background-image: ${(p) => (p.expanded ? "none" : `url(${p.theme.curve})`)};
  background-repeat: no-repeat;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;

  ${MEDIA_MIN_MEDIUM} {
    display: none;
  }
`;

const Toggle = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.constants.white};
  color: ${(p) => p.theme.constants.black};
  cursor: pointer;
  padding: 0.1rem 0 0 0.2rem;
  box-shadow: 0 0.2rem 0.8rem 0 rgb(0 0 0 / 15%);

  .fa-angle-left {
    transition: transform 0.2s;
    transform: scale(1, -1);
    display: ${(p) => p.expanded && "none"};
    font-size: 2.4rem;
    padding: 0.4rem 0.2rem 0 0;
  }

  .fa-bars {
    display: ${(p) => !p.expanded && "none"};
    font-size: 1.6rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    display: none;
  }
`;

const Header = styled.div`
  display: none;

  ${MEDIA_MIN_MEDIUM} {
    width: 100%;
    display: flex;

  }
`;

const Body = styled.div`
  align-self: flex-start;
  width: 100%;
  margin-top: 8rem;
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
`;

const MenuItem = styled.li`
  position: relative;
  display: flex;
  justify-content: center;

  a {
    width: 80%;
    height: 8rem;
    color: ${(p) => (p.active ? (p) => p.theme.colors.buttonText : "white")};
    cursor: pointer;
    display: flex;
    align-items: center;
    letter-spacing: 0.05rem;
    font-size: 1.5rem;
    background-color: ${(p) => (p.active ? (p) => p.theme.colors.primaryGradient : "transparent")};
    flex-shrink: 0;
    font-weight: ${(p) => (p.active ? "700" : "400")};
    border-radius: 0.5rem;

    ${MEDIA_MIN_MEDIUM} {
      padding: 1rem;
      font-size: 1.2rem;
      width: 10rem;
    }
  }

  i {
    font-size: 2.2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  &:hover {
    a {
      background: ${(p) =>
              p.active ? (p) => p.theme.colors.primaryGradient : "rgba(255, 255, 255, 0.1)"};
    }
  }
`;

const Test = styled.p`
  color: ${p => p.theme.colors.red};
  position: absolute;
  right: 0.8rem;
  top: 0.2rem;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
`

const Divider = styled.hr`
  height: 0.1rem;
  border: none;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg,
  rgba(255, 255, 255, 0) 0%,
  rgba(255, 255, 255, 1) 50%,
  rgba(255, 255, 255, 0) 100%);
  margin: 1rem 2rem 0;
  display: block;
  opacity: 0.2;

  ${MEDIA_MIN_MEDIUM} {
    margin: 3rem 2rem 0;
  }
`;

const MainMenu = styled(Menu)``;

const MenuNavlink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MainMenuItem = styled(MenuItem)``;

const SecondaryMenu = styled(Menu)``;

export default ({sidebarExpanded, setSidebarExpanded, routes, activeRoute, ...props}) => {
  const visibleRoutes = routes.filter((route) => route.visible !== false);

  return (
    <Container expanded={sidebarExpanded} {...props}>
      <ToggleWrapper expanded={sidebarExpanded}>
        <Toggle expanded={sidebarExpanded} onClick={() => setSidebarExpanded(!sidebarExpanded)}>
          <i className="fal fa-bars"></i>
          <i className="fal fa-angle-left"></i>
        </Toggle>
      </ToggleWrapper>
      <Header>
        <SidebarLogo/>
        {isTestEnvironment() && <Test>test</Test>}
      </Header>
      <Body>
        <MainMenu>
          {visibleRoutes &&
            visibleRoutes.map((route) => (
              <MainMenuItem
                key={route.path}
                onClick={() => isMobile() && setSidebarExpanded(true)}
                active={
                  activeRoute.path === route.path ||
                  (activeRoute.parentPath && activeRoute.parentPath === route.path)
                }>
                <MenuNavlink to={route.layout + route.path}>
                  <i className={route.icon}></i>
                  <span>{route.name}</span>
                </MenuNavlink>
              </MainMenuItem>
            ))}
        </MainMenu>
        <Divider/>
        <SecondaryMenu>
          <MainMenuItem
            expanded={sidebarExpanded}
            onClick={() => isMobile() && setSidebarExpanded(true)}
            active={
              activeRoute.path === "/configurations" || activeRoute.parentPath === "/configurations"
            }>
            <MenuNavlink to="/admin/configurations">
              <i className="fa-thin fa-gear"></i>
              <span>Configurations</span>
            </MenuNavlink>
          </MainMenuItem>
        </SecondaryMenu>
      </Body>
      <ThemeToggle expanded={sidebarExpanded}/>
    </Container>
  );
};
