import gql from "graphql-tag";

const CANCEL_ORDER = gql`
  mutation CancelOrder($id: ID!, $reason: InputReason) {
    cancelOrder(id: $id,  reason: $reason) {
      id
      status
      statusLog {
        status
        timestamp
      }
    }
  }
`;

export default CANCEL_ORDER;
