import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import DELETE_STORE from "graphql/Store/DeleteStore";
import { useNotification } from "context/NotificationContext";
import DeleteButton from "components/ActionButtons/DeleteButton";
import Loader from "components/Ui/Loader";
import Dialog from "components/Dialog/Dialog";
import getFullCountryName from "helpers/getFullCountryName";

export default ({ countryCode, languageCode, history }) => {
  const [open, setOpen] = useState(false);
  const { setNotification } = useNotification();

  const gotoRules = () => {
    history.push({
      pathname: "/admin/stores",
    });
  };

  return (
    <Mutation
      mutation={DELETE_STORE}
      variables={{ countryCode, languageCode}}
      onCompleted={({ deleteStore }) => {
        setNotification({
          status: "success",
          message: `Store "${getFullCountryName(countryCode)}" successfully deleted`,
        });
        setOpen(false);
        setTimeout(gotoRules, 500);
      }}
      onError={() => {
        setNotification({
          status: "error",
          message: `Store ${getFullCountryName(countryCode)} could not be deleted, please contact support`,
        });
      }}>
      {(deleteStore, { loading }) => {
        if (loading) return <Loader />;

        return (
          <>
            <DeleteButton handleOnClick={() => setOpen(true)}>Delete</DeleteButton>
            <Dialog
              header="Delete store"
              text={`Delete store ${getFullCountryName(countryCode)}?`}
              open={open}
              handleClose={() => setOpen(false)}
              handleOk={deleteStore}
            />
          </>
        );
      }}
    </Mutation>
  );
};
