import React from "react";
import styled from "styled-components/macro";
import uuid from "react-uuid";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0 2rem;
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    justify-content: flex-start;
  }
`;

const PaginationButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.6rem;
  height: 2.6rem;
  background: none;
  cursor: pointer;
  padding: 0 0 0 0.1rem;
  color: ${(p) => p.theme.colors.white};
  border-radius: 1.3rem;
  border: 0.1rem solid transparent;
  transition: border 0.2s;
  margin: 0 0.5rem;

  ${MEDIA_MIN_MEDIUM} {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;

    &:hover {
      border: 0.1rem solid ${(p) => p.theme.colors.opac1};
    }
  }
`;

const ArrowButton = styled(PaginationButton)`
  ${(p) =>
    p.disabled &&
    `
  pointer-events: none;
  opacity: 0.5;

  `}
`;

const NumberButton = styled(PaginationButton)`
  pointer-events: ${(p) => (p.active ? "none" : "all")};
  background: ${(p) => (p.active ? (p) => p.theme.colors.primaryGradient : "transparent")};
  color: ${(p) => p.active && p.theme.colors.buttonText};
  font-weight: ${(p) => (p.active ? "700" : "400")};
`;

const PageNumbers = styled.div`
  margin: 0;
  display: flex;
  align-items: center;

  > div {
    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }

    ${MEDIA_MIN_MEDIUM} {
      &:nth-child(3),
      &:nth-child(4) {
        display: inline-flex;
      }
    }
  }
`;

const Elipsis = styled.span`
  width: 3rem;
  margin: 0;
  text-align: center;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 1rem;
  }
`;

const Pagination = ({ children, itemsPerPage, totalItems, currentPage, setCurrentPage }) => {
  const numberOfPages = Math.ceil(totalItems / itemsPerPage);

  const LeftButton = () => {
    const pageNumber =
      currentPage < 5 ? 2 : currentPage > numberOfPages - 2 ? numberOfPages - 3 : currentPage - 1;
    return (
      <NumberButton
        type="button"
        active={pageNumber === currentPage}
        onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </NumberButton>
    );
  };

  const MiddleButton = () => {
    const pageNumber =
      currentPage < 5 ? 3 : currentPage > numberOfPages - 2 ? numberOfPages - 2 : currentPage;

    return (
      <NumberButton
        type="button"
        active={pageNumber === currentPage}
        onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </NumberButton>
    );
  };

  const RightButton = () => {
    const pageNumber =
      currentPage < 5 ? 4 : currentPage > numberOfPages - 2 ? numberOfPages - 1 : currentPage + 1;

    return (
      <NumberButton
        selector=".top"
        type="button"
        active={pageNumber === currentPage}
        onClick={() => setCurrentPage(pageNumber)}>
        {pageNumber}
      </NumberButton>
    );
  };

  return (
    <>
      {children.map((item) => (
        <React.Fragment key={uuid()}>{item}</React.Fragment>
      ))}
      <Container>
        <ArrowButton disabled={currentPage === 1} onClick={() => setCurrentPage(1)}>
          <i className="fal fa-chevron-double-left"></i>
        </ArrowButton>

        <ArrowButton disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
          <i className="fal fa-chevron-left"></i>
        </ArrowButton>

        <PageNumbers>
          <NumberButton type="button" active={currentPage === 1} onClick={() => setCurrentPage(1)}>
            1
          </NumberButton>

          {currentPage > 4 && numberOfPages > 5 && <Elipsis>...</Elipsis>}

          {numberOfPages > 1 && <LeftButton />}
          {numberOfPages > 2 && <MiddleButton />}
          {numberOfPages > 3 && <RightButton />}

          {currentPage < numberOfPages - 2 && numberOfPages > 5 && <Elipsis>...</Elipsis>}

          {numberOfPages > 4 && (
            <NumberButton
              type="button"
              active={currentPage === numberOfPages}
              onClick={() => setCurrentPage(numberOfPages)}>
              {numberOfPages}
            </NumberButton>
          )}
        </PageNumbers>

        <ArrowButton
          disabled={currentPage === numberOfPages}
          onClick={() => setCurrentPage(currentPage + 1)}>
          <i className="fal fa-chevron-right"></i>
        </ArrowButton>

        <ArrowButton
          disabled={currentPage === numberOfPages}
          onClick={() => setCurrentPage(numberOfPages)}>
          <i className="fal fa-chevron-double-right"></i>
        </ArrowButton>
      </Container>
    </>
  );
};

export default Pagination;
