import gql from "graphql-tag";

const SEARCH_ACTIVE_DISCOUNT_CODES = gql`
  query searchDiscounts($query: AWSJSON!) {
    searchDiscounts(query: $query) {
      totalHits
    }
  }
`;

export default SEARCH_ACTIVE_DISCOUNT_CODES;
