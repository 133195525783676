import React from "react";
import UserAuth0 from "./UserAuth0";
import UserOkta from "./UserOkta";
import { providers, configuredAuthProvider } from "helpers/authProvider";

export default React.memo(({ simplified, props }) => {
  return (
    <>
      {configuredAuthProvider === providers.AUTH0 && (
        <UserAuth0 simplified={simplified} props={props} />
      )}
      {configuredAuthProvider === providers.OKTA && (
        <UserOkta simplified={simplified} props={props} />
      )}
    </>
  );
});
