import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import Moment from "react-moment";
import DiscountOrders from "components/Discount/DiscountOrders";
import GET_DISCOUNT_CODE from "graphql/Discount/DiscountCode/GetDiscountCode";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import FlagIcon from "components/Ui/FlagIcon";
import Sidebar from "components/Discount/Sidebar";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import isDiscountCodeActive from "components/Discount/Helpers/isDiscountCodeActive";
import { Currency } from "helpers/money";
import getFullCountryName from "helpers/getFullCountryName";
import FilterProducts from "../DiscountCode/FilterProducts";

const CodeValue = styled(Value)`
  justify-content: flex-end;
`;

const TypeValue = styled(CodeValue)`
  text-transform: capitalize;
`;

const CodeAttributes = styled(Attributes)`
  margin: -1.5rem 0 2rem;
  display: flex;
  flex-wrap: wrap;
`;

const Valid = styled.div`
  font-size: 2rem;

  .fa-check-circle {
    color: green;
  }

  .fa-times-circle {
    color: red;
  }
`;

const ExpandButton = styled.i`
  margin-left: 0.8rem;
  transition: font-weight 0.6s;
  &:hover {
    cursor: pointer;
    font-weight: 700;
  }
`;

export default (props) => {
  const [discountCode, setDiscountCode] = useState("");
  const [id] = useState(props.match.params.id);
  const [showStores, setShowStores] = useState(false);
  const [showDiscountInFixedPrice, setShowDiscountInFixedPrice] = useState(false);

  const toCurrencyAmount = (price) => {
    const Amount = Currency(price.currencyUnit);
    return Amount(price.amount).toFormat("$0,0");
  };

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/vouchers", "vouchers"],
          [`admin/voucher/${id}`, `${discountCode}`],
        ]}
      />
      <Header heading="Vouchers" />
      <PageContainer>
        <Sidebar />
        <GridContainer expanded>
          <Query
            query={GET_DISCOUNT_CODE}
            variables={{ id }}
            onCompleted={(data) => {
              setDiscountCode(data.getDiscount.code);
            }}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );
              const discount = data.getDiscount;
              return (
                discount && (
                  <>
                    <GridItem columns="12">
                      <Box
                        preHeading="Voucher"
                        heading={discount.code}
                        subHeading={discount.id}
                        showGoBackButton
                        goBackOnClick={() => props.history.push("/admin/vouchers")}>
                        <CodeAttributes>
                          <GridItem mobilePadding="0" desktopPadding="0 1.5rem 0 0" columns="6">
                            <Attribute>
                              <Label>Active:</Label>
                              <CodeValue>
                                {isDiscountCodeActive(
                                  discount.startDate,
                                  discount.endDate,
                                  discount.usageCount,
                                  discount.usageLimit
                                ) ? (
                                  <Valid>
                                    <i className={"fal fa-check-circle"} />
                                  </Valid>
                                ) : (
                                  <Valid>
                                    <i className={"fal fa-times-circle"} />
                                  </Valid>
                                )}
                              </CodeValue>
                            </Attribute>
                            <Attribute>
                              <Label>Discount code:</Label>
                              <CodeValue>{discount.code}</CodeValue>
                            </Attribute>
                            <Attribute>
                              <Label>Type:</Label>
                              <TypeValue>{discount.type}</TypeValue>
                            </Attribute>
                            <Attribute>
                              <Label>Group:</Label>
                              <TypeValue>{discount.group}</TypeValue>
                            </Attribute>
                            <Attribute>
                              <Label>Valid from:</Label>
                              <CodeValue>{discount.startDate}</CodeValue>
                            </Attribute>
                            <Attribute>
                              <Label>
                                {discount.discountInFixedPrice
                                  ? "Discount in fixed price:"
                                  : "Discount in percentage:"}
                              </Label>
                              {discount.discountInPercentage ? (
                                <CodeValue>{`${discount.discountInPercentage}%`}</CodeValue>
                              ) : (
                                <>
                                  <CodeValue>{discount.discountInFixedPrice.length}</CodeValue>
                                  <ExpandButton
                                    onClick={() =>
                                      setShowDiscountInFixedPrice(!showDiscountInFixedPrice)
                                    }
                                    className={
                                      showDiscountInFixedPrice
                                        ? "fal fa-chevron-up"
                                        : "fal fa-chevron-down"
                                    }
                                  />
                                </>
                              )}
                            </Attribute>
                            {discount.discountInFixedPrice &&
                              showDiscountInFixedPrice &&
                              discount.discountInFixedPrice.map((price) => (
                                <Attribute key={price.currencyUnit}>
                                  <Label>{price.currencyUnit}</Label>
                                  <CodeValue>{toCurrencyAmount(price)}</CodeValue>
                                </Attribute>
                              ))}
                          </GridItem>
                          <GridItem mobilePadding="0" desktopPadding="0 0 0 1.5rem" columns="6">
                            <Attribute>
                              <Label>Created:</Label>
                              <CodeValue>
                                <Moment format="YYYY-MM-DD HH:mm">{discount.created}</Moment>
                              </CodeValue>
                            </Attribute>
                            <Attribute>
                              <Label>Last updated:</Label>
                              <CodeValue>
                                <Moment format="YYYY-MM-DD HH:mm">{discount.lastUpdated}</Moment>
                              </CodeValue>
                            </Attribute>
                            <Attribute>
                              <Label>Usage count:</Label>
                              <CodeValue>{discount.usageCount}</CodeValue>
                            </Attribute>
                            <Attribute>
                              <Label>Valid to:</Label>
                              <CodeValue>{discount.endDate}</CodeValue>
                            </Attribute>
                            <Attribute>
                              <Label>Stores:</Label>
                              <CodeValue>{discount.stores.length}</CodeValue>
                              <ExpandButton
                                onClick={() => setShowStores(!showStores)}
                                className={showStores ? "fal fa-chevron-up" : "fal fa-chevron-down"}
                              />
                            </Attribute>
                            {showStores &&
                              discount.stores.map((store) => (
                                <Attribute>
                                  <FlagIcon countryCode={store.countryCode} />
                                  <CodeValue>{getFullCountryName(store.countryCode)}</CodeValue>
                                </Attribute>
                              ))}
                            {discount?.minItems && (
                              <Attribute>
                                <Label>Min items</Label>
                                <CodeValue>{discount?.minItems}</CodeValue>
                              </Attribute>
                            )}
                          </GridItem>
                        </CodeAttributes>
                      </Box>
                    </GridItem>
                    {discount?.filterRules?.length > 0 && (
                      <FilterProducts
                        preHeading="Voucher"
                        heading="Products"
                        subHeading="Voucher applicable on products below"
                        filterRules={discount.filterRules}
                      />
                    )}
                  </>
                )
              );
            }}
          </Query>
          <DiscountOrders discountId={id} discountCode={discountCode} history={props.history} />
        </GridContainer>
      </PageContainer>
    </>
  );
};
