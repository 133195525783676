import gql from "graphql-tag";

const GET_CONFIGURATIONS = gql`
  query getConfigurations {
    fortnox: getConfiguration(service: "fortnox") {
      name
      service
      data
    }
    klaviyo: getConfiguration(service: "klaviyo") {
      name
      service
      data
    }
    brink: getConfiguration(service: "brink") {
      name
      service
      data
    }
  }
`;

export default GET_CONFIGURATIONS;
