import gql from "graphql-tag";

const PRODUCT_AGGREGATES = gql`
  query productAggregates($query: AWSJSON!) {
    productAggregates(query: $query) {
      aggregations
    }
  }
`;

export default PRODUCT_AGGREGATES;
