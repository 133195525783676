import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  ${MEDIA_MIN_MEDIUM} {
    padding: ${(p) => p.padding || "1.5rem"};
  }
`;

export default ({ padding, collapse, ...props }) => (
  <Container padding={padding} collapse={collapse} {...props}></Container>
);
