import gql from "graphql-tag";

const GROUP_VOUCHERS = gql`
  query discountAggregates($query: AWSJSON!) {
    discountAggregates(query: $query) {
      aggregations
    }
  }
`;

export default GROUP_VOUCHERS;
