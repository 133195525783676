import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import esb from "elastic-builder";
import { useAdminContext } from "context/AdminContext";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import SearchForm from "components/Table/Search/SearchForm";
import QuickFilter from "components/Table/Filter/QuickFilter";
import QuickFilterOption from "components/Table/Filter/QuickFilterOption";
import ClearButton from "components/Table/Filter/ClearButton";
import StoreSelector from "components/StoreSelector/StoreSelector";
import FilterButton from "components/Ui/FilterButton";
import OrderValueRange from "./Filter/OrderValueRange";
import ShippingSelector from "./Filter/ShippingSelector";
import { getFilterQuery } from "./Filter/getFilterQuery";
import Button from "../../components/Ui/Button";
import TimeSpan from "./Filter/TimeSpan";

const Container = styled.div`
    width: 100%;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
`;

const FilterForm = styled.form`
    display: flex;
    flex-direction: column;
`

const Filter = styled.div`
    background: ${(p) => p.theme.colors.background};
    border-radius: 0.6rem;
    padding: 1rem 2rem;
    border: 0.1rem solid ${(p) => p.theme.colors.backgroundOpac};
    margin-bottom: 1rem;

    :hover {
        transition: background 0.2s ease-in;
        background: ${(p) => (p.expanded ? (p) => p.theme.colors.background : p.theme.colors.greyOpac)};
    }
`;

const FilterHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;

    i {
        transition: transform 0.2s;
        transform: rotate(${(p) => (p.$expanded ? "180deg" : "0deg")});
    }
`;

const FilterDropdown = styled.div`
    border-top: 0.1rem solid ${(p) => p.theme.colors.greyOpac};
`;
const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    gap: 1.5rem;
    width: 100%;
    padding: 0 0 2rem;
    margin-top: 1rem;
    min-height: 4rem;

    ${MEDIA_MIN_LARGE} {
        padding: 0;
        margin: 0 0 2rem;
        justify-content: center;
    }

    button {
        margin: 0 !important;
    }
`;
const Heading = styled.span`
    font-size: 1.4rem;
    font-weight: 700;
    display: flex;
    align-items: center;

    i {
        font-size: 2rem;
        color: ${(p) => (p.$filterActive ? p.theme.colors.primaryDarker : p.theme.colors.white)};
    }

    i {
        transform: none;
        margin-right: 1rem;
    }
`;

const HeadingClearButton = styled(Button)`
    height: 3rem;
    width: 6rem;
    margin-left: 1rem;
`;

const SelectWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 80rem;
    margin-bottom: 2rem;

    ${MEDIA_MIN_LARGE} {
        flex-direction: row;
        margin-bottom: 0;
    }
`;

const StoreSelect = styled(StoreSelector)`
    margin: 0 1rem;

    .multi-select {
        .dropdown-heading {
            height: 4rem;
        }
    }
`;

const FILTER_STATUSES = [
  { value: "failed", label: "Failed" },
  { value: "canceled", label: "Canceled" },
  { value: "waiting", label: "Waiting" },
];

export default () => {
  const {
    orderFollowUpFilter,
    updateOrderFollowUpFilter,
    clearOrderFollowUpFilter,
    orderFollowUpTableSettings,
  } = useAdminContext();


  const [inputError, setInputError] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [appliedOrderFilter, setAppliedOrderFilter] = useState(orderFollowUpFilter)

  const searchAndFilterOnReference = (query) => {
    updateOrderFollowUpFilter(
      "esbQuery",
      esb.requestBodySearch()
        .query(query.boolQuery.mustNot([esb.matchQuery("statusLog.status", "success")]).must(esb.queryStringQuery(searchInput.trim()).fuzziness("AUTO").fields(["reference"])))
        .sort(esb.sort("lastUpdated", "desc"))
        .size(orderFollowUpTableSettings.ordersPerPage)
        .from(0)
        .trackTotalHits(true),
      query.filterActive
    )
  }

  const searchAndFilterOnInput = (query) => {
    updateOrderFollowUpFilter(
      "esbQuery",
      esb.requestBodySearch()
        .query(query.boolQuery.mustNot(esb.matchQuery("statusLog.status", "success")).must(esb.queryStringQuery(formatInput(searchInput)).analyzeWildcard()))
        .sort(esb.sort("lastUpdated", "desc"))
        .size(orderFollowUpTableSettings.ordersPerPage)
        .from(0)
        .trackTotalHits(true),
      query.filterActive
    );
  }

  const filterWithoutSearch = (query) => {
    updateOrderFollowUpFilter(
      "esbQuery",
      esb.requestBodySearch()
        .query(query.boolQuery.mustNot([esb.matchQuery("statusLog.status", "success")]))
        .sort(esb.sort("lastUpdated", "desc"))
        .size(orderFollowUpTableSettings.ordersPerPage)
        .from(0)
        .trackTotalHits(true),
      query.filterActive
    );
  }

  const searchAndFilterOrders = (event) => {
    event.preventDefault();
    searchInput.length ? setInputError(false) : setInputError(true);
    const query = getFilterQuery(orderFollowUpFilter)

    console.log(query)
    if (/^\d+$/.test(searchInput.trim())) {
      searchAndFilterOnReference(query)
    } else if (searchInput && searchInput.replace(/\s/g, "") !== "") {
      searchAndFilterOnInput(query)
    } else {
      setSearchInput("");
      filterWithoutSearch(query)
    }
    setAppliedOrderFilter(orderFollowUpFilter)
  };

  const formatInput = (input) => {
    if (/^([0-9a-fA-F]{8})-(([0-9a-fA-F]{4}-){3})([0-9a-fA-F]{12})$/.test(input)) {
      return `"${input}"`;
    }
    return input.replace("@", "*");
  };


  const clearFilter = (event) => {
    setExpanded(false);
    event && event.preventDefault();
    event && event.stopPropagation();
    clearFilterInput();
  };

  const clearFilterInput = () => clearOrderFollowUpFilter();

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    searchAndFilterOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <SearchForm
        autoComplete="off"
        placeholder="Order number, e-mail, name..., (Use * as wildcard)"
        inputError={inputError}
        setInputError={setInputError}
        setSearchInput={setSearchInput}
        searchFunc={searchAndFilterOrders}
        defaultValue={searchInput}
      />
      <Filter expanded={expanded}>
        <FilterHeader onClick={() => setExpanded(!expanded)} $expanded={expanded}>
          <Heading $filterActive={!!orderFollowUpFilter?.filterActive}>
            <i className="fa-light fa-sliders"></i>All filters{" "}
            {orderFollowUpFilter?.filterActive ? `(${Object.keys(appliedOrderFilter).length - 2})` : ""}
            {orderFollowUpFilter?.filterActive && (
              <HeadingClearButton onClick={clearFilter}>Clear</HeadingClearButton>
            )}
          </Heading>

          <i className="fa-regular fa-chevron-down"></i>
        </FilterHeader>
        {expanded && (
          <FilterDropdown>
            <QuickFilter expanded="false">
              <div>
                {FILTER_STATUSES.map((status) => (
                  <QuickFilterOption
                    key={status.value}
                    active={orderFollowUpFilter?.filterStatus?.includes(status.value)}
                    onClick={() => {
                      updateOrderFollowUpFilter(
                        "filterStatus",
                        orderFollowUpFilter?.filterStatus?.includes(status.value)
                          ? orderFollowUpFilter?.filterStatus?.filter((value) => value !== status.value)
                          : orderFollowUpFilter?.filterStatus
                            ? [...orderFollowUpFilter?.filterStatus, status.value]
                            : [status.value]
                      );
                    }}>
                    {status.label}
                  </QuickFilterOption>
                ))}
              </div>
            </QuickFilter>
            <FilterForm>
              <SelectWrapper>
                  <StoreSelect
                    showContinents
                    dropDownTop="4rem"
                    small
                    selectedStores={orderFollowUpFilter.filterStores ?? []}
                    setSelectedStores={(value) => updateOrderFollowUpFilter("filterStores", value)}
                    inOrderFilter
                  />
                <ShippingSelector
                  label={"Select shipping methods"}
                  updateOrderFilter={updateOrderFollowUpFilter}
                  orderFilter={orderFollowUpFilter}
                />
              </SelectWrapper>
              <TimeSpan orderFilter={orderFollowUpFilter} updateOrderFilter={updateOrderFollowUpFilter} />
              <OrderValueRange
                updateOrderFilter={updateOrderFollowUpFilter}
                orderFilter={orderFollowUpFilter}
              />
                <Buttons>
                  {Object.keys(orderFollowUpFilter).length > 2 && (
                    <>
                      {orderFollowUpFilter.filterActive && <ClearButton onClick={clearFilter} />}
                      <FilterButton isLoading={false} onClick={searchAndFilterOrders}>
                        <i className="fal fa-sliders-h"></i>Apply
                      </FilterButton>
                    </>
                  )}
                </Buttons>
            </FilterForm>
          </FilterDropdown>
        )}
      </Filter>
    </Container>
  );
};
