import React from "react";
import styled, { keyframes } from "styled-components/macro";

const styledKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
  padding: 0.7rem;
  border-radius: 2.2rem;
  background: ${(p) => p.theme.colors.background};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 3rem;
    height: 3rem;
    border: 0.2rem solid ${(p) => p.color || p.theme.colors.white};
    border-radius: 50%;
    animation: ${styledKeyframes} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(p) => p.color || p.theme.colors.white} transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;

export default ({ color, ...props }) => (
  <Container>
    <Loader color={color} {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Loader>
  </Container>
);
