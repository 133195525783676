import React from "react";
import styled from "styled-components/macro";
import esb from "elastic-builder";
import { Query } from "@apollo/client/react/components";
import PRODUCT_AGGREGATES from "graphql/Product/ProductAggregates";
import MultiSelect from "components/Ui/MultiSelect";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
`;

const StyledMultiSelect = styled(MultiSelect)`
.dropdown-heading {
  height: 4.8rem !important;
}
`;

const allCategories = esb.requestBodySearch()
  .query(esb.queryStringQuery("type:product AND !(archived:1)"))
  .agg(esb.termsAggregation("categories", "category.keyword").size(100));

export default ({ selected, setSelected, exclude = false }) => {
  const getOptions = (aggregations, exclude) => {
    const categories = aggregations ? JSON.parse(aggregations).categories : { buckets: [] };

    const options = categories.buckets.map((category) => ({
      value: category.key,
      label: category.key,
    }));

    exclude && options.unshift({ value: "Shipping", label: "Shipping" });
    return options;
  };

  const updateSelectedWithShipping = (categories) => {
    categories.unshift({ value: "Shipping", label: "Shipping" });
    setSelected(categories);
  };

  const updateSelected = (categories) =>
    exclude && categories.length < 1
      ? updateSelectedWithShipping(categories)
      : setSelected(categories);

  return (
    <Query
      query={PRODUCT_AGGREGATES}
      variables={{ query: JSON.stringify(allCategories.toJSON()) }}
      fetchPolicy={"cache-first"}>
      {({ error, data }) => {
        if (error)
          return <ErrorMessage>An error occurred getting data, contact support</ErrorMessage>;
        let options = [];
        if (data) options = getOptions(data?.productAggregates.aggregations, exclude);

        const itemRenderer = ({ checked, option, onClick }) => (
          <Label>
            <span>
              <span>{option.label}</span>
            </span>
            <input
              disabled={option.value === "Shipping" && "disabled"}
              type="checkbox"
              onChange={onClick}
              checked={checked}
              tabIndex="-1"
              style={{ float: "right" }}
            />
          </Label>
        );

        return (
          <StyledMultiSelect
            options={options}
            selected={selected}
            setSelected={updateSelected}
            label={`Select categories ${exclude ? "to exclude" : ""}`}
            ItemRenderer={itemRenderer}
            valueRenderer={(selected) => {
              if (selected.length === 0) {
                return <span>No categories {exclude ? "excluded" : "selected"}</span>;
              }
              if (selected.length === options.length) {
                return <span>All categories {exclude ? "excluded" : "selected"}</span>;
              }
              return (
                <span>
                  {selected.length} {selected.length === 1 ? "category" : "categories"}{" "}
                  {exclude ? "excluded" : "selected"}
                </span>
              );
            }}
            overrideStrings={{
              selectAll: `Select all ${options.length} categories`,
            }}
          />
        );
      }}
    </Query>
  );
};
