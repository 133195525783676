import React from "react";
import styled from "styled-components/macro";
import ProductVariant from "./ProductVariant";

const Variants = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

export default ({ variants, subscribeToMore, expandedVariantId }) => (
  <Variants preHeading={`Variants (${variants.length})`}>
    {variants.length > 0 ? (
      variants
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((variant) => (
          <ProductVariant key={variant.id} variant={variant} subscribeToMore={subscribeToMore} expanded={variant.id === expandedVariantId}/>
        ))
    ) : (
      <p>No variants found for this product</p>
    )}
  </Variants>
);
