import gql from "graphql-tag";

const UPDATE_DISCOUNT_CODE = gql`
    mutation updateDiscount(
        $id: ID!
        $discountInPercentage: Int
        $discountInFixedPrice: [InputMoney]
        $usageLimit: Int
        $description: String
        $startDate: AWSDate!
        $endDate: AWSDate!
        $stores: [InputDiscountStore]
        $validForProducts: [String]
        $notForProducts: [String]
        $notForProductVariants: [String]
        $notForCategories: [String]
        $categories: [String]
        $minItems: Int
        $maxItems: Int
        $recurrent: Boolean
        $combine: Boolean
        $gifts: [String]
        $freeShipping: Boolean
        $filterRules: [InputFilterRule!]
        $discountRules: [InputFilterRule!]
        $currencyOptions: [InputCurrencyOption!]
    ) {
        updateDiscount(
            updateDiscount: {
                id: $id
                discountInPercentage: $discountInPercentage
                discountInFixedPrice: $discountInFixedPrice
                usageLimit: $usageLimit
                description: $description
                startDate: $startDate
                endDate: $endDate
                stores: $stores
                gifts: $gifts
                validForProducts: $validForProducts
                notForProducts: $notForProducts
                notForProductVariants: $notForProductVariants
                notForCategories: $notForCategories
                categories: $categories
                minItems: $minItems
                maxItems: $maxItems
                recurrent: $recurrent
                combine: $combine
                freeShipping: $freeShipping
                filterRules: $filterRules
                discountRules: $discountRules
                currencyOptions: $currencyOptions
            }
        ) {
            id
            code
            startDate
            endDate
            stores {
                countryCode
            }
            notForCategories
            discountInPercentage
            discountInFixedPrice {
                amount
                currencyUnit
            }
            categories
            description
            usageLimit
            created
            lastUpdated
            minItems
            maxItems
            gifts {
                id
            }
            recurrent
            combine
            freeShipping
            filterRules {
                mode
                key
                values
            }
            discountRules {
                mode
                key
                values
            }
            currencyOptions {
                atLeastTotalPriceWithDiscount
                currencyUnit
            }
        }
    }
`;

export default UPDATE_DISCOUNT_CODE;
