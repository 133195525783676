import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";
import GROUP_VOUCHERS from "graphql/Discount/Vouchers/GroupVouchers";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import VoucherGroupsTable from "./VoucherGroupsTable";
import Sidebar from "components/Discount/Sidebar";
import Loader from "components/Ui/Loader";
import SearchVoucherGroup from "./SearchVoucherGroup";

const DiscountsBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
    }
  }
`;

const VOUCHERS_PER_PAGE = 25;

const ALL_VOUCHER_GROUPS = esb.requestBodySearch()
  .query(esb.queryStringQuery("!(archived:1) AND (type:VOUCHER)"))
  .agg(
    esb
      .termsAggregation("groups", "group.keyword")
      .size(1000)
      .agg(esb.dateHistogramAggregation("created", "created", "1h"))
  );

export default (props) => {
  const [esbQuery, setEsbQuery] = useState(ALL_VOUCHER_GROUPS);

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/vouchers", "Vouchers"],
        ]}
      />
      <Header heading="Vouchers" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <DiscountsBox preHeading="Overview" heading="Vouchers">
            <SearchVoucherGroup
                allVouchers={ALL_VOUCHER_GROUPS}
                esbQuery={esbQuery}
                setEsbQuery={setEsbQuery}
              />
              <Query
                query={GROUP_VOUCHERS}
                variables={{ query: JSON.stringify(esbQuery.toJSON()) }}>
                {({ loading, error, data }) => {
                  if (loading) return <Loader />;
                  if (error)
                    return (
                      <ErrorMessage inBox>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );
                  return (
                    <VoucherGroupsTable
                      data={data}
                      loading={loading}
                      vouchersPerPage={VOUCHERS_PER_PAGE}
                      history={props.history}
                    />
                  );
                }}
              </Query>
            </DiscountsBox>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};
