import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query, Mutation } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form-old";
import uuid from "react-uuid";
import ISO6391 from "iso-639-1";
import LocaleCode from "locale-code";
import ALL_STORES from "graphql/Store/AllStores";
import ADD_SHIPPING_OPTION from "graphql/Shipping/ShippingOption/AddShippingOption";
import { useNotification } from "context/NotificationContext";
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import Toggle from "components/Ui/Toggle";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Input from "components/Ui/Input";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import Button from "components/Ui/Button";
import IconButton from "components/Ui/Buttons/IconButton";
import Sidebar from "components/Shipping/Sidebar";
import ProviderChoice from "components/Shipping/ProviderChoice";
import Header from "components/Header/Header";
import AddTranslation from "components/Shipping/AddTranslation";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import { formatPrice } from "../../../helpers/money";
import AttributeList from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import getEditFreeShipping from "helpers/getEditFreeShipping";

const OptionBox = styled(Box)`
  padding-bottom: 5rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 1rem;
  }
`;

const Container = styled(GridContainer)`
  margin-top: 3rem;

  ${MEDIA_MIN_LARGE} {
    margin-top: 4rem;
  }
`;

const IdLabel = styled(Label)`
  margin-bottom: 0.8rem;
`;

const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${MEDIA_MIN_LARGE} {
    justify-content: flex-start;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const IdButton = styled(Button)`
  width: 6rem;
  margin-left: 1rem;
  height: 5rem;
  top: -0.1rem;
  i {
    margin: 0;
  }
`;

const TranslationItem = styled(GridItem)``;

const TranslationWrapper = styled.div`
  ${MEDIA_MIN_LARGE} {
    display: flex;
    align-items: flex-start;
  }
`;

const Translations = styled.div`
  &:not(:empty) {
    padding-bottom: 3rem;
  }
`;

const Translation = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  > div {
    display: flex;
    align-items: center;
  }

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const AddTranslationButton = styled(Button)`
  i {
    margin-right: 1.5rem;
  }
  align-self: center;
  ${MEDIA_MIN_LARGE} {
    width: 25%;
    margin-left: 1rem;
    height: 5rem;
    top: -0.1rem;
    i {
      margin-right: 1rem;
    }
  }
`;

const PriceInput = styled(Input)`
  ${MEDIA_MIN_MEDIUM} {
    margin-right: 2rem;
  }
`;

export default ({ history }) => {
  const { control, handleSubmit, errors } = useForm();
  const { setNotification } = useNotification();
  const [providerId, setProviderId] = useState(null);
  const [addId, setAddId] = useState(false);
  const [descriptions, setDescriptions] = useState({});
  const [displayNames, setDisplayNames] = useState({});
  const [showDescTranslation, setShowDescTranslation] = useState(false);
  const [showNameTranslation, setShowNameTranslation] = useState(false);
  const [freeShipping, setFreeShipping] = useState(true);

  const getLanguageName = (code) => {
    if (ISO6391.getName(code)) return ISO6391.getName(code);
    if (LocaleCode.getLanguageName(code.replace("_", "-")))
      return LocaleCode.getLanguageName(code.replace("_", "-"));
    return "";
  };

  const removeDescription = (description) =>
    setDescriptions([...descriptions].filter((d) => !(d.langCode === description.langCode)));
  const removeDisplayName = (displayName) =>
    setDisplayNames([...displayNames].filter((d) => !(d.langCode === displayName.langCode)));

  const updateDescription = (langCode, description) => {
    const updatedDescriptions = { ...descriptions, [langCode]: description };
    setDescriptions(updatedDescriptions);
  };

  const updateDisplayName = (langCode, displayName) => {
    const updatedDisplayNames = { ...displayNames, [langCode]: displayName };
    setDisplayNames(updatedDisplayNames);
  };

  const toShippingOption = (data, price) => {
    const id = data.id ? data.id : uuid();
    return {
      variables: {
        id: id,
        name: data.enDisplayName,
        imageUrl: data.imageUrl,
        price: price,
        type: "shippingOption",
        category: "Shipping",
        ...(getEditFreeShipping
          ? {
              customerAttribute: JSON.stringify({ "brink:freeShipping": freeShipping }),
            }
          : {}),
        attribute: JSON.stringify({
          description: { ...descriptions, en: data.enDescription },
          displayName: { ...displayNames, en: data.enDisplayName },
        }),
        relatedProduct: providerId,
      },
    };
  };

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/configurations", "Configurations"],
          ["admin/add-shipping-option/", "Add shipping option"],
        ]}
      />
      <Header heading="Shipping options" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query query={ALL_STORES} variables={{ from: 0, size: 300 }}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );
              const filteredCurrencyUnits = data.allStores.stores
                .map((store) => store.currencyUnit)
                .filter((currencyUnit, index, arr) => arr.indexOf(currencyUnit) === index);
              return (
                <Mutation
                  mutation={ADD_SHIPPING_OPTION}
                  onCompleted={() => {
                    setNotification({
                      status: "success",
                      message: `Shipping option successfully added`,
                    });
                    history.push("/admin/shipping-options");
                  }}
                  onError={() => {
                    setNotification({
                      status: "error",
                      message:
                        "An error occurred adding the shipping option, please contact support",
                    });
                  }}>
                  {(updateShipping, { loading: addShippingLoading }) => {
                    const onSubmit = (data) => {
                      const price = Object.keys(data.price).map((key) => ({
                        amount: formatPrice(data.price[key].amount, key),
                        currencyUnit: key,
                      }));

                      updateShipping(toShippingOption(data, price)).catch((e) => {
                        console.log(e);
                      });
                    };
                    return (
                      <GridItem columns="12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <OptionBox
                            preHeading="Shipping option"
                            heading="Add new shipping option"
                            showGoBackButton
                            goBackOnClick={() => history.push("/admin/shipping-options")}>
                            {addShippingLoading && <Loader />}

                            <ActionButtons inBox footerOnMobile>
                              <ActionButton type="submit">
                                <i className="fal fa-fw fa-check" />
                                Save
                              </ActionButton>
                            </ActionButtons>
                            {getEditFreeShipping() && (
                              <AttributeList>
                                <Attribute>
                                  <Label>Free shipping</Label>
                                  <Toggle
                                    data-tip="When creating a discount code with free shipping, should this option be included?"
                                    active={freeShipping}
                                    handleToggle={() => setFreeShipping(!freeShipping)}
                                  />
                                </Attribute>
                              </AttributeList>
                            )}
                            <Container collapse padding="0" columns="12">
                            <GridItem mobilePadding="0" desktopPadding="0" columns="12">
                                <ProviderChoice
                                  setProviderValue={(value) => setProviderId(value)}
                                />
                              </GridItem>
                              <GridItem mobilePadding="0" desktopPadding="0 1.5rem 0 0" columns="6">
                                <IdLabel>ID</IdLabel>
                                {addId ? (
                                  <InputWrapper>
                                    <Controller
                                      as={Input}
                                      control={control}
                                      type="text"
                                      name="id"
                                      defaultValue=""
                                      errors={errors}
                                      relativeError
                                    />
                                    <IdButton type="button" onClick={() => setAddId(false)}>
                                      <i className="fal fa-fw fa-times"></i>
                                    </IdButton>
                                  </InputWrapper>
                                ) : (
                                  <InputWrapper>
                                    <Input defaultValue="Auto generated" disabled={true}></Input>
                                    <IdButton type="button" onClick={() => setAddId(true)}>
                                      <i className="fal fa-fw fa-pen"></i>
                                    </IdButton>
                                  </InputWrapper>
                                )}
                              </GridItem>
                            </Container>
                            <Container collapse padding="0">
                              <GridItem mobilePadding="0" desktopPadding="0 1.5rem 0 0" columns="6">
                                <Controller
                                  as={Input}
                                  control={control}
                                  rules={{ required: "This is a required field" }}
                                  type="text"
                                  defaultValue=""
                                  icon="image"
                                  name="imageUrl"
                                  errors={errors}
                                  label="Image URL"
                                />
                              </GridItem>
                            </Container>
                            <GridContainer collapse padding="0">
                              <TranslationItem columns="12" padding="0 0 2rem">
                                <>
                                  <TranslationWrapper>
                                    <InputWrapper>
                                      <Controller
                                        as={Input}
                                        control={control}
                                        rules={{ required: "This is a required field" }}
                                        type="text"
                                        defaultValue=""
                                        icon="box"
                                        name="enDisplayName"
                                        errors={errors}
                                        label="Display name (English)"
                                      />
                                    </InputWrapper>

                                    <AddTranslationButton
                                      type="button"
                                      handleOnClick={() =>
                                        setShowNameTranslation(!showNameTranslation)
                                      }>
                                      <i
                                        className={`fa-light fa-layer-${
                                          showNameTranslation ? "minus" : "plus"
                                        }`}
                                      />
                                      Translation
                                    </AddTranslationButton>
                                  </TranslationWrapper>
                                  <Translations>
                                    {Object.keys(displayNames).map((key) => {
                                      return (
                                        <Translation key={key}>
                                          <Label>{`${getLanguageName(key)} (${key})`}</Label>
                                          <Value>{displayNames[key]}</Value>
                                          <IconButton
                                            onClick={() => {
                                              removeDisplayName(key);
                                            }}>
                                            <i className="fal fa-fw fa-trash-alt" />
                                          </IconButton>
                                        </Translation>
                                      );
                                    })}
                                  </Translations>

                                  <AddTranslation
                                    control={control}
                                    errors={errors}
                                    showTranslation={showNameTranslation}
                                    setShowTranslation={setShowNameTranslation}
                                    translations={displayNames}
                                    updateTranslation={updateDisplayName}
                                  />
                                </>
                              </TranslationItem>
                              <TranslationItem columns="12" padding="0">
                                <>
                                  <TranslationWrapper>
                                    <InputWrapper>
                                      <Controller
                                        as={Input}
                                        control={control}
                                        rules={{ required: "This is a required field" }}
                                        type="text"
                                        defaultValue=""
                                        name="enDescription"
                                        icon="memo-pad"
                                        errors={errors}
                                        label="Description (English)"
                                      />
                                    </InputWrapper>

                                    <AddTranslationButton
                                      type="button"
                                      handleOnClick={() =>
                                        setShowDescTranslation(!showDescTranslation)
                                      }>
                                      <i
                                        className={`fa-light fa-layer-${
                                          showDescTranslation ? "minus" : "plus"
                                        }`}
                                      />
                                      Translation
                                    </AddTranslationButton>
                                  </TranslationWrapper>
                                  <Translations>
                                    {Object.keys(descriptions).map((key) => {
                                      return (
                                        <Translation key={key}>
                                          <Label>{`${getLanguageName(key)} (${key})`}</Label>
                                          <Value>{descriptions[key].translation}</Value>
                                          <IconButton
                                            onClick={() => {
                                              removeDescription(key);
                                            }}>
                                            <i className="fal fa-fw fa-trash-alt"></i>
                                          </IconButton>
                                        </Translation>
                                      );
                                    })}
                                  </Translations>
                                  <AddTranslation
                                    showTranslation={showDescTranslation}
                                    setShowTranslation={setShowDescTranslation}
                                    translations={descriptions}
                                    updateTranslation={updateDescription}
                                  />
                                </>
                              </TranslationItem>
                            </GridContainer>

                            <GridContainer padding="0">
                              <h5>Price</h5>
                              <PriceContainer>
                                {filteredCurrencyUnits.map((currencyUnit) => {
                                  return (
                                    <GridItem columns="3" padding="0" key={currencyUnit}>
                                      <Controller
                                        as={PriceInput}
                                        control={control}
                                        rules={{
                                          required: "This is a required field",
                                        }}
                                        type="number"
                                        icon="tag"
                                        label={currencyUnit}
                                        name={`price[${currencyUnit}].amount`}
                                        defaultValue={0}
                                        errors={errors}
                                      />
                                    </GridItem>
                                  );
                                })}
                              </PriceContainer>
                            </GridContainer>
                          </OptionBox>
                        </form>
                      </GridItem>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
