import React from "react";
import styled from "styled-components";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Row from "components/Table/Row";
import Rows from "components/Table/Rows";
import Tooltip from "components/Ui/Tooltip";
import TableHeader from "components/Table/Header";
import InputNew from "components/Ui/InputNew";

const Conditions = styled.div`
  width: 100%;
  position: relative;

  & + & {
    margin-top: 3rem;
  }

  h5 {
    border-top: 0;
    padding: 0 0 3rem;
  }
`;

const ConditionsHeader = styled(TableHeader)`
  > div {
    cursor: default;
    width: 30%;
  }
`;

const ConditionRow = styled(Row)`
  justify-content: space-between;
  padding: 0;
  padding-top: 3rem;
  ${MEDIA_MIN_LARGE} {
    padding: 0;
    padding-top: 3rem;
    min-height: 6rem;
  }
`;

const PlaceInput = styled.span`
  width: 100%;
  padding-left: 1rem;
`;

const AmountInput = styled(InputNew)`
  width: 40%;
  min-width: 10rem;
  height: 3rem;
`;

const RowLabel = styled.div`
  width: 100%;
  padding-bottom: 3rem;
  padding-left: 4rem;
`;

const NoStoresMessage = styled.div`
  display: flex;
  width: 100%;
  padding: 4rem;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 700;
`;

const FixedPriceOptions = ({
  availableCurrencies,
  register,
  errors,
  discountToCreate,
  setValue,
  updateDiscountToCreate,
}) => {
  return (
    <Conditions>
      <Tooltip />
      <ConditionsHeader>
        <div>Currency code</div>
        <div>
          Discount amount *&nbsp;
          <i className="fal fa-fw fa-info-circle" data-tip="Only positive numbers, ex. 60" />
        </div>
        <div>
          Minimum order value *&nbsp;
          <i className="fal fa-fw fa-info-circle" data-tip="Only non-negative numbers, ex. 60" />
        </div>
      </ConditionsHeader>
      <Rows>
        {availableCurrencies?.length > 0 ? (
          availableCurrencies
            ?.map((currencyUnit) => (
              <ConditionRow key={currencyUnit}>
                <RowLabel>{currencyUnit}</RowLabel>
                <PlaceInput>
                  <AmountInput
                    {...register(`discountInFixedPrice-${currencyUnit}`, {
                      min: {
                        value: 1,
                        message: 'Needs to be a positive number'
                      }
                    })}
                    onChange={(e) => {
                      setValue(`discountInFixedPrice-${currencyUnit}`, e.target.value);
                      updateDiscountToCreate({
                        discountInFixedPrice: discountToCreate?.discountInFixedPrice?.map((r) =>
                          r.currencyUnit !== currencyUnit
                            ? r
                            : {
                                currencyUnit: r.currencyUnit,
                                amount: e.target.value,
                              }
                        ),
                      });
                    }}
                    type="number"
                    placeholder=""
                    errors={errors}
                    defaultValue={0}
                  />
                </PlaceInput>
                <PlaceInput>
                  <AmountInput
                    {...register(`atLeastTotalPriceWithDiscount-${currencyUnit}`, {
                      min: {
                        value: 0,
                        message: "Needs to be a non-negative number",
                      },
                    })}
                    onChange={(e) => {
                      setValue(`atLeastTotalPriceWithDiscount-${currencyUnit}`, e.target.value);
                      updateDiscountToCreate({
                        currencyOptions: discountToCreate?.currencyOptions?.map((r) =>
                          r.currencyUnit !== currencyUnit
                            ? r
                            : {
                                currencyUnit: r.currencyUnit,
                                atLeastTotalPriceWithDiscount: e.target.value,
                              }
                        ),
                      });
                    }}
                    type="number"
                    placeholder=""
                    errors={errors}
                    defaultValue={0}
                  />
                </PlaceInput>
              </ConditionRow>
            ))
            .reverse()
        ) : (
          <NoStoresMessage>No stores selected</NoStoresMessage>
        )}
      </Rows>
    </Conditions>
  );
};

export default FixedPriceOptions;
