import gql from "graphql-tag";
import { PRODUCT_DETAILS_FRAGMENT, PRODUCT_STOCK_FRAGMENT } from "../Fragments";

const GET_PRODUCTS_WITH_STOCK = gql`
    query getProducts($ids: [ID!]!) {
        getProducts(ids: $ids) {
            ...ProductDetails
            ...ProductStock
        }
    }
    ${PRODUCT_DETAILS_FRAGMENT}
    ${PRODUCT_STOCK_FRAGMENT}
`;

export default GET_PRODUCTS_WITH_STOCK