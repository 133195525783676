import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";
import SEARCH_PERSONS from "graphql/Person/SearchPersons";

import PageContainer from "components/Page/PageContainer";
import GridContainer from "components/Grid/GridContainer";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import CustomerDetails from "components/Customer/CustomerDetails";
import CustomerOrders from "components/Customer/CustomerOrders";

const Container = styled(GridContainer)`
  display: block;
  padding-top: 0;
`;

const CustomerBox = styled(Box)`
  min-height: 40rem;
  align-items: flex-start;
`;

export default ({ id, history }) => {
  const [esbQuery] = useState(esb.requestBodySearch().query(esb.queryStringQuery(`id:${id}`)));
  const [customer, setCustomer] = useState(null);

  return (
    <PageContainer>
      <Container>
        <Query
          query={SEARCH_PERSONS}
          variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
          onCompleted={({ searchPersons }) => {
            setCustomer(searchPersons.persons[0]);
          }}>
          {({ loading, error }) => (
            <>
              <GridContainer padding="0" collapse>
                <GridItem columns="12" mobilePadding="1.5rem">
                  <CustomerBox
                    preHeading="Customer"
                    heading={customer && `${customer.givenName} ${customer.familyName}`}
                    subHeading={customer && customer.id}
                    headingIcon="user">
                    {loading && <Loader />}
                    {error && (
                      <ErrorMessage>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    )}
                    {customer && <CustomerDetails customer={customer} />}
                  </CustomerBox>
                  {customer && <CustomerOrders personId={id} history={history} />}
                </GridItem>
              </GridContainer>
            </>
          )}
        </Query>
      </Container>
    </PageContainer>
  );
};
