import React from "react";
import styled from "styled-components/macro";
import ReactTooltip from "react-tooltip";

const StyledTooltip = styled(ReactTooltip)`
  max-width: 25rem;
  white-space: normal;
  background: ${(p) => p.theme.colors.tooltipBackground} !important;
  color: ${(p) => p.theme.colors.black} !important;
  line-height: 1.8rem;
  padding-top: 1rem !important;

  &:after {
    border-top-color: ${(p) => p.theme.colors.tooltipBackground} !important;
  }
`;

export default ({ ...props }) => <StyledTooltip effect="solid" place="top" multiline {...props} />;
