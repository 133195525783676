import React from "react";
import styled from "styled-components/macro";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

import Logo from "./Ui/Logo.js";
import Button from "./Ui/Button";
import getClientDetails from "helpers/getClientDetails.js";
import { providers, configuredAuthProvider } from "helpers/authProvider";
import { useAuth0 } from "@auth0/auth0-react";
import OktaLoginForm from "./OktaLoginForm";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.constants.secondaryBackground};

  ${MEDIA_MIN_LARGE} {
    height: 100vh;
  }
`;

const LoginForm = styled.div`
  width: calc(100% - 6rem);
  margin: 0 auto;
  max-width: 45rem;

  i {
    color: ${(p) => p.theme.constants.black};
  }

  img,
  > div {
    display: block;
    margin: 0 auto;
  }
`;

const BrinkLogo = styled(Logo)`
  max-width: 7rem;
  position: absolute;
  bottom: 3rem;
  left: 0;
  right: 0;
`;

const StyledButton = styled(Button)`
  width: 75%;
  margin: 3rem auto;
`;

const Login = ({ issuer }) => {
  return (
    <Container>
      {configuredAuthProvider === providers.OKTA && <OktaLoginForm />}
      {configuredAuthProvider === providers.AUTH0 && (
        <LoginForm>
          {getClientDetails().logo}
          <BrinkLogo />
          <LoginButton />
        </LoginForm>
      )}
    </Container>
  );
};

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <StyledButton loaderColor="white" onClick={() => loginWithRedirect("/admin")}>
      Login
    </StyledButton>
  );
};

export default Login;
