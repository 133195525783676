import gql from "graphql-tag";

const DELETE_DISCOUNT_CODE = gql`
  mutation deleteDiscount($id: ID!, $deleteMode: DeleteMode) {
    deleteDiscount(id: $id, deleteMode: $deleteMode) {
      status
    }
  }
`;

export default DELETE_DISCOUNT_CODE;
