import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Container = styled.div`
  min-height: calc(100vh - 31.1rem);
  display: flex;
  flex-wrap: wrap;
 

  ${MEDIA_MIN_LARGE} {
    flex-wrap: nowrap;
    min-height: calc(100vh - 20.1rem);
    width: 100%
  }
`;

export default ({ children, ...props }) => <Container {...props}>{children}</Container>;
