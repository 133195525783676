import gql from "graphql-tag";

const GET_SHIPPING_RULE = gql`
  query getRule($id: ID!) {
    getRule(id: $id) {
      id
      active
      brinkRule
      created
      lastUpdated
      name
      ruleTemplate {
        id
      }
      templateData
      type
    }
  }
`;

export default GET_SHIPPING_RULE;
