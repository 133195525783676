import React, { useState } from "react";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import GET_INVENTORIES from "graphql/Stock/GetInventories";
import Header from "components/Header/Header";
import Box from "components/Content/Box";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Sidebar from "components/Store/Sidebar";
import InventoriesTable from "./InventoriesTable";
import { useQuery } from "@apollo/client";
import ALL_STORES from "../../graphql/Store/AllStores";

const Stores = (props) => {
  const [inventoriesWithStores, setInventoriesWithStores] = useState();
  const [inventories, setInventories] = useState();

  const { loading: storeLoading, error: storeError } = useQuery(ALL_STORES, {
    variables: { from: 0, size: 300 },
    onCompleted: (data) => {
      setInventoriesWithStores(
        data?.allStores?.stores
          .map((store) =>
            store.inventories.map((inventory) => ({
              ...inventory.inventory,
              store: store,
            }))
          )
          .flatMap((i) => i)
          .reduce((acc, inventory) => {
            if (inventory["id"] === undefined) return acc;
            return Object.assign(acc, {
              [inventory["id"]]: (acc[inventory["id"]] || []).concat(inventory.store),
            });
          }, {})
      );
    },
  });

  const { loading: inventoryLoading, error: inventoryError } = useQuery(GET_INVENTORIES, {
    onCompleted: (data) => setInventories(data?.getInventories),
  });

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/configurations", "Configurations"],
          ["admin/inventories", "Inventories"],
        ]}
      />
      <Header heading="Inventories"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <Box preHeading="Overview" heading="Inventories">
              {storeError || inventoryError ? (
                <ErrorMessage>An error occurred getting data, contact support</ErrorMessage>
              ) : (
                <InventoriesTable
                  inventoriesTotalHits={inventories?.length}
                  inventories={
                    inventoriesWithStores && inventories
                      ? inventories.map((inventory) => ({
                          ...inventory,
                          connectedStores: inventoriesWithStores[inventory.id],
                          totalConnectedStores: inventoriesWithStores[inventory.id]?.length ?? 0,
                        }))
                      : []
                  }
                  history={props.history}
                  loading={storeLoading && inventoryLoading}
                />
              )}
            </Box>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default (props) => {
  return Stores(props);
};
