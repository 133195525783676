import React, { useState } from "react";
import FilterProducts from "../../../../../views/Discount/DiscountCode/FilterProducts";
import FilterRules from "../../../../../views/Discount/DiscountCode/FilterRules";

const FilterOptions = ({
  filterRules,
  discountRules,
  history,
  setFilterRules,
  setDiscountRules,
  control,
  OptionsBox,
  Row,
  discountInPercentageInput,
  recurrentToggle,
  minItems,
  minItemsInput,
  maxItemsInput,
}) => {
  const [filterProducts, setFilterProducts] = useState([]);
  return (
    <>
      <OptionsBox hideHeader>
        <Row>
          {discountInPercentageInput}
          {minItemsInput}
          {maxItemsInput}
        </Row>
        {minItems > 0 && <Row>{recurrentToggle}</Row>}
      </OptionsBox>
      <OptionsBox
        preHeading="Selection"
        heading="Add rules for cart and discount"
        subHeading="Add filter rules to the products that need to be in the cart for the discount to be applied. If the certain products are in the cart, the discount will then be applied on the products defined with discount rules. A preview of the included products is shown below">
        {minItems > 0 && (
          <FilterRules
            filterRules={filterRules}
            setFilterRules={setFilterRules}
            control={control}
            title="Products in cart"
          />
        )}

        <FilterRules
          filterRules={discountRules}
          setFilterRules={setDiscountRules}
          control={control}
          title="Products with discount"
        />
      </OptionsBox>
      {minItems > 0 && (
        <FilterProducts
          padding="1rem 0 0 0"
          history={history}
          filterRules={filterRules}
          heading="Products in cart"
          subHeading="Products that must be in the cart for the discount code to be applicable"
          setFilterProducts={setFilterProducts}
        />
      )}
      <FilterProducts
        padding="1rem 0 0 0"
        history={history}
        filterRules={discountRules}
        heading="Products with discount"
        subHeading="Products that the discount code will apply on"
        excludeIds={minItems > 0 ? filterProducts.map((p) => p.id) : null}
      />
    </>
  );
};

export default FilterOptions;
