import gql from "graphql-tag";

export const PRODUCT_DETAILS_FRAGMENT = gql`
    fragment ProductDetails on Product {
        __typename
        id
        type
        name
        imageUrl
        category
        relatedProduct {
            id
        }
    }
`;

export const PRODUCT_STOCK_FRAGMENT = gql`
    fragment ProductStock on Product {
        __typename
        stock {
            productId
            stock
        }
        stockInventory {
            id
            validateStock
            stockQuantity
            reservedQuantity
            availableQuantity
            isAvailable
        }
    }
`;