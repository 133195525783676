import gql from "graphql-tag";

const UPDATED_ORDER_SUBSCRIPTION = gql`
  subscription UpdatedOrder {
    updatedOrder {
      id
      status
      statusLog {
        status
        timestamp
      }
    }
  }
`;

export default UPDATED_ORDER_SUBSCRIPTION;
