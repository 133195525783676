import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import { useQuery } from "@apollo/client";
import esb from "elastic-builder";
import SEARCH_PERSONS from "graphql/Person/SearchPersons";
import SEARCH_PERSONS_EXPORT from "graphql/Person/SearchPersonsExport";
import { useAdminContext } from "context/AdminContext";
import Header from "components/Header/Header";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import CustomersTable from "./CustomersTable";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import SearchCustomers from "./SearchCustomers";
import Customer from "./Customer";
import CustomersExport from "./CustomersExport";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";

const TableContainer = styled(GridContainer)`
  padding-top: 0;
`;

const PERSONS_PER_PAGE = 25;
const ALL_PERSONS = esb.requestBodySearch()
  .trackTotalHits(true)
  .sort(esb.sort("lastUpdated", "desc"))
  .size(PERSONS_PER_PAGE)
  .from(0);

export default function Customers({ history }) {
  const [esbQuery, setEsbQuery] = useState(ALL_PERSONS);
  const [customers, setCustomers] = useState();
  const [customersTotalHits, setCustomersTotalHits] = useState(0);
  const [activeTab, setActiveTab] = useState("table");
  const { customerTabs, setCustomerTabs } = useAdminContext();
  const [urlId, setUrlId] = useState();
  const { data } = useQuery(SEARCH_PERSONS, {
    variables: {
      id: urlId,
      includeReservation: false,
      query: JSON.stringify(
        esb.requestBodySearch().query(esb.queryStringQuery(`id:${urlId}`)).toJSON()
      ),
    },
    skip: !urlId,
  });

  const closeTab = (id) => {
    setCustomerTabs(customerTabs.filter((item) => item.id !== id));

    if (customerTabs.length === 1) return;
    const tabIndex = customerTabs.findIndex((t) => t.id === id);

    setActiveTab(
      tabIndex === customerTabs.length - 1
        ? customerTabs[tabIndex - 1].id
        : customerTabs[tabIndex + 1].id
    );
  };

  useEffect(() => {
    customerTabs.length > 0 && setActiveTab(customerTabs[customerTabs.length - 1].id);
  }, [customerTabs]);

  useEffect(() => {
    const id = window.location.pathname.split("/")[3];

    if (id) {
      setUrlId(id);
      if (data) {
        const customer = data.searchPersons.persons[0];
        !customerTabs.find((item) => item.id === id) &&
          setCustomerTabs((prev) => [
            ...prev,
            { id, reference: `${customer.givenName.charAt(0)}. ${customer.familyName}` },
          ]);
        setActiveTab(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Breadcrumbs slugs={[["admin/customers", "Customers"]]} />
      <Header heading="Customers" />
      <Tabs>
        <Tab
          onClick={() => setActiveTab("table")}
          active={customerTabs.length === 0 || activeTab === "table"}>
          Customers
        </Tab>
        {customerTabs.map((tab) => (
          <Tab
            key={tab.id}
            tab={tab}
            onClick={() => setActiveTab(tab.id)}
            active={tab.id === activeTab}
            closeTab={closeTab}>
            {tab.reference}
          </Tab>
        ))}
      </Tabs>
      {customerTabs.length === 0 || activeTab === "table" ? (
        <TableContainer>
          <GridItem columns="12" mobilePadding="1.5rem">
            <Box preHeading="Overview" heading="Customer table">
              <SearchCustomers allCustomers={ALL_PERSONS} setEsbQuery={setEsbQuery} />
              <Query
                query={SEARCH_PERSONS}
                variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
                onCompleted={(data) => {
                  setCustomers(data.searchPersons.persons);
                  setCustomersTotalHits(data.searchPersons.totalHits);
                }}>
                {({ loading, error, fetchMore, subscribeToMore }) => {
                  if (error)
                    return (
                      <ErrorMessage inBox>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );

                  return (
                    <CustomersTable
                      esbQuery={esbQuery}
                      customers={customers}
                      setCustomers={setCustomers}
                      customersTotalHits={customersTotalHits}
                      loading={loading}
                      fetchMore={fetchMore}
                      subscribeToMore={subscribeToMore}
                      history={history}
                      personsPerPage={PERSONS_PER_PAGE}
                      setCustomerTabs={setCustomerTabs}
                      customerTabs={customerTabs}
                      setActiveTab={setActiveTab}>
                      {({ totalHits }) => (
                        <CustomersExport
                          size={totalHits}
                          query={SEARCH_PERSONS_EXPORT}
                          esbQuery={esbQuery}
                        />
                      )}
                    </CustomersTable>
                  );
                }}
              </Query>
            </Box>
          </GridItem>
        </TableContainer>
      ) : (
        customerTabs.map(
          (tab) => activeTab === tab.id && <Customer key={tab.id} id={tab.id} history={history} />
        )
      )}
    </>
  );
}
