import gql from "graphql-tag";

const SEARCH_ORDERS_EXPORT = gql`
    query searchOrders(
        $query: AWSJSON!
        $id: Boolean!
        $reference: Boolean!
        $status: Boolean!
        $paymentProvider: Boolean!
        $paymentMethod: Boolean!
        $orderTaxAmount: Boolean!
        $orderAmount: Boolean!
        $currencyUnit: Boolean!
        $created: Boolean!
        $lastUpdated: Boolean!
        $discountCode: Boolean!
        $email: Boolean!
        $country: Boolean!
        $givenName: Boolean!
        $familyName: Boolean!
        $city: Boolean!
        $streetAddress: Boolean!
        $postalCode: Boolean!
        $region: Boolean!
        $phone: Boolean!
        $creditedNetAmount: Boolean!
        $refundAmount: Boolean!
        $orderAmountWithDiscount: Boolean!
        $customerAttribute: Boolean!
    ) {
        searchOrders(query: $query) {
            orders {
                id @include(if: $id)
                reference @include(if: $reference)
                status @include(if: $status)
                paymentProvider @include(if: $paymentProvider)
                paymentMethod @include(if: $paymentMethod)
                orderTaxAmount @include(if: $orderTaxAmount)
                orderAmount @include(if: $orderAmount)
                orderAmountWithDiscount @include(if: $orderAmountWithDiscount)
                refundAmount @include(if: $refundAmount)
                currencyUnit @include(if: $currencyUnit)
                created @include(if: $created)
                lastUpdated @include(if: $lastUpdated)
                discount @include(if: $discountCode) {
                    code
                }
                person @include(if: $email) {
                    email
                }
                shippingAddress {
                    country @include(if: $country)
                    givenName @include(if: $givenName)
                    familyName @include(if: $familyName)
                    city @include(if: $city)
                    streetAddress @include(if: $streetAddress)
                    postalCode @include(if: $postalCode)
                    region @include(if: $region)
                    phone @include(if: $phone)
                }
                customerAttribute @include(if: $customerAttribute)
                orderLines @include(if: $creditedNetAmount) {
                    id
                    taxRate
                    type
                    name
                }
            }
            totalHits
        }
    }
`;

export default SEARCH_ORDERS_EXPORT;
