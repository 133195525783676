import React from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";
import moment from "moment/min/moment-with-locales";
import SEARCH_DISCOUNT_CODES from "graphql/Discount/SearchDiscounts";
import SEARCH_DISCOUNT_RULES from "graphql/Discount/DiscountRule/SearchDiscountRules";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Sidebar from "components/Discount/Sidebar";
import TableHeader from "components/Table/Header";
import isDiscountCodeActive from "components/Discount/Helpers/isDiscountCodeActive";
import DiscountValue from "components/Discount/DiscountValue";
import activeDiscounts from "components/Discount/Helpers/activeDiscounts";

const Wrapper = styled(PageContainer)`
  h5 {
    padding-top: 3rem;
  }
`;

const DiscountCodeUsage = styled.div`
  width: 100%;
  min-height: 40rem;
`;

const Totals = styled.div`
  div {
    display: flex;
    align-items: center;
    height: 5.3rem;
    justify-content: space-between;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};

    span {
      display: flex;
      align-items: center;
    }

    &:first-of-type {
      font-size: 1.6rem;
    }

    i {
      margin-right: 1rem;
      font-size: 1.8rem;
    }

    .fa-check-circle {
      color: green;
    }

    .fa-times-circle {
      color: red;
    }
  }
`;

const Table = styled.ul`
  padding: 0;
  list-style: none;
`;

const DiscountsHeader = styled(TableHeader)`
  padding-left: 2rem;

  div {
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: center;

    &:first-child {
      width: 50%;
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
`;

const Row = styled.li`
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 1.5rem 2rem 1.3rem;
  cursor: pointer;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};

  &:hover {
    background: ${(p) => p.theme.colors.greyOpac};
  }

  div {
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: center;

    &:first-child {
      width: 50%;
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }
`;

const Rule = styled(Row)`
  div {
    &:first-child {
      width: 100%;
    }
    &:last-child {
      justify-content: flex-start;
    }
  }
`;

const IsActive = styled.span`
  color: ${(p) => (p.isActive ? "green" : "red")};
  font-size: 0.6rem;
  margin-right: 1rem;
  margin-top: 0.2rem;
`;

const ALL_DISCOUNT_RULES = esb.requestBodySearch()
  .query(esb.boolQuery().must(esb.matchQuery("type", "CART")).must(esb.matchQuery("archived", 0)))
  .size(1000);

const MOST_USED_DISCOUNT_CODES = esb.requestBodySearch()
  .trackTotalHits(true)
  .query(esb.queryStringQuery("!(archived:1) OR !(type:VOUCHER)"))
  .sort(esb.sort("counter", "desc"))
  .size(5);

export default ({ history }) => {
  const handleDiscountCodeClick = (discountCodeId) => {
    history.push(`/admin/discount-codes/${discountCodeId}`);
  };

  const handleDiscountRuleClick = (discountRuleId) => {
    history.push(`/admin/discount-rule/${discountRuleId}`);
  };

  const isActive = (rule) => {
    if (rule.active) {
      const currentDate = moment().startOf("day");
      if (rule.startDate && rule.endDate) {
        return currentDate.isBetween(moment(rule.startDate), moment(rule.endDate), undefined, "[]");
      } else if (rule.startDate) {
        return currentDate.isSameOrAfter(moment(rule.startDate));
      } else if (rule.endDate) {
        return currentDate.isSameOrBefore(moment(rule.endDate));
      }
    }
    return rule.active;
  };

  const totalActiveDiscountCodes = activeDiscounts();

  return (
    <>
      <Breadcrumbs slugs={[["admin/discounts", "Discounts"]]} />
      <Header heading="Discounts" />
      <Wrapper>
        <Sidebar />
        <GridContainer>
          <GridItem columns="6">
            <Box preHeading="Overview" heading="Discount codes">
              <Query
                query={SEARCH_DISCOUNT_CODES}
                variables={{ query: JSON.stringify(MOST_USED_DISCOUNT_CODES.toJSON()) }}>
                {({ loading, error, data }) => {
                  if (loading) return <Loader />;
                  if (error)
                    return (
                      <ErrorMessage inBox>
                        An error occurred getting data, contact support
                      </ErrorMessage>
                    );

                  const { discounts } = data.searchDiscounts;

                  const discountCodes = discounts
                    .slice()
                    .sort((a, b) => a.usageCount - b.usageCount)
                    .reverse();

                  return (
                    <DiscountCodeUsage>
                      <Totals>
                        <div>
                          <span>Number of discount codes:</span>
                          <span>{data.searchDiscounts.totalHits}</span>
                        </div>
                        <div>
                          <span>
                            <i className="fal fa-check-circle"></i>Active discount codes:
                          </span>
                          <span> {totalActiveDiscountCodes}</span>
                        </div>
                        <div>
                          <span>
                            <i className="fal fa-times-circle"></i>Inactive discount codes:
                          </span>
                          <span> {data.searchDiscounts.totalHits - totalActiveDiscountCodes}</span>
                        </div>
                      </Totals>

                      <h5>Top 5 used discount codes</h5>
                      <Table>
                        <DiscountsHeader>
                          <div>Code</div>
                          <div>Discount</div>
                          <div>Usage count</div>
                        </DiscountsHeader>
                        {discountCodes.slice(0, 5).map((discountCode) => {
                          return (
                            <Row
                              key={discountCode.id}
                              onClick={() => handleDiscountCodeClick(discountCode.id)}>
                              <div>
                                <IsActive
                                  isActive={isDiscountCodeActive(
                                    discountCode.startDate,
                                    discountCode.endDate,
                                    discountCode.usageCount,
                                    discountCode.usageLimit
                                  )}>
                                  <i className="fas fa-circle"></i>
                                </IsActive>
                                {discountCode.code}
                              </div>
                              <div>
                                <DiscountValue discount={discountCode} />
                              </div>
                              <div>{discountCode.usageCount}</div>
                            </Row>
                          );
                        })}
                      </Table>
                    </DiscountCodeUsage>
                  );
                }}
              </Query>
            </Box>
          </GridItem>
          <GridItem columns="6">
            <Box preHeading="Overview" heading="Discount rules">
              <Query
                query={SEARCH_DISCOUNT_RULES}
                variables={{ query: JSON.stringify(ALL_DISCOUNT_RULES.toJSON()) }}>
                {({ loading, error, data }) => {
                  if (loading) return <Loader />;
                  if (error)
                    return (
                      <ErrorMessage inBox>
                        An error occurred when loading data, please contact support
                      </ErrorMessage>
                    );

                  const { rules } = data.searchRules;
                  const activeRules = rules.filter((rule) => isActive(rule));

                  return (
                    <DiscountCodeUsage>
                      <Totals>
                        <div>
                          <span>Number of discount rules:</span>
                          <span>{rules.length}</span>
                        </div>
                        <div>
                          <span>
                            <i className="fal fa-check-circle"></i>Active discount rules:
                          </span>
                          <span>{activeRules.length}</span>
                        </div>
                        <div>
                          <span>
                            <i className="fal fa-times-circle"></i>Inactive discount rules:
                          </span>
                          <span>{data.searchRules.totalHits - activeRules.length} </span>
                        </div>
                      </Totals>

                      <h5>Currently active discount rules</h5>
                      <Table>
                        <DiscountsHeader>Rule</DiscountsHeader>
                        {activeRules.map((discountRule) => (
                          <Rule
                            key={discountRule.id}
                            onClick={() => handleDiscountRuleClick(discountRule.id)}>
                            <div>
                              <IsActive isActive={discountRule.active}>
                                <i className="fas fa-circle"></i>
                              </IsActive>
                              {discountRule.name}
                            </div>
                          </Rule>
                        ))}
                      </Table>
                    </DiscountCodeUsage>
                  );
                }}
              </Query>
            </Box>
          </GridItem>
        </GridContainer>
      </Wrapper>
    </>
  );
};
