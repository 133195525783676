import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "../../variables/mediaQueries";

const Label = styled.div`
  width: 13rem;
  line-height: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${MEDIA_MIN_MEDIUM} {
    width: 20rem;
  }
`;

export default ({ children, ...props }) => {
  return <Label {...props}>{children}</Label>;
};
