import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";

import DELETE_SHIPPING_RULE from "graphql/Shipping/ShippingRule/DeleteShippingRule";

import { useNotification } from "context/NotificationContext";

import DeleteButton from "components/ActionButtons/DeleteButton";
import Loader from "components/Ui/Loader";
import Dialog from "components/Dialog/Dialog";

export default ({ id, history }) => {
  const [open, setOpen] = useState(false);
  const { setNotification } = useNotification();

  const gotoRules = () => {
    history.push({
      pathname: "/admin/shipping-rules",
    });
  };

  return (
    <Mutation
      mutation={DELETE_SHIPPING_RULE}
      variables={{ id, deleteMode: "SOFT" }}
      onCompleted={({ deleteRule }) => {
        setNotification({
          status: "success",
          message: `Shipping rule "${deleteRule.name}" successfully deleted`,
        });
        setOpen(false);
        setTimeout(gotoRules, 500);
      }}
      onError={() => {
        setNotification({
          status: "error",
          message: `Shipping rule ${id} could not be deleted, please contact support`,
        });
      }}>
      {(deleteRule, { loading }) => {
        if (loading) return <Loader />;

        return (
          <>
            <DeleteButton handleOnClick={() => setOpen(true)}>Delete</DeleteButton>
            <Dialog
              header="Delete shipping rule?"
              text="Deleting the shipping rule will not effect already used shipping rules"
              open={open}
              handleClose={() => setOpen(false)}
              handleOk={deleteRule}
            />
          </>
        );
      }}
    </Mutation>
  );
};
