export default (customerAttribute) => {
  let attributes = [];

  Object.keys(customerAttribute).map((key) => {
    if (key === "fortnox")
      attributes.push({
        label: "Fortnox Invoice Number:",
        value: customerAttribute[key].invoiceNumber,
      });
    if (key === "acknowledged")
      attributes.push({ label: "Acknowledged by ERP", value: customerAttribute[key] });
    if (key === "merchantReferences") {
      if(customerAttribute[key]?.merchantReference1) attributes.push({ label: "Merchant 1", value: customerAttribute[key]?.merchantReference1 });
      if(customerAttribute[key]?.merchantReference2) attributes.push({ label: "Merchant 2", value: customerAttribute[key]?.merchantReference2 });
    }
    return null;
  });
  return attributes;
};
