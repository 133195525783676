import React from "react";
import styled from "styled-components/macro";

import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import QuickFilter from "components/Table/Filter/QuickFilter";
import QuickFilterOption from "components/Table/Filter/QuickFilterOption";
import HoM from "components/Ui/HoM";

const Periods = styled(QuickFilter)`
  display: flex;
  min-height: 0;
  margin-top: 1.5rem;
  width: 100%;

  ${MEDIA_MIN_LARGE} {
    padding: 0;
    justify-content: flex-end;
  }

  > div {
    margin: 0;
    height: 10rem;
    display: flex;
    ${MEDIA_MIN_LARGE} {
      justify-content: flex-end;
      height: auto
    }
  }
`;

const Period = styled(QuickFilterOption)`
  white-space: pre-wrap;
  display:flex;
  justify-content: center;
  width: 8rem;
  
  ${MEDIA_MIN_MEDIUM}{
    margin: 1rem 0 0 1rem;
  }

  ${MEDIA_MIN_LARGE} {
    margin: 1rem 0 0 1rem;
    width: auto;
  }
`;

export default ({ period, periods, setPeriod, customPeriod }) => {
  return (
    <Periods>
      <div>
        <Period active={period.id === "day"} onClick={() => setPeriod(periods.day)}>
          <HoM>Last </HoM>24h
        </Period>
        <Period active={period.id === "week"} onClick={() => setPeriod(periods.week)}>
          <HoM>Last </HoM>7 days
        </Period>
        <Period active={period.id === "month"} onClick={() => setPeriod(periods.month)}>
          <HoM>Last </HoM>30 days
        </Period>
        <Period active={period.id === "quarter"} onClick={() => setPeriod(periods.quarter)}>
          <HoM>Last </HoM>90 days
        </Period>
        <Period active={period.id === "custom"} onClick={() => setPeriod(customPeriod)}>
          <HoM></HoM>Custom
        </Period>
      </div>
    </Periods>
  );
};
