import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import { useNotification } from "context/NotificationContext";
import ActionButtons from "../ActionButtons/ActionButtons";
import ActionButton from "../ActionButtons/ActionButton";
import Dialog from "../Dialog/Dialog";
import Loader from "../Ui/Loader";
import EXTEND_PAYMENT_ORDER from "graphql/Order/ExtendPaymentOrder";
import isKlarnaProvider from "helpers/isKlarnaProvider";
import moment from "moment";

export default function ExtendPayment({ order, refetch }) {
  const [dialog, setDialog] = useState(false);
  const { setNotification } = useNotification();

  if (!order) return null;
  if (!isKlarnaProvider(order)) return null;
  if (moment(order.paymentExpiresAt).isBefore(moment())) return null;

  const openDialog = setDialog.bind(this, true);
  const closeDialog = setDialog.bind(this, false);

  return (
    <>
      <ActionButtons inBox>
        <ActionButton handleOnClick={openDialog}>Extend expiry</ActionButton>
      </ActionButtons>
      <Mutation mutation={EXTEND_PAYMENT_ORDER} refetchQueries={refetch}>
        {(extendExpiry, { loading }) => {
          function onSubmit() {
            extendExpiry({
              variables: {
                id: order.id,
              },
            })
              .then(() => {
                setNotification({
                  status: "success",
                  message: `Payment expiry extended`,
                });
              })
              .catch(() => {
                setNotification({
                  status: "error",
                  message: `Failed to extend payment expiry date`,
                });
              })
              .finally(closeDialog);
          }
          return (
            <Dialog
              open={dialog}
              header="Extend expiry date"
              text="You will extend the expiry date by the number of days dictated in your contract with Klarna."
              handleClose={closeDialog}
              handleOk={onSubmit}>
              {loading && <Loader />}
            </Dialog>
          );
        }}
      </Mutation>
    </>
  );
}
