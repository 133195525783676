import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Attribute from "components/AttributeList/Attribute";
import Input from "components/Ui/Input";
import Value from "components/AttributeList/Value";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const EditButton = styled.button`
  background: transparent;
  border: 0.1rem solid ${(p) => p.theme.colors.opac1};
  cursor: pointer;
  margin-left: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 1.4rem;
  padding: 0.1rem 0 0 0.1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 8rem;
  justify-content: flex-end;
`;

const Label = styled.div`
  width: 20rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const CommentInput = styled(Input)``;
const DeleteButton = styled(EditButton)``;

export default ({ comment, onRemove, onEdit }) => {
  const [editComment, setEditComment] = useState(false);
  const [newComment, setNewComment] = useState("");

  useEffect(() => {
    setNewComment(comment.value);
  }, [comment]);

  return (
    <Attribute>
      <Label>{comment.author}</Label>
      {editComment ? (
        <Container>
          <CommentInput
            defaultValue={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            type="text"></CommentInput>
          <ButtonContainer>
            <EditButton
              onClick={() => {
                onEdit(comment.id, newComment);
                setEditComment(false);
              }}>
              <i className="fal fa-check" />
            </EditButton>
            <EditButton onClick={() => setEditComment()}>
              <i className="fal fa-times" />
            </EditButton>
          </ButtonContainer>
        </Container>
      ) : (
        <Container>
          <Value>{comment.value}</Value>
          <ButtonContainer>
            <EditButton onClick={() => setEditComment(!editComment)}>
              <i className="fal fa-pen" />
            </EditButton>
            <DeleteButton onClick={() => onRemove(comment.id)}>
              <i className="fal fa-trash-alt"></i>
            </DeleteButton>
          </ButtonContainer>
        </Container>
      )}
    </Attribute>
  );
};
