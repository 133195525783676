import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import styled from "styled-components/macro";
import uuid from "react-uuid";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Button from "components/Ui/Button";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import ShippingOptions from "components/Table/Rows";
import ShippingOption from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Ui/Loader";

const Header = styled(TableHeader)`
  padding-left: 5rem;

  ${MEDIA_MIN_LARGE} {
    padding-left: 7rem;
  }
`;

const Image = styled.img`
  width: 3rem;
  margin-left: 1rem;

  ${MEDIA_MIN_LARGE} {
    margin-left: 2rem;
  }
`;

const Name = styled(Column)`
  width: 75%;
  padding: 0 2rem;

  ${MEDIA_MIN_LARGE} {
    width: 30%;
  }
`;

const Category = styled(Column)`
  width: 30%;
`;

const Active = styled(Column)`
  width: 20%;

  .fa-check-circle {
    color: green;
    font-size: 2.4rem;
  }

  .fa-times-circle {
    color: red;
    font-size: 2.4rem;
  }

  ${MEDIA_MIN_LARGE} {
    width: 10%;
  }
`;

const Date = styled(Column)`
  width: 15%;
`;

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};
`;

export default ({ data, loading, optionsPerPage, fetchMore, history, esbQuery }) => {
  const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage);
  const [goToPageInput, setGoToPageInput] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState("down");
  const [sortField, setSortField] = useState("lastUpdated");
  const [shippingOptions, setShippingOptions] = useState(data && data.searchProducts.products);
  const shippingOptionsTotalHits = data && data.searchProducts.products.length;

  useEffect(() => {
    const from = (currentPage - 1) * itemsPerPage;
    try {
      fetchMore({
        variables: { query: JSON.stringify(esbQuery.size(itemsPerPage).from(from).toJSON()) },
        updateQuery: (_, { fetchMoreResult }) => {
          setShippingOptions(fetchMoreResult.searchProducts.products);
        },
      });
    } catch (error) {
      console.error(error);
    }
    setGoToPageInput(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, esbQuery]);

  const handlProductClick = (productId) => {
    history.push(`/admin/shipping-option/${productId.replaceAll("/", "~")}`, { id: productId });
  };

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";
    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  const getSortType = (field, sort) =>
    field === sortField ? sortTypes[sort].class : sortTypes.default.class;

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const toValue = (sortField, obj) => {
    if (Number.isInteger(obj[sortField]) || sortField === "active") {
      return obj[sortField];
    } else {
      return obj[sortField].toLowerCase();
    }
  };

  return (
    <>
      <Toolbar>
        <HoM>
          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={(e) => setCurrentPage(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of {shippingOptionsTotalHits ? Math.ceil(shippingOptionsTotalHits / itemsPerPage) : "-"}{" "}
            pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          Total shipping rules: <span>{shippingOptionsTotalHits || "-"}</span>
        </TotalItems>
      </Toolbar>
      <Header>
        <Name>
          <span>Name</span>
          <SortButton onClick={() => onSortChange("name")}>
            <i className={`fas fa-${getSortType("name", currentSort)}`} />
          </SortButton>
        </Name>
        <Active>
          <span>Active</span>
          <SortButton onClick={() => onSortChange("active")}>
            <i className={`fas fa-${getSortType("active", currentSort)}`} />
          </SortButton>
        </Active>
        <Category hideOnMobile>
          <span>Category</span>
          <SortButton onClick={() => onSortChange("category")}>
            <i className={`fas fa-${getSortType("category", currentSort)}`} />
          </SortButton>
        </Category>
        <Date hideOnMobile>
          <span>Created</span>
          <SortButton onClick={() => onSortChange("created")}>
            <i className={`fas fa-${getSortType("created", currentSort)}`} />
          </SortButton>
        </Date>
        <Date hideOnMobile>
          <span>Last updated</span>
          <SortButton onClick={() => onSortChange("lastUpdated")}>
            <i className={`fas fa-${getSortType("lastUpdated", currentSort)}`} />
          </SortButton>
        </Date>
      </Header>
      <ShippingOptions>
        {loading && <Loader />}
        {shippingOptions && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={data.searchProducts.totalHits}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {[...shippingOptions].sort(sortTypes[currentSort].fn).map((product) => {
              return (
                <ShippingOption
                  key={uuid()}
                  onClick={() => handlProductClick(product.id)}
                  history={history}>
                  <Image src={`${product.imageUrl}?fm=webp&w=150`} alt={product.name} />
                  <Name>
                    <strong>{product.name}</strong>
                  </Name>
                  {product.active ? (
                    <Active>
                      <i className={"fal fa-check-circle"} />
                    </Active>
                  ) : (
                    <Active>
                      <i className={"fal fa-times-circle"} />
                    </Active>
                  )}
                  <Category hideOnMobile>{product.category}</Category>
                  <Date hideOnMobile>
                    <Moment format="YYYY-MM-DD HH:mm">{product.created}</Moment>
                  </Date>
                  <Date hideOnMobile>
                    <Moment format="YYYY-MM-DD HH:mm">{product.lastUpdated}</Moment>
                  </Date>
                </ShippingOption>
              );
            })}
          </Pagination>
        )}
      </ShippingOptions>
    </>
  );
};
