const getFriendlyPaymentName = (paymentMethod) => {
  switch (paymentMethod) {
    case "CreditCard":
      return "Credit card";

    case "AlipayHK":
      return "Alipay - Hong Kong";

    case "KlarnaAccount":
      return "Klarna - Account";

    case "KlarnaPayNow":
      return "Klarna - Pay now";

    case "Unionpay":
      return "UnionPay";

    case "BancontactMobile":
      return "Bancontact Mobile";

    case "Paypal":
      return "PayPal";

    case "Verkkopankki":
      return "Verkkopankki - Finnish Online Banking";

    case "ApplePay":
      return "Apple Pay";

    default:
      return paymentMethod;
  }
};

export default getFriendlyPaymentName;
