import React from "react";
import styled from "styled-components/macro";
import { shadow } from "variables/colors";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import DotLoader from "components/Ui/DotLoader";

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem 1.5rem;
  background: ${(p) => p.theme.colors.secondaryBackground};
  box-shadow: ${shadow};
  transition: transform 0.2s;
  border-radius: 0.6rem;

  h5 {
    color: ${(p) => p.theme.colors.secondaryText};
    margin: 3rem 0 2rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 3rem;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: ${(p) => (p.hideHeader ? "none" : `0.1rem solid ${p.theme.colors.whiteOpac}`)};
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  height: 10rem;
`;

const Icon = styled.div`
  margin-right: 1.5rem;
  width: 4rem;
  height: 4rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.colors.primaryGradient};
  color: ${(p) => p.theme.colors.buttonText};
  border-radius: 3rem;
  border: 0.3rem solid ${(p) => p.theme.colors.black};
  box-shadow: 0 0.2rem 0.8rem 0 rgb(0 0 0 / 15%);
  padding: 0.2rem 0 0 0;

  ${MEDIA_MIN_MEDIUM} {
    width: 6rem;
    height: 6rem;
    font-size: 2rem;
    padding: 0.2rem 0 0 0.1rem;
  }
`;

const PreHeading = styled.h4`
  font-size: 1.2rem;
  text-transform: uppercase;
  width: 100%;
  margin: 0 0 0.5rem;
  color: ${(p) => p.theme.colors.primaryDarker};
  font-weight: 400;
  letter-spacing: 0.15rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.4rem;
  }
`;

const HeadingLoader = styled.div`
  padding: 1rem 0 1rem 1.8rem;
`;

const Heading = styled.h3`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.6rem;
  text-transform: ${(p) => (p.disableUppercaseHeading ? "none" : "uppercase")};
  font-weight: 700;
  letter-spacing: 0.05rem;
  width: 100%;
  margin: 0 0 0.2rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 2rem;
  }

  span {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .fa-check-circle {
    margin-left: 1rem;
    color: green;
  }

  .fa-times-circle {
    margin-left: 1rem;
    color: red;
  }
`;

const SubHeading = styled.span`
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: ${(p) => p.theme.colors.darkerGrey};
  text-transform: initial;
  display: block;
  margin-top: 0.5rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
`;

const Description = styled.p`
  width: 100%;
  margin-top: 1rem;
`;

const Footer = styled.p`
  width: 100%;
  font-size: 1.2rem;
  opacity: 0.6;
  margin-bottom: 0;

  i {
    margin-right: 0.5rem;
  }
`;

const GoBackButton = styled.button`
  width: 7rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  color: ${(p) => p.theme.colors.white};
  border-right: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  margin-right: 2rem;
  padding-right: 1rem;
  font-size: 2rem;
  cursor: pointer;
  ${MEDIA_MIN_MEDIUM} {
    width: 5rem;
    height: 3rem;
  }
`;

export default ({
  children,
  preHeading,
  headingIcon,
  heading,
  subHeading,
  stickyHeader,
  description,
  footer,
  hideHeader,
  showGoBackButton,
  goBackOnClick,
  disableUppercaseHeading,
  headingLoader,
  ...props
}) => (
  <Container {...props}>
    {!hideHeader && (
      <Header hideHeader={hideHeader}>
        {showGoBackButton && (
          <GoBackButton onClick={() => goBackOnClick()}>
            <i className="fal fa-chevron-left"></i>
          </GoBackButton>
        )}
        {headingIcon && (
          <Icon>
            <i className={`fal fa-fw fa-${headingIcon}`}></i>
          </Icon>
        )}
        <div>
          {preHeading && <PreHeading>{preHeading}</PreHeading>}
          {headingLoader ? (
            <HeadingLoader>
              <DotLoader />
            </HeadingLoader>
          ) : (
            heading && (
              <Heading
                disableUppercaseHeading={disableUppercaseHeading}
                stickyHeader={stickyHeader}>
                {heading}
              </Heading>
            )
          )}
          {subHeading && <SubHeading>{subHeading}</SubHeading>}
        </div>
      </Header>
    )}
    {description && <Description>{description}</Description>}
    {children}
    {footer && (
      <Footer>
        <i className="far fa-calendar-alt"></i>
        {footer}
      </Footer>
    )}
  </Container>
);
