import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Container = styled.span`
  display: none;
  pointer-events: none;

  ${MEDIA_MIN_LARGE} {
    display: inline-block;
    pointer-events: all;
  }
`;

export default ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};
