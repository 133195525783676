import React, { useState } from "react";
import styled from "styled-components/macro";
import languages from "@cospired/i18n-iso-languages";
import Tooltip from "components/Ui/Tooltip";
import Input from "components/Ui/Input";
import Button from "../Ui/Button";
import LocaleCode from "locale-code";

const LangCode = styled.div`
  width: 11rem;
  display: flex;
  flex-shrink: 0;
`;

const LangCodeInput = styled(Input)`
  width: 10rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(p) => (p.showTranslation ? "20rem" : 0)};
  overflow: hidden;
  transition: 0.4s ease-in height;
`;

const TranslationContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0;
  align-items: center;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 1rem;
`;

const AddButton = styled(Button)`
  width: 20rem;
  margin: 0;
`;

export default ({ showTranslation, updateTranslation }) => {
  const [langCode, setLangCode] = useState("");
  const [translation, setTranslation] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Invalid code");

  const validCode = (code) =>
    LocaleCode.validateCountryCode(code.replace("_", "-")) || languages.isValid(code);

  const addTranslation = () => {
    if (validCode(langCode)) {
        updateTranslation(langCode,translation );
        setLangCode("");
        setTranslation("");
        setCodeError(false);
    } else {
      setErrorMessage("Invalid code");
      setCodeError(true);
    }
  };

  return (
    <Wrapper showTranslation={showTranslation}>
      <Tooltip />
      <TranslationContainer>
        <LangCode>
          <LangCodeInput
            data-tip="Language code or locale for translation, ex. sv, da or nl-BE, da-DK"
            type="text"
            value={langCode}
            name="langCode"
            label="Locale"
            errorText={errorMessage}
            error={codeError}
            onChange={(e) => setLangCode(e.target.value)}
          />
        </LangCode>
        <Input
          type="text"
          value={translation}
          name="translation"
          label="Translation"
          icon="globe"
          errorText="Error"
          onChange={(e) => setTranslation(e.target.value)}
        />
      </TranslationContainer>
      <ActionContainer>
        <AddButton type="button" onClick={() => addTranslation()}>
          Save Translation
        </AddButton>
      </ActionContainer>
    </Wrapper>
  );
};
