import React from "react";
import styled from "styled-components/macro";
import { Currency } from "../../helpers/money";

const Container = styled.span``;

export default ({ amount, currencyUnit, short = false }) => {
  const Amount = Currency(currencyUnit);
  const money = Amount(amount);
  return <Container>{short ? money.toUnit() : money.toFormat()}</Container>;
};
