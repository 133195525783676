import React, { useState } from "react";
import ActionButton from "./../ActionButtons/ActionButton";
import SET_ORDER_AS_SHIPPED from "graphql/Order/SetOrderAsShipped";
import Loader from "components/Ui/Loader";
import Dialog from "components/Dialog/Dialog";
import { useNotification } from "context/NotificationContext";
import { Mutation } from "@apollo/client/react/components";

export default function MarkOrderAsShipped({ order, refetch }) {
  const { setNotification } = useNotification();
  const [open, setOpen] = useState(false);

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const orderId = order.id;
  return (
    <>
      <ActionButton handleOnClick={() => setOpen(!open)}>
        <i className="fal fa-fw fa-box-check" />
        Mark as shipped
      </ActionButton>
      <Mutation
        mutation={SET_ORDER_AS_SHIPPED}
        variables={{ id: orderId }}
        refetchQueries={refetch}
        onCompleted={({ setOrderAsShipped }) => {
          setOpen(false);
          setNotification({
            status: "success",
            message: `Order "${setOrderAsShipped.reference}" successfully marked as shipped`,
          });
        }}
        onError={() => {
          setNotification({
            status: "error",
            message: `Order could not be could not be marked as shipped, please contact support`,
          });
        }}>
        {(setOrderAsShipped, { loading }) => {
          if (loading) return <Loader />;
          return (
            <Dialog
              header={`Mark order #${order.reference} as shipped`}
              text="An email may be sent on mark order as shipped"
              open={open}
              handleClose={handleClose}
              handleOk={() => setOrderAsShipped()}
            />
          );
        }}
      </Mutation>
    </>
  );
}
