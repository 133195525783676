import React from "react";
import Moment from "react-moment";
import getFriendlyPaymentName from "helpers/getFriendlyPaymentName";
import GridItem from "components/Grid/GridItem";
import Money from "components/Money/Money";
import OrderStatus from "components/Order/OrderStatus";
import GridContainer from "components/Grid/GridContainer";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";

export default ({ order }) => (
  <GridContainer padding="0" collapse>
    <GridItem mobilePadding="1rem 0 0" desktopPadding="1rem 2rem 2rem 0" columns="6">
      <Attributes>
        <Attribute>
          <Label>Order reference:</Label>
          <Value>{order.reference}</Value>
        </Attribute>
        <Attribute>
          <Label> Total order amount:</Label>
          <Money amount={order.orderAmountWithDiscount} currencyUnit={order.currencyUnit} />
        </Attribute>
        <Attribute>
          <Label>Status:</Label>
          <Value>
            <OrderStatus status={order.status} showTitle showIcon />
          </Value>
        </Attribute>
      </Attributes>
    </GridItem>
    <GridItem mobilePadding="0 0 2rem" desktopPadding="1rem 0 2rem 2rem" columns="6">
      <Attributes>
        <Attribute>
          <Label>Order date:</Label>
          <Value>
            <Moment format="YYYY-MM-DD HH:mm">{order.created}</Moment>
          </Value>
        </Attribute>
        <Attribute>
          <Label>Last updated:</Label>
          <Value>
            <Moment format="YYYY-MM-DD HH:mm">{order.lastUpdated}</Moment>
          </Value>
        </Attribute>
        {order.paymentMethod && (
          <Attribute>
            <Label>Payment method:</Label>
            <Value>{getFriendlyPaymentName(order.paymentMethod)}</Value>
          </Attribute>
        )}
      </Attributes>
    </GridItem>
  </GridContainer>
);
