import Link from "components/Ui/Link";
import React from "react";
import styled from "styled-components/macro";

import TableHeader from "components/Table/Header";
import Rows from "components/Table/Rows";
import Addon from "components/Table/Row";
import Placeholder from "components/Ui/PlaceholderImage";
import Column from "components/Table/Column";

const ImageColumn = styled(Column)`
  width: 25%;
  padding: 0 1rem;
`;

const Image = styled.img`
  width: 4rem;
  margin-left: 0;
`;

const Addons = styled(Rows)`
  min-height: 0;
`;

const PlaceholderImage = styled(Placeholder)`
  width: 4rem;
  margin-left: 0;
`;

const Name = styled(Column)`
  width: 75%;
  padding: 0 1rem;
  color: black;
`;

export default function CompatibleAddons({ addons }) {
  if (!addons?.length) return null;

  return (
    <>
      <TableHeader>Compatible add-ons</TableHeader>
      <Addons>
        {addons.map((addon) => (
          <Link key={addon.id} to={`/admin/addon/${addon.id.replaceAll("/", "~")}`}>
            <Addon>
              <ImageColumn>
                {addon.imageUrl ? (
                  <Image src={`${addon.imageUrl}?fm=webp&w=50`} alt={addon.name} />
                ) : (
                  <PlaceholderImage />
                )}
              </ImageColumn>
              <Name>
                <strong>{addon.name}</strong>
              </Name>
            </Addon>
          </Link>
        ))}
      </Addons>
    </>
  );
}
