import gql from "graphql-tag";

const GET_DISCOUNT_RULE = gql`
  query getRule($id: ID!) {
    getRule(id: $id) {
      id
      active
      brinkRule
      created
      lastUpdated
      name
      startDate
      endDate
      ruleTemplate {
        id
        jsonSchema
        handlebarTemplate
      }
      templateData
      type
    }
  }
`;

export default GET_DISCOUNT_RULE;
