import gql from "graphql-tag";

const ADD_OR_UPDATE_CONFIGURATION = gql`
  mutation addOrUpdateConfiguration($service: String!, $name: String!, $data: AWSJSON!) {
    addOrUpdateConfigurations(configurations: { service: $service, name: $name, data: $data }) {
      service
      name
      data
    }
  }
`;

export default ADD_OR_UPDATE_CONFIGURATION;
