import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";

import GET_CONFIGURATION from "graphql/Configuration/GetConfiguration";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Sidebar from "components/Configuration/Sidebar";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Table/Header";
import Webhooks from "components/Table/Rows";
import Webhook from "components/Table/Row";
import Column from "components/Table/Column";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const Content = styled.div`
  width: 100%;

  p {
    margin-bottom: 3rem;
  }

  a {
    color: ${(p) => p.theme.colors.primary};
  }

  form {
    input {
      padding-left: 2rem;
      max-width: 50rem;
    }

    > div {
    }
  }

  h5 {
    margin-bottom: 3rem;
    padding-top: 3.2rem;
  }
`;

const Name = styled(Column)`
  width: 100%;

  ${MEDIA_MIN_LARGE} {
    width: 40%;
  }
`;

const Url = styled(Column)`
  width: 60%;
`;

export default ({ history }) => {
  const [webhooks, setWebhooks] = useState();

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/configurations", "Configurations"],
          ["admin/webhook-product", "Product webhooks"],
        ]}
      />
      <Header heading="Configurations" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query
            query={GET_CONFIGURATION}
            variables={{ service: "brink" }}
            onCompleted={(data) => {
              const productWebhooks = data.getConfiguration.filter((config) =>
                config.name.includes("webhook.product")
              );
              const destructured = productWebhooks.map((webhook) => ({
                name: webhook.name,
                data: JSON.parse(webhook.data),
              }));
              setWebhooks(destructured);
            }}>
            {({ loading: loadingConfig, error: errorConfig }) => {
              if (loadingConfig || !webhooks) return <Loader />;
              if (errorConfig)
                return (
                  <ErrorMessage>
                    An error occurred when loading data, please contact support
                  </ErrorMessage>
                );

              return (
                <GridItem columns="12">
                  <Box
                    preHeading="Webhooks"
                    heading="Product"
                    showGoBackButton
                    goBackOnClick={() => history.push("/admin/configurations")}>
                    <Content>
                      <p>
                        Needs to be activated and configured for your account by one of our admins.{" "}
                        <br /> Please contact{" "}
                        <a href="mailto:support@brinkcommerce.com">support@brinkcommerce.com</a> for
                        assistance.
                      </p>
                      <ActionButtons inBox>
                        <ActionButton
                          handleOnClick={() => history.push(`/admin/add-product-webhook`)}>
                          <i className="fal fa-plus" /> Add
                        </ActionButton>
                      </ActionButtons>
                      {webhooks && (
                        <>
                          <h5>Webhooks</h5>

                          <TableHeader>
                            <Name>Name</Name>
                            <Url hideOnMobile>URL</Url>
                          </TableHeader>
                          <Webhooks>
                            {webhooks.map((webhook) => (
                              <Webhook
                                key={webhook.name}
                                onClick={() =>
                                  history.push(`/admin/product-webhook/${webhook.name}`)
                                }>
                                <Name>{webhook.name}</Name>{" "}
                                <Url hideOnMobile>{webhook.data.url}</Url>
                              </Webhook>
                            ))}
                          </Webhooks>
                        </>
                      )}
                    </Content>
                  </Box>
                </GridItem>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
