import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import DELETE_SHIPPING_OPTION from "graphql/Shipping/ShippingOption/DeleteShippingOption";
import { useNotification } from "context/NotificationContext";
import DeleteButton from "components/ActionButtons/DeleteButton";
import Loader from "components/Ui/Loader";
import Dialog from "components/Dialog/Dialog";

export default ({ id, history, name }) => {
  const [open, setOpen] = useState(false);
  const { setNotification } = useNotification();

  return (
    <Mutation
      mutation={DELETE_SHIPPING_OPTION}
      variables={{ id, deleteMode: "SOFT" }}
      onCompleted={() => {
        setNotification({
          status: "success",
          message: `Shipping option successfully deleted`,
        });
        history.push("/admin/shipping-options");
      }}
      onError={() => {
        setNotification({
          status: "error",
          message: `Shipping option could not be deleted, please contact support`,
        });
      }}>
      {(deleteShippingOption, { loading }) => {
        if (loading) return <Loader />;
        return (
          <>
            <DeleteButton handleOnClick={() => setOpen(true)}>Delete</DeleteButton>
            <Dialog
              header="Delete shipping option"
              text={`Delete shipping option ${name}?`}
              open={open}
              handleClose={() => setOpen(false)}
              handleOk={deleteShippingOption}
            />
          </>
        );
      }}
    </Mutation>
  );
};
