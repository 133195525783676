import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Loader from "../Loader";

const Button = styled.button`
  background: transparent;
  border: 0.1rem solid ${(p) => p.theme.colors.opac1};
  cursor: ${(p) => (p.disabled ? "initial" : "pointer")};
  margin-left: 1.5rem;
  width: ${(p) => (p.large ? "4rem" : "3.4rem")};
  height: ${(p) => (p.large ? "4rem" : "3.4rem")};
  border-radius: ${(p) => (p.large ? "2rem" : "1.7rem")};
  margin-bottom: ${(p) => (p.large ? "0.2rem" : "0.1rem")};
  color: ${(p) => p.theme.colors.white};
  padding: 0.1rem 0 0 0.1rem;
  flex-shrink: 0;
  transition: box-shadow 0.2s;
  box-shadow: none;

  ${MEDIA_MIN_LARGE} {
    &:hover {
      box-shadow: ${(p) => (p.disabled ? "none" : "0 0.2rem 0.8rem 0 rgb(0 0 0 / 15%)")};
    }
  }
`;

const ButtonLoader = styled(Loader)`
  background: none;
`;

const IconButton = ({ children, type, handleOnClick, isLoading, disabled, large, ...props }) => (
  <Button
    onClick={handleOnClick ? (e) => handleOnClick(e) : null}
    type={type || "button"}
    disabled={disabled}
    large={large}
    {...props}>
    {isLoading ? <ButtonLoader /> : children}
  </Button>
);

export default IconButton;
