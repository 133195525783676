import React from "react";
import { MultiSelect } from "react-multi-select-component";
import styled from "styled-components/macro";

const Container = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  .multi-select {
    color: ${(p) => p.theme.constants.black};
    border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
    border-radius: 0.3rem;

    --rmsc-primary: ${(p) => p.theme.constants.black};
    --rmsc-hover: ${(p) => p.theme.colors.backgroundOpac};
    --rmsc-selected: ${(p) => p.theme.colors.inputBorderColor};
    --rmsc-border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
    --rmsc-gray: ${(p) => p.theme.constants.black};
    --rmsc-background: ${(p) => p.theme.colors.inputBackgroundColor};
    --rmsc-spacing: 1.5rem;
    --rmsc-border-radius: 0.3rem;
    --rmsc-height: 4.8rem;

    .dropdown-container {
      background: ${(p) => p.theme.colors.inputBackgroundColor};
    }

    .dropdown-heading {
      cursor: pointer;
      padding-right: 3rem;
      height: 4.8rem;
    }

    .dropdown-heading-dropdown-arrow {
      display: none;
    }

    .dropdown-content {
      width: calc(100% + 0.2rem);
      left: -0.1rem;
    }

    .panel-content {
      overflow-x: hidden;

      .select-panel {
        li,
        > div,
        > label {
          border-bottom: 0.1rem solid #eee;
        }
      }
    }

    input {
      margin-right: 1.5rem;
    }

    span {
      padding: 0;
    }

    .dropdown-container {
      background-position: 97% 50%;
      background-repeat: no-repeat;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);

      &:focus-within {
        border: none;
        box-shadow: none;
      }
    }

    &:focus {
      outline: none;
      background: transparent;
    }

    label {
      color: ${(p) => p.theme.constants.black};
    }
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.8rem;
  display: block;
  font-weight: 700;
`;

export default ({
  options,
  label,
  selected,
  setSelected,
  hasSelectAll = true,
  disableSearch,
  overrideStrings,
  valueRenderer,
  ItemRenderer,
  className,
}) => {
  if (!options) return null;
  return (
    <Container className={className}>
      {label && <Label>{label}</Label>}
      <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy={label || "Select"}
        hasSelectAll={hasSelectAll}
        disableSearch={disableSearch}
        overrideStrings={overrideStrings}
        valueRenderer={valueRenderer}
        ItemRenderer={ItemRenderer}
        ClearSelectedIcon={<></>}
      />
    </Container>
  );
};
