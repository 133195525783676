import React from "react";
import styled from "styled-components/macro";
import Moment from "react-moment";

import { MEDIA_MIN_MEDIUM } from "../../variables/mediaQueries";
import OrderStatus from "components/Order/OrderStatus";

const Container = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  background: #f9f9f9;
  margin: 2.5rem 0 0;

  ${MEDIA_MIN_MEDIUM} {
    margin: 2.5rem 0 2rem;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 1.5rem 0 1.3rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};

  &:last-child {
    border-bottom: none;
  }
`;

const Timestamp = styled.div`
  font-weight: 700;
  width: 12rem;
  flex-shrink: 0;
  letter-spacing: 0;

  ${MEDIA_MIN_MEDIUM} {
    width: 20.8rem;
  }
`;

const Status = styled.div`
  margin-left: 1rem;
`;

export default ({ orderDate, history, ...props }) => {
  return (
    <Container {...props}>
      <List>
        {history &&
          history
            .map((event) => (
              <ListItem key={event.timestamp}>
                <Timestamp>
                  <Moment format="YYYY-MM-DD HH:mm">{event.timestamp}</Moment>:
                </Timestamp>
                <Status>
                  <OrderStatus status={event.status} useTooltipAsTitle />
                </Status>
              </ListItem>
            ))
            .reverse()}
      </List>
    </Container>
  );
};
